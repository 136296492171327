import React, { Component, useEffect} from "react";
import './App.css';
import { BrowserRouter as Router, Route, Switch, Link, Redirect } from "react-router-dom";
import io from "socket.io-client";

//Pages
import ControllerPage from "./components/controller.jsx";
import MsukGridPage from "./components/msukGrid.jsx";
import RotaxGridPage from "./components/rotaxGrid.jsx";
import GridPage from "./components/grid.jsx";
import DriverPage from "./components/driver.jsx";
import Graphics from './components/graphics';
import Editor from "./components/editor.jsx";
import Setup from "./components/setup.jsx";
import Pusher from 'pusher-js';
import QualifyingPage from "./components/qualifying.jsx";
import LiveChampionshipsPage from "./components/liveChampionshipsPage.jsx";
import OnboardView from "./components/Graphics/onboardView";
import WebRTCStreamer from "./components/Controller/webCam";
import NameTest from "./components/nameTest.jsx";
import StatsPage from "./components/statsPage.jsx";

const _ = require('lodash');
const request = require('request')
const today = new Date()
const todayDate = today.getDate() + "/" + (today.getMonth()+1) + "/" + today.getFullYear()


//taken from alphatiming
const alphaPusher = {
  app_id: '46792',
  key: '3aaffebc8193ea83cb2f',
  secret: '9e231b9a9966903ef64a',
  host: "eu"
}

const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});

let series = params.series;
let vmix = params.vmix;
let camera = params.onboard;
let server = params.server;
var socketIP = "https://controls.alphalive.co.uk"
var room = series
var dataSocketIP = ""

if (server == "1"){
    var socketIP = "https://tsl.alphalive.co.uk"
} else if (server == "2") {
    var socketIP = "https://tsl2.alphalive.co.uk"
} else if (server == "3") {
    var socketIP = "https://tsl3.alphalive.co.uk"
} else if (room == "gtws" || room == "gb3" || room == "onside"){
    var socketIP = "https://ts.alphalive.co.uk"
} else if (room == "pscse" || room == "Alkamel"){
    var socketIP = "https://Alkamel.alphalive.co.uk"
	//var socketIP = "https://ts2.alphalive.co.uk"
    //When pscse is on timeservice it is https://ts2.alphalive.co.uk
} else if (room =="akesports"){
    console.log("ASSETTO")
    // dataSocketIP = "http://152.53.2.124:30000"
    // dataSocketIP = "http://127.0.0.1:3000"
    // dataSocketIP = "http://152.53.2.124:30000"
    // dataSocketIP = "http://77.68.119.159:9092"
    dataSocketIP = "https://ac.alphalive.co.uk"

    // dataSocketIP = "http://34.142.74.44:30000"
    
    
}

const socket = io.connect(socketIP);
const dataSocket = io.connect(dataSocketIP)

var replaySocket = null

var HSClockInt = null

console.log(series,vmix,camera)

class App extends Component {
    state = {
        Camera: camera,
        Controls: {
            AutoCol: false,
            IncludePs: false,
            ClockShow: true,
            TowerShow: true,
            ResultsShow: false,
            NotificationShow: false,
            FastestLapShow: false,
            ChampionshipShow: true,
            ChampionshipSelect: 0,
            ComparisonShow: false,
            ComparisonLeadDriver: undefined,
            TowerMargin: [0,0,35,70,105,140,175,210,245,280,315,350,385,420,455,490,525,560,595,630,665,700,735,770,805,840,875,910,945,980,1015,1050,1085,1120,1155,1190,1225,1260,1295,1330,1365,1400,1435,1470,1505,1540,1575,1610,1645,1680,1715,1750,1785,1820,1855,1890,1925,1960,1995,2030,2065,2100,2135,2170,2205,2240,2275,2310,2345,2380,2415,2450,2485,2520,2555,2590,2625,2660,2695,2730,2765,2800,2835,2870,2905,2940,2975,3010,3045,3080,3115,3150,3185,3220],
            ResultsMargin: -100,
            TowerPagesMargin: [-385,-350,-315,-280,-245,-210,-175,-140,-105,-70,-35,0,35,70,105,140,175,210,245,280,315,350,385,420,455,490,525,560,595,630,665,700,735,770,805,840,875,910,945,980,1015,1050,1085,1120,1155,1190,1225,1260,1295,1330,1365,1400,1435,1470,1505,1540,1575,1610,1645,1680,1715,1750,1785,1820,1855,1890,1925,1960,1995,2030,2065,2100,2135,2170,2205,2240,2275,2310,2345,2380,2415,2450,2485,2520,2555,2590,2625,2660,2695,2730,2765,2800,2835,2870,2905,2940,2975,3010,3045,3080,3115,3150,3185,3220],
            CurrentPage: 0,
            BattleShow: false,
            BattleDrivers: [],
            BattlePosition: undefined,
            ProgressionShow: false,
            ProgressionPosition: undefined,
            Col4: "gap",
            Expand: false,
            ExpandDriverID: undefined,
            Onboard: false,
            OnboardDriverID: undefined,
            LiveChampsTitle: "LIVE CHAMPIONSHIP",
            LiveChampsDisplay: "liveChampHide",
            GridPage: 1,
            GridShow: false
        },
        Session:  {
            SessionStartTime: "2022-11-27T15:17:50.9357426+00:00",
            ResultAvailable: true,
            ShowNotifications: true,
            EventId: 123456789,
            SessionId: 123456789,
            SessionName: "No Data",
            SessionType: "Race",
            CircuitMap: "No Data",
            TrackLength: 0,
            TrackName: "No Data",
            Weather: null,
            Temperature: null,
            TrackCondition: null,
            IsTeamEvent: false,
            MultiCls: false,
            ns: null,
            nst: null,
            bn: null,
            bst: null,
            bet: null,
            officials: {
                CompetitionSecretary: "",
                Timekeeper: ""
            },
            dp: 2,
            blt: {
                cn: 11,
                cnpb: "#FF0000",
                cnpf: "#FFFFFF",
                ln: 17,
                tm: 46200
            },
            bs1: {
                cn: 11,
                cnpb: "#FF0000",
                cnpf: "#FFFFFF",
                ln: 17,
                tm: 15060
            },
            bs2: {
                cn: 33,
                cnpb: "#FF0000",
                cnpf: "#FFFFFF",
                ln: 17,
                tm: 31030
            },
            bs3: null,
            tt: null,
            SD: "12m",
            flds: [
                "gd",
                "ps",
                "lpe"
            ],
            nto: -0.04,
            ntst: "2022-11-27T15:33:45.329Z",
            ntip: "132.163.96.2",
            ntsn: "time.nist.gov",
            State: "Ended",
            fcy: false,
            Sequence: 1273,
            Sectors: "3",
            RaceTime: 0,
            Rd: "1L",
            Last: false,
            End: false,
            show_race_time: true,
            TimingSystem: "na",
            Date: todayDate,
            Clear: false,
            Competitors: []
        },
        Races: [],
        TimeService: {
            Equips: [],
            Enrollments: [],
            Drivers: [],
            Categories: [],
        },
        RaceClass:[
                {
                  name: "LMP2",
                  nbc:"#000080",
                  nfc:"#ffffff",
                },{
                  name: "LMP2 AM",
                  nbc: "#ffffff",
                  nfc: "#000080"
                },{
                  name: "LMP3",
                  nbc: "#75bf62",
                  nfc:"#ffffff",
                },{
                  name: "GT",
                  nbc: "#b6610c",
                  nfc:"#ffffff",
                },{
                    name: "GT3",
                    nbc: "#75bf62",
                    nfc:"#ffffff",
                },{
                    name: "GTX",
                    nbc: "#b6610c",
                    nfc:"#ffffff",
                },{
                    name: "Cup 1",
                    nbc: "#bf362b",
                    nfc:"#ffffff",
                },{
                    name: "Cup 2",
                    nbc: "#ff8100",
                    nfc:"#ffffff",
                },{
                    name: "Cup 3",
                    nbc: "#ffff00",
                    nfc:"#ffffff",
                },{
                    name: "Cup 4",
                    nbc: "#7a00ff",
                    nfc:"#ffffff",
                },{
                    name: "PRO",
                    nbc: "#466eb3",
                    nfc:"#ffffff",
                },{
                    name: "AM",
                    nbc: "#bf362b",
                    nfc:"#ffffff",
                },{
                    name: "PRO/AM",
                    nbc: "#0d0e0e",
                    nfc:"#ffffff",
                },{
                    name: "CT",
                    nbc: "#75bf62",
                    nfc:"#ffffff",
                },{
                    name: "Class 4",
                    nbc: "#bf362b",
                    nfc:"#ffffff",
                },{
                    name: "Class N",
                    nbc: "#7a00ff",
                    nfc:"#ffffff",
                },{
                    name: "FWS",
                    nbc: "#a1ca1f",
                    nfc:"#ffffff",
                }
              ],
        RaceClassPSCSE:[
            {
                name: "LMP2",
                nbc:"#d5001c",
                nfc:"#ffffff",
            },{
                name: "LMP2 AM",
                nbc: "#dad9de",
                nfc: "#000000"
            },{
                name: "LMP3",
                nbc: "#d5001c",
                nfc:"#ffffff",
            },{
                name: "GT",
                nbc: "#dad9de",
                nfc:"#000000",
            },            {
                name: "PRO",
                nbc:"#d5001c",
                nfc:"#ffffff",
            },{
                name: "PROAM",
                nbc: "#dad9de",
                nfc: "#000000"
            },{
                name: "PRO-AM",
                nbc: "#dad9de",
                nfc: "#000000"
            },{
                name: "AM",
                nbc: "#d5001c",
                nfc:"#ffffff",
            },{
                name: "GT4",
                nbc: "#dad9de",
                nfc:"#000000",
            },
        ],
        LT:{
            Left: '1300px',
            Top: '900px',
            Position: "",
            Number: "",
            Name:"",
            AdditionalInfo: "",
            nbc: "#0A72F2",
            nfc: "#FFFFFF",
            nat: "GBR"
        },
        OnboardConnection: false,
        ClientStates: {
            TimingName: "alphalive",
            DriverPhotos: false,
            FlSponsor: false,
            TowerSponsor: false,
            TowerHeadHeight: "40px",
            TowerSponsorLogo: "Blank.png",
            TowerSponsorHeight: "35px",
            TowerSponsorWidth: "126.19px",
            PrimaryColour: "",
            SecondaryColour: "",
            ResultsSponsor: false,
            CountryFlags: false,
            DefaultProgression: 4
        },      
        ExampleSession: {
            SessionStartTime: "2022-11-27T15:17:50.9357426+00:00",
            ResultAvailable: true,
            ShowNotifications: true,
            EventId: 157,
            SessionId: 5356,
            SessionName: "Junior Max A Final",
            SessionType: "Race",
            CircuitMap: "whilton_international",
            TrackLength: 1128,
            TrackName: "Whilton Mill",
            Weather: null,
            Temperature: null,
            TrackCondition: null,
            IsTeamEvent: false,
            MultiCls: false,
            ns: null,
            nst: null,
            bn: null,
            bst: null,
            bet: null,
            officials: {
                CompetitionSecretary: "Paul Sirett",
                Timekeeper: "Amy Catchpole"
            },
            dp: 2,
            blt: {
                cn: 11,
                cnpb: "#FF0000",
                cnpf: "#FFFFFF",
                ln: 17,
                tm: 46200
            },
            bs1: {
                cn: 11,
                cnpb: "#FF0000",
                cnpf: "#FFFFFF",
                ln: 17,
                tm: 15060
            },
            bs2: {
                cn: 33,
                cnpb: "#FF0000",
                cnpf: "#FFFFFF",
                ln: 17,
                tm: 31030
            },
            bs3: null,
            tt: null,
            SD: "12m + 1L",
            flds: [
                "gd",
                "ps",
                "lpe"
            ],
            nto: -0.04,
            ntst: "2022-11-27T15:33:45.329Z",
            ntip: "132.163.96.2",
            ntsn: "time.nist.gov",
            State: "Ended",
            fcy: false,
            Sequence: 1273,
            Sectors: "3",
            RaceTime: 0,
            Rd: "17L",
            Last: false,
            End: false,
            show_race_time: true,
            TimingSystem: "wmkc",
            Date: "2022-11-27",
            Clear: false,
            Competitors: [
                {
                    "CompetitorId": 102201,
                    "CompetitorNumber": "999",
                    "CompetitorName": "Finn LESLIE",
                    "CompetitorClass": "JM",
                    "CompetitorSubClass": "",
                    "DriverName": null,
                    "Position": 2,
                    "NumberOfLaps": 17,
                    "LastLaptime": 46360,
                    "RaceTime": 803570,
                    "RunningTime": 803570,
                    "BestLaptime": 46360,
                    "BestLapNumber": 17,
                    "IsBestLapInRace": false,
                    "IsCurrentLapPBestLap": true,
                    "Seen": true,
                    "InPit": false,
                    "Retired": true,
                    "GridPosition": 1,
                    "PositionChange": 0,
                    "Gap": "1.70",
                    "gd": 140,
                    "Behind": "1.70",
                    "Split1": 15250,
                    "Split2": null,
                    "Split3": null,
                    "IsBestSector1": false,
                    "pbs1": false,
                    "IsBestSector2": false,
                    "pbs2": false,
                    "IsBestSector3": false,
                    "pbs3": false,
                    "cs": 2,
                    "el": 46450,
                    "TakenChequered": false,
                    "ocl": true,
                    "clnr": false,
                    "blnr": false,
                    "tt": null,
                    "nat": null,
                    "ps": 1,
                    "lpe": 0,
                    "nbc": "#FF0000",
                    "nfc": "#FFFFFF",
                    "es1": 18810,
                    "es2": 33700
                },
                {
                    "CompetitorId": 102200,
                    "CompetitorNumber": "37",
                    "CompetitorName": "Jessica WHITE",
                    "CompetitorClass": "JM",
                    "CompetitorSubClass": "",
                    "DriverName": null,
                    "Position": 10,
                    "NumberOfLaps": 17,
                    "LastLaptime": 47320,
                    "RaceTime": 817790,
                    "RunningTime": 817790,
                    "BestLaptime": 46860,
                    "BestLapNumber": 9,
                    "IsBestLapInRace": false,
                    "IsCurrentLapPBestLap": false,
                    "Seen": true,
                    "InPit": true,
                    "Retired": false,
                    "GridPosition": 0,
                    "PositionChange": 0,
                    "Gap": "3.56",
                    "gd": 380,
                    "Behind": "15.92",
                    "Split1": 16920,
                    "Split2": null,
                    "Split3": null,
                    "IsBestSector1": false,
                    "pbs1": false,
                    "IsBestSector2": false,
                    "pbs2": false,
                    "IsBestSector3": false,
                    "pbs3": false,
                    "cs": 2,
                    "el": 48510,
                    "TakenChequered": true,
                    "ocl": true,
                    "clnr": false,
                    "blnr": false,
                    "tt": null,
                    "nat": null,
                    "ps": 1,
                    "lpe": 0,
                    "nbc": "#FF0000",
                    "nfc": "#FFFFFF",
                    "es1": 18720,
                    "es2": 34420
                },
                {
                    "CompetitorId": 102202,
                    "CompetitorNumber": "88",
                    "CompetitorName": "Joshua GRAHAM",
                    "CompetitorClass": "JM",
                    "CompetitorSubClass": "",
                    "DriverName": null,
                    "Position": 7,
                    "NumberOfLaps": 17,
                    "LastLaptime": 47910,
                    "RaceTime": 813120,
                    "RunningTime": 813120,
                    "BestLaptime": 46620,
                    "BestLapNumber": 13,
                    "IsBestLapInRace": false,
                    "IsCurrentLapPBestLap": false,
                    "Seen": true,
                    "InPit": true,
                    "Retired": false,
                    "GridPosition": 2,
                    "PositionChange": 0,
                    "Gap": "0.29",
                    "gd": 210,
                    "Behind": "11.25",
                    "Split1": 16800,
                    "Split2": null,
                    "Split3": null,
                    "IsBestSector1": false,
                    "pbs1": false,
                    "IsBestSector2": false,
                    "pbs2": false,
                    "IsBestSector3": false,
                    "pbs3": false,
                    "cs": 2,
                    "el": 49100,
                    "TakenChequered": true,
                    "ocl": true,
                    "clnr": false,
                    "blnr": false,
                    "tt": null,
                    "nat": null,
                    "ps": 1,
                    "lpe": 0,
                    "nbc": "#FF0000",
                    "nfc": "#FFFFFF",
                    "es1": 20450,
                    "es2": 34090
                },
                {
                    "CompetitorId": 102203,
                    "CompetitorNumber": "11",
                    "CompetitorName": "Firstname LASTNAME",
                    "CompetitorClass": "JM",
                    "CompetitorSubClass": "",
                    "DriverName": null,
                    "Position": 3,
                    "NumberOfLaps": 17,
                    "LastLaptime": 46200,
                    "RaceTime": 803840,
                    "RunningTime": 803840,
                    "BestLaptime": 46200,
                    "BestLapNumber": 17,
                    "IsBestLapInRace": true,
                    "IsCurrentLapPBestLap": true,
                    "Seen": true,
                    "InPit": true,
                    "Retired": false,
                    "GridPosition": 3,
                    "PositionChange": 0,
                    "Gap": "0.27",
                    "gd": -160,
                    "Behind": "1.97",
                    "Split1": 15290,
                    "Split2": null,
                    "Split3": null,
                    "IsBestSector1": false,
                    "pbs1": false,
                    "IsBestSector2": false,
                    "pbs2": false,
                    "IsBestSector3": false,
                    "pbs3": false,
                    "cs": 2,
                    "el": 46430,
                    "TakenChequered": true,
                    "ocl": true,
                    "clnr": false,
                    "blnr": false,
                    "tt": null,
                    "nat": null,
                    "ps": 1,
                    "lpe": 0,
                    "nbc": "#FF0000",
                    "nfc": "#FFFFFF",
                    "es1": 18980,
                    "es2": 33950
                },
                {
                    "CompetitorId": 102205,
                    "CompetitorNumber": "53",
                    "CompetitorName": "William ANTROBUS",
                    "CompetitorClass": "JM",
                    "CompetitorSubClass": "",
                    "DriverName": null,
                    "Position": 6,
                    "NumberOfLaps": 17,
                    "LastLaptime": 47700,
                    "RaceTime": 812830,
                    "RunningTime": 812830,
                    "BestLaptime": 46610,
                    "BestLapNumber": 12,
                    "IsBestLapInRace": false,
                    "IsCurrentLapPBestLap": false,
                    "Seen": true,
                    "InPit": true,
                    "Retired": false,
                    "GridPosition": 4,
                    "PositionChange": 0,
                    "Gap": "1.23",
                    "gd": 690,
                    "Behind": "10.96",
                    "Split1": 16690,
                    "Split2": null,
                    "Split3": null,
                    "IsBestSector1": false,
                    "pbs1": false,
                    "IsBestSector2": false,
                    "pbs2": false,
                    "IsBestSector3": false,
                    "pbs3": false,
                    "cs": 2,
                    "el": 48790,
                    "TakenChequered": true,
                    "ocl": true,
                    "clnr": false,
                    "blnr": false,
                    "tt": null,
                    "nat": null,
                    "ps": 1,
                    "lpe": 0,
                    "nbc": "#FF0000",
                    "nfc": "#FFFFFF",
                    "es1": 19200,
                    "es2": 34690
                },
                {
                    "CompetitorId": 102206,
                    "CompetitorNumber": "33",
                    "CompetitorName": "Leo BROWN",
                    "CompetitorClass": "JM",
                    "CompetitorSubClass": "",
                    "DriverName": null,
                    "Position": 1,
                    "NumberOfLaps": 17,
                    "LastLaptime": 46220,
                    "RaceTime": 801870,
                    "RunningTime": 801870,
                    "BestLaptime": 46210,
                    "BestLapNumber": 14,
                    "IsBestLapInRace": false,
                    "IsCurrentLapPBestLap": false,
                    "Seen": true,
                    "InPit": true,
                    "Retired": false,
                    "GridPosition": 5,
                    "PositionChange": 0,
                    "Gap": "",
                    "gd": 0,
                    "Behind": "",
                    "Split1": 15880,
                    "Split2": null,
                    "Split3": null,
                    "IsBestSector1": false,
                    "pbs1": false,
                    "IsBestSector2": false,
                    "pbs2": false,
                    "IsBestSector3": false,
                    "pbs3": false,
                    "cs": 2,
                    "el": 46910,
                    "TakenChequered": true,
                    "ocl": true,
                    "clnr": false,
                    "blnr": false,
                    "tt": null,
                    "nat": null,
                    "ps": 1,
                    "lpe": 0,
                    "nbc": "#FF0000",
                    "nfc": "#FFFFFF",
                    "es1": 19280,
                    "es2": 34240
                },
                {
                    "Battle": true,
                    "CompetitorId": 102208,
                    "CompetitorNumber": "65",
                    "CompetitorName": "Spencer BROUGHAM",
                    "CompetitorClass": "JM",
                    "CompetitorSubClass": "",
                    "DriverName": null,
                    "Position": 5,
                    "NumberOfLaps": 17,
                    "LastLaptime": 47010,
                    "RaceTime": 811600,
                    "RunningTime": 811600,
                    "BestLaptime": 46660,
                    "BestLapNumber": 12,
                    "IsBestLapInRace": false,
                    "IsCurrentLapPBestLap": false,
                    "Seen": true,
                    "InPit": true,
                    "Retired": false,
                    "GridPosition": 7,
                    "PositionChange": 0,
                    "Gap": "0.40",
                    "gd": 120,
                    "Behind": "9.73",
                    "Split1": 16610,
                    "Split2": null,
                    "Split3": null,
                    "IsBestSector1": false,
                    "pbs1": false,
                    "IsBestSector2": false,
                    "pbs2": false,
                    "IsBestSector3": false,
                    "pbs3": false,
                    "cs": 2,
                    "el": 48120,
                    "TakenChequered": true,
                    "ocl": true,
                    "clnr": false,
                    "blnr": false,
                    "tt": null,
                    "nat": null,
                    "ps": 1,
                    "lpe": 0,
                    "nbc": "#FF0000",
                    "nfc": "#FFFFFF",
                    "es1": 19490,
                    "es2": 34300
                },
                {
                    "CompetitorId": 102207,
                    "CompetitorNumber": "89",
                    "CompetitorName": "Deacon RUSSELL",
                    "CompetitorClass": "JM",
                    "CompetitorSubClass": "",
                    "DriverName": null,
                    "Position": 9,
                    "NumberOfLaps": 17,
                    "LastLaptime": 46940,
                    "RaceTime": 814230,
                    "RunningTime": 814230,
                    "BestLaptime": 46650,
                    "BestLapNumber": 14,
                    "IsBestLapInRace": false,
                    "IsCurrentLapPBestLap": false,
                    "Seen": true,
                    "InPit": true,
                    "Retired": false,
                    "GridPosition": 6,
                    "PositionChange": 0,
                    "Gap": "0.24",
                    "gd": -40,
                    "Behind": "12.36",
                    "Split1": 16660,
                    "Split2": null,
                    "Split3": null,
                    "IsBestSector1": false,
                    "pbs1": false,
                    "IsBestSector2": false,
                    "pbs2": false,
                    "IsBestSector3": false,
                    "pbs3": false,
                    "cs": 2,
                    "el": 48410,
                    "TakenChequered": true,
                    "ocl": true,
                    "clnr": false,
                    "blnr": false,
                    "tt": null,
                    "nat": null,
                    "ps": 1,
                    "lpe": 0,
                    "nbc": "#FF0000",
                    "nfc": "#FFFFFF",
                    "es1": 19600,
                    "es2": 35570
                },
                {
                    "CompetitorId": 102210,
                    "CompetitorNumber": "14",
                    "CompetitorName": "Arthur THACKER",
                    "CompetitorClass": "JM",
                    "CompetitorSubClass": "",
                    "DriverName": null,
                    "Position": 11,
                    "NumberOfLaps": 17,
                    "LastLaptime": 46880,
                    "RaceTime": 822770,
                    "RunningTime": 822770,
                    "BestLaptime": 46880,
                    "BestLapNumber": 17,
                    "IsBestLapInRace": false,
                    "IsCurrentLapPBestLap": true,
                    "Seen": true,
                    "InPit": true,
                    "Retired": false,
                    "GridPosition": 9,
                    "PositionChange": 0,
                    "Gap": "4.98",
                    "gd": -440,
                    "Behind": "20.90",
                    "Split1": 15730,
                    "Split2": null,
                    "Split3": null,
                    "IsBestSector1": false,
                    "pbs1": false,
                    "IsBestSector2": false,
                    "pbs2": false,
                    "IsBestSector3": false,
                    "pbs3": false,
                    "cs": 2,
                    "el": 47170,
                    "TakenChequered": true,
                    "ocl": true,
                    "clnr": false,
                    "blnr": false,
                    "tt": null,
                    "nat": null,
                    "ps": 1,
                    "lpe": 0,
                    "nbc": "#FF0000",
                    "nfc": "#FFFFFF",
                    "es1": 19950,
                    "es2": 35370
                },
                {
                    "CompetitorId": 102212,
                    "CompetitorNumber": "44",
                    "CompetitorName": "Archie BUTTLE",
                    "CompetitorClass": "JM",
                    "CompetitorSubClass": "",
                    "DriverName": null,
                    "Position": 18,
                    "NumberOfLaps": 17,
                    "LastLaptime": 49400,
                    "RaceTime": 829500,
                    "RunningTime": 829500,
                    "BestLaptime": 46710,
                    "BestLapNumber": 16,
                    "IsBestLapInRace": false,
                    "IsCurrentLapPBestLap": false,
                    "Seen": true,
                    "InPit": false,
                    "Retired": false,
                    "GridPosition": 11,
                    "PositionChange": 0,
                    "Gap": "0.84",
                    "gd": 600,
                    "Behind": "27.63",
                    "Split1": 15380,
                    "Split2": 34020,
                    "Split3": null,
                    "IsBestSector1": false,
                    "pbs1": false,
                    "IsBestSector2": false,
                    "pbs2": false,
                    "IsBestSector3": false,
                    "pbs3": false,
                    "cs": 1,
                    "el": 49400,
                    "TakenChequered": true,
                    "ocl": true,
                    "clnr": false,
                    "blnr": false,
                    "tt": null,
                    "nat": null,
                    "ps": 0,
                    "lpe": 0,
                    "nbc": "#FF0000",
                    "nfc": "#FFFFFF",
                    "es1": 21180,
                    "es2": 39690
                },
                {
                    "CompetitorId": 102214,
                    "CompetitorNumber": "83",
                    "CompetitorName": "Alfie RICHARDS",
                    "CompetitorClass": "JM",
                    "CompetitorSubClass": "",
                    "DriverName": null,
                    "Position": 8,
                    "NumberOfLaps": 17,
                    "LastLaptime": 46980,
                    "RaceTime": 813990,
                    "RunningTime": 813990,
                    "BestLaptime": 46760,
                    "BestLapNumber": 12,
                    "IsBestLapInRace": false,
                    "IsCurrentLapPBestLap": false,
                    "Seen": true,
                    "InPit": true,
                    "Retired": false,
                    "GridPosition": 13,
                    "PositionChange": 0,
                    "Gap": "0.87",
                    "gd": -930,
                    "Behind": "12.12",
                    "Split1": 16420,
                    "Split2": null,
                    "Split3": null,
                    "IsBestSector1": false,
                    "pbs1": false,
                    "IsBestSector2": false,
                    "pbs2": false,
                    "IsBestSector3": false,
                    "pbs3": false,
                    "cs": 2,
                    "el": 48150,
                    "TakenChequered": true,
                    "ocl": true,
                    "clnr": false,
                    "blnr": false,
                    "tt": null,
                    "nat": null,
                    "ps": 1,
                    "lpe": 0,
                    "nbc": "#FF0000",
                    "nfc": "#FFFFFF",
                    "es1": 20230,
                    "es2": 35700
                },
                {
                    "CompetitorId": 102209,
                    "CompetitorNumber": "41",
                    "CompetitorName": "Zac HESLOP",
                    "CompetitorClass": "JM",
                    "CompetitorSubClass": "",
                    "DriverName": null,
                    "Position": 17,
                    "NumberOfLaps": 17,
                    "LastLaptime": 48800,
                    "RaceTime": 828660,
                    "RunningTime": 828660,
                    "BestLaptime": 47140,
                    "BestLapNumber": 16,
                    "IsBestLapInRace": false,
                    "IsCurrentLapPBestLap": false,
                    "Seen": true,
                    "InPit": true,
                    "Retired": false,
                    "GridPosition": 8,
                    "PositionChange": 0,
                    "Gap": "1.57",
                    "gd": 30,
                    "Behind": "26.79",
                    "Split1": 16740,
                    "Split2": null,
                    "Split3": null,
                    "IsBestSector1": false,
                    "pbs1": false,
                    "IsBestSector2": false,
                    "pbs2": false,
                    "IsBestSector3": false,
                    "pbs3": false,
                    "cs": 2,
                    "el": 50160,
                    "TakenChequered": true,
                    "ocl": true,
                    "clnr": false,
                    "blnr": false,
                    "tt": null,
                    "nat": null,
                    "ps": 1,
                    "lpe": 0,
                    "nbc": "#FF0000",
                    "nfc": "#FFFFFF",
                    "es1": 19330,
                    "es2": 35680
                },
                {
                    "CompetitorId": 102216,
                    "CompetitorNumber": "30",
                    "CompetitorName": "Joseph MCMAHON",
                    "CompetitorClass": "JM",
                    "CompetitorSubClass": "",
                    "DriverName": null,
                    "Position": 20,
                    "NumberOfLaps": 17,
                    "LastLaptime": 48490,
                    "RaceTime": 830200,
                    "RunningTime": 830200,
                    "BestLaptime": 46710,
                    "BestLapNumber": 16,
                    "IsBestLapInRace": false,
                    "IsCurrentLapPBestLap": false,
                    "Seen": true,
                    "InPit": false,
                    "Retired": false,
                    "GridPosition": 15,
                    "PositionChange": 0,
                    "Gap": "0.43",
                    "gd": -230,
                    "Behind": "28.33",
                    "Split1": 15300,
                    "Split2": 33190,
                    "Split3": null,
                    "IsBestSector1": false,
                    "pbs1": false,
                    "IsBestSector2": false,
                    "pbs2": false,
                    "IsBestSector3": false,
                    "pbs3": false,
                    "cs": 1,
                    "el": 48490,
                    "TakenChequered": true,
                    "ocl": true,
                    "clnr": false,
                    "blnr": false,
                    "tt": null,
                    "nat": null,
                    "ps": 0,
                    "lpe": 0,
                    "nbc": "#FF0000",
                    "nfc": "#FFFFFF",
                    "es1": 20300,
                    "es2": 35710
                },
                {
                    "Battle": true,
                    "CompetitorId": 102218,
                    "CompetitorNumber": "73",
                    "CompetitorName": "Reg HEYWOOD",
                    "CompetitorClass": "JM",
                    "CompetitorSubClass": "",
                    "DriverName": null,
                    "Position": 4,
                    "NumberOfLaps": 17,
                    "LastLaptime": 46890,
                    "RaceTime": 811200,
                    "RunningTime": 811200,
                    "BestLaptime": 46410,
                    "BestLapNumber": 12,
                    "IsBestLapInRace": false,
                    "IsCurrentLapPBestLap": false,
                    "Seen": true,
                    "InPit": true,
                    "Retired": false,
                    "GridPosition": 17,
                    "PositionChange": 0,
                    "Gap": "7.36",
                    "gd": 690,
                    "Behind": "9.33",
                    "Split1": 15610,
                    "Split2": null,
                    "Split3": null,
                    "IsBestSector1": false,
                    "pbs1": false,
                    "IsBestSector2": false,
                    "pbs2": false,
                    "IsBestSector3": false,
                    "pbs3": false,
                    "cs": 2,
                    "el": 46980,
                    "TakenChequered": true,
                    "ocl": true,
                    "clnr": false,
                    "blnr": false,
                    "tt": null,
                    "nat": null,
                    "ps": 1,
                    "lpe": 0,
                    "nbc": "#FF0000",
                    "nfc": "#FFFFFF",
                    "es1": 19780,
                    "es2": 35130
                },
                {
                    "CompetitorId": 102211,
                    "CompetitorNumber": "48",
                    "CompetitorName": "George ALLEN",
                    "CompetitorClass": "JM",
                    "CompetitorSubClass": "",
                    "DriverName": null,
                    "Position": 31,
                    "NumberOfLaps": 9,
                    "LastLaptime": 46760,
                    "RaceTime": 440560,
                    "RunningTime": 440560,
                    "BestLaptime": 46760,
                    "BestLapNumber": 9,
                    "IsBestLapInRace": false,
                    "IsCurrentLapPBestLap": true,
                    "Seen": true,
                    "InPit": true,
                    "Retired": false,
                    "GridPosition": 10,
                    "PositionChange": 0,
                    "Gap": "7 Laps",
                    "gd": 0,
                    "Behind": "8 Laps",
                    "Split1": 15620,
                    "Split2": null,
                    "Split3": null,
                    "IsBestSector1": false,
                    "pbs1": false,
                    "IsBestSector2": false,
                    "pbs2": false,
                    "IsBestSector3": false,
                    "pbs3": false,
                    "cs": 2,
                    "el": 47010,
                    "TakenChequered": true,
                    "ocl": false,
                    "clnr": false,
                    "blnr": false,
                    "tt": null,
                    "nat": null,
                    "ps": 1,
                    "lpe": 0,
                    "nbc": "#FF0000",
                    "nfc": "#FFFFFF",
                    "es1": 19620,
                    "es2": 35850
                },
                {
                    "CompetitorId": 102220,
                    "CompetitorNumber": "27",
                    "CompetitorName": "William REA",
                    "CompetitorClass": "JM",
                    "CompetitorSubClass": "",
                    "DriverName": null,
                    "Position": 19,
                    "NumberOfLaps": 17,
                    "LastLaptime": 48720,
                    "RaceTime": 829770,
                    "RunningTime": 829770,
                    "BestLaptime": 46700,
                    "BestLapNumber": 16,
                    "IsBestLapInRace": false,
                    "IsCurrentLapPBestLap": false,
                    "Seen": true,
                    "InPit": false,
                    "Retired": false,
                    "GridPosition": 19,
                    "PositionChange": 0,
                    "Gap": "0.27",
                    "gd": -680,
                    "Behind": "27.90",
                    "Split1": 15290,
                    "Split2": 33430,
                    "Split3": null,
                    "IsBestSector1": false,
                    "pbs1": true,
                    "IsBestSector2": false,
                    "pbs2": false,
                    "IsBestSector3": false,
                    "pbs3": false,
                    "cs": 1,
                    "el": 48720,
                    "TakenChequered": true,
                    "ocl": true,
                    "clnr": false,
                    "blnr": false,
                    "tt": null,
                    "nat": null,
                    "ps": 0,
                    "lpe": 0,
                    "nbc": "#FF0000",
                    "nfc": "#FFFFFF",
                    "es1": 20200,
                    "es2": 35770
                },
                {
                    "CompetitorId": 102213,
                    "CompetitorNumber": "99",
                    "CompetitorName": "William PEMBLE",
                    "CompetitorClass": "JM",
                    "CompetitorSubClass": "",
                    "DriverName": null,
                    "Position": 23,
                    "NumberOfLaps": 17,
                    "LastLaptime": 46500,
                    "RaceTime": 833930,
                    "RunningTime": 833930,
                    "BestLaptime": 46500,
                    "BestLapNumber": 17,
                    "IsBestLapInRace": false,
                    "IsCurrentLapPBestLap": true,
                    "Seen": true,
                    "InPit": false,
                    "Retired": false,
                    "GridPosition": 12,
                    "PositionChange": 0,
                    "Gap": "0.21",
                    "gd": -360,
                    "Behind": "32.06",
                    "Split1": 15190,
                    "Split2": 31310,
                    "Split3": null,
                    "IsBestSector1": false,
                    "pbs1": false,
                    "IsBestSector2": false,
                    "pbs2": true,
                    "IsBestSector3": false,
                    "pbs3": false,
                    "cs": 1,
                    "el": 46500,
                    "TakenChequered": true,
                    "ocl": true,
                    "clnr": false,
                    "blnr": false,
                    "tt": null,
                    "nat": null,
                    "ps": 0,
                    "lpe": 0,
                    "nbc": "#FF0000",
                    "nfc": "#FFFFFF",
                    "es1": 20740,
                    "es2": 41450
                },
                {
                    "CompetitorId": 102215,
                    "CompetitorNumber": "42",
                    "CompetitorName": "Stefan KACZMARCZYK",
                    "CompetitorClass": "JM",
                    "CompetitorSubClass": "",
                    "DriverName": null,
                    "Position": 14,
                    "NumberOfLaps": 17,
                    "LastLaptime": 47000,
                    "RaceTime": 825010,
                    "RunningTime": 825010,
                    "BestLaptime": 46780,
                    "BestLapNumber": 15,
                    "IsBestLapInRace": false,
                    "IsCurrentLapPBestLap": false,
                    "Seen": true,
                    "InPit": true,
                    "Retired": false,
                    "GridPosition": 14,
                    "PositionChange": 0,
                    "Gap": "0.40",
                    "gd": 70,
                    "Behind": "23.14",
                    "Split1": 15710,
                    "Split2": null,
                    "Split3": null,
                    "IsBestSector1": false,
                    "pbs1": false,
                    "IsBestSector2": false,
                    "pbs2": false,
                    "IsBestSector3": false,
                    "pbs3": false,
                    "cs": 2,
                    "el": 47490,
                    "TakenChequered": true,
                    "ocl": true,
                    "clnr": false,
                    "blnr": false,
                    "tt": null,
                    "nat": null,
                    "ps": 1,
                    "lpe": 0,
                    "nbc": "#FF0000",
                    "nfc": "#FFFFFF",
                    "es1": 20220,
                    "es2": 36320
                },
                {
                    "CompetitorId": 102222,
                    "CompetitorNumber": "59",
                    "CompetitorName": "Gabriel CULLEN",
                    "CompetitorClass": "JM",
                    "CompetitorSubClass": "",
                    "DriverName": null,
                    "Position": 21,
                    "NumberOfLaps": 17,
                    "LastLaptime": 47290,
                    "RaceTime": 830750,
                    "RunningTime": 830750,
                    "BestLaptime": 46990,
                    "BestLapNumber": 12,
                    "IsBestLapInRace": false,
                    "IsCurrentLapPBestLap": false,
                    "Seen": true,
                    "InPit": false,
                    "Retired": false,
                    "GridPosition": 21,
                    "PositionChange": 0,
                    "Gap": "0.55",
                    "gd": -1200,
                    "Behind": "28.88",
                    "Split1": 15540,
                    "Split2": 31750,
                    "Split3": null,
                    "IsBestSector1": false,
                    "pbs1": false,
                    "IsBestSector2": false,
                    "pbs2": false,
                    "IsBestSector3": false,
                    "pbs3": false,
                    "cs": 1,
                    "el": 47290,
                    "TakenChequered": true,
                    "ocl": true,
                    "clnr": false,
                    "blnr": false,
                    "tt": null,
                    "nat": null,
                    "ps": 0,
                    "lpe": 0,
                    "nbc": "#FF0000",
                    "nfc": "#FFFFFF",
                    "es1": 20430,
                    "es2": 38070
                },
                {
                    "CompetitorId": 102217,
                    "CompetitorNumber": "34",
                    "CompetitorName": "Jacob JUKES",
                    "CompetitorClass": "JM",
                    "CompetitorSubClass": "",
                    "DriverName": null,
                    "Position": 32,
                    "NumberOfLaps": 4,
                    "LastLaptime": 48340,
                    "RaceTime": 213210,
                    "RunningTime": 213210,
                    "BestLaptime": 48340,
                    "BestLapNumber": 4,
                    "IsBestLapInRace": false,
                    "IsCurrentLapPBestLap": true,
                    "Seen": true,
                    "InPit": true,
                    "Retired": false,
                    "GridPosition": 16,
                    "PositionChange": 0,
                    "Gap": "5 Laps",
                    "gd": 0,
                    "Behind": "13 Laps",
                    "Split1": 15790,
                    "Split2": null,
                    "Split3": null,
                    "IsBestSector1": false,
                    "pbs1": true,
                    "IsBestSector2": false,
                    "pbs2": false,
                    "IsBestSector3": false,
                    "pbs3": false,
                    "cs": 2,
                    "el": 48100,
                    "TakenChequered": true,
                    "ocl": false,
                    "clnr": false,
                    "blnr": false,
                    "tt": null,
                    "nat": null,
                    "ps": 1,
                    "lpe": 0,
                    "nbc": "#FF0000",
                    "nfc": "#FFFFFF",
                    "es1": 21140,
                    "es2": 39930
                },
                {
                    "CompetitorId": 102219,
                    "CompetitorNumber": "127",
                    "CompetitorName": "James TESTER",
                    "CompetitorClass": "JM",
                    "CompetitorSubClass": "",
                    "DriverName": null,
                    "Position": 30,
                    "NumberOfLaps": 16,
                    "LastLaptime": 49590,
                    "RaceTime": 815080,
                    "RunningTime": 815080,
                    "BestLaptime": 47520,
                    "BestLapNumber": 9,
                    "IsBestLapInRace": false,
                    "IsCurrentLapPBestLap": false,
                    "Seen": true,
                    "InPit": true,
                    "Retired": false,
                    "GridPosition": 18,
                    "PositionChange": 0,
                    "Gap": "1 Lap",
                    "gd": 2560,
                    "Behind": "1 Lap",
                    "Split1": 16730,
                    "Split2": null,
                    "Split3": null,
                    "IsBestSector1": false,
                    "pbs1": false,
                    "IsBestSector2": false,
                    "pbs2": false,
                    "IsBestSector3": false,
                    "pbs3": false,
                    "cs": 2,
                    "el": 50710,
                    "TakenChequered": true,
                    "ocl": true,
                    "clnr": false,
                    "blnr": false,
                    "tt": null,
                    "nat": null,
                    "ps": 1,
                    "lpe": 0,
                    "nbc": "#FF0000",
                    "nfc": "#FFFFFF",
                    "es1": 20130,
                    "es2": 36380
                },
                {
                    "CompetitorId": 102221,
                    "CompetitorNumber": "52",
                    "CompetitorName": "Timo JUNGLING",
                    "CompetitorClass": "JM",
                    "CompetitorSubClass": "",
                    "DriverName": null,
                    "Position": 12,
                    "NumberOfLaps": 17,
                    "LastLaptime": 46710,
                    "RaceTime": 824070,
                    "RunningTime": 824070,
                    "BestLaptime": 46710,
                    "BestLapNumber": 17,
                    "IsBestLapInRace": false,
                    "IsCurrentLapPBestLap": true,
                    "Seen": true,
                    "InPit": true,
                    "Retired": false,
                    "GridPosition": 20,
                    "PositionChange": 0,
                    "Gap": "1.30",
                    "gd": -170,
                    "Behind": "22.20",
                    "Split1": 15550,
                    "Split2": null,
                    "Split3": null,
                    "IsBestSector1": false,
                    "pbs1": false,
                    "IsBestSector2": false,
                    "pbs2": false,
                    "IsBestSector3": false,
                    "pbs3": false,
                    "cs": 2,
                    "el": 47000,
                    "TakenChequered": true,
                    "ocl": true,
                    "clnr": false,
                    "blnr": false,
                    "tt": null,
                    "nat": null,
                    "ps": 1,
                    "lpe": 0,
                    "nbc": "#FF0000",
                    "nfc": "#FFFFFF",
                    "es1": 20780,
                    "es2": 38710
                },
                {
                    "CompetitorId": 102224,
                    "CompetitorNumber": "222",
                    "CompetitorName": "Oliver KERR",
                    "CompetitorClass": "JM",
                    "CompetitorSubClass": "",
                    "DriverName": null,
                    "Position": 16,
                    "NumberOfLaps": 17,
                    "LastLaptime": 48770,
                    "RaceTime": 827090,
                    "RunningTime": 827090,
                    "BestLaptime": 46890,
                    "BestLapNumber": 14,
                    "IsBestLapInRace": false,
                    "IsCurrentLapPBestLap": false,
                    "Seen": true,
                    "InPit": true,
                    "Retired": false,
                    "GridPosition": 23,
                    "PositionChange": -1,
                    "Gap": "0.11",
                    "gd": 270,
                    "Behind": "25.22",
                    "Split1": 16580,
                    "Split2": null,
                    "Split3": null,
                    "IsBestSector1": false,
                    "pbs1": false,
                    "IsBestSector2": false,
                    "pbs2": false,
                    "IsBestSector3": false,
                    "pbs3": false,
                    "cs": 2,
                    "el": 49480,
                    "TakenChequered": true,
                    "ocl": true,
                    "clnr": false,
                    "blnr": false,
                    "tt": null,
                    "nat": null,
                    "ps": 1,
                    "lpe": 0,
                    "nbc": "#FF0000",
                    "nfc": "#FFFFFF",
                    "es1": 20870,
                    "es2": 38750
                },
                {
                    "CompetitorId": 102223,
                    "CompetitorNumber": "155",
                    "CompetitorName": "Mitchell MULVEY",
                    "CompetitorClass": "JM",
                    "CompetitorSubClass": "",
                    "DriverName": null,
                    "Position": 33,
                    "NumberOfLaps": 2,
                    "LastLaptime": 55430,
                    "RaceTime": 116340,
                    "RunningTime": 116340,
                    "BestLaptime": 55430,
                    "BestLapNumber": 2,
                    "IsBestLapInRace": false,
                    "IsCurrentLapPBestLap": true,
                    "Seen": true,
                    "InPit": true,
                    "Retired": false,
                    "GridPosition": 22,
                    "PositionChange": 0,
                    "Gap": "2 Laps",
                    "gd": 0,
                    "Behind": "15 Laps",
                    "Split1": 18020,
                    "Split2": null,
                    "Split3": null,
                    "IsBestSector1": false,
                    "pbs1": true,
                    "IsBestSector2": false,
                    "pbs2": false,
                    "IsBestSector3": false,
                    "pbs3": false,
                    "cs": 2,
                    "el": 54570,
                    "TakenChequered": true,
                    "ocl": false,
                    "clnr": false,
                    "blnr": false,
                    "tt": null,
                    "nat": null,
                    "ps": 1,
                    "lpe": 0,
                    "nbc": "#FF0000",
                    "nfc": "#FFFFFF",
                    "es1": 20020,
                    "es2": 38790
                },
                {
                    "CompetitorId": 102226,
                    "CompetitorNumber": "38",
                    "CompetitorName": "George SPILSBURY",
                    "CompetitorClass": "JM",
                    "CompetitorSubClass": "",
                    "DriverName": null,
                    "Position": 29,
                    "NumberOfLaps": 17,
                    "LastLaptime": 47510,
                    "RaceTime": 846670,
                    "RunningTime": 846670,
                    "BestLaptime": 47030,
                    "BestLapNumber": 16,
                    "IsBestLapInRace": false,
                    "IsCurrentLapPBestLap": false,
                    "Seen": true,
                    "InPit": false,
                    "Retired": false,
                    "GridPosition": 25,
                    "PositionChange": 0,
                    "Gap": "2.77",
                    "gd": -120,
                    "Behind": "44.80",
                    "Split1": 15900,
                    "Split2": 31610,
                    "Split3": null,
                    "IsBestSector1": false,
                    "pbs1": false,
                    "IsBestSector2": false,
                    "pbs2": false,
                    "IsBestSector3": false,
                    "pbs3": false,
                    "cs": 1,
                    "el": 47510,
                    "TakenChequered": true,
                    "ocl": true,
                    "clnr": false,
                    "blnr": false,
                    "tt": null,
                    "nat": null,
                    "ps": 0,
                    "lpe": 0,
                    "nbc": "#FF0000",
                    "nfc": "#FFFFFF",
                    "es1": 20890,
                    "es2": 39620
                },
                {
                    "CompetitorId": 102225,
                    "CompetitorNumber": "12",
                    "CompetitorName": "Matas MIEZANSKAS",
                    "CompetitorClass": "JM",
                    "CompetitorSubClass": "",
                    "DriverName": null,
                    "Position": 13,
                    "NumberOfLaps": 17,
                    "LastLaptime": 46930,
                    "RaceTime": 824610,
                    "RunningTime": 824610,
                    "BestLaptime": 46930,
                    "BestLapNumber": 17,
                    "IsBestLapInRace": false,
                    "IsCurrentLapPBestLap": true,
                    "Seen": true,
                    "InPit": true,
                    "Retired": false,
                    "GridPosition": 24,
                    "PositionChange": 0,
                    "Gap": "0.54",
                    "gd": 220,
                    "Behind": "22.74",
                    "Split1": 15350,
                    "Split2": null,
                    "Split3": null,
                    "IsBestSector1": false,
                    "pbs1": false,
                    "IsBestSector2": false,
                    "pbs2": false,
                    "IsBestSector3": false,
                    "pbs3": false,
                    "cs": 2,
                    "el": 47070,
                    "TakenChequered": true,
                    "ocl": true,
                    "clnr": false,
                    "blnr": false,
                    "tt": null,
                    "nat": null,
                    "ps": 1,
                    "lpe": 0,
                    "nbc": "#FF0000",
                    "nfc": "#FFFFFF",
                    "es1": 19700,
                    "es2": 37250
                },
                {
                    "CompetitorId": 102228,
                    "CompetitorNumber": "75",
                    "CompetitorName": "Harrison MORROW",
                    "CompetitorClass": "JM",
                    "CompetitorSubClass": "",
                    "DriverName": null,
                    "Position": 28,
                    "NumberOfLaps": 17,
                    "LastLaptime": 47630,
                    "RaceTime": 843900,
                    "RunningTime": 843900,
                    "BestLaptime": 47480,
                    "BestLapNumber": 16,
                    "IsBestLapInRace": false,
                    "IsCurrentLapPBestLap": false,
                    "Seen": true,
                    "InPit": false,
                    "Retired": false,
                    "GridPosition": 27,
                    "PositionChange": 0,
                    "Gap": "4.38",
                    "gd": 670,
                    "Behind": "42.03",
                    "Split1": 15500,
                    "Split2": 32130,
                    "Split3": null,
                    "IsBestSector1": false,
                    "pbs1": true,
                    "IsBestSector2": false,
                    "pbs2": false,
                    "IsBestSector3": false,
                    "pbs3": false,
                    "cs": 1,
                    "el": 47630,
                    "TakenChequered": true,
                    "ocl": true,
                    "clnr": false,
                    "blnr": false,
                    "tt": null,
                    "nat": null,
                    "ps": 0,
                    "lpe": 0,
                    "nbc": "#FF0000",
                    "nfc": "#FFFFFF",
                    "es1": 21660,
                    "es2": 39730
                },
                {
                    "CompetitorId": 102227,
                    "CompetitorNumber": "15",
                    "CompetitorName": "Joshua SMITH",
                    "CompetitorClass": "JM",
                    "CompetitorSubClass": "",
                    "DriverName": null,
                    "Position": 15,
                    "NumberOfLaps": 17,
                    "LastLaptime": 48500,
                    "RaceTime": 826980,
                    "RunningTime": 826980,
                    "BestLaptime": 46610,
                    "BestLapNumber": 15,
                    "IsBestLapInRace": false,
                    "IsCurrentLapPBestLap": false,
                    "Seen": true,
                    "InPit": true,
                    "Retired": false,
                    "GridPosition": 26,
                    "PositionChange": 1,
                    "Gap": "1.97",
                    "gd": 1500,
                    "Behind": "25.11",
                    "Split1": 16490,
                    "Split2": null,
                    "Split3": null,
                    "IsBestSector1": false,
                    "pbs1": false,
                    "IsBestSector2": false,
                    "pbs2": false,
                    "IsBestSector3": false,
                    "pbs3": false,
                    "cs": 2,
                    "el": 49360,
                    "TakenChequered": true,
                    "ocl": true,
                    "clnr": false,
                    "blnr": false,
                    "tt": null,
                    "nat": null,
                    "ps": 1,
                    "lpe": 0,
                    "nbc": "#FF0000",
                    "nfc": "#FFFFFF",
                    "es1": 20270,
                    "es2": 38320
                },
                {
                    "CompetitorId": 102435,
                    "CompetitorNumber": "131",
                    "CompetitorName": "Zaki HUSSAIN",
                    "CompetitorClass": "JM",
                    "CompetitorSubClass": "",
                    "DriverName": null,
                    "Position": 22,
                    "NumberOfLaps": 17,
                    "LastLaptime": 46860,
                    "RaceTime": 833720,
                    "RunningTime": 833720,
                    "BestLaptime": 46820,
                    "BestLapNumber": 16,
                    "IsBestLapInRace": false,
                    "IsCurrentLapPBestLap": false,
                    "Seen": true,
                    "InPit": false,
                    "Retired": false,
                    "GridPosition": 30,
                    "PositionChange": 0,
                    "Gap": "2.97",
                    "gd": -430,
                    "Behind": "31.85",
                    "Split1": 15290,
                    "Split2": 31570,
                    "Split3": null,
                    "IsBestSector1": false,
                    "pbs1": true,
                    "IsBestSector2": false,
                    "pbs2": false,
                    "IsBestSector3": false,
                    "pbs3": false,
                    "cs": 1,
                    "el": 46860,
                    "TakenChequered": true,
                    "ocl": true,
                    "clnr": false,
                    "blnr": false,
                    "tt": null,
                    "nat": null,
                    "ps": 0,
                    "lpe": 0,
                    "nbc": "#FF0000",
                    "nfc": "#FFFFFF",
                    "es1": 19670,
                    "es2": 41260
                },
                {
                    "CompetitorId": 102229,
                    "CompetitorNumber": "76",
                    "CompetitorName": "Freddie INGRAM",
                    "CompetitorClass": "JM",
                    "CompetitorSubClass": "",
                    "DriverName": null,
                    "Position": 25,
                    "NumberOfLaps": 17,
                    "LastLaptime": 47770,
                    "RaceTime": 836260,
                    "RunningTime": 836260,
                    "BestLaptime": 47230,
                    "BestLapNumber": 16,
                    "IsBestLapInRace": false,
                    "IsCurrentLapPBestLap": false,
                    "Seen": true,
                    "InPit": false,
                    "Retired": false,
                    "GridPosition": 28,
                    "PositionChange": -1,
                    "Gap": "0.31",
                    "gd": 480,
                    "Behind": "34.39",
                    "Split1": 15900,
                    "Split2": 31870,
                    "Split3": null,
                    "IsBestSector1": false,
                    "pbs1": false,
                    "IsBestSector2": false,
                    "pbs2": false,
                    "IsBestSector3": false,
                    "pbs3": false,
                    "cs": 1,
                    "el": 47770,
                    "TakenChequered": true,
                    "ocl": true,
                    "clnr": false,
                    "blnr": false,
                    "tt": null,
                    "nat": null,
                    "ps": 0,
                    "lpe": 0,
                    "nbc": "#FF0000",
                    "nfc": "#FFFFFF",
                    "es1": 19980,
                    "es2": 39580
                },
                {
                    "CompetitorId": 102437,
                    "CompetitorNumber": "114",
                    "CompetitorName": "Harry HURST-GROVER",
                    "CompetitorClass": "JM",
                    "CompetitorSubClass": "",
                    "DriverName": null,
                    "Position": 26,
                    "NumberOfLaps": 17,
                    "LastLaptime": 47480,
                    "RaceTime": 838110,
                    "RunningTime": 838110,
                    "BestLaptime": 47400,
                    "BestLapNumber": 11,
                    "IsBestLapInRace": false,
                    "IsCurrentLapPBestLap": false,
                    "Seen": true,
                    "InPit": false,
                    "Retired": false,
                    "GridPosition": 32,
                    "PositionChange": 0,
                    "Gap": "1.85",
                    "gd": -290,
                    "Behind": "36.24",
                    "Split1": 15590,
                    "Split2": 31890,
                    "Split3": null,
                    "IsBestSector1": false,
                    "pbs1": false,
                    "IsBestSector2": false,
                    "pbs2": false,
                    "IsBestSector3": false,
                    "pbs3": false,
                    "cs": 1,
                    "el": 47480,
                    "TakenChequered": true,
                    "ocl": true,
                    "clnr": false,
                    "blnr": false,
                    "tt": null,
                    "nat": null,
                    "ps": 0,
                    "lpe": 0,
                    "nbc": "#FF0000",
                    "nfc": "#FFFFFF",
                    "es1": 20280,
                    "es2": 39750
                },
                {
                    "CompetitorId": 102436,
                    "CompetitorNumber": "54",
                    "CompetitorName": "Blake NOBLE",
                    "CompetitorClass": "JM",
                    "CompetitorSubClass": "",
                    "DriverName": null,
                    "Position": 24,
                    "NumberOfLaps": 17,
                    "LastLaptime": 47290,
                    "RaceTime": 835950,
                    "RunningTime": 835950,
                    "BestLaptime": 46960,
                    "BestLapNumber": 13,
                    "IsBestLapInRace": false,
                    "IsCurrentLapPBestLap": false,
                    "Seen": true,
                    "InPit": false,
                    "Retired": false,
                    "GridPosition": 31,
                    "PositionChange": 1,
                    "Gap": "2.02",
                    "gd": 790,
                    "Behind": "34.08",
                    "Split1": 15510,
                    "Split2": 31780,
                    "Split3": null,
                    "IsBestSector1": false,
                    "pbs1": false,
                    "IsBestSector2": false,
                    "pbs2": false,
                    "IsBestSector3": false,
                    "pbs3": false,
                    "cs": 1,
                    "el": 47290,
                    "TakenChequered": true,
                    "ocl": true,
                    "clnr": false,
                    "blnr": false,
                    "tt": null,
                    "nat": null,
                    "ps": 0,
                    "lpe": 0,
                    "nbc": "#FF0000",
                    "nfc": "#FFFFFF",
                    "es1": 20210,
                    "es2": 39640
                },
                {
                    "CompetitorId": 102438,
                    "CompetitorNumber": "77",
                    "CompetitorName": "Ramarion UBHI",
                    "CompetitorClass": "JM",
                    "CompetitorSubClass": "",
                    "DriverName": null,
                    "Position": 27,
                    "NumberOfLaps": 17,
                    "LastLaptime": 46960,
                    "RaceTime": 839520,
                    "RunningTime": 839520,
                    "BestLaptime": 46960,
                    "BestLapNumber": 17,
                    "IsBestLapInRace": false,
                    "IsCurrentLapPBestLap": true,
                    "Seen": true,
                    "InPit": false,
                    "Retired": false,
                    "GridPosition": 33,
                    "PositionChange": 0,
                    "Gap": "1.41",
                    "gd": -520,
                    "Behind": "37.65",
                    "Split1": 15510,
                    "Split2": 31450,
                    "Split3": null,
                    "IsBestSector1": false,
                    "pbs1": false,
                    "IsBestSector2": false,
                    "pbs2": true,
                    "IsBestSector3": false,
                    "pbs3": false,
                    "cs": 1,
                    "el": 46960,
                    "TakenChequered": true,
                    "ocl": true,
                    "clnr": false,
                    "blnr": false,
                    "tt": null,
                    "nat": null,
                    "ps": 0,
                    "lpe": 0,
                    "nbc": "#FF0000",
                    "nfc": "#FFFFFF",
                    "es1": 20410,
                    "es2": 39660
                }
            ]
        },
        Notifications: [],
        NotificationSequence: undefined,
        NotificationSelected: 0,
        ExampleChampionship: [{
            "Position":1,
            "Driver":"CARL BOARDLEY",
            "Total Points":334,
            "DroppedPoints":29,
            "LicencePoints":0,
            "PointsToCount":305,
            "PointsChange":0,
            "PositionChange":0
        },{
            "Position":2,
            "Driver":"BRUCE WINFIELD",
            "Total Points":272,
            "DroppedPoints":0,
            "LicencePoints":9,
            "PointsToCount":263,
            "PointsChange":3,
            "PositionChange":0
        },{
            "Position":3,
            "Driver":"ALEX LEY",
            "TotalPoints":247,
            "DroppedPoints":0,
            "LicencePoints":15,
            "PointsToCount":232,
            "PointsChange":6,
            "PositionChange":2
        },{
            "Position":4,
            "Driver":"JENSON BRICKLEY",
            "Total Points":280,
            "DroppedPoints":30,
            "LicencePoints":18,
            "PointsToCount":232,
            "PointsChange":5,
            "PositionChange":-1
        },{
            "Position":5,
            "Driver":"JAC CONSTABLE",
            "TotalPoints":236,
            "DroppedPoints":0,
            "LicencePoints":9,
            "PointsToCount":227,
            "PointsChange":0,
            "PositionChange":-1
        },{
            "Position":6,
            "Driver":"ADAM SHEPHERD",
            "TotalPoints":223,
            "DroppedPoints":0,
            "LicencePoints":0,
            "PointsToCount":223,
            "PointsChange":0,
            "PositionChange":0
        }]
    }

      // Function to handle key presses
    handleKeyPress = (event) => {
        var controls = this.state.Controls
        if(event.key == "F22" || event.key == "PageDown"){
            controls.GridPage = controls.GridPage + 1
        } else if (event.key == "F23" || event.key == "PageUp"){
            if(controls.GridPage > 1){
                controls.GridPage = controls.GridPage -1
            }
        }

        this.setState({Controls: controls})
        console.log('Key pressed:', event.key); // Optional: Log the key press to the console
    };

    componentDidMount() {
        request("https://data.alphalive.co.uk/post/stream/check?timingInfo="+series, (error, response, json) => {
                var json = JSON.parse(json)
                if(json[0].preset_list){
                    var preset = JSON.parse(json[0].preset_list)
                } else {
                    var preset = null
                }
                
                json = JSON.parse(json[0].timing_states)
                console.log("clientStates", json);
                this.setState({
                    ClientStates: json
                })
                console.log(json.TimingSystem)
                if (json.TimingSystem == "Apex" || json.TimingSystem == "ApexTest"){
                
                    setInterval(() => this.pages(), 15000);
                    setInterval(() => this.clockOn(), 500);
                    setInterval(() => this.resetPosChange(), 10000);
                    setInterval(() => this.fastestLapCheck(), 9150);
                    setInterval(() => this.notificationCheck(), 10000);
                    
                    //Apex Timing 
                    if(json.TimingSystem == "Apex"){

                        if ("WebSocket" in window) {
                            var websocket = new WebSocket('wss://apex.alphalive.co.uk'); //proxy through alpha server to add SSL certificate
                            // var websocket = new WebSocket('ws://www.apex-timing.com:7566/'); //motorsport timing UK
                            // var websocket = new WebSocket('ws://www.apex-timing.com:7637/'); //Koridas (RMCGF)// server.js
const net = require('net');
const { Server } = require("socket.io");
const http = require('http');

// Create an HTTP server for the socket.io server
const httpServer = http.createServer();
const io = new Server(httpServer, {
  cors: {
    origin: "*", // Allow CORS for all origins, adjust as needed
    methods: ["GET", "POST"]
  }
});

// Create a TCP server
const tcpServer = net.createServer((socket) => {
  console.log('TCP client connected');

  // Event listener for receiving data
  socket.on('data', (data) => {
    try {
      // Decode the bytes to a string and parse as JSON
      const jsonString = data.toString();
      const jsonData = JSON.parse(jsonString);

      // Log the parsed JSON data
      console.log('Received JSON data:', jsonData);

      // Broadcast the data to all clients in the 'akesports' room
      io.to('akesports').emit('message', jsonData);
      console.log('Broadcasted to room akesports');
    } catch (err) {
      console.error('Failed to parse JSON:', err.message);
    }
  });

  // Event listener for client disconnection
  socket.on('end', () => {
    console.log('TCP client disconnected');
  });

  // Handle any errors
  socket.on('error', (err) => {
    console.error('Socket error:', err.message);
  });
});

// Start the TCP server and listen on port 9090
tcpServer.listen(9090, '127.0.0.1', () => {
  console.log('TCP server is listening on port 9090');
});

// Set up the socket.io server
io.on('connection', (socket) => {
  console.log('Socket.io client connected');

  // Join the 'akesports' room
  socket.join('akesports');
  console.log(`Client joined room akesports`);

  // Handle client disconnection
  socket.on('disconnect', () => {
    console.log('Socket.io client disconnected');
  });
});

// Start the HTTP server for socket.io
httpServer.listen(9092, () => {
  console.log('Socket.io server is listening on port 9092');
});

                        }
                        else if ("MozWebSocket" in window) {
                            // var websocket = new MozWebSocket('ws://www.apex-timing.com:7566/');
                        }
                        else {
                            console.log("Web socket not found");
                            return;
                        }
                        websocket.onopen = () => {
                        console.log("Connected");
                        // websocket_connected = true;
                        };
                        websocket.onmessage = evt => {
                            var split = evt.data.split("\n");
                            this.apexMessage(split)
                        };
                        websocket.onerror = evt => {
                            console.log("Error");
                        };
                        websocket.onclose = () =>  {
                            console.log('Closed');
                            // websocket_connected = false;
                        };
                        } else {
                        // console.log("Replay Apex")
                        replaySocket = io.connect('https://replay.alphalive.co.uk');
                        replaySocket.emit("join_room", "replay");

                        replaySocket.on("receive_message", (evt) => {
                            // console.log(evt)
                            var split = evt.evt.split("\n");
                            this.apexMessage(split)
                        })
                    }

                } else if (json.TimingSystem == "TSL"){
                    room = 'TSL'
                    
                    if(series == "Darkside"){
                        room = "Darkside"
                    }
                    
                    setInterval(() => this.tslClockOn(), 500);
                    setInterval(() => this.notificationCheck(), 10000);
                    setInterval(() => this.pages(), 15000);
                    setInterval(() => this.fastestLapCheck(), 10000);
                // } else if (json.TimingSystem == "HS" || json.TimingSystem == "Speedhive"){
                } else if (json.TimingSystem == "HS"){
                    setInterval(() => this.pages(), 15000);
                    setInterval(() => this.fastestLapCheck(), 10000);
                } else if (json.TimingSystem == "Speedhive"){
                    setInterval(() => this.pages(), 15000);
                    setInterval(() => this.fastestLapCheck(), 10000);
                } else if (json.TimingSystem == "rMonitor"){
                    setInterval(() => this.pages(), 15000);
                    setInterval(() => this.fastestLapCheck(), 10000);
                } else if (json.TimingSystem == "TimeService"){
                    room = 'TimeService'
                    setInterval(() => this.timeServiceClockOn(), 500);
                    setInterval(() => this.pages(), 15000);
                    setInterval(() => this.fastestLapCheck(), 10000);

                } else if (json.TimingSystem == "Alkamel"){
					this.setState({
						RaceClass:[
							{
								name: "LMP2",
								nbc:"#d5001c",
								nfc:"#ffffff",
							},{
								name: "LMP2 AM",
								nbc: "#dad9de",
								nfc: "#000000"
							},{
								name: "LMP3",
								nbc: "#d5001c",
								nfc:"#ffffff",
							},{
								name: "GT",
								nbc: "#dad9de",
								nfc:"#000000",
							},            {
								name: "PRO",
								nbc:"#d5001c",
								nfc:"#ffffff",
							},{
								name: "PROAM",
								nbc: "#dad9de",
								nfc: "#000000"
							},{
								name: "PRO-AM",
								nbc: "#dad9de",
								nfc: "#000000"
							},{
								name: "AM",
								nbc: "#d5001c",
								nfc:"#ffffff",
							},{
								name: "GT4",
								nbc: "#dad9de",
								nfc:"#000000",
							},
						],
					})
                    room = 'Alkamel'
                    setInterval(() => this.pages(), 15000);
                    
                } else if (json.TimingSystem == "AC"){
                    console.log("ASSETTO")

                    dataSocket.on("message", (data) => {
                        // console.log(data)
                        this.acLocalMessage(data)
                    })

                    // setInterval(() => this.tslClockOn(), 500);
                } else if (json.TimingSystem == "AC2"){
                    console.log("ASSETTO")

                    dataSocket.emit('get_session_info')

                    dataSocket.emit('enable_realtime_report', 100)
                    
                    dataSocket.on("new_session", (data) => {
                        console.log(data)
                        data.type = "new_session"
                        this.acMessage(data)
                    })
                    dataSocket.on("car_info", (data) => {
                        console.log(data)
                        data.type = "car_info"
                        this.acMessage(data)
                    })
                    dataSocket.on("session_info", (data) => {
                        console.log(data)
                        data.type = "session_info"
                        this.acMessage(data)
                    })
                    dataSocket.on("server_error", (data) => {
                        console.log(data)
                        data.type = "server_error"
                        this.acMessage(data)
                    })
                    dataSocket.on("chat", (data) => {
                        console.log(data)
                        data.type = "chat"
                        this.acMessage(data)
                    })
                    dataSocket.on("car_update", (data) => {
                        console.log(data)
                        data.type = "car_update"
                        this.acMessage(data)
                    })
                    dataSocket.on("lap_completed", (data) => {
                        console.log(data)
                        data.type = "lap_completed"
                        this.acMessage(data)
                    })
                    dataSocket.on("client_loaded", (data) => {
                        console.log(data)
                        data.type = "client_loaded"
                        this.acMessage(data)
                    })
                    dataSocket.on("connection_closed", (data) => {
                        console.log(data)
                        data.type = "connection_closed"
                        this.acMessage(data)
                    })

                    dataSocket.on("received_message", (data) => {
                        console.log(data)
                        this.acMessage(data)
                    })

                    if(preset){
                        console.log(preset)
                        this.setState({
                            PresetList: preset
                        })
                    } else {
                        console.log(preset)
                    }

                    setInterval(() => this.tslClockOn(), 500);
                } else {
                // Alpha Timing
                setInterval(() => this.pages(), 15000);
                setInterval(() => this.clockOn(), 500);
                setInterval(() => this.resetPosChange(), 10000);
                setInterval(() => this.notificationCheck(), 10000);
                setInterval(() => this.fastestLapCheck(), 10000);
                setInterval(() => this.automaticCol(), 10000);

                this.getInitialState()

                this.pusher = new Pusher(alphaPusher.key, {
                app_id: alphaPusher.app_id,
                cluster: alphaPusher.host,
                secret: alphaPusher.secret,
                encrypted: true
                })

                this.setState({
                    vmix : vmix,
                    camera : camera,
                })

                this.channel = this.pusher.subscribe(series+"prod")
                this.channel2 = this.pusher.subscribe(series+"prod-notifications")
                this.channel.bind("update", this.messageEventHandler)
                this.channel.bind("new_session", this.messageEventHandler)
                this.channel.bind("clear", this.messageEventHandler)
                this.channel2.bind("update", this.notificationHandler)
                // this.channel2.bind("new_session", this.notificationHandler)
                // this.channel2.bind("clear", this.notificationHandler)    
                
                this.pusher.log = function(message) {
                    if (window.console && window.console.log) window.console.log(message);
                };
                }
                
                console.log(room)
                socket.emit("join_room", room);
                })  
                

        //THIS IS WHERE THE SOCKET.IO MESSAGES COME IN
        socket.on("receive_message", (data) => {
			console.log(data)
            // console.log(data.message)
            // console.log(data.author)
            // console.log(data.type)
            var controls = this.state.Controls
            var session = this.state.Session
            var races = this.state.Races
            
            if (data.author == "Server"){
                var messageJSON = JSON.parse(data.message)
                console.log(data.type, messageJSON)
                if (this.state.ClientStates.TimingSystem == "TSL"){
                    switch(data.type){
                    case "DATA ActiveSession":
                        console.log("ActiveSession")
                        console.log(JSON.parse(data.message))
                        if (messageJSON.LengthTime && messageJSON.LengthTime != "0.000"){
                            var sd = this.msRacetime(messageJSON.LengthTime/1000)
                        }

                        if (messageJSON.LengthLaps){
                            var sdLaps = messageJSON.LengthLaps
                            if (sd){
                                sd = sd + " + " + sdLaps + "L"
                            } else {
                                var sd = sdLaps+"Laps"
                                var timeRemaining = messageJSON.LapsRemaining + "L" || "0"
                            }
                        }

                        if (messageJSON.State){
                            switch(messageJSON.State){
                                case "Pending":
                                    var raceState = "Formation"
                                    break
                                case "Active":
                                    var raceState = "Formation"
                                    break
                                case "Green":
                                    var raceState = "Running"
                                    break
                                case "Yellow":
                                    var raceState = "Running"
                                    var fcy = true
                                    break
                                case "FCY":
                                    var raceState = "Running"
                                    var fcy = true
                                    break
                                case "Red":
                                    var raceState = "RedFlag"
                                    break
                                case "Finished":
                                    var raceState = "ChequeredFlag"
                                    break
                                case "Complete":
                                    var ended = true
                                    break
                            }
                        }

                        if (messageJSON.Type){
                            session.SessionType = messageJSON.Type
                        }

                        if (session.SessionId != messageJSON.ID){
                            var competitors = []
                            session.blt = null
                            
                            controls = {
                                AutoCol: false,
                                IncludePs: false,
                                ClockShow: true,
                                TowerShow: true,
                                ResultsShow: false,
                                NotificationShow: false,
                                FastestLapShow: false,
                                ChampionshipShow: true,
                                ChampionshipSelect: 0,
                                ComparisonShow: false,
                                ComparisonLeadDriver: undefined,
                                TowerMargin: [0,0,35,70,105,140,175,210,245,280,315,350,385,420,455,490,525,560,595,630,665,700,735,770,805,840,875,910,945,980,1015,1050,1085,1120,1155,1190,1225,1260,1295,1330,1365,1400,1435,1470,1505,1540,1575,1610,1645,1680,1715,1750,1785,1820,1855,1890,1925,1960,1995,2030,2065,2100,2135,2170,2205,2240,2275,2310,2345,2380,2415,2450,2485,2520,2555,2590,2625,2660,2695,2730,2765,2800,2835,2870,2905,2940,2975,3010,3045,3080,3115,3150,3185,3220],
                                ResultsMargin: -100,
                                TowerPagesMargin: [-385,-350,-315,-280,-245,-210,-175,-140,-105,-70,-35,0,35,70,105,140,175,210,245,280,315,350,385,420,455,490,525,560,595,630,665,700,735,770,805,840,875,910,945,980,1015,1050,1085,1120,1155,1190,1225,1260,1295,1330,1365,1400,1435,1470,1505,1540,1575,1610,1645,1680,1715,1750,1785,1820,1855,1890,1925,1960,1995,2030,2065,2100,2135,2170,2205,2240,2275,2310,2345,2380,2415,2450,2485,2520,2555,2590,2625,2660,2695,2730,2765,2800,2835,2870,2905,2940,2975,3010,3045,3080,3115,3150,3185,3220],
                                CurrentPage: 0,
                                BattleShow: false,
                                BattleDrivers: [],
                                BattlePosition: undefined,
                                ProgressionShow: false,
                                ProgressionPosition: undefined,
                                Col4: "gap",
                                LiveChampsDisplay: 'liveChampHide',
                                GridPage: 1,
                                GridShow: false
                            }

                        } else {
                            var competitors = session.Competitors
                        }

                        if (session.blt != null){
                            var blt = session.blt
                        } else {
                            var blt = null
                        }

                            session = {
                                SessionStartTime: messageJSON.ActualStart,
                                ResultAvailable: null,
                                ShowNotifications: null,
                                EventId: messageJSON.ID,
                                ServerTime: session.ServerTime || 0,
                                SessionId: messageJSON.ID,
                                SessionName: messageJSON.Name,
                                SessionType: messageJSON.Type,
                                SessionTimeRemaining: timeRemaining || session.TimeRemaining || 0,
                                CircuitMap: messageJSON.TrackName,
                                TrackLength: messageJSON.TrackLength,
                                TrackName: messageJSON.TrackDisplayName,
                                tn: messageJSON.TrackDisplayName,
                                Weather: messageJSON.WeatherConditions,
                                Temperature: null,
                                TrackCondition: messageJSON.TrackConditions,
                                IsTeamEvent: null,
                                MultiCls: messageJSON.Classes,
                                ns: null,
                                nst: null,
                                bn: null,
                                bst: null,
                                bet: null,
                                officials: {
                                    CompetitionSecretary: null,
                                    Timekeeper: null,
                                },
                                dp: null,
                                blt: blt,
                                bs1: null,
                                bs2: null,
                                bs3: null,
                                tt: null,
                                LengthTime: messageJSON.LengthTime,
                                SD: sd ,
                                flds: [
                                    "gd",
                                    "ps",
                                    "lpe"
                                ],
                                nto: -0.04,
                                ntst: "2022-11-27T15:33:45.329Z",
                                ntip: "132.163.96.2",
                                ntsn: "time.nist.gov",
                                State: raceState || messageJSON.State,
                                fcy: fcy || false,
                                Sequence: null,
                                Sectors: session.Sectors || null,
                                RaceTime: 0,
                                Rd: timeRemaining || messageJSON.TimeRemaining || session.TimeRemaining || 0,
                                Last: false,
                                End: ended || false,
                                show_race_time: false,
                                TimingSystem: null,
                                Time: null,
                                Date: null,
                                Clear: false,
                                Competitors: competitors,
                                TSLGrouped: false || messageJSON.IsGrouped || session.IsGrouped || false,
                                TSLGroupedType: messageJSON.GroupType || session.GroupType || null,
                                TSLGroupedFL: messageJSON.GroupFastLapTime || session.GroupFastLapTime || null,
                                TSLGroupedFLCID: messageJSON.GroupFastLapCompetitorID || session.GroupFastLapCompetitorID || null,
                        }

                        // if(messageJSON.IsGrouped){
                        //     session.TSLGrouped = messageJSON.IsGrouped
                        //     session.TSLGroupedType = messageJSON.GroupType || null
                        //     session.TSLGroupedFL = messageJSON.GroupFastLapTime || null
                        //     session.TSLGroupedFLCID = messageJSON.GroupFastLapCompetitorID || null
                        // } else {
                        //     session.TSLGrouped = false
                        //     session.TSLGroupedType = null
                        //     session.TSLGroupedFL = null
                        //     session.TSLGroupedFLCID = null
                        // }

                        break
                    case "DATA ActiveCompetitor":
                        if (session) {
                            if (messageJSON.PrimaryClass == "GT Academy"){
                                if (messageJSON.SubClass == "GTA"){
                                    var nbc = "#2A2D2C"
                                    var nfc = "#e9811f"
                                } else if (messageJSON.SubClass == "R"){
                                    var nbc = "#ffffff"
                                    var nfc = "#e9811f"
                                } else {
                                    var nbc = "white"
                                    var nfc = "black"
                                }
                                
                            } else if (messageJSON.PrimaryClass == "GT"){
                                if (messageJSON.SubClass == "GT5A"){
                                    var nbc = "#e9811f"
                                    var nfc = "#ffffff"
                                } else if (messageJSON.SubClass == "GT5P"){
                                    var nbc = "#ffffff"
                                    var nfc = "#e9811f"
                                } else if (messageJSON.SubClass == "GTP"){
                                    var nbc = "#2A2D2C"
                                    var nfc = "#e9811f"
                                } else {
                                    var nbc = "white"
                                    var nfc = "black"
                                }
                                
                            } else if (messageJSON.PrimaryClass == "Junior"){
                                if (messageJSON.SubClass == "J"){
                                    var nbc = "#2A2D2C"
                                    var nfc = "#e9811f"
                                } else if (messageJSON.SubClass == "R"){
                                    var nbc = "#ffffff"
                                    var nfc = "#e9811f"
                                } else {
                                    var nbc = "white"
                                    var nfc = "black"
                                }
                                
                            } else if (session.MultiCls){
                                var classes = session.MultiCls
                                var classIndex = classes.findIndex(x => x.SubClass === messageJSON.SubClass)
                                if (classIndex > -1){
                                    var nbc = classes[classIndex].Colour.substring(0,7);
                                    var hexRed = parseInt(nbc.substring(1, 3), 16) / 255;
                                    var hexGreen = parseInt(nbc.substring(3, 5), 16) / 255;
                                    var hexBlue = parseInt(nbc.substring(5, 7), 16) / 255;
                                    var lum = 0.2126 * hexRed + 0.7152 * hexGreen + 0.0722 * hexBlue;
                                    if (lum > 0.5) {
                                        var nfc = "black"
                                    } else {
                                        var nfc = "white"
                                    }
                                } else {
                                    var nbc = "#2faae1"
                                    var nfc = "#ffffff"
                                }
                            } else {
                                var nbc = "#2faae1"
                                var nfc = "#ffffff"
                            }

                            if (session.Competitors){
                                var competitorsState = session.Competitors
                                var driverIndex = competitorsState.findIndex( x => x.CompetitorId == messageJSON.ID)
                                console.log(driverIndex)
                            }

                        }

                        if (messageJSON.FirstName){
                            var compName = messageJSON.FirstName + " " + messageJSON.LastName || ""
                            var picName = messageJSON.FirstName +  messageJSON.LastName || ""
                        } else {
                            var compName = messageJSON.DisplayName
                            var picName =  messageJSON.DisplayName || ""
                        }
                        
                        if(driverIndex == -1){
                            var driver = {
                                "CompetitorId": messageJSON.ID || "",
                                "CompetitorNumber": messageJSON.StartNumber || "",
                                "CompetitorName": compName || "",
                                "Picture" : "https://alphalive.co.uk/streamphotos/"+series+"/"+picName.toUpperCase() || "",
                                "CompetitorClass": messageJSON.PrimaryClass || "",
                                "CompetitorSubClass": messageJSON.SubClass || "",
                                "teamName": messageJSON.Sponsor || "",
                                "vehicle": messageJSON.Vehicle || "",
                                "DriverName": messageJSON.ShortName || "",
                                "Position": messageJSON.GridPos || 999,
                                "NumberOfLaps": 0,
                                "LastLaptime": "",
                                "LapData": [],
                                "RaceTime": null,
                                "RunningTime": null,
                                "BestLaptime": null,
                                "BestLapNumber": null,
                                "IsBestLapInRace": false,
                                "Seen": false,
                                "InPit": false,
                                "Retired": false,
                                "GridPosition": messageJSON.GridPos || 999,
                                "PositionChange": 0,
                                "PosChangeClass" : "positionneutral",
                                "posChangeTime" : 0,
                                "Gap": 0,
                                "gd": 999,
                                "Behind": 0,
                                "Split1": 0,
                                "Split2": 0,
                                "Split3": 0,
                                "IsBestSector1": false,
                                "pbs1": 0,
                                "IsBestSector2": false,
                                "pbs2": 0,
                                "IsBestSector3": false,
                                "pbs3": 0,
                                "cs": null,
                                "el": null,
                                "TakenChequered": false,
                                "ocl": null,
                                "clnr": null,
                                "blnr": null,
                                "tt": null,
                                "nat": messageJSON.Nationality,
                                "ps": 0,
                                "lpe": 0,
                                "nbc": nbc,
                                "nfc": nfc,
                                "es1": null,
                                "es2": null
                            }
                            session.Competitors.push(driver) 
                        } else {
                            var driver = {
                                "CompetitorId": messageJSON.ID || "",
                                "CompetitorNumber": messageJSON.StartNumber || "",
                                "CompetitorName": compName || "",
                                "Picture" : "https://alphalive.co.uk/streamphotos/"+series+"/"+picName.toUpperCase() || "",
                                "CompetitorClass": messageJSON.PrimaryClass || "",
                                "CompetitorSubClass": messageJSON.SubClass || "",
                                "CurrentLap": session.Competitors[driverIndex].CurrentLap || {"LapNo": 0, "Sectors": []},
                                "teamName": messageJSON.Sponsor || "",
                                "vehicle": messageJSON.Vehicle || "",
                                "DriverName": messageJSON.ShortName || "",
                                "Position": session.Competitors[driverIndex].Position || messageJSON.GridPos || 999,
                                "NumberOfLaps": session.Competitors[driverIndex].NumberOfLaps || 0,
                                "LastLaptime": session.Competitors[driverIndex].LastLaptime || "",
                                "LapData": session.Competitors[driverIndex].LapData || [],
                                "RaceTime": session.Competitors[driverIndex].RaceTime || null,
                                "RunningTime": session.Competitors[driverIndex].RunningTime || null,
                                "BestLaptime": session.Competitors[driverIndex].BestLaptime || null,
                                "BestLapNumber": session.Competitors[driverIndex].BestLapNumber || null,
                                "IsBestLapInRace": session.Competitors[driverIndex].IsBestLapInRace || false,
                                "Seen": session.Competitors[driverIndex].Seen || false,
                                "InPit": session.Competitors[driverIndex].InPit || false,
                                "Retired": session.Competitors[driverIndex].Retired || false,
                                "GridPosition": session.Competitors[driverIndex].GridPosition || messageJSON.GridPos || 999,
                                "PositionChange": 0,
                                "PosChangeClass" : "positionneutral",
                                "posChangeTime" : 0,
                                "Gap": session.Competitors[driverIndex].Gap,
                                "gd": session.Competitors[driverIndex].gd || 999,
                                "Behind": session.Competitors[driverIndex].Behind,
                                "Split1": session.Competitors[driverIndex].Split1 || 0,
                                "Split2": session.Competitors[driverIndex].Split2 || 0,
                                "Split3": session.Competitors[driverIndex].Split3 || 0,
                                "IsBestSector1": session.Competitors[driverIndex].IsBestSector1 || false,
                                "pbs1": session.Competitors[driverIndex].pbs1 || 0,
                                "IsBestSector2": session.Competitors[driverIndex].IsBestSector2 || false,
                                "pbs2": session.Competitors[driverIndex].pbs2 || 0,
                                "IsBestSector3": session.Competitors[driverIndex].IsBestSector3 || false,
                                "pbs3": session.Competitors[driverIndex].pbs3 || 0,
                                "cs": session.Competitors[driverIndex].cs || null,
                                "el": session.Competitors[driverIndex].el || null,
                                "TakenChequered": session.Competitors[driverIndex].TakenChequered || false,
                                "ocl": session.Competitors[driverIndex].ocl || null,
                                "clnr": session.Competitors[driverIndex].clnr || null,
                                "blnr": session.Competitors[driverIndex].blnr || null,
                                "tt": session.Competitors[driverIndex].tt || null,
                                "nat": messageJSON.Nationality,
                                "ps": session.Competitors[driverIndex].ps || 0,
                                "lpe": session.Competitors[driverIndex].lpe || 0,
                                "nbc": nbc,
                                "nfc": nfc,
                                "es1": session.Competitors[driverIndex].es1 || null,
                                "es2": session.Competitors[driverIndex].es2 || null,
                                "GroupBehind": session.Competitors[driverIndex].GroupBehind || null,
                                "GroupBestLaptime": session.Competitors[driverIndex].GroupBestLaptime || null,
                                "GroupGap": session.Competitors[driverIndex].GroupGap || null,
                                "GroupNumberOfLaps": session.Competitors[driverIndex].GroupNumberOfLaps || null,
                                "GroupPosition": session.Competitors[driverIndex].GroupPosition || null,
                                "GroupPositionChange": session.Competitors[driverIndex].GroupPositionChange || null,
                            }
                            session.Competitors[driverIndex] = driver
                        }
                        
                        break
                    case "DATA Result":
                        var competitorsState = session.Competitors
                        var driverIndex = competitorsState.findIndex( x => x.CompetitorId === messageJSON.CompetitorID)
                        if (driverIndex > -1){
                            if (competitorsState[driverIndex].TakenChequered == true || messageJSON.inpit == 1 || messageJSON.status == "missing" || this.state.Session.SessionType == "Qual" || this.state.ClientStates.Sectors == false){
                                if (messageJSON.Diff < 0) {
                                    var gap = messageJSON.Diff + " Laps"
                                } else {
                                    var gap = this.msToTime(messageJSON.Diff)
                                }

                                if (messageJSON.Gap < 0){
                                    var behind = messageJSON.Gap + " Laps"
                                    if (competitorsState[driverIndex].SectorPosUpdate != true ){
                                        competitorsState[driverIndex].Position = messageJSON.Position
                                    }
                                } else {
                                    var behind = this.msToTime(messageJSON.Gap)
                                    competitorsState[driverIndex].Position = messageJSON.Position
                                }

                                if (messageJSON.Status == "Missing"){
                                    var retired = true
                                } else {
                                    var retired = false
                                }

                                if (messageJSON.Status == "Finished"){
                                    var finished = true
                                } else {
                                    var finished = false
                                }

                                competitorsState[driverIndex].Gap = gap
                                competitorsState[driverIndex].Behind = behind || competitorsState[driverIndex].Behind 
                                competitorsState[driverIndex].NumberOfLaps = messageJSON.Laps
                                competitorsState[driverIndex].ps = messageJSON.PitStops
                                competitorsState[driverIndex].InPit = messageJSON.InPit
                                competitorsState[driverIndex].PositionChange = messageJSON.PositionChange
                                competitorsState[driverIndex].BestLaptime = messageJSON.FastLapTime/1000
                                competitorsState[driverIndex].TakenChequered = finished
                                competitorsState[driverIndex].Retired = retired
                             }
                        }
                        break
                    case "DATA GroupResult":
                        console.log("GroupResult", messageJSON)
                        var competitorsState = session.Competitors
                        var driverIndex = competitorsState.findIndex( x => x.CompetitorId === messageJSON.CompetitorID)
                        console.log(driverIndex)
                        if (driverIndex > -1){
                            if (messageJSON.Diff < 0) {
                                var gap = messageJSON.Diff + " Laps"
                            } else {
                                var gap = this.msToTime(messageJSON.Diff)
                            }

                            if (messageJSON.Gap < 0){
                                var behind = messageJSON.Gap + " Laps"
                            } else {
                                var behind = this.msToTime(messageJSON.Gap) 
                            }
                            
                            if (messageJSON.Status == "Missing"){
                                var retired = true
                            } else {
                                var retired = false
                            }

                            if (messageJSON.Status == "Finished"){
                                var finished = true
                            } else {
                                var finished = false
                            }
                            competitorsState[driverIndex].GroupPosition = messageJSON.Position
                            competitorsState[driverIndex].GroupGap = gap
                            competitorsState[driverIndex].GroupBehind = behind
                            competitorsState[driverIndex].GroupNumberOfLaps = messageJSON.Laps
                            // competitorsState[driverIndex].Groupps = messageJSON.PitStops
                            // competitorsState[driverIndex].GroupInPit = messageJSON.InPit
                            competitorsState[driverIndex].GroupPositionChange = messageJSON.PositionChange
                            competitorsState[driverIndex].GroupBestLaptime = messageJSON.FastLapTime/1000
                            // competitorsState[driverIndex].GroupTakenChequered = finished
                            // competitorsState[driverIndex].GroupRetired = retired
                        }
                        break
                    case "DATA Lap":
                        var competitorsState = session.Competitors
                        var driverIndex = competitorsState.findIndex( x => x.CompetitorId === messageJSON.CompetitorID)
                        if (driverIndex > -1){
                            var lapObj = {
                            "LapNo": messageJSON.Lap,
                            "LapTime": messageJSON.LapTime/1000,
                            "Sectors": messageJSON.SectorTimes,
                            "PassingTime": messageJSON.PassingTime,
                            "Position": messageJSON.Position
                            }
                            competitorsState[driverIndex].LapData.push(lapObj)

                            if (parseInt(messageJSON.Lap) > 2){
                                if(session.blt != null){
                                    var currentBestTime = session.blt.tm
                                    
                                    if (lapObj.LapTime < currentBestTime){
                                        var blt = {
                                            cn: competitorsState[driverIndex].CompetitorNumber,
                                            cnpb: competitorsState[driverIndex].nbc,
                                            cnpf: competitorsState[driverIndex].nfc,
                                            ln: lapObj.LapNo,
                                            tm: lapObj.LapTime,
                                            sectors: lapObj.Sectors,
                                            shown: false,
                                            pusher: true
                                        }
                                        competitorsState[driverIndex].IsBestLapInRace = true
                                        session.blt = blt
                                        var i = 0

                                        while(i< competitorsState.length){
                                            if (i != driverIndex){
                                                competitorsState[i].IsBestLapInRace = false
                                            }
                                            i++
                                        }
                                    }
                                } else {
                                    var blt = {
                                        cn: competitorsState[driverIndex].CompetitorNumber,
                                        cnpb: competitorsState[driverIndex].nbc,
                                        cnpf: competitorsState[driverIndex].nfc,
                                        ln: lapObj.LapNo,
                                        tm: lapObj.LapTime,
                                        sectors: lapObj.Sectors,
                                        shown: false,
                                        pusher: true
                                    }
                                    competitorsState[driverIndex].IsBestLapInRace = true
                                    session.blt = blt
                                    var i = 0
                                    while(i< competitorsState.length){
                                        if (i != driverIndex){
                                            competitorsState[i].IsBestLapInRace = false
                                        }
                                        i++
                                    }
                                }
                            } else {
                                if(session.blt != null){
                                    var currentBestTime = session.blt.tm
                                    
                                    if (lapObj.LapTime < currentBestTime){
                                        var blt = {
                                            cn: competitorsState[driverIndex].CompetitorNumber,
                                            cnpb: competitorsState[driverIndex].nbc,
                                            cnpf: competitorsState[driverIndex].nfc,
                                            ln: lapObj.LapNo,
                                            tm: lapObj.LapTime,
                                            sectors: lapObj.Sectors,
                                            shown: true,
                                            pusher: true
                                        }
                                        competitorsState[driverIndex].IsBestLapInRace = true
                                        session.blt = blt
                                        var i = 0

                                        while(i< competitorsState.length){
                                            if (i != driverIndex){
                                                competitorsState[i].IsBestLapInRace = false
                                            }
                                            i++
                                        }
                                    }
                                } else {
                                    var blt = {
                                        cn: competitorsState[driverIndex].CompetitorNumber,
                                        cnpb: competitorsState[driverIndex].nbc,
                                        cnpf: competitorsState[driverIndex].nfc,
                                        ln: lapObj.LapNo,
                                        tm: lapObj.LapTime,
                                        sectors: lapObj.Sectors,
                                        shown: true,
                                        pusher: true
                                    }
                                    competitorsState[driverIndex].IsBestLapInRace = true
                                    session.blt = blt
                                    var i = 0
                                    while(i< competitorsState.length){
                                        if (i != driverIndex){
                                            competitorsState[i].IsBestLapInRace = false
                                        }
                                        i++
                                    }
                                }
                            }

                            if (messageJSON.Diff < 0) {
                                if (messageJSON.Diff == -1){
                                    var gap = messageJSON.Diff + " Lap"
                                } else {
                                    var gap = messageJSON.Diff + " Laps"
                                }
                            } else {
                                var gap = this.msToTime(messageJSON.Diff)
                            }

                            if (messageJSON.Gap < 0){
                                if (messageJSON.Gap == -1){
                                    var behind = messageJSON.Gap + " Lap"
                                } else {
                                    var behind = messageJSON.Gap + " Laps"
                                }
                                
                                if (competitorsState[driverIndex].SectorPosUpdate != true ){
                                    competitorsState[driverIndex].Position = messageJSON.Position
                                }
                            } else {
                                var behind = this.msToTime(messageJSON.Gap)
                                competitorsState[driverIndex].Position = messageJSON.Position
                            }

                            competitorsState[driverIndex].LastLaptime = messageJSON.LapTime/1000
                            competitorsState[driverIndex].SectorPosUpdate = false
                            competitorsState[driverIndex].Gap = gap || competitorsState[driverIndex].gap
                            competitorsState[driverIndex].Behind = behind || competitorsState[driverIndex].Behind 
                        }
                        
                        break
                    case "DATA Grid":

                        break
                    case "DATA Ping":
                        if (messageJSON.SessionTimeRemaining){
                            session.SessionTimeRemaining = messageJSON.SessionTimeRemaining/1000
                            var currentTime = Date.now()
                            session.ServerTime = Math.round(currentTime);
                        }
                        break
                    case "DATA RCMsg":
                        var messageArray = this.state.Notifications
                        if (messageJSON.Message != ""){
                            var rcMSG = {
                            t: new Date(Date.now()).getHours() + ":" + new Date(Date.now()).getMinutes() + "." +  new Date(Date.now()).getSeconds(),
                            nt: messageJSON.Message,
                            cnu: "",
                            cna: "",
                            displayed: false
                            }
                            messageArray.push(rcMSG)
                            this.setState({
                                Notifications: messageArray
                            })
                        }
                        
                        break
                    case "DATA Sector":
                        console.log("Sector")
                        var sector = JSON.parse(data.message)
                        console.log(sector)
                        if (sector.IsSpeedTrap == 0){
                            if(session.Sectors){
                                var sectorIndex = session.Sectors.findIndex( x => x.ID == parseInt(sector.ID))
                                if (sectorIndex > -1){
                                    if (sector.BestTimeCompetitorID){
                                        session.Sectors[sectorIndex].BestTime = sector.BestTime
                                    }
                                    if (sector.BestTimeCompetitorID){
                                        session.Sectors[sectorIndex].BestTimeCompetitorID = sector.BestTimeCompetitorID
                                    }
                                } else {
                                    session.Sectors.push(sector)
                                }
                            } else {
                                session.Sectors = [sector]
                            }
                        }
                        break
                    case "DATA Inter":
                        console.log("Inter")
                        if (session.Sectors){
                            var inter = JSON.parse(data.message)
                            console.log(inter)
                            var competitorsState = session.Competitors
                            var driverIndex = competitorsState.findIndex( x => x.CompetitorId == parseInt(inter.CompetitorID))

                            var sectors = session.Sectors
							console.log(sectors)
							if(sectors.length == 1){
								var sectorIndex = -1
							} else {
								var sectorIndex = sectors.findIndex( x => x.ID == parseInt(inter.SectorID))
							}
                            

                            if (driverIndex > -1){
                                var driver = competitorsState[driverIndex]

                                // if (inter.Position){
                                //     if (inter.Position != driver.Position){
                                //         //Check number of positions gained and bumped drivers inbetween back
                                //         var gained = driver.Position - inter.Position
                                    
                                //         while(gained > 0){
                                //             var posToCheck = driver.Position - gained
                                //             var newDriverIndex = competitorsState.findIndex( x => x.Position == parseInt(posToCheck))
                                //             console.log(driver.Position, gained, posToCheck, newDriverIndex)

                                //             if(newDriverIndex != -1){
                                //                 competitorsState[newDriverIndex].Position = parseInt(competitorsState[newDriverIndex].Position)+1
                                //                 competitorsState[newDriverIndex].Behind = ""
                                //                 competitorsState[newDriverIndex].Gap = ""
                                //                 competitorsState[newDriverIndex].SectorPosUpdate = true 
                                //             }
                                            
                                //             gained = gained -1
                                //         }

                                //         // driver.Position = inter.Position
                                //         // driver.SectorPosUpdate = true
                                //     }
                                //     driver.Position = inter.Position
                                //     driver.SectorPosUpdate = true
                                // }

                                // if(inter.Diff){
                                //     var lapsIndex = driver.Behind.includes("LAPS")
                                //     if (lapsIndex != true){
                                //         if (inter.Diff < 0) {
                                //             var gap = inter.Diff + " Laps"
                                //         } else {
                                //             var gap = this.msToTime(inter.Diff)
                                //         } 
                                //     }
                                // }
                                
                                // if(inter.Gap){
                                //     var lapsIndex = driver.Gap.includes("LAPS")
                                //     if (lapsIndex != true){
                                //         if (inter.Gap < 0){
                                //             var behind = inter.Gap + " Laps"
                                //         } else {
                                //             var behind = this.msToTime(inter.Gap)
                                //         } 
                                //     }
                                // }
                                
                                // driver.Behind = behind || driver.Behind
                                // driver.Gap = gap || driver.Gap

                                if (sectorIndex > -1){
                                    if (driver.LapData){
                                        if (driver.CurrentLap){
                                            if (driver.CurrentLap.LapNo < inter.Lap){
                                                driver.CurrentLap = {
                                                    "LapNo": inter.Lap,
                                                    "Sectors": [inter]
                                                }
                                            } else {
                                                driver.CurrentLap.Sectors.push(inter)
                                            }
                                        } else {
                                            driver.CurrentLap = {
                                                "LapNo": inter.Lap,
                                                "Sectors": [inter]
                                            }
                                        }
                                    }
                                }
                            }

                            var lapObj = {
                                "LapNo": messageJSON.Lap,
                                "LapTime": messageJSON.LapTime/1000,
                                "Sectors": messageJSON.SectorTimes
                                }
                        }
                        
                        break
                    case "Championship":
                        var Championship = messageJSON.ChampionshipPositions
                        console.log(Championship)
                        this.setState({
                            championship: Championship,
                        })
                        break
                    case "DATA Sort1":
                        console.log("Sort1", messageJSON)
                        var competitorsState = session.Competitors
                        var driverIndex = competitorsState.findIndex( x => x.CompetitorId === messageJSON.CompetitorID)
                        if (driverIndex > -1){
                            if (competitorsState[driverIndex].TakenChequered != true && this.state.ClientStates.Sectors == true){
                                if (messageJSON.Diff < 0) {
                                    var gap = messageJSON.Diff + " Laps"
                                } else {
                                    if (competitorsState[driverIndex].Gap){
                                        var lapsDown = competitorsState[driverIndex].Gap.indexOf("Lap")
                                    } else {
                                        var lapsDown = -1
                                    }
                                    
                                    if(lapsDown == -1){
                                        var gap = this.msToTime(messageJSON.Diff)
                                    } else {
                                        var gap = competitorsState[driverIndex].Gap
                                    }  
                                }

                                if (messageJSON.Gap < 0){
                                    var behind = messageJSON.Gap + " Laps"
                                    if (competitorsState[driverIndex].SectorPosUpdate != true ){
                                        competitorsState[driverIndex].Position = messageJSON.Position
                                    }
                                } else {
                                    if(competitorsState[driverIndex].Behind){
                                        var behindLapsDown = competitorsState[driverIndex].Behind.indexOf("Lap")
                                    } else {
                                        var behindLapsDown = -1
                                    }
                                    
                                    if(behindLapsDown == -1){
                                        var behind = this.msToTime(messageJSON.Gap)
                                    } else {
                                        var behind = competitorsState[driverIndex].Behind
                                    }
                                    
                                    competitorsState[driverIndex].Position = messageJSON.Position
                                }

                                if (messageJSON.Status == "Missing"){
                                    var retired = true
                                } else {
                                    var retired = false
                                }

                                if (messageJSON.Status == "Finished"){
                                    var finished = true
                                } else {
                                    var finished = false
                                }

                                competitorsState[driverIndex].Gap = gap
                                competitorsState[driverIndex].Behind = behind || competitorsState[driverIndex].Behind 
                                competitorsState[driverIndex].NumberOfLaps = messageJSON.Laps
                                competitorsState[driverIndex].ps = messageJSON.PitStops
                                competitorsState[driverIndex].InPit = messageJSON.InPit
                                competitorsState[driverIndex].PositionChange = messageJSON.PositionChange
                                competitorsState[driverIndex].BestLaptime = messageJSON.FastLapTime/1000
                                competitorsState[driverIndex].TakenChequered = finished
                                competitorsState[driverIndex].Retired = retired
                            }
                        }
                        break
                    }
                
                } else if (this.state.ClientStates.TimingSystem == "HS"){
                    switch(data.type){
                    case "init":
                        break
                    case "Result":
                        break
                    case "FastestLap":
                        break
                    case "Lap":
                        break
                    }
                } else if (this.state.ClientStates.TimingSystem == "ApexTest"){

                }
                
            } else if (data.author == "Controller"){
                console.log(data)
                if(data.type == "Database"){
                    this.setState({
                        ClientStates: data.message
                    })
                } else if (data.type == "State") {
                    console.log("State", data.message)
                    if (data.message == "Yellow"){
                        session.fcy = true
                        session.End = false
                    } else if (data.message == "Running"){
                        if (!session.StartTime){
                            session.StartTime = Date.now()
                            console.log(HSClockInt)
                            if(HSClockInt == null){
                                console.log(null)
                                var lapsIndex = session.SD.indexOf("Laps")
                                console.log(lapsIndex)
                                if (lapsIndex != -1){
                                    HSClockInt = setInterval(() => this.HSClock(), 500);
                                }
                            }
                        } else if (HSClockInt == null){
                            var lapsIndex = session.SD.indexOf("Laps")
                            if (lapsIndex != -1){
                                HSClockInt = setInterval(() => this.HSClock(), 500);
                            }
                        } else {
                            console.log("HERE")
                        }
                        session.fcy = false
                        session.Last = false
                        session.State = data.message
                    } else if (data.message == "RedFlag"){
                        session.fcy = false
                        session.State = data.message
                        session.End = false
                        if (HSClockInt != null){
                            clearInterval(HSClockInt)
                            HSClockInt = null
                            session.Last = false
                            
                        }
                    } else if (data.message == "ChequeredFlag"){
                        session.State = data.message
                        session.fcy = false
                        session.Last = false
                        session.End = true
                        if (HSClockInt != null){
                            clearInterval(HSClockInt)
                            HSClockInt = null
                        }
                    } else if (data.message == "LastLap"){
                        if (HSClockInt != null){
                            clearInterval(HSClockInt)
                            HSClockInt = null
                            session.Last = true
                            session.End = false
                        }
                    } else if (data.message == "Starting"){
                            session.State = "Starting"
                            console.log("here")
                            var lapsIndex = session.SD.indexOf("Laps")

                            if (lapsIndex > -1){
                                this.setState({timeRemaining: "0 / "+session.SD})
                            } else {
                            this.HSClock(true) 
                            }
                            session.Last = false
                            session.End = false
                    } else {
                        console.log("HERE1")
                        session.fcy = false
                        session.State = data.message
                        session.Last = false
                        session.End = false
                    }
                    

                } else if (data.type == "ChampionshipSelect"){
                    console.log("ChampChange")
                    controls.ChampionshipSelect = parseInt(data.message)
                } else if (data.type == "RaceSelect"){
                    // First we find the session place holder in the races list and then replace it with the current session state
                    // If the session does not exist we add it to the end of the array unless it is the dummy session
                    var sessionIndex = races.findIndex( x => x.SessionId == session.SessionId)
                    if(sessionIndex > -1){
                        races[sessionIndex] = session
                    } else {
                        //check if dummy session do not save data if it is dummy session
                        if (session.SessionName != 'No Data'){
                            console.log('session saved')
                            races[races.length] = session
                        } else {
                            console.log('dummy data - not saved')
                        }
                    }

                    // Now we have saved the current session data we can move the required session into the current session slot.
                    if (races[data.message]){
                        session = races[data.message]
                    }
                    
                } else if (data.type == "CompetitorUpdate"){
                    console.log(data.message1, data.message2)

                    var compArray = session.Competitors
                    
                    compArray[data.message1] = data.message2

                    session.Competitors = compArray

                } else if (data.type == "PresetList"){
                    this.setState({
                        PresetList: data.message
                    })
                } else {
                    switch(data.message){
                    case "resultsShow": 
                        if(this.state.ClientStates.Skin == 'Hyundai'){
                            controls.ResultsMargin = -35
                        } else {
                            controls.ResultsMargin = -100
                        }
                        
                        controls.ResultsShow = true
                        controls.ClockShow = false
                        break
                    case "resultsPage":
                        if(this.state.ClientStates.Skin == "gb3" || this.state.ClientStates.Skin == "basic"){
                            controls.ResultsMargin = controls.ResultsMargin -450
                        } else if (this.state.ClientStates.Skin == "Hyundai"){
                            controls.ResultsMargin = controls.ResultsMargin -700
                        } else {
                            controls.ResultsMargin = controls.ResultsMargin -650
                        }
                        break
                    case "resultsHide":
                        controls.ResultsShow = false
                        break
                    case "gridsShow": 
                        controls.GridShow = true
                        controls.ClockShow = false
                        break
                    case "gridsHide":
                        controls.GridShow = false
                        break                        
                    case "towerShow":
                        controls.TowerShow = true
                        break
                    case "towerHide":
                        controls.TowerShow = false
                        break
                    case "clockShow":
                        controls.ClockShow = true
                        break
                    case "clockHide":
                        controls.ClockShow = false
                        break
                    case "battleShow":
                        console.log(data.message2)
                        controls.BattlePosition = parseInt(data.message2)
                        controls.BattleDrivers = data.message3
                        controls.BattleShow = true
                        if(controls.BattlePosition > 12){
                            this.pages()
                        }
                        break
                    case "battleClear":
                        controls.BattleShow = false
                        break
                    case "compareShow":
                        controls.ComparisonShow = true
                        break
                    case "champStandingsShow":
                        controls.LiveChampsDisplay = "liveChampShow"
                        break
                    case "champStandingsHide":
                        controls.LiveChampsDisplay = "liveChampHide"
                        break
                    case "compareHide":
                        controls.ComparisonShow = false
                        break
                    case "compare":
                        controls.ComparisonLeadDriver = data.message2
                        break
                    case "col4Pos":
                        controls.Col4 = "positions"
                        break
                    case "col4Gap":
                        controls.Col4 = "gap"
                        break
                    case "col4Diff":
                        controls.Col4 = "diff"
                        break
                    case "col4Pits":
                        controls.Col4 = "pits"
                        break
                    case "col4None":
                        controls.Col4 = "none"
                        break
                    case "progressionShow":
                        controls.ProgressionShow = true
                        break
                    case "progressionClear":
                        controls.ProgressionShow = false
                        break
                    case "expand":
                        var competitorsState = session.Competitors
                        var driverIndex = competitorsState.findIndex( x => x.CompetitorId == parseInt(data.message2))

                        if (data.message2 != controls.ExpandDriverID){
                            controls.Expand = true
                            controls.ExpandDriverID = data.message2
                            controls.ExpandIndex = driverIndex
                            if (competitorsState[driverIndex].Position > 10){
                                this.pages()
                            }
                        } else {
                            controls.Expand = false
                            controls.ExpandDriverID = undefined
                            controls.ExpandIndex = undefined
                        }
                        break
                    case "onboard":
                        if(this.state.OnboardConnection == true){
                            var competitorsState = session.Competitors
                            var driverIndex = competitorsState.findIndex( x => x.CompetitorId == parseInt(data.message2))

                            if (data.message2 != controls.OnboardDriverID){
                                console.log('onboardOpen')
                                controls.Onboard = true
                                controls.OnboardDriverID = data.message2
                                controls.OnboardIndex = driverIndex
                                if (competitorsState[driverIndex].Position > 10){
                                    this.pages()
                                }
                            } else {
                                console.log('onboardClose')
                                controls.Onboard = false
                                controls.OnboardDriverID = undefined
                            }
                            break
                        } else {
                            console.log('onboardClose')
                            controls.Onboard = false
                            controls.OnboardDriverID = undefined
                        }
                        break
                    case "LTShow":
                        this.setState({
                            LT: data.message2
                        })
                        controls.LT = true
                        break
                    case "LTHide":
                        controls.LT = false
                        break
                    case "LTDataShow":
                        this.setState({
                            LT: data.message2
                        })
                        controls.DataLT = true
                        break
                    case "LTDataHide":
                        controls.DataLT = false
                        break
                    case "catShow":
                        controls.CategoryExplainer = true
                        break
                    case "catClear":
                        controls.CategoryExplainer = false
                        break
                    case "NAME-TEAM":
                        console.log("TEAM")
                        controls.Name = "TEAM"
                        break
                    case "NAME-DRIVER":
                        console.log("DRIVER")
                        controls.Name = "DRIVER"
                        break
                    case "AC_SET_GRID":
                        console.log("SETTING GRID")
                        var competitors = session.Competitors
                        var newGrid = data.message2

                        var i = 0 
                        while (i < newGrid.length){
                            var index = competitors.findIndex( x => x.CompetitorId == newGrid[i].CompetitorId)
                            if(index > -1 ){
                                competitors[index].GridPosition = newGrid[i].Position
                                competitors[index].QualTime = this.msToTime(newGrid[i].BestLaptime*1000)
                            }
                            
                            i++
                        }
                        session.Competitors = competitors
                        break
                    case "SessionNameChange":
                        session.SessionName = data.message2
                        session.nameOveride = true
                        break
                    }            
                }
            } else if (data.author == "Timing"){
                console.log(JSON.parse(data.message))
                var newStandings = JSON.parse(data.message)
                
                if (this.state.Session.Competitors){
                    if (data.type == "Standings"){
                        var i = 0
                        if(this.state.Session.SessionName != data.session){
                            controls = {
                                AutoCol: false,
                                IncludePs: false,
                                ClockShow: true,
                                TowerShow: true,
                                ResultsShow: false,
                                NotificationShow: false,
                                FastestLapShow: false,
                                ChampionshipShow: true,
                                ChampionshipSelect: 0,
                                ComparisonShow: false,
                                ComparisonLeadDriver: undefined,
                                TowerMargin: [0,0,35,70,105,140,175,210,245,280,315,350,385,420,455,490,525,560,595,630,665,700,735,770,805,840,875,910,945,980,1015,1050,1085,1120,1155,1190,1225,1260,1295,1330,1365,1400,1435,1470,1505,1540,1575,1610,1645,1680,1715,1750,1785,1820,1855,1890,1925,1960,1995,2030,2065,2100,2135,2170,2205,2240,2275,2310,2345,2380,2415,2450,2485,2520,2555,2590,2625,2660,2695,2730,2765,2800,2835,2870,2905,2940,2975,3010,3045,3080,3115,3150,3185,3220],
                                ResultsMargin: -100,
                                TowerPagesMargin: [-385,-350,-315,-280,-245,-210,-175,-140,-105,-70,-35,0,35,70,105,140,175,210,245,280,315,350,385,420,455,490,525,560,595,630,665,700,735,770,805,840,875,910,945,980,1015,1050,1085,1120,1155,1190,1225,1260,1295,1330,1365,1400,1435,1470,1505,1540,1575,1610,1645,1680,1715,1750,1785,1820,1855,1890,1925,1960,1995,2030,2065,2100,2135,2170,2205,2240,2275,2310,2345,2380,2415,2450,2485,2520,2555,2590,2625,2660,2695,2730,2765,2800,2835,2870,2905,2940,2975,3010,3045,3080,3115,3150,3185,3220],
                                CurrentPage: 0,
                                BattleShow: false,
                                BattleDrivers: [],
                                BattlePosition: undefined,
                                ProgressionShow: false,
                                ProgressionPosition: undefined,
                                Col4: "gap",
                                Expand: false,
                                ExpandDriverID: undefined,
                                Onboard: false,
                                OnboardDriverID: undefined,
                            }
                            session.SessionName = data.session
                            session.Competitors = data.newStandings
                            if (data.laps){
                                console.log(data.laps)
                                session.Rd = data.laps+"L"
                                console.log(session.Rd)
                            } else {
                                session.SD = data.duration
                            }
                        } else {
                        var competitorsState = session.Competitors

                            while(i < newStandings.length){
                                var driverIndex = competitorsState.findIndex( x => parseInt(x.CompetitorId) == parseInt(newStandings[i].CompetitorId))
                                //check for fastest lap
                                console.log(session.blt.tm, newStandings[i].CompetitorName,competitorsState[driverIndex].BestLaptime, newStandings[i].BestLaptime)
                                if (session.blt.tm > newStandings[i].BestLaptime){
                                    var fastestLap = session.blt 
                                    console.log(true)
                                    if (fastestLap.tm > newStandings[i].BestLaptime){
                                        
                                        var newFL = {
                                            cn: newStandings[i].CompetitorNumber,
                                            cnpb: newStandings[i].nbc,
                                            cnpf: newStandings[i].nfc,
                                            ln: newStandings[i].NumberOfLaps,
                                            tm: newStandings[i].BestLaptime,
                                            shown: false,
                                            pusher: true
                                        }

                                        newStandings[i].IsBestLapInRace = true

                                        session.blt = newFL
                                    }
                                } else if (competitorsState[driverIndex].IsBestLapInRace == true){
                                    //check if driver is still the fastest
                                    if (session.blt.cn == newStandings[i].CompetitorNumber){
                                        newStandings[i].IsBestLapInRace = true
                                    } else {
                                        newStandings[i].IsBestLapInRace = false
                                    }
                                }

                                if (competitorsState[driverIndex].NumberOfLaps != newStandings[i].NumberOfLaps){
                                    var newLapObj = {
                                        "LapNo": newStandings[i].NumberOfLaps,
                                        "LapTime": newStandings[i].LastLaptime
                                    }
                                    if(competitorsState[driverIndex].LapData){
                                        newStandings[i].LapData.push(newLapObj)
                                    } else {
                                        newStandings[i].LapData = [newLapObj]
                                    }
                                    
                                }

                                if(driverIndex > -1){
                                    session.Competitors[driverIndex] = newStandings[i] 
                                } else {
                                    session.Competitors.push(newStandings[i])
                                }
                                i++
                            } 
                        }

                        if (data.raceState){
                            if (data.raceState == "finish-flag"){
                                session.State = "ChequeredFlag"
                                session.End = true
                            } else if (data.raceState == "finish-flag"){
                                session.State = "RedFlag"
                                session.fcy = false
                                session.End = false
                            } else if(data.raceState == "green-flag"){
                                session.State = "Running"
                                session.fcy = false
                                session.End = false
                            } else if(data.raceState == "yellow-flag"){
                                session.State = "Running"
                                session.fcy = true
                                session.End = false
                            }
                        }

                        if (data.laps){
                            console.log(data.laps)
                            session.Rd = data.laps+"L"
                            console.log(session.Rd)
                        } else {
                            session.SD = data.duration
                        }
                    }
                } else {
                    console.log("here")
                    var newSession = {
                        SessionStartTime: "",
                        ResultAvailable: true,
                        ShowNotifications: true,
                        EventId: 1,
                        SessionId: 1,
                        SessionName: data.session || "",
                        SessionType: "Race",
                        CircuitMap: "croft_circuit",
                        TrackLength: 1128,
                        TrackName: "Croft Circuit",
                        Weather: null,
                        Temperature: null,
                        TrackCondition: null,
                        IsTeamEvent: false,
                        MultiCls: false,
                        ns: null,
                        nst: null,
                        bn: null,
                        bst: null,
                        bet: null,
                        officials: {
                            CompetitionSecretary: "",
                            Timekeeper: ""
                        },
                        dp: 2,
                        blt: {
                            cn: 11,
                            cnpb: "#FF0000",
                            cnpf: "#FFFFFF",
                            ln: 17,
                            tm: 4620000000000000,
                            shown: true
                        },
                        bs1: {
                            cn: 11,
                            cnpb: "#FF0000",
                            cnpf: "#FFFFFF",
                            ln: 17,
                            tm: 15060
                        },
                        bs2: {
                            cn: 33,
                            cnpb: "#FF0000",
                            cnpf: "#FFFFFF",
                            ln: 17,
                            tm: 31030
                        },
                        bs3: null,
                        tt: null,
                        SD: data.duration || "16 Laps" || "",
                        flds: [
                            "gd",
                            "ps",
                            "lpe"
                        ],
                        nto: -0.04,
                        ntst: "2022-11-27T15:33:45.329Z",
                        ntip: "132.163.96.2",
                        ntsn: "time.nist.gov",
                        State: "Formation",
                        fcy: false,
                        Sequence: 1273,
                        Sectors: 3,
                        RaceTime: 0,
                        Rd: "15M",
                        Last: false,
                        End: false,
                        show_race_time: true,
                        TimingSystem: "wmkc",
                        Date: "2022-11-27",
                        Clear: false,
                        Competitors: newStandings
                    }
                    session = newSession
                }

                
            } else if (data.author == "rMonitor"){
                // console.log("message", data.message)
                // // if (!session){
                // //     var session = {
                // //             SessionStartTime: null,
                // //             ResultAvailable: null,
                // //             ShowNotifications: null,
                // //             EventId: null,
                // //             ServerTime: null,
                // //             SessionId: null,
                // //             SessionName: null,
                // //             SessionType: null,
                // //             SessionTimeRemaining: null,
                // //             CircuitMap: null,
                // //             TrackLength: null,
                // //             TrackName: null,
                // //             tn: null,
                // //             Weather: null,
                // //             Temperature: null,
                // //             TrackCondition: null,
                // //             IsTeamEvent: null,
                // //             MultiCls: null,
                // //             ns: null,
                // //             nst: null,
                // //             bn: null,
                // //             bst: null,
                // //             bet: null,
                // //             officials: {
                // //                 CompetitionSecretary: null,
                // //                 Timekeeper: null,
                // //             },
                // //             dp: null,
                // //             blt: blt,
                // //             bs1: null,
                // //             bs2: null,
                // //             bs3: null,
                // //             tt: null,
                // //             LengthTime: null,
                // //             SD: sd ,
                // //             flds: [
                // //                 "gd",
                // //                 "ps",
                // //                 "lpe"
                // //             ],
                // //             nto: -0.04,
                // //             ntst: "2022-11-27T15:33:45.329Z",
                // //             ntip: "132.163.96.2",
                // //             ntsn: "time.nist.gov",
                // //             State: null,
                // //             fcy: null,
                // //             Sequence: null,
                // //             Sectors: null,
                // //             RaceTime: 0,
                // //             Rd: null,
                // //             Last: false,
                // //             End: ended || false,
                // //             show_race_time: false,
                // //             TimingSystem: null,
                // //             Time: null,
                // //             Date: null,
                // //             Clear: false,
                // //             Competitors: null,
                // //             TSLGrouped: null,
                // //             TSLGroupedType: null,
                // //             TSLGroupedFL: null,
                // //             TSLGroupedFLCID: null,
                // //         }
                // // }

                let rMonitorTimeStamp = (timestamp) => {
                    const [hours, minutes, secondsWithMs] = timestamp.split(':');
                    const [seconds, milliseconds] = secondsWithMs.split('.');
                
                    return (
                        parseInt(hours, 10) * 60 * 60 * 1000 +
                        parseInt(minutes, 10) * 60 * 1000 +
                        parseInt(seconds, 10) * 1000 +
                        (milliseconds ? parseInt(milliseconds, 10) : 0) // Handle possible absence of milliseconds
                    );
                }

                let msToTime = (timestamp) => {
                    function pad(n, z = 2) {
                        return ('00' + n).slice(-z);
                    }
                
                    if (timestamp === 9223372036854776000) {
                        return "";
                    }
                
                    let ms = timestamp % 1000;
                    let seconds = Math.floor(timestamp / 1000) % 60;
                    let minutes = Math.floor(timestamp / (1000 * 60)) % 60;
                    let hours = Math.floor(timestamp / (1000 * 60 * 60));
                
                    if (hours === 0 && minutes === 0 && seconds === 0) {
                        return "0." + pad(ms, 3);
                    } else if (hours === 0 && minutes === 0 && seconds < 10) {
                        return seconds + '.' + pad(ms, 3);
                    } else if (hours === 0 && minutes === 0) {
                        return pad(seconds) + '.' + pad(ms, 3);
                    } else if (hours === 0 && minutes < 10) {
                        return minutes + ':' + pad(seconds) + '.' + pad(ms, 3);
                    } else if (hours === 0) {
                        return pad(minutes) + ':' + pad(seconds) + '.' + pad(ms, 3);
                    } else {
                        return hours + ':' + pad(minutes) + ':' + pad(seconds) + '.' + pad(ms, 3);
                    }
                }

                switch(data.message.Identifier){
                    case "$A":
                        if(session.Competitors){
                            console.log(session.Competitors)
                            console.log(session.MultiCls)
                            var index = session.Competitors.findIndex(x => x.CompetitorId === data.message.RegNum);
                            if (index > -1){
                                session.Competitors[index].CompetitorClass = data.message.ClassNumber
                                session.Competitors[index].CompetitorName = data.message.FirstName + " " + data.message.LastName
                                session.Competitors[index].nat = data.message.Nationality
                                session.Competitors[index].CompetitorNumber = data.message.Number
                            } else {
                                //get category info
                                if(session){
                                    if(session.MultiCls){
                                        var catIndex = session.MultiCls.findIndex(x => x.ClassNum === data.message.ClassNumber);
                                    
                                        var objComp = {
                                            "CompetitorId": data.message.RegNum,
                                            "CompetitorNumber": data.message.Number,
                                            "CompetitorName": data.message.FirstName + " " + data.message.LastName,
                                            "CompetitorClass": data.message.ClassNumber,
                                            "CompetitorSubClass": "",
                                            "DriverName": null,
                                            "Position": 999,
                                            "NumberOfLaps": 0,
                                            "LastLaptime": null,
                                            "RaceTime": null,
                                            "RunningTime": null,
                                            "BestLaptime": null,
                                            "BestLapNumber": null,
                                            "IsBestLapInRace": false,
                                            "IsCurrentLapPBestLap": false,
                                            "Seen": true,
                                            "InPit": false,
                                            "Retired": false,
                                            "GridPosition": 999,
                                            "PositionChange": 0,
                                            "Gap": null,
                                            "gd": null,
                                            "Behind": null,
                                            "Split1": null,
                                            "Split2": null,
                                            "Split3": null,
                                            "IsBestSector1": false,
                                            "pbs1": false,
                                            "IsBestSector2": false,
                                            "pbs2": false,
                                            "IsBestSector3": false,
                                            "pbs3": false,
                                            "cs": null,
                                            "el": null,
                                            "TakenChequered": false,
                                            "ocl": null,
                                            "clnr": false,
                                            "blnr": false,
                                            "tt": null,
                                            "nat": null,
                                            "ps": 0,
                                            "lpe": 0,
                                            "nbc": "#2FAAE1",
                                            "nfc": "#FFFFFF",
                                            "es1": 18810,
                                            "es2": 33700
                                        }
                                    
                                    } else {
                                        var objComp = {
                                            "CompetitorId": data.message.RegNum,
                                            "CompetitorNumber": data.message.Number,
                                            "CompetitorName": data.message.FirstName + " " + data.message.LastName,
                                            "CompetitorClass": data.message.ClassNumber,
                                            "CompetitorSubClass": "",
                                            "DriverName": null,
                                            "Position": 999,
                                            "NumberOfLaps": 0,
                                            "LastLaptime": null,
                                            "RaceTime": null,
                                            "RunningTime": null,
                                            "BestLaptime": null,
                                            "BestLapNumber": null,
                                            "IsBestLapInRace": false,
                                            "IsCurrentLapPBestLap": false,
                                            "Seen": true,
                                            "InPit": false,
                                            "Retired": false,
                                            "GridPosition": 999,
                                            "PositionChange": 0,
                                            "Gap": null,
                                            "gd": null,
                                            "Behind": null,
                                            "Split1": null,
                                            "Split2": null,
                                            "Split3": null,
                                            "IsBestSector1": false,
                                            "pbs1": false,
                                            "IsBestSector2": false,
                                            "pbs2": false,
                                            "IsBestSector3": false,
                                            "pbs3": false,
                                            "cs": null,
                                            "el": null,
                                            "TakenChequered": false,
                                            "ocl": null,
                                            "clnr": false,
                                            "blnr": false,
                                            "tt": null,
                                            "nat": null,
                                            "ps": 0,
                                            "lpe": 0,
                                            "nbc": "#2FAAE1",
                                            "nfc": "#FFFFFF",
                                            "es1": 18810,
                                            "es2": 33700
                                        }
                                    }
                                }
                            

                            

                                session.Competitors.push(objComp)
                            }
                        } else {
                            //get category info
                            var catIndex = session.MultiCls.findIndex(x => x.ClassNum === data.message.ClassNumber);

                            var objComp = {
                                "CompetitorId": data.message.RegNum,
                                "CompetitorNumber": data.message.Number,
                                "CompetitorName": data.message.FirstName + " " + data.message.LastName,
                                "CompetitorClass": data.message.ClassNumber,
                                "CompetitorSubClass": "",
                                "DriverName": null,
                                "Position": 999,
                                "NumberOfLaps": 0,
                                "LastLaptime": null,
                                "RaceTime": null,
                                "RunningTime": null,
                                "BestLaptime": null,
                                "BestLapNumber": null,
                                "IsBestLapInRace": false,
                                "IsCurrentLapPBestLap": false,
                                "Seen": true,
                                "InPit": false,
                                "Retired": false,
                                "GridPosition": 999,
                                "PositionChange": 0,
                                "Gap": null,
                                "gd": null,
                                "Behind": null,
                                "Split1": null,
                                "Split2": null,
                                "Split3": null,
                                "IsBestSector1": false,
                                "pbs1": false,
                                "IsBestSector2": false,
                                "pbs2": false,
                                "IsBestSector3": false,
                                "pbs3": false,
                                "cs": null,
                                "el": null,
                                "TakenChequered": false,
                                "ocl": null,
                                "clnr": false,
                                "blnr": false,
                                "tt": null,
                                "nat": null,
                                "ps": 0,
                                "lpe": 0,
                                "nbc": "#2FAAE1",
                                "nfc": "#FFFFFF",
                                "es1": 18810,
                                "es2": 33700
                            }

                            session.Competitors = [objComp]

                        }
                        break
                    case "$B":
                        if (session.SessionId != data.message.RegNum){
                            //Initialises a new session
                            session = {
                                SessionStartTime: null,
                                ResultAvailable: null,
                                ShowNotifications: null,
                                EventId: "",
                                SessionId: null,
                                SessionName: "",
                                SessionType: null,
                                CircuitMap: null,
                                TrackLength: null,
                                TrackName: null,
                                Weather: null,
                                Temperature: null,
                                TrackCondition: null,
                                IsTeamEvent: null,
                                MultiCls: null,
                                ns: null,
                                nst: null,
                                bn: null,
                                bst: null,
                                bet: null,
                                officials: {
                                    CompetitionSecretary: null,
                                    Timekeeper: null,
                                },
                                dp: null,
                                blt: null,
                                bs1: null,
                                bs2: null,
                                bs3: null,
                                tt: null,
                                SD: "10M+1L",
                                flds: [
                                    "gd",
                                    "ps",
                                    "lpe"
                                ],
                                nto: -0.04,
                                ntst: "2022-11-27T15:33:45.329Z",
                                ntip: "132.163.96.2",
                                ntsn: "time.nist.gov",
                                State: null,
                                fcy: false,
                                Sequence: null,
                                Sectors: null,
                                RaceTime: 0,
                                Rd: null,
                                Last: false,
                                End: false,
                                show_race_time: false,
                                TimingSystem: null,
                                Time: null,
                                Date: null,
                                Clear: false,
                                Competitors: [],
                            }
        
                            controls = {
                                    AutoCol: false,
                                    IncludePs: false,
                                    ClockShow: true,
                                    TowerShow: true,
                                    ResultsShow: false,
                                    NotificationShow: false,
                                    FastestLapShow: false,
                                    ChampionshipShow: true,
                                    ChampionshipSelect: 0,
                                    ComparisonShow: false,
                                    ComparisonLeadDriver: undefined,
                                    TowerMargin: [0,0,35,70,105,140,175,210,245,280,315,350,385,420,455,490,525,560,595,630,665,700,735,770,805,840,875,910,945,980,1015,1050,1085,1120,1155,1190,1225,1260,1295,1330,1365,1400,1435,1470,1505,1540,1575,1610,1645,1680,1715,1750,1785,1820,1855,1890,1925,1960,1995,2030,2065,2100,2135,2170,2205,2240,2275,2310,2345,2380,2415,2450,2485,2520,2555,2590,2625,2660,2695,2730,2765,2800,2835,2870,2905,2940,2975,3010,3045,3080,3115,3150,3185,3220],
                                    ResultsMargin: -100,
                                    TowerPagesMargin: [-385,-350,-315,-280,-245,-210,-175,-140,-105,-70,-35,0,35,70,105,140,175,210,245,280,315,350,385,420,455,490,525,560,595,630,665,700,735,770,805,840,875,910,945,980,1015,1050,1085,1120,1155,1190,1225,1260,1295,1330,1365,1400,1435,1470,1505,1540,1575,1610,1645,1680,1715,1750,1785,1820,1855,1890,1925,1960,1995,2030,2065,2100,2135,2170,2205,2240,2275,2310,2345,2380,2415,2450,2485,2520,2555,2590,2625,2660,2695,2730,2765,2800,2835,2870,2905,2940,2975,3010,3045,3080,3115,3150,3185,3220],
                                    CurrentPage: 0,
                                    BattleShow: false,
                                    BattleDrivers: [],
                                    BattlePosition: undefined,
                                    ProgressionShow: false,
                                    ProgressionPosition: undefined,
                                    Col4: "gap",
                                    Expand: false,
                                    ExpandDriverID: undefined,
                                    Onboard: false,
                                    OnboardDriverID: undefined,
                                    LiveChampsTitle: "LIVE CHAMPIONSHIP",
                                    LiveChampsDisplay: "liveChampHide",
                                    GridPage: 1,
                                    GridShow: false
                            }
                        }
                        //Session information
                        session.SessionName = data.message.RaceName || null
                        session.SessionId = data.message.RegNum || null
                        session.SessionType = "Race"
                        break
                    case '$C':
                        //category information - Need to fix the class colour options so that it autogenerates, similar to the HS code.
                        if(session.MultiCls){
                            var index = session.MultiCls.findIndex(x => x.ClassNum === data.message.ClassNum);
                            if (index > -1){
                                session.MultiCls[index].ClassName = data.message.ClassName
                                session.MultiCls[index].ClassNum = data.message.ClassNum
                            } else {
                                var objCategory = {
                                ClassName: data.message.ClassName,
                                ClassNum: data.message.ClassNum,
                                nbc: "#000000",
                                nfc: "#FFFFFF"
                                }
                                session.MultiCls.push(objCategory)
                            }
                            
                        } else {
                            var objCategory = {
                                ClassName: data.message.ClassName,
                                ClassNum: data.message.ClassNum,
                                nbc: "#2FAAE1",
                                nfc: "#FFFFFF"
                            }

                            session.MultiCls = [objCategory]
                        }
                        break
                    case "$E":
                        //session information
                        if(data.message.Setting == "TRACKLENGTH"){
                            session.TrackLength = data.message.Value
                        }

                        if(data.message.Setting == "TRACKNAME"){
                            session.TrackName = data.message.Value 
                        }
                        break
                    case "$G":
                        //position and lap information
                        if(session){
                            if(session.Competitors){
                            var index = session.Competitors.findIndex(x => x.CompetitorId === data.message.RegNum);
                            session.Competitors[index].NumberOfLaps = data.message.Laps
                            if (session.Competitors[index].Position == 999){
                                session.Competitors[index].GridPosition = parseInt(data.message.Position)
                            }

                            session.Competitors[index].Position = parseInt(data.message.Position)
                            }
                        }
                        break
                    case "$H":
                        //practice/qualifying information
                        if(session){
                            if(session.Competitors){
                                var index = session.Competitors.findIndex(x => x.CompetitorId === data.message.RegNum);
                                // session.Competitors[index].Position = data.message.Position
                                session.Competitors[index].BestLapNumber = data.message.BestLap
                                session.Competitors[index].BestLaptime = rMonitorTimeStamp(data.message.BestLapTime)

                                

                                if(session.blt == null){
                                    if(session){
                                        session.blt = {
                                            cn: session.Competitors[index].CompetitorNumber,
                                            cnpb: session.Competitors[index].nbc,
                                            cnpf: session.Competitors[index].nfc,
                                            ln: session.Competitors[index].BestLapNumber,
                                            tm: session.Competitors[index].BestLaptime,
                                            shown: false
                                        }
                                    }
                                    
                                } else if (session.blt.tm > session.Competitors[index].BestLaptime){
                                    session.blt = {
                                        cn: session.Competitors[index].CompetitorNumber,
                                        cnpb: session.Competitors[index].nbc,
                                        cnpf: session.Competitors[index].nfc,
                                        ln: session.Competitors[index].BestLapNumber,
                                        tm: session.Competitors[index].BestLaptime,
                                        shown: false
                                    }
                                }
                            }
                        }

                        break
                    case "$I":
                        //  resets all data ready for a new session
                        // this function is ignored and instead only refreshed in case B if session id is different
                        // session = {
                        //     SessionStartTime: null,
                        //     ResultAvailable: null,
                        //     ShowNotifications: null,
                        //     EventId: "",
                        //     SessionId: null,
                        //     SessionName: "",
                        //     SessionType: null,
                        //     CircuitMap: null,
                        //     TrackLength: null,
                        //     TrackName: null,
                        //     Weather: null,
                        //     Temperature: null,
                        //     TrackCondition: null,
                        //     IsTeamEvent: null,
                        //     MultiCls: null,
                        //     ns: null,
                        //     nst: null,
                        //     bn: null,
                        //     bst: null,
                        //     bet: null,
                        //     officials: {
                        //         CompetitionSecretary: null,
                        //         Timekeeper: null,
                        //     },
                        //     dp: null,
                        //     blt: null,
                        //     bs1: null,
                        //     bs2: null,
                        //     bs3: null,
                        //     tt: null,
                        //     SD: "10M+1L",
                        //     flds: [
                        //         "gd",
                        //         "ps",
                        //         "lpe"
                        //     ],
                        //     nto: -0.04,
                        //     ntst: "2022-11-27T15:33:45.329Z",
                        //     ntip: "132.163.96.2",
                        //     ntsn: "time.nist.gov",
                        //     State: null,
                        //     fcy: false,
                        //     Sequence: null,
                        //     Sectors: null,
                        //     RaceTime: 0,
                        //     Rd: null,
                        //     Last: false,
                        //     End: false,
                        //     show_race_time: false,
                        //     TimingSystem: null,
                        //     Time: null,
                        //     Date: null,
                        //     Clear: false,
                        //     Competitors: [],
                        // }

                        // controls = {
                        //         AutoCol: false,
                        //         IncludePs: false,
                        //         ClockShow: true,
                        //         TowerShow: true,
                        //         ResultsShow: false,
                        //         NotificationShow: false,
                        //         FastestLapShow: false,
                        //         ChampionshipShow: true,
                        //         ChampionshipSelect: 0,
                        //         ComparisonShow: false,
                        //         ComparisonLeadDriver: undefined,
                        //         TowerMargin: [0,0,35,70,105,140,175,210,245,280,315,350,385,420,455,490,525,560,595,630,665,700,735,770,805,840,875,910,945,980,1015,1050,1085,1120,1155,1190,1225,1260,1295,1330,1365,1400,1435,1470,1505,1540,1575,1610,1645,1680,1715,1750,1785,1820,1855,1890,1925,1960,1995,2030,2065,2100,2135,2170,2205,2240,2275,2310,2345,2380,2415,2450,2485,2520,2555,2590,2625,2660,2695,2730,2765,2800,2835,2870,2905,2940,2975,3010,3045,3080,3115,3150,3185,3220],
                        //         ResultsMargin: -100,
                        //         TowerPagesMargin: [-385,-350,-315,-280,-245,-210,-175,-140,-105,-70,-35,0,35,70,105,140,175,210,245,280,315,350,385,420,455,490,525,560,595,630,665,700,735,770,805,840,875,910,945,980,1015,1050,1085,1120,1155,1190,1225,1260,1295,1330,1365,1400,1435,1470,1505,1540,1575,1610,1645,1680,1715,1750,1785,1820,1855,1890,1925,1960,1995,2030,2065,2100,2135,2170,2205,2240,2275,2310,2345,2380,2415,2450,2485,2520,2555,2590,2625,2660,2695,2730,2765,2800,2835,2870,2905,2940,2975,3010,3045,3080,3115,3150,3185,3220],
                        //         CurrentPage: 0,
                        //         BattleShow: false,
                        //         BattleDrivers: [],
                        //         BattlePosition: undefined,
                        //         ProgressionShow: false,
                        //         ProgressionPosition: undefined,
                        //         Col4: "gap",
                        //         Expand: false,
                        //         ExpandDriverID: undefined,
                        //         LiveChampsTitle: "LIVE CHAMPIONSHIP",
                        //         LiveChampsDisplay: "liveChampHide",
                        // }

                        // console.log(session)
                        break
                    case "$J":
                        //lap passing information
                        if(session){
                            var index = session.Competitors.findIndex(x => x.CompetitorId === data.message.RegNum);
                        session.Competitors[index].LastLaptime = rMonitorTimeStamp(data.message.LapTime);
                        session.Competitors[index].RunningTime = rMonitorTimeStamp(data.message.TotalTime);

                        var indexLeader = session.Competitors.findIndex(x => x.Position == "1");
                        // session.Competitors[index].CompetitorNumber = indexLeader;
                        var indexPosForward = session.Competitors.findIndex(x => x.Position == session.Competitors[index].Position - 1);

                        if(session.Competitors[index].Position != 1){
                        //Calculate Time Behind leader
                            if(indexLeader > -1 && session.Competitors[indexLeader].RunningTime != null){
                                if(session.Competitors[index].NumberOfLaps == session.Competitors[indexLeader].NumberOfLaps){
                                    console.log("Here")
                                    session.Competitors[index].Behind = msToTime(session.Competitors[index].RunningTime - session.Competitors[indexLeader].RunningTime)
                                } else {
                                    session.Competitors[index].Behind = msToTime(session.Competitors[index].RunningTime - session.Competitors[indexLeader].RunningTime)
                                    // session.Competitors[index].Behind = session.Competitors[indexLeader].NumberOfLaps - session.Competitors[index].NumberOfLaps 
                                    // if (session.Competitors[index].Behind > 1){
                                    //     session.Competitors[index].Behind = session.Competitors[index].Behind + " Laps"
                                    // } else {
                                    //     session.Competitors[index].Behind = session.Competitors[index].Behind + " Lap"
                                    // }
                                }
                                
                            } else {
                                session.Competitors[index].Behind = null
                            }
                            
                            //Calculate Behind pos infront
                            if(indexPosForward > -1 && session.Competitors[indexPosForward].RunningTime != null){
                                if(session.Competitors[index].NumberOfLaps == session.Competitors[indexPosForward].NumberOfLaps){
                                    session.Competitors[index].Gap = msToTime(session.Competitors[index].RunningTime - session.Competitors[indexPosForward].RunningTime)
                                } else {
                                    session.Competitors[index].Gap = msToTime(session.Competitors[index].RunningTime - session.Competitors[indexPosForward].RunningTime)
                                
                                    // session.Competitors[index].Gap = session.Competitors[indexPosForward].NumberOfLaps - session.Competitors[index].NumberOfLaps 
                                    // if (session.Competitors[index].Gap > 1){
                                    //     session.Competitors[index].Gap = session.Competitors[index].Gap + " Laps"
                                    // } else {
                                    //     session.Competitors[index].Gap = session.Competitors[index].Gap + " Lap"
                                    // }
                                }
                            } else {
                                session.Competitors[index].Gap = null
                            } 
                        } else {
                            session.Competitors[index].Behind = null
                            session.Competitors[index].Gap = null
                        }
                        }
                        
                        

                        break
                    case "$F":
                    console.log(data.message.FlagStatus)
                        if(data.message.FlagStatus == "Yellow"){
                            session.fcy = true
                        } else {
                            session.fcy = false
                        }

                        if(data.message.FlagStatus == "Red   "){
                            session.State = "RedFlag"
                        } else if (data.message.FlagStatus == "Green ") {
                            session.State = "Running"
                        } else if (data.message.FlagStatus == "Finish") {
                            session.State = "ChequeredFlag"
                        }

                        //Remaining Duration
                        if (data.message.LapsToGo == 9999){
                            session.SD = "rMonitor - Time"
                            this.setState({
                                timeRemaining: data.message.TimeToGo
                            })
                        } else {
                            session.SD = "rMonitor - Laps"
                            this.setState({
                                timeRemaining: data.message.LapsToGo
                            })
                        }
                        
                        break
                    case "COMP":
                        if(session.Competitors){
                            console.log(session.Competitors)
                            console.log(session.MultiCls)
                            var index = session.Competitors.findIndex(x => x.CompetitorId === data.message.RegNum);
                            if (index > -1){
                                session.Competitors[index].CompetitorClass = data.message.ClassNum
                                session.Competitors[index].CompetitorName = data.message.FirstName + " " + data.message.LastName
                                session.Competitors[index].nat = data.message.Nationality
                                session.Competitors[index].CompetitorNumber = data.message.Number
                            } else {
                                //get category info
                            var catIndex = session.MultiCls.findIndex(x => x.ClassNum === data.message.ClassNumber);

                            var objComp = {
                                "CompetitorId": data.message.RegNum,
                                "CompetitorNumber": data.message.Number,
                                "CompetitorName": data.message.FirstName + " " + data.message.LastName,
                                "CompetitorClass": data.message.ClassNumber,
                                "CompetitorSubClass": "",
                                "DriverName": null,
                                "Position": 999,
                                "NumberOfLaps": 0,
                                "LastLaptime": null,
                                "RaceTime": null,
                                "RunningTime": null,
                                "BestLaptime": null,
                                "BestLapNumber": null,
                                "IsBestLapInRace": false,
                                "IsCurrentLapPBestLap": false,
                                "Seen": true,
                                "InPit": false,
                                "Retired": false,
                                "GridPosition": 999,
                                "PositionChange": 0,
                                "Gap": null,
                                "gd": null,
                                "Behind": null,
                                "Split1": null,
                                "Split2": null,
                                "Split3": null,
                                "IsBestSector1": false,
                                "pbs1": false,
                                "IsBestSector2": false,
                                "pbs2": false,
                                "IsBestSector3": false,
                                "pbs3": false,
                                "cs": null,
                                "el": null,
                                "TakenChequered": false,
                                "ocl": null,
                                "clnr": false,
                                "blnr": false,
                                "tt": null,
                                "nat": null,
                                "ps": 0,
                                "lpe": 0,
                                "nbc": session.MultiCls[catIndex].nbc,
                                "nfc": session.MultiCls[catIndex].nfc,
                                "es1": 18810,
                                "es2": 33700
                            }

                                session.Competitors.push(objComp)
                            }
                        } else {
                            //get category info
                            var catIndex = session.MultiCls.findIndex(x => x.ClassNum === data.message.ClassNumber);

                            var objComp = {
                                "CompetitorId": data.message.RegNum,
                                "CompetitorNumber": data.message.Number,
                                "CompetitorName": data.message.FirstName + " " + data.message.LastName,
                                "CompetitorClass": data.message.ClassNumber,
                                "CompetitorSubClass": "",
                                "DriverName": null,
                                "Position": 999,
                                "NumberOfLaps": 0,
                                "LastLaptime": null,
                                "RaceTime": null,
                                "RunningTime": null,
                                "BestLaptime": null,
                                "BestLapNumber": null,
                                "IsBestLapInRace": false,
                                "IsCurrentLapPBestLap": false,
                                "Seen": true,
                                "InPit": false,
                                "Retired": false,
                                "GridPosition": 999,
                                "PositionChange": 0,
                                "Gap": null,
                                "gd": null,
                                "Behind": null,
                                "Split1": null,
                                "Split2": null,
                                "Split3": null,
                                "IsBestSector1": false,
                                "pbs1": false,
                                "IsBestSector2": false,
                                "pbs2": false,
                                "IsBestSector3": false,
                                "pbs3": false,
                                "cs": null,
                                "el": null,
                                "TakenChequered": false,
                                "ocl": null,
                                "clnr": false,
                                "blnr": false,
                                "tt": null,
                                "nat": null,
                                "ps": 0,
                                "lpe": 0,
                                "nbc": session.MultiCls[catIndex].nbc,
                                "nfc": session.MultiCls[catIndex].nfc,
                                "es1": 18810,
                                "es2": 33700
                            }

                            session.Competitors = [objComp]

                        }
                        break
                    case "COR":
                        break
                }
            } else if (data.author == "TimeService"){

                var timeService = this.state.TimeService

                function cleanJsonData(data) {
                    // Parse the JSON string
                    // let data = JSON.parse(jsonString);
                
                    // Iterate over each key in the object
                    for (let key in data) {
                        // Check if the property value is a string
                        if (typeof data[key] === 'string') {
                            // Remove leading and trailing quotes if present
                            data[key] = data[key].replace(/^"|"$/g, '');
                        }
                    }
                
                    return data;
                }

                data.message = cleanJsonData(data.message)

                function findSessionIndex(data, id){
                    var raceIndex = data.findIndex( x => x.SessionId === id)
                    return raceIndex
                }

                function findEnrollmentIndex(data, id){
                    var enrollmentIndex = data.findIndex( x => x.CompetitorId === id)
                    return enrollmentIndex
                }

                function findDriverIndex(data, id){
                    var driverIndex = data.findIndex(x => x.DriverID === id)
                    return driverIndex
                  }

                function sortBySessionName(array) {
                    return array.sort((a, b) => {
                        let nameA = a.SessionName.toUpperCase(); // ignore upper and lowercase
                        let nameB = b.SessionName.toUpperCase(); // ignore upper and lowercase
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }
                
                        // names must be equal
                        return 0;
                    });
                }

                switch(data.type){
                    case 'init':
                        console.log('init', data.message1, data.message2)

                        races = sortBySessionName(data.message1)
                        timeService = data.message2
                        break
                    case 0:
                        // Protocol version information
                        console.log("Time Service Protocol = " + data.message)
                        break
                    case 1:
                        // This record relates to a collection of enrollments called an Equip 
                        // I think this is Team information but may have more than one car racing, so a collection of teams such as Fusion or Synergy.
                        break
                    case 2:
                        // I believe this is the driver information or team information if team.
                        // If this is a single person then the definition Driver Data (DataID=3) does not exist. The Field Data “TeamName” also does not exist, but the
                        // Field Names NameF, NameM and NameL do exist and are filled with the name of the single driver.
                        var enrollmentIndex = timeService.Enrollments.findIndex( x => x.CompetitorId == data.message.EnrollmentID)
                        if(enrollmentIndex > -1){
                            //Modify existing enrollment record
                            var enrollmentObj = {
                                EquipeID: data.message.EquipeID || timeService.Enrollments[enrollmentIndex].EquipeID || "",
                                CompetitorId: data.message.EnrollmentID || timeService.Enrollments[enrollmentIndex].CompetitorId || "",
                                NameF: data.message.NameF || timeService.Enrollments[enrollmentIndex].NameF || "",
                                NameM: data.message.NameM || timeService.Enrollments[enrollmentIndex].NameM || "",
                                NameL: data.message.NameL || timeService.Enrollments[enrollmentIndex].NameL || "",
                                Nationality: data.message.Nationality || timeService.Enrollments[enrollmentIndex].Nationality || "",
                                ShortName:data.message. ShortName || timeService.Enrollments[enrollmentIndex].ShortName || "",
                                ClubId: data.message.ClubId || timeService.Enrollments[enrollmentIndex].ClubId || "",
                                StartNumber: data.message.StartNumber || timeService.Enrollments[enrollmentIndex].StartNumber || "",
                                TeamName: data.message.TeamName || timeService.Enrollments[enrollmentIndex].TeamName || "",
                                VehicleBrand: data.message.VehicleBrand || timeService.Enrollments[enrollmentIndex].VehicleBrand || "",
                                VehicleType: data.message.VehicleType || timeService.Enrollments[enrollmentIndex].VehicleType || "",
                                Sponsor: data.message.Sponsor || timeService.Enrollments[enrollmentIndex].VehicleType || "",
                                Tyres: data.message.Tyres || timeService.Enrollments[enrollmentIndex].Tyres || "",
                                Note: data.message.Note || timeService.Enrollments[enrollmentIndex].Note || "",
                                RaceClassGroup: data.message.RaceClassGroup || timeService.Enrollments[enrollmentIndex].RaceClassGroup || "",
                                RaceClass: data.message.RaceClass || timeService.Enrollments[enrollmentIndex].RaceClass || "",
                                ChampPos: data.message.ChampPos || timeService.Enrollments[enrollmentIndex].ChampPos || "",
                                ChampPoints: data.message.ChampPoints || timeService.Enrollments[enrollmentIndex].ChampPoints || "",
                                ShortName: data.message.ShortName || timeService.Enrollments[enrollmentIndex].ShortName || "",
                                Entrant: data.message.Entrant || timeService.Enrollments[enrollmentIndex].Entrant || "",
                                DivisionName: data.message.DivisionName || timeService.Enrollments[enrollmentIndex].DivisionName || "",
                                TeamShortName: data.message.TeamShortName || timeService.Enrollments[enrollmentIndex].TeamShortName || "",
                            }

                            if(enrollmentObj.RaceClass){
                                var raceClassIndex = this.state.RaceClass.findIndex( x => x.name == enrollmentObj.RaceClass)
                                if(raceClassIndex > -1 ){
                                    enrollmentObj.nbc = this.state.RaceClass[raceClassIndex].nbc
                                    enrollmentObj.nfc = this.state.RaceClass[raceClassIndex].nfc
                                }
                            } else if (enrollmentObj.RaceClassGroup){
								var raceClassIndex = this.state.RaceClass.findIndex( x => x.name == enrollmentObj.RaceClassGroup)
								if(raceClassIndex > -1 ){
									enrollmentObj.nbc = this.state.RaceClass[raceClassIndex].nbc
									enrollmentObj.nfc = this.state.RaceClass[raceClassIndex].nfc
								}
							}

                            timeService.Enrollments[enrollmentIndex] = enrollmentObj
                        } else {
                            //Add new enrollment record
                            var enrollmentObj = {
                                EquipeID: data.message.EquipeID || "",
                                CompetitorId: data.message.EnrollmentID || "",
                                NameF: data.message.NameF || "",
                                NameM: data.message.NameM || "",
                                NameL: data.message.NameL || "",
                                Nationality: data.message.Nationality || "",
                                ShortName:data.message. ShortName || "",
                                ClubId: data.message.ClubId || "",
                                StartNumber: data.message.StartNumber || "",
                                TeamName: data.message.TeamName || "",
                                VehicleBrand: data.message.VehicleBrand || "",
                                VehicleType: data.message.VehicleType || "",
                                Sponsor: data.message.Sponsor || "",
                                Tyres: data.message.Tyres || "",
                                Note: data.message.Note || "",
                                RaceClassGroup: data.message.RaceClassGroup || "",
                                RaceClass: data.message.RaceClass || "",
                                ChampPos: data.message.ChampPos || "",
                                ChampPoints: data.message.ChampPoints || "",
                                ShortName: data.message.ShortName || "",
                                Entrant: data.message.Entrant || "",
                                DivisionName: data.message.DivisionName || "",
                                TeamShortName: data.message.TeamShortName || "",
                            }
                            if(enrollmentObj.RaceClass){
                                var raceClassIndex = this.state.RaceClass.findIndex( x => x.name == enrollmentObj.RaceClass)
                                if(raceClassIndex > -1 ){
                                    enrollmentObj.nbc = this.state.RaceClass[raceClassIndex].nbc
                                    enrollmentObj.nfc = this.state.RaceClass[raceClassIndex].nfc
                                }
                            } else if (enrollmentObj.RaceClassGroup){
								var raceClassIndex = this.state.RaceClass.findIndex( x => x.name == enrollmentObj.RaceClassGroup)
								if(raceClassIndex > -1 ){
									enrollmentObj.nbc = this.state.RaceClass[raceClassIndex].nbc
									enrollmentObj.nfc = this.state.RaceClass[raceClassIndex].nfc
								}
							}
                            timeService.Enrollments[timeService.Enrollments.length] = enrollmentObj
                        }

                        break
                    case 3:
                        // This record is only in use if it's a team, it will be the data for a individual driver in the team
                        var driverIndex = timeService.Drivers.findIndex( x => x.DriverID == data.message.DriverID)
                        if(driverIndex > -1){
                            //Modify existing driver record
                            var driverObj = {
                                DriverID: data.message.DriverID || timeService.Drivers[driverIndex].DriverID || "",
                                EnrollmentID: data.message.EnrollmentID || timeService.Drivers[driverIndex].EnrollmentID || "",
                                NameF: data.message.NameF || timeService.Drivers[driverIndex].NameF || "",
                                NameM: data.message.NameM || timeService.Drivers[driverIndex].NameM || "",
                                NameL: data.message.NameL || timeService.Drivers[driverIndex].NameL || "",
                                Nationality: data.message.Nationality || timeService.Drivers[driverIndex].Nationality || "",
                                ShortName:data.message. ShortName || timeService.Drivers[driverIndex].ShortName || "",
                                ClubId: data.message.ClubId || timeService.Drivers[driverIndex].ClubId || "",
                            }

                            timeService.Drivers[driverIndex] = driverObj
                        } else {
                            //Add new driver record
                            var driverObj = {
                                DriverID: data.message.DriverID || "",
                                EnrollmentID: data.message.EnrollmentID || "",
                                NameF: data.message.NameF || "",
                                NameM: data.message.NameM || "",
                                NameL: data.message.NameL || "",
                                Nationality: data.message.Nationality || "",
                                ShortName:data.message. ShortName || "",
                                ClubId: data.message.ClubId || "",
                            }
                            timeService.Drivers[timeService.Drivers.length] = driverObj
                        } 
                        break
                    case 4:
                        // Time of day sync - example CurrentTime=756499982011000, timestr=2023-12-21 - 18:53:02.011
                        timeService.CurrentTime = data.message.CurrentTime || timeService.CurrentTime || null
                        timeService.TimeStr = data.message.TimeStr || timeService.TimeStr || null
                        if (data.message.CurrentTime){
                            //Calculate Time Offset
                            let now = Date.now() * 1000
                            timeService.LocalTime = now
                            timeService.TimeOffset = now - data.message.CurrentTime 

                        }
                        break
                    case 5:
                        // Heat (Session) Data
                        // A individual object will be sent for each session in the timing system.
                        // A check is require to know if it's a change to the session object which is the active session, if not it needs to adjust the information in State.Races array or add to this array if new
                        if (data.message.HeatID == session.SessionId){
                            //Modify the existing session
                            session.SessionName = data.message.HeatName || session.SessionName || "No Name"
                            session.SeriesName = data.message.GroupName || session.SeriesName || "No Series"
                            session.SessionStartTime = data.message.TimeStarted || session.SessionStartTime || ""
                            session.Last = data.message.LastLap || session.Last || false
                            session.LengthMode = data.message.LengthMode || session.LengthMode || "0"
                            session.PoleSide = data.message.Pole || session.Pole || "L"
                            session.RacetimeClockHalted = data.message.RacetimeClockHalted || session.RacetimeClockHalted || 0
                            session.ClockHaltedDuration = data.message.ClockHaltedDuration || session.ClockHaltedDuration || 0

                            //Session State Calculation
                            if(data.message.Status){
                                switch(data.message.Status){
                                    case "PENDING":
                                        var raceState = "Formation"
                                        var fcy = false
                                        var ended = false
                                        break
                                    case "ACTIVE":
                                        var raceState = "Formation"
                                        var fcy = false
                                        var ended = false
                                        break
                                    case "GREEN":
                                        var raceState = "Running"
                                        var fcy = false
                                        var ended = false
                                        break
                                    case "FULLCOURSEYELLOW":
                                        var raceState = "Running"
                                        var fcy = true
                                        var ended = false
                                        break
                                    case "YELLOW":
                                        var raceState = "Running"
                                        var fcy = true
                                        var ended = false
                                        break
                                    case "SAFETYCAR":
                                        var raceState = "Running"
                                        var fcy = true
                                        var ended = false
                                        break
                                    case "CODE60":
                                        var raceState = "Running"
                                        var fcy = true
                                        var ended = false
                                        break
                                    case "RED":
                                        var raceState = "RedFlag"
                                        var fcy = false
                                        var ended = false
                                        break
                                    case "FINISH":
                                        var raceState = "ChequeredFlag"
                                        var fcy = false
                                        var ended = false
                                        break
                                    case "COMPLETED":
                                        var raceState = "ChequeredFlag"
                                        var ended = true
                                        var fcy = false
                                        break
                                }

                                session.State = raceState || "Formation"
                                session.fcy = fcy
                                session.End = ended
                            }
                            
                            //Session Duration Calculation
                            //First convert the Micro seconds to Hours and Minutes
                            if(data.message.TimeLength || data.message.LapsLength){
                                if (data.message.TimeLength){
                                    session.LengthTime = data.message.TimeLength
                                    var totalSeconds = data.message.TimeLength / 1000000;
                                    var hours = Math.floor(totalSeconds / 3600);
                                    totalSeconds %= 3600;
                                    var minutes = Math.floor(totalSeconds / 60);
                                }

                                if (data.message.LengthMode != "1"){
                                    var laps = parseInt(data.message.LapsLength)
                                    if(hours && minutes){
                                        var sessionDuration = hours+"H" +minutes +"M + " + 1 +"L"
                                    } else if (hours){
                                        var sessionDuration = hours+"H + "+ 1 +"L"
                                    } else if (minutes){
                                        var sessionDuration = minutes +"M + " + 1 +"L"
                                    } else {
                                        var sessionDuration = laps +"L"
                                        session.LapsDuration = parseInt(laps)
                                    }
                                } else {
                                    if(data.message.LapsLength == "0"){
                                    if(hours && minutes){
                                        var sessionDuration = hours+"H" +minutes +"M"
                                        } else if (hours){
                                            var sessionDuration = hours+"H"
                                        } else {
                                            var sessionDuration = minutes+"M"
                                        } 
                                    } else {
                                        var laps = parseInt(data.message.LapsLength)
                                        var sessionDuration = laps +"L"
                                    }
                                    
                                } 
                            }
                            session.SD = sessionDuration || session.SD
                            session.LapsDuration = laps || session.LapsDuration || 0
                            
                            
                            //Covert HeatType to Qual or Race for SessionType
                            if (data.message.HeatType){
                                session.SessionType = data.message.HeatType == 'Q' ? 'Qual' : 'Race'
                            }

                            //Laps Remaining Calculation if required
                            if (session.LengthMode == 1 && data.message.Laps && session.LapsDuration){
                                session.Rd = session.LapsDuration - parseInt(data.message.Laps)
                            }





                        } else {
                            //Find the session in the Races list and modify or create a new session if does not exist.
                            //Find session
                            if(races){
                                var raceIndex = races.findIndex( x => x.SessionId === data.message.HeatID)
                                if(raceIndex == -1){
                                    raceIndex = races.length
                                }
                            } else {
                                var raceIndex = 0
                            }
                            
                            //Modify or create the new session
                            if(!races[raceIndex]){
                                races[raceIndex] = {
                                    SessionStartTime: "2022-11-27T15:17:50.9357426+00:00",
                                    ResultAvailable: true,
                                    ShowNotifications: true,
                                    EventId: 123456789,
                                    SessionId: 123456789,
                                    SessionName: "No Data",
                                    SessionType: "Race",
                                    CircuitMap: "No Data",
                                    TrackLength: 0,
                                    TrackName: "No Data",
                                    Weather: null,
                                    Temperature: null,
                                    TrackCondition: null,
                                    IsTeamEvent: false,
                                    MultiCls: false,
                                    ns: null,
                                    nst: null,
                                    bn: null,
                                    bst: null,
                                    bet: null,
                                    officials: {
                                        CompetitionSecretary: "Paul Sirett",
                                        Timekeeper: "Amy Catchpole"
                                    },
                                    dp: 2,
                                    blt: {
                                        cn: 11,
                                        cnpb: "#FF0000",
                                        cnpf: "#FFFFFF",
                                        ln: 17,
                                        tm: 46200
                                    },
                                    bs1: {
                                        cn: 11,
                                        cnpb: "#FF0000",
                                        cnpf: "#FFFFFF",
                                        ln: 17,
                                        tm: 15060
                                    },
                                    bs2: {
                                        cn: 33,
                                        cnpb: "#FF0000",
                                        cnpf: "#FFFFFF",
                                        ln: 17,
                                        tm: 31030
                                    },
                                    bs3: null,
                                    tt: null,
                                    SD: "12m + 1L",
                                    flds: [
                                        "gd",
                                        "ps",
                                        "lpe"
                                    ],
                                    nto: -0.04,
                                    ntst: "2022-11-27T15:33:45.329Z",
                                    ntip: "132.163.96.2",
                                    ntsn: "time.nist.gov",
                                    State: "Ended",
                                    fcy: false,
                                    Sequence: 1273,
                                    Sectors: "3",
                                    RaceTime: 0,
                                    Rd: "",
                                    Last: false,
                                    End: false,
                                    show_race_time: true,
                                    TimingSystem: "na",
                                    Date: todayDate,
                                    Clear: false,
                                    Competitors: []
                                }
                            }
                            
                            races[raceIndex].SessionId = data.message.HeatID
                            races[raceIndex].SessionName = data.message.HeatName || races[raceIndex].SessionName || "No Name"
                            races[raceIndex].SeriesName = data.message.GroupName || races[raceIndex].SeriesName || "No Series"
                            races[raceIndex].SessionStartTime = data.message.TimeStarted || races[raceIndex].SessionStartTime || ""
                            races[raceIndex].Last = data.message.LastLap || races[raceIndex].Last || false
                            races[raceIndex].LengthMode = data.message.LengthMode || races[raceIndex].LengthMode || '1'
                            races[raceIndex].PoleSide = data.message.Pole || races[raceIndex].Pole || "L"
                            races[raceIndex].RacetimeClockHalted = data.message.RacetimeClockHalted || races[raceIndex].RacetimeClockHalted || 0
                            races[raceIndex].ClockHaltedDuration = data.message.ClockHaltedDuration || races[raceIndex].ClockHaltedDuration || 0

                            //Session State Calculation
                            if(data.message.Status){
                                switch(data.message.Status){
                                    case "PENDING":
                                        var raceState = "Formation"
                                        break
                                    case "ACTIVE":
                                        var raceState = "Formation"
                                        break
                                    case "GREEN":
                                        var raceState = "Running"
                                        break
                                    case "YELLOW":
                                        var raceState = "Running"
                                        var fcy = true
                                        break
                                    case "CODE60":
                                        var raceState = "Running"
                                        var fcy = true
                                        break
                                    case "RED":
                                        var raceState = "RedFlag"
                                        break
                                    case "FINISH":
                                        var raceState = "ChequeredFlag"
                                        break
                                    case "COMPLETED":
                                        var raceState = "ChequeredFlag"
                                        var ended = true
                                        break
                                }

                                races[raceIndex].State = raceState || "Formation"
                            }
                            
                            //Session Duration Calculation
                            //First convert the Micro seconds to Hours and Minutes
                            if(data.message.TimeLength || data.message.LapsLength){
                                if (data.message.TimeLength){
                                    races[raceIndex].LengthTime = data.message.TimeLength
                                    var totalSeconds = data.message.TimeLength / 1000000;
                                    var hours = Math.floor(totalSeconds / 3600);
                                    totalSeconds %= 3600;
                                    var minutes = Math.floor(totalSeconds / 60);
                                }

                                if (races[raceIndex].LengthMode != "1"){
                                    var laps = parseInt(data.message.LapsLength)
                                    if(hours && minutes){
                                        var sessionDuration = hours+"H" +minutes +"M + " + 1 +"L"
                                    } else if (hours){
                                        var sessionDuration = hours+"H + "+ 1 +"L"
                                    } else if (minutes){
                                        var sessionDuration = minutes +"M + " + 1 +"L"
                                    } else {
                                        var sessionDuration = laps +"L"
                                        races[raceIndex].LapsDuration = parseInt(laps)
                                    }
                                } else {
                                    if(data.message.LapsLength == "0"){
                                    if(hours && minutes){
                                        var sessionDuration = hours+"H" +minutes +"M"
                                        } else if (hours){
                                            var sessionDuration = hours+"H"
                                        } else {
                                            var sessionDuration = minutes+"M"
                                        } 
                                    } else {
                                        var laps = parseInt(data.message.LapsLength)
                                        var sessionDuration = laps +"L"
                                    }
                                    
                                } 
                            }
                            races[raceIndex].SD = sessionDuration || races[raceIndex].SD
                            races[raceIndex].LapsDuration = laps || races[raceIndex].LapsDuration || 0

                            //Covert HeatType to Qual or Race for SessionType
                            if (data.message.HeatType){
                                races[raceIndex].SessionType = data.message.HeatType == 'Q' ? 'Qual' : 'Race'
                            }

                            //Laps Remaining Calculation if required
                            if (races[raceIndex].LengthMode == 1 && data.message.Laps && races[raceIndex].LapsDuration){
                                races[raceIndex].Rd = races[raceIndex].LapsDuration - parseInt(data.message.Laps)
                            }
                        }
                        
                        break
                    case 6:
                        // Lap Data
                        // Find session index using the session function
                        if(data.message.HeatID == session.SessionId){
                            var raceIndex = -2 //set raceIndex to -2 to help identify that we are working with the active session
                            var tempSession = session //create a temp session object that will be later moved into the main object
                        } else {
                            var raceIndex = findSessionIndex(races,data.message.HeatID)
                            if(raceIndex > -1){
                                var tempSession = races[raceIndex] //create a temp session object that will be later moved into the main object
                            }
                        }
                        var compArray = tempSession.Competitors
                        // Look for competitor in session, then look for competitor in drivers list
                        var enrollmentIndex = findEnrollmentIndex(compArray, data.message.EnrollmentID)

                        if(enrollmentIndex == -1){
                            // Look for enrollment information in TimeService state object
                            var newEnrollmentIndex = findEnrollmentIndex(timeService.Enrollments, data.message.EnrollmentID)
                            if(newEnrollmentIndex > -1){
                                var enrollmentObj = timeService.Enrollments[newEnrollmentIndex]   
								
								if(timeService.Enrollments[newEnrollmentIndex].NameF || timeService.Enrollments[newEnrollmentIndex].NameL ){
									var DriverName = timeService.Enrollments[newEnrollmentIndex].NameF +" "+ timeService.Enrollments[newEnrollmentIndex].NameL
								}
                            } else {
                                console.log("No Enrollment found")
                                break
                            }
                            // Create new competitor object for session

                
                              if(data.message.DriverID){
                                var driverIndex = findDriverIndex(timeService.Drivers, data.message.DriverID)
                                console.log(driverIndex)
                                if(driverIndex > -1){
                                    var DriverName = timeService.Drivers[driverIndex].NameF + " " + timeService.Drivers[driverIndex].NameL
                                }
                              }

                            var competitor = {
                                "CompetitorId": data.message.EnrollmentID,
                                "CompetitorNumber": timeService.Enrollments[newEnrollmentIndex].StartNumber || 0,
                                "CompetitorName": DriverName || timeService.Enrollments[newEnrollmentIndex].TeamName || "Undefined",
								"CompetitorLastName": timeService.Drivers[driverIndex].NameL || "",
                                "CompetitorClass": timeService.Enrollments[newEnrollmentIndex].RaceClass,
                                "CompetitorSubClass": timeService.Enrollments[newEnrollmentIndex].RaceClassGroup,
                                "teamName": timeService.Enrollments[newEnrollmentIndex].Entrant || timeService.Enrollments[newEnrollmentIndex].TeamName || "",
                                "vehicle": timeService.Enrollments[newEnrollmentIndex].VehicleBrand || "",
                                "DriverName": timeService.Enrollments[newEnrollmentIndex].ShortName || "",
                                "Position": 999,
                                "NumberOfLaps": 0,
                                "PositionInClass": 999,
                                "LastLaptime": null,
                                "RaceTime": null,
                                "RunningTime": null,
                                "BestLaptime": null,
                                "BestLapNumber": null,
                                "IsBestLapInRace": false,
                                "IsCurrentLapPBestLap": false,
                                "Seen": true,
                                "InPit": false,
                                "Retired": false,
                                "GridPosition": 999,
                                "PositionChange": 0,
                                "Gap": null,
                                "gd": null,
                                "Behind": null,
                                "Split1": null,
                                "Split2": null,
                                "Split3": null,
                                "IsBestSector1": false,
                                "pbs1": false,
                                "IsBestSector2": false,
                                "pbs2": false,
                                "IsBestSector3": false,
                                "pbs3": false,
                                "cs": 1,
                                "el": null, //estimated lap
                                "TakenChequered": false,
                                "ocl": true,
                                "clnr": false,
                                "blnr": false,
                                "tt": null,
                                "nat": timeService.Enrollments[newEnrollmentIndex].Nationality || null,
                                "ps": 0,
                                "lpe": 0,
                                "nbc": timeService.Enrollments[newEnrollmentIndex].nbc || "#0A72F2",
                                "nfc": timeService.Enrollments[newEnrollmentIndex].nfc || "#FFFFFF",
                                "es1": null, //estimated s1
                                "es2": null, //estimated s2
                                "es3": null  //estimated s3
                            }

                            enrollmentIndex = compArray.length
                        } else {
                            var competitor = compArray[enrollmentIndex]

                            if(data.message.DriverID){
                                var driverIndex = findDriverIndex(timeService.Drivers, data.message.DriverID)
                                if(driverIndex > -1){
                                    if(competitor.nameOveride != true){
                                      competitor.CompetitorName = timeService.Drivers[driverIndex].NameF + " " + timeService.Drivers[driverIndex].NameL
									  competitor.CompetitorLastName = timeService.Drivers[driverIndex].NameL || ""
                                    }
                                }
                            }
                        }

                        if(data.message.Diff){
                            if (data.message.Diff < 0){
                                if (data.message.Diff < -1){
                                    var gap = data.message.Diff + " Laps"
                                } else {
                                    var gap = data.message.Diff + " Lap"
                                }
                            } else if (data.message.Diff == "9223372036854775807"){
                                var gap = 0
                            }
                        }

                        if(data.message.Gap){
                            if (data.message.Gap < 0){
                                if (data.message.Gap < -1){
                                    var behind = data.message.Gap + " Laps"
                                } else {
                                    var behind = data.message.Gap + " Lap"
                                }
                                
                            } else if (data.message.Gap == "9223372036854775807"){
                                var behind = 0
                            }
                        }

                        

                        competitor.Position = parseInt(data.message.Pos) || competitor.Position || 999
                        competitor.NumberOfLaps = data.message.Laps || competitor.NumberOfLaps || 0
                        competitor.PositionInClass = parseInt(data.message.PIC) || competitor.PositionInClass || 999
                        competitor.Gap = gap || this.msToTime(data.message.Diff) || competitor.Gap || null
                        competitor.Behind =  behind || this.msToTime(data.message.Gap) || competitor.Behind || null
                        competitor.LastLaptime = data.message.LastLap/1000 || competitor.LastLaptime || null
                        competitor.BestLaptime = data.message.FastLap/1000 || competitor.BestLaptime || null
                        competitor.BestLapNumber = data.message.FastLapNr || competitor.BestLapNumber || null

                        if (data.message.LastLapImprovement){
                            switch (parseInt(data.message.LastLapImprovement)){
                                case 0:
                                    break
                                case 1:
                                    break
                                case 2:
                                    //New overall fastest lap
                                    tempSession.blt.tm = data.message.LastLap/1000
                                    tempSession.blt.cn = parseInt(competitor.CompetitorNumber)
                                    tempSession.blt.shown = false
                                    tempSession.blt.ln = parseInt(competitor.NumberOfLaps)
                                    tempSession.blt.cnpb = competitor.nbc
                                    tempSession.blt.cnpf = competitor.nfc
                                    tempSession.blt.pusher = true
                                    break
                            }
                        }

                        if (data.message.Laps){
                            //Add lap to lap time array
                            var lapObj = {
                                LapNo: competitor.NumberOfLaps,
                                LapTime: competitor.LastLaptime
                            }
                            if(competitor.LapData){
                                competitor.LapData[competitor.LapData.length] = lapObj
                            } else {
                                competitor.LapData = [lapObj]
                            }
                        }

                        // Competitor State Conversion
                        if (data.message.State){
                            switch (parseInt(data.message.State)){
                                case 0:
                                    // 0: Participation finished
                                    competitor.TakenChequered = true
                                    competitor.Retired = false
                                    break
                                case 1:
                                    // 1: Participation racing
                                    competitor.TakenChequered = false
                                    competitor.Retired = false
                                    break
                                case 2:
                                    // 2: Participation in the tank zone
                                    break
                                case 3:
                                    // 3: Participation in pit
                                    if(competitor.InPit != true){
                                        competitor.ps = competitor.ps+1
                                    }
                                    competitor.InPit = true
                                    competitor.Retired = false
                                    break
                                case 4:
                                    // 4: Participation in outlap
                                    competitor.InPit = false
                                    competitor.Retired = false
                                    break
                                case 5:
                                    // 5: Participation is missing (not in pit and not seen for more than 100 sec.)
                                    competitor.Retired = true
                                    break
                                case 6:
                                    // 6: Participation aborted
                                    break
                                case 7:
                                    // 7: Participation is waiting for a start signal
                                    break
                                case 8:
                                    // 8: Initial (unknown) state
                                    break
                                case 9:
                                    // 9: Participation not started
                                    break
                                case 10:
                                    // 10: Participation is disqualified
                                    competitor.Retired = true
                                    break
                            }
                        }

                        if (competitor.CompetitorClass){
                            if(tempSession.Categories){
                                var categoryIndex = tempSession.Categories.indexOf(competitor.CompetitorClass)
                                if(categoryIndex == -1){
                                    tempSession.Categories[tempSession.Categories.length] = competitor.CompetitorClass
                                }
                            } else {
                                tempSession.Categories = [competitor.CompetitorClass]
                            }
                        }

                        tempSession.Competitors[enrollmentIndex] = competitor

                        // Merge session back into right location
                        if (raceIndex == -2){
                            session = tempSession
                        } else if (raceIndex == -1) {
                            break
                        } else {
                            races[raceIndex] = tempSession
                        }


                        break
                    case 7:
                        // Intermediate Data
                        // Find session index using the session function
                        if(data.message.HeatID == session.SessionId){
                            var raceIndex = -2 //set raceIndex to -2 to help identify that we are working with the active session
                            var tempSession = session //create a temp session object that will be later moved into the main object
                        } else {
                            var raceIndex = findSessionIndex(races,data.message.HeatID)
                            if(raceIndex > -1){
                                var tempSession = races[raceIndex] //create a temp session object that will be later moved into the main object
                            }
                        }
                        var compArray = tempSession.Competitors
                        // Look for competitor in session, then look for competitor in drivers list
                        var enrollmentIndex = findEnrollmentIndex(compArray, data.message.EnrollmentID)

                        if(enrollmentIndex == -1){
                            // Look for enrollment information in TimeService state object
                            var newEnrollmentIndex = findEnrollmentIndex(timeService.Enrollments, data.message.EnrollmentID)
                            if(newEnrollmentIndex > -1){
                                var enrollmentObj = timeService.Enrollments[newEnrollmentIndex]
								
								if(timeService.Enrollments[newEnrollmentIndex].NameF || timeService.Enrollments[newEnrollmentIndex].NameL ){
									var DriverName = timeService.Enrollments[newEnrollmentIndex].NameF +" "+ timeService.Enrollments[newEnrollmentIndex].NameL
								}
                            } else {
                                console.log("No Enrollment found")
                                break
                            }
                            // Create new competitor object for session
                            if(data.message.DriverID){
                                var driverIndex = findDriverIndex(timeService.Drivers, data.message.DriverID)
                                if(driverIndex > -1){
                                    competitor.CompetitorName = timeService.Drivers[driverIndex].NameF + " " + timeService.Drivers[driverIndex].NameL
									competitor.CompetitorLastName =  timeService.Drivers[driverIndex].NameL || ""
                                }
                            }

                            var competitor = {
                                "CompetitorId": data.message.EnrollmentID,
                                "CompetitorNumber": timeService.Enrollments[newEnrollmentIndex].StartNumber || 0,
                                "CompetitorName": DriverName || timeService.Enrollments[newEnrollmentIndex].TeamName || "Undefined",
								"CompetitorLastName": timeService.Drivers[driverIndex].NameL || "",
                                "CompetitorClass": timeService.Enrollments[newEnrollmentIndex].RaceClass,
                                "CompetitorSubClass": timeService.Enrollments[newEnrollmentIndex].RaceClassGroup,
                                "teamName": timeService.Enrollments[newEnrollmentIndex].Entrant || timeService.Enrollments[newEnrollmentIndex].TeamName || "",
                                "vehicle": timeService.Enrollments[newEnrollmentIndex].VehicleBrand || "",
                                "DriverName": timeService.Enrollments[newEnrollmentIndex].ShortName || "",
                                "Position": 999,
                                "NumberOfLaps": 0,
                                "PositionInClass": 999,
                                "LastLaptime": null,
                                "RaceTime": null,
                                "RunningTime": null,
                                "BestLaptime": null,
                                "BestLapNumber": null,
                                "IsBestLapInRace": false,
                                "IsCurrentLapPBestLap": false,
                                "Seen": true,
                                "InPit": false,
                                "Retired": false,
                                "GridPosition": 999,
                                "PositionChange": 0,
                                "Gap": null,
                                "gd": null,
                                "Behind": null,
                                "Split1": null,
                                "Split2": null,
                                "Split3": null,
                                "IsBestSector1": false,
                                "pbs1": false,
                                "IsBestSector2": false,
                                "pbs2": false,
                                "IsBestSector3": false,
                                "pbs3": false,
                                "cs": 1,
                                "el": null, //estimated lap
                                "TakenChequered": false,
                                "ocl": true,
                                "clnr": false,
                                "blnr": false,
                                "tt": null,
                                "nat": timeService.Enrollments[newEnrollmentIndex].Nationality || null,
                                "ps": 0,
                                "lpe": 0,
                                "nbc": timeService.Enrollments[newEnrollmentIndex].nbc || "#0A72F2",
                                "nfc": timeService.Enrollments[newEnrollmentIndex].nfc || "#FFFFFF",
                                "es1": null, //estimated s1
                                "es2": null, //estimated s2
                                "es3": null  //estimated s3
                            }

                            enrollmentIndex = compArray.length
                        } else {
                            var competitor = compArray[enrollmentIndex]

                            if(data.message.DriverID){
                                var driverIndex = findDriverIndex(timeService.Drivers, data.message.DriverID)
                                if(driverIndex > -1){
                                    if(competitor.nameOveride != true){
                                        competitor.CompetitorName = timeService.Drivers[driverIndex].NameF + " " + timeService.Drivers[driverIndex].NameL
										competitor.CompetitorLastName = timeService.Drivers[driverIndex].NameL || ""
                                    }
                                }
                            }
                        }

                        if(data.message.Diff){
                            if (data.message.Diff < 0){
                                if (data.message.Diff < -1){
                                    var gap = data.message.Diff + " Laps"
                                } else {
                                    var gap = data.message.Diff + " Lap"
                                }
                                
                            } else if (data.message.Diff == "9223372036854775807"){
                                var gap = 0
                            }
                            
                            competitor.Gap = gap || this.msToTime(data.message.Diff) || competitor.Gap || null
                        }

                        if(data.message.Gap){
                            if (data.message.Gap < 0){
                                if (data.message.Gap < -1){
                                    var behind = data.message.Gap + " Laps"
                                } else {
                                    var behind = data.message.Gap + " Lap"
                                }
                                
                            } else if (data.message.Gap == "9223372036854775807"){
                                var behind = 0
                            }
                            
                            competitor.Behind =  behind || this.msToTime(data.message.Gap) || competitor.Behind || null
                        }

                        competitor.Position = parseInt(data.message.Pos) || competitor.Position || 999
                        competitor.PositionInClass = parseInt(data.message.PIC) || competitor.PositionInClass || 999
                        
                        

                        tempSession.Competitors[enrollmentIndex] = competitor

                        // Merge session back into right location
                        if (raceIndex == -2){
                            session = tempSession
                        } else if (raceIndex == -1) {
                            break
                        } else {
                            races[raceIndex] = tempSession
                        }

                        break
                    case 8:
                        // Start Grid Data
                        // Find session index using the session function
                        if(session.SessionId == data.message.HeatID){
                            // If session is active - create TempSession object later to be merged in
                            var sessionIndex = -2
                            var tempSession = session
                            var enrollmentIndex = findEnrollmentIndex(tempSession.Competitors, data.message.EnrollmentID)
                        } else {
                            // If session is not active - create TempSession object later to be merged in
                            var sessionIndex = findSessionIndex(races,data.message.HeatID)
                            if(sessionIndex > -1){
                                var tempSession = races[sessionIndex]
                                var enrollmentIndex = findEnrollmentIndex(tempSession.Competitors, data.message.EnrollmentID)
                            } else {
                                break
                            }
                        }

                        var compArray = tempSession.Competitors

                        if(enrollmentIndex > -1){
                            // if competitor object already exists in session
                            var competitor = compArray[enrollmentIndex]
                        } else {
                            // if competitor object does not exist in session find enrollment and create competitor object
							if(timeService.Enrollments[newEnrollmentIndex].NameF || timeService.Enrollments[newEnrollmentIndex].NameL ){
								var DriverName = timeService.Enrollments[newEnrollmentIndex].NameF +" "+ timeService.Enrollments[newEnrollmentIndex].NameL
							 }
							
                            var newEnrollmentIndex = findEnrollmentIndex(timeService.Enrollments, data.message.EnrollmentID)
                            if(newEnrollmentIndex > -1){
                                // Create new competitor object for session
                                var competitor = {
                                    "CompetitorId": data.message.EnrollmentID,
                                    "CompetitorNumber": timeService.Enrollments[newEnrollmentIndex].StartNumber || 0,
                                    "CompetitorName": timeService.Enrollments[newEnrollmentIndex].NameF + timeService.Enrollments[newEnrollmentIndex].NameL || timeService.Enrollments[newEnrollmentIndex].TeamName || "Undefined",
                                    "CompetitorLastName": timeService.Drivers[driverIndex].NameL || "",
									"CompetitorClass": timeService.Enrollments[newEnrollmentIndex].RaceClass,
                                    "CompetitorSubClass": timeService.Enrollments[newEnrollmentIndex].RaceClassGroup,
                                    "teamName": timeService.Enrollments[newEnrollmentIndex].Entrant || timeService.Enrollments[newEnrollmentIndex].TeamName || "",
                                    "vehicle": timeService.Enrollments[newEnrollmentIndex].VehicleBrand || "",
                                    "DriverName": timeService.Enrollments[newEnrollmentIndex].ShortName || "",
                                    "Position": 999,
                                    "NumberOfLaps": 0,
                                    "PositionInClass": 999,
                                    "LastLaptime": null,
                                    "RaceTime": null,
                                    "RunningTime": null,
                                    "BestLaptime": null,
                                    "BestLapNumber": null,
                                    "IsBestLapInRace": false,
                                    "IsCurrentLapPBestLap": false,
                                    "Seen": true,
                                    "InPit": false,
                                    "Retired": false,
                                    "GridPosition": 999,
                                    "PositionChange": 0,
                                    "Gap": null,
                                    "gd": null,
                                    "Behind": null,
                                    "Split1": null,
                                    "Split2": null,
                                    "Split3": null,
                                    "IsBestSector1": false,
                                    "pbs1": false,
                                    "IsBestSector2": false,
                                    "pbs2": false,
                                    "IsBestSector3": false,
                                    "pbs3": false,
                                    "cs": 1,
                                    "el": null, //estimated lap
                                    "TakenChequered": false,
                                    "ocl": true,
                                    "clnr": false,
                                    "blnr": false,
                                    "tt": null,
                                    "nat": timeService.Enrollments[newEnrollmentIndex].Nationality || null,
                                    "ps": 0,
                                    "lpe": 0,
                                    "nbc": "#0A72F2",
                                    "nfc": "#FFFFFF",
                                    "es1": null, //estimated s1
                                    "es2": null, //estimated s2
                                    "es3": null  //estimated s3
                                
                            
                                }
                            } else {
                                break
                            }
                        }

                        if(data.message.GridPos == "0"){
                            //Sets the grid pos to the alpha live data default for not having a grid spot.
                            data.message.GridPos = compArray.length
                        }

                        // if(data.message.GridTime){
                        //     data.message.GridTime = this.msToTime(data.message.GridTime)
                        // } else {
                        //     data.message.GridTime = ""
                        // }

                        competitor.GridPosition = parseInt(data.message.GridPos)
                        competitor.qualification = data.message.GridTime || ""

                        if(enrollmentIndex > -1){
                            compArray[enrollmentIndex] = competitor
                        } else {
                            compArray[compArray.length] = competitor
                        }

                        if(sessionIndex == -2){
                            session = tempSession
                        } else if(sessionIndex > -1){
                            races[sessionIndex] = tempSession
                        }

                        break
                    case 9:
                        // Result Data
                        // Find session index using the session function
                        break
                    case 10:
                        // Race Control Messages 




                        break
                    case 11:
                        // Weather Information
                        break
                    case 12:
                        // Loop Data
                        break
                    case 13:
                        // There is no 13
                        break
                    case 14:
                        // Pre announced laps - used for circuit displays to tell the leader laps remaining
                        break
                    case 15:
                        // Light Beam Data
                        break
                    case 16:
                        // Pitstop and stint data
                        
                        break
                }

                this.setState({
                    TimeService: timeService
                })

            } else if (data.author == "Alkamel"){
                // console.log(data.message)
                if(data.message.timing){
                    if(data.message.timing.session){
                        //Session info - Keep this at the top as will reset the session object for adding further information that is sent at the same time
                        if(data.message.timing.session.info){
                            if(data.message.timing.session.info.sessionDbId){
                                //Check if current session or new session
                                if(data.message.timing.session.info.sessionDbId != session.SessionId){
                                    var newSessionInfo = data.message.timing.session.info
                                    //New Session
                                    console.log("NEW SESSION")
                                    var obj = {
                                            "SessionStartTime": "2022-11-27T15:17:50.9357426+00:00",
                                            "ResultAvailable": true,
                                            "ShowNotifications": true,
                                            "EventId": newSessionInfo.eventDbId || 1,
                                            "SessionId": newSessionInfo.sessionDbId || 1,
                                            "SessionName": newSessionInfo.champName.toUpperCase() + " - " + newSessionInfo.name.toUpperCase() || undefined,
                                            "SessionType": newSessionInfo.type || "RACE",
                                            "CircuitMap": "No Data",
                                            "TrackLength": 0,
                                            "TrackName": "No Data",
                                            "Weather": null,
                                            "Temperature": null,
                                            "TrackCondition": null,
                                            "IsTeamEvent": false,
                                            "MultiCls": false,
                                            "ns": null,
                                            "nst": null,
                                            "bn": null,
                                            "bst": null,
                                            "bet": null,
                                            "officials": {
                                              "CompetitionSecretary": "Paul Sirett",
                                              "Timekeeper": "Amy Catchpole"
                                            },
                                            "dp": 2,
                                            "blt": {
                                              "cn": 11,
                                              "cnpb": "#FF0000",
                                              "cnpf": "#FFFFFF",
                                              "ln": 17,
                                              "tm": 46200
                                            },
                                            "bs1": {
                                              "cn": 11,
                                              "cnpb": "#FF0000",
                                              "cnpf": "#FFFFFF",
                                              "ln": 17,
                                              "tm": 15060
                                            },
                                            "bs2": {
                                              "cn": 33,
                                              "cnpb": "#FF0000",
                                              "cnpf": "#FFFFFF",
                                              "ln": 17,
                                              "tm": 31030
                                            },
                                            "bs3": null,
                                            "tt": null,
                                            "SD": "12m + 1L",
                                            "flds": [
                                              "gd",
                                              "ps",
                                              "lpe"
                                            ],
                                            "nto": -0.04,
                                            "ntst": "2022-11-27T15:33:45.329Z",
                                            "ntip": "132.163.96.2",
                                            "ntsn": "time.nist.gov",
                                            "State": "Formation",
                                            "fcy": false,
                                            "Sequence": 1273,
                                            "Sectors": "3",
                                            "RaceTime": 0,
                                            "Rd": "1L",
                                            "Last": false,
                                            "End": false,
                                            "show_race_time": true,
                                            "TimingSystem": "na",
                                            "Date": "28/1/2024",
                                            "Clear": false,
                                            "Competitors": []
                                        }
                                    session = obj
                                } else {
                                    console.log("CURRENT SESSION UPDATE")

                                }
                            }
                        }
                        //Session Status
                        if(data.message.timing.session.status){
                            var status = data.message.timing.session.status
                            session.LengthTime = status.finalTime || session.durationSeconds || undefined
                                //Session Duration Calculation
                                //First convert the seconds to Hours and Minutes
                                if (status.finalTime){
                                    session.LengthTime = status.finalTime
                                    var totalSeconds = status.finalTime;
                                    var hours = Math.floor(totalSeconds / 3600);
                                    totalSeconds %= 3600;
                                    var minutes = Math.floor(totalSeconds / 60);
                                }

                                if (status.finalType == "BY_TIME" && session.SessionType == "RACE"){
                                    if(hours && minutes){
                                        var sessionDuration = hours+"H" +minutes +"M + " + 1 +"L"
                                    } else if (hours){
                                        var sessionDuration = hours+"H + "+ 1 +"L"
                                    } else if (minutes){
                                        var sessionDuration = minutes +"M + " + 1 +"L"
                                    } else {
                                        // var sessionDuration = laps +"L"
                                        // session.LapsDuration = parseInt(laps)
                                    }
                                }

                                if (status.finalType == "BY_TIME" && session.SessionType == "QUAL"){
                                    if(hours && minutes){
                                        var sessionDuration = hours+"H" +minutes +"M"
                                    } else if (hours){
                                        var sessionDuration = hours+"H"
                                    } else if (minutes){
                                        var sessionDuration = minutes +"M"
                                    } else {
                                        // var sessionDuration = laps +"L"
                                        // session.LapsDuration = parseInt(laps)
                                    }
                                }

                            session.SD = sessionDuration || session.SD
                            session.LapsDuration = laps || session.LapsDuration || 0
                            
                            // Current Session State Calculations
                            if(status.currentFlag){
                                //Current flag type (track status). Values can be RED , GREEN , YELLOW ,FULL_YELLOW , SAFETY_CAR , NONE
                                switch (status.currentFlag){
                                    case "RED":
                                        session.State = "RedFlag"
                                        session.fcy = false
                                        break
                                    case "GREEN":
                                        session.State = "Running"
                                        session.fcy = false
                                        break
                                    case "YELLOW":
                                        session.State = "Yellow"
                                        session.fcy = true
                                        break
                                    case "FULL_YELLOW":
                                        session.State = "Yellow"
                                        session.fcy = true
                                        break
                                    case "SAFETY_CAR":
                                        session.State = "RedFlag"
                                        session.fcy = false
                                        break
                                    case "NONE":
                                        session.State = "Formation"
                                        session.fcy = false
                                        break
                                }
                            }

                            if(status.isFinished){
                                if(status.isFinished == true){
                                    session.End = true
                                    session.State = "ChequeredFlag"
                                    session.fcy = false  
                                } else {
                                    session.End = false
                                }
                            }

                            //Check for session delay to help clock after redflags
                            session.ClockHaltedDuration = status.stoppedSeconds || session.ClockHaltedDuration || 0                 
                        }
                        //Competitors
                        if(data.message.timing.session.entry){
                            var entries = data.message.timing.session.entry
                            var compArray = session.Competitors
                            for (let key in entries) {
                                // console.log(key)
                                // console.log(key, entries[key])
                                // check if competitor already exists in session or not
                                var compIndex = compArray.findIndex( x => x.CompetitorId == key)

                                
                                if(compIndex == -1){
                                    // if competitor does not exist create new object and add to array

                                    // first look up class colour information
                                    var raceClassArray = this.state.RaceClass
                                    var raceClassIndex = raceClassArray.findIndex( x => x.name == entries[key].class)
                                    // console.log("classindex",raceClassIndex)

                                    var competitor = {
                                        "CompetitorId": key,
                                        "CompetitorNumber": entries[key].number || 0,
                                        "CompetitorName": entries[key].drivers[entries[key].currentDriver].firstName.toUpperCase() + " " + entries[key].drivers[entries[key].currentDriver].lastName.toUpperCase() || "Undefined",
                                        "CompetitorLastName": entries[key].drivers[entries[key].currentDriver].lastName.toUpperCase() || "",
										"CompetitorClass": entries[key].class || "",
                                        "CompetitorSubClass": entries[key].class || "",
                                        "teamName": entries[key].team || "",
                                        "vehicle": entries[key].vehicle || "",
                                        "DriverName": entries[key].name || "",
                                        "Position": session.Competitors.length+1,
                                        "NumberOfLaps": 0,
                                        "PositionInClass": 999,
                                        "LastLaptime": null,
                                        "RaceTime": null,
                                        "RunningTime": null,
                                        "BestLaptime": null,
                                        "BestLapNumber": null,
                                        "IsBestLapInRace": false,
                                        "IsCurrentLapPBestLap": false,
                                        "Seen": true,
                                        "InPit": false,
                                        "Retired": false,
                                        "GridPosition": 999,
                                        "PositionChange": 0,
                                        "Gap": null,
                                        "gd": null,
                                        "Behind": null,
                                        "Split1": null,
                                        "Split2": null,
                                        "Split3": null,
                                        "IsBestSector1": false,
                                        "pbs1": false,
                                        "IsBestSector2": false,
                                        "pbs2": false,
                                        "IsBestSector3": false,
                                        "pbs3": false,
                                        "cs": 1,
                                        "el": null, //estimated lap
                                        "TakenChequered": false,
                                        "ocl": true,
                                        "clnr": false,
                                        "blnr": false,
                                        "tt": null,
                                        "nat": entries[key].drivers[entries[key].currentDriver].country || null,
                                        "ps": 0,
                                        "lpe": 0,
                                        "nbc": raceClassArray[raceClassIndex].nbc || "#0A72F2",
                                        "nfc": raceClassArray[raceClassIndex].nfc || "#FFFFFF",
                                        "es1": null, //estimated s1
                                        "es2": null, //estimated s2
                                        "es3": null  //estimated s3
                                    }

                                    session.Competitors[session.Competitors.length] = competitor
                                } else {
                                    // if competitor does exist modify perameters
                                    compArray[compIndex].CompetitorNumber = entries[key].number || compArray[compIndex].CompetitorNumber || 0
                                    compArray[compIndex].CompetitorName = entries[key].drivers[entries[key].currentDriver].firstName.toUpperCase() + " " + entries[key].drivers[entries[key].currentDriver].lastName.toUpperCase() || compArray[compIndex].CompetitorName || "Undefined"
                                    compArray[compIndex].CompetitorLastName = entries[key].drivers[entries[key].currentDriver].lastName.toUpperCase()
									compArray[compIndex].CompetitorClass = entries[key].class || compArray[compIndex].CompetitorClass || ""
                                    compArray[compIndex].CompetitorSubClass = entries[key].class || compArray[compIndex].CompetitorSubClass || ""
                                    compArray[compIndex].teamName = entries[key].team || compArray[compIndex].teamName || ""
                                    compArray[compIndex].vehicle = entries[key].vehicle || compArray[compIndex].vehicle || ""
                                    compArray[compIndex].DriverName = entries[key].name || compArray[compIndex].DriverName || ""

                                    session.Competitors = compArray
                                }
                            }                            
                        }
                        //Standings - Overall - Active (Do not use finishLine)
                        if(data.message.timing.session.standings){
                            var standings = data.message.timing.session.standings
                            var compArray = session.Competitors
                            console.log(standings)
                            if(standings.overall){
                                console.log(standings.overall)
                                if(standings.overall.active){
                                    var active = standings.overall.active
                                    for (let key in active) {
                                        if(active[key].participant){
                                            var compIndex = compArray.findIndex( x => x.CompetitorId == parseInt(active[key].participant))
                                        } else {
                                            var compIndex = compArray.findIndex( x => x.Position == parseInt(key))
                                        }
                                        
                                        //Check that the competitor exists in session
                                        if(compIndex != -1){
                                            var competitor = compArray[compIndex]

                                            competitor.Position = key

                                            //Behind Check
                                            var behind = ""
                                            if (active[key].gapFirstTime){
                                                var behind = this.msToTime(active[key].gapFirstTime*1000)
                                            } else if (active[key].gapFirstLaps){
                                                var behind = "+"+active[key].gapFirstLaps+ " Lap"
                                            } else if (key != 1){
                                                var behind = competitor.Behind
                                            }

                                            //Gap Check
                                            var gap = ""
                                            if (active[key].gapFirstTime){
                                                var gap = this.msToTime(active[key].gapPreviousTime*1000)
                                            } else if (active[key].gapFirstLaps){
                                                var gap = "+"+active[key].gapPreviousLaps+ " Lap"
                                            } else if (key != 1){
                                                var gap = competitor.Gap
                                            }

                                            competitor.Behind = behind
                                            competitor.Gap = gap
                                            competitor.NumberOfLaps = active[key].lapNumber || competitor.NumberOfLaps || 0

                                            session.Competitors[compIndex] = competitor
                                        }
                                    }
                                }
                                //ParticipantDetails - This contains the last lap information and total pitstops
                                if(standings.overall.participantDetails){
                                    var participantArray = standings.overall.participantDetails

                                    for (let key in participantArray) {
                                        var compIndex = compArray.findIndex( x => x.CompetitorId == parseInt(key))
                                        if(compIndex != -1){
                                            var competitor = compArray[compIndex]
                                            if(participantArray[key].lastLap){
                                                competitor.LastLaptime = participantArray[key].lastLap.time
                                                var lapObj = {
                                                    "LapNo": participantArray[key].currentLap-1,
                                                    "LapTime": participantArray[key].lastLap.time
                                                }
                                                if(competitor.LapData){
                                                    competitor.LapData[competitor.LapData.length] = lapObj
                                                } else {
                                                    competitor.LapData = []
                                                    competitor.LapData[competitor.LapData.length] = lapObj
                                                }
                                            }
                                            if(participantArray[key].bestLap){
                                                competitor.BestLaptime = participantArray[key].bestLap.time
                                            }
                                            if(participantArray[key].pitStops){
                                                competitor.ps = participantArray[key].pitStops
                                            }

                                            session.Competitors[compIndex] = competitor
                                        } else {
                                            console.log("competitor not found")
                                        }

                                    }



                                }
                            }
                        }
                        //Live Events - Currently just for detecting pitstops
                        if(data.message.timing.liveEvents){
                            var compArray = session.Competitors
                            var pitMessage = {participant: -1}
                            if(data.message.timing.liveEvents.pitIn){
                                var pitMessage = data.message.timing.liveEvents.pitIn
                                var inPit = true
                            } else if(data.message.timing.liveEvents.pitOut){
                                var pitMessage = data.message.timing.liveEvents.pitOut
                                var inPit = false
                                
                            }
                            if(pitMessage.participant){
                                var compIndex = compArray.findIndex( x => x.CompetitorId == parseInt(pitMessage.participant))
                                if(compIndex != -1){
                                    compArray[compIndex].InPit = inPit
                                }
                            }
                            session.Competitors = compArray
                        }
                        //Start Grid - Store data for later
                        if(data.message.timing.session.startingGrid){
                            this.setState({
                                alkamelStartGrid: data.message.timing.session.startingGrid
                            })

                            setTimeout(() => {
                                this.alkamelStartGridData();
                            }, 1000);
                        }
                    }
                }
            } else if (data.author == "AC"){
                console.log("AC")
                this.acMessage(data)
            }
            
            // console.log(session)
            this.setState({
                Controls: controls,
                Session: session,
                Races: races
            })

            // console.log(data)
        })


    }

    acServerPing = async (ip, port, driverName) => {
        try {
            // Construct the URL
            // const url = `http://localhost:3000/fetch-tables?ip=${ip}&port=${port}`;
            const url = `https://acping.alphalive.co.uk/fetch-tables?ip=${ip}&port=${port}`;
    
            // Fetch data from the server
            const response = await fetch(url);
            console.log(ip, port)

            // Check for HTTP errors
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            // Parse the JSON response
            const data = await response.json();
    
            // Optionally update the state if needed
            // this.setState({ tableData: data });
            console.log(data)

            var serverData = data.competitors
            var session = this.state.Session
            var competitors = session.Competitors
            var serverIndex = 0
            while (serverIndex < serverData.length){
                
                var serverDriver = serverData[serverIndex]
                var storedIndex = competitors.findIndex( x => x.CompetitorName == serverDriver.name)
                
                console.log(storedIndex, serverDriver)

                if(storedIndex > -1){
                    competitors[storedIndex].Position = serverDriver.position
					if(serverDriver.laps){competitors[storedIndex].NumberOfLaps = serverDriver.laps}

                    if(serverDriver.bestLap != "--:--:---"){
                        competitors[storedIndex].BestLaptime = this.generateMStime(serverDriver.bestLap)
                        console.log(competitors[storedIndex].BestLaptime)
                    }
                    if(serverDriver.lastLap != "--:--:---"){
                        competitors[storedIndex].LastLaptime = this.generateMStime(serverDriver.lastLap)
                        console.log(competitors[storedIndex].LastLaptime)
                    }
                }

                serverIndex++
            }
            session.Competitors = competitors
            this.setState({
                Session: session
            })

        } catch (error) {
            console.error('Error:', error);
    
            // Optionally return an empty value or rethrow the error
            throw error;
        }
    };

    generateMStime = (time) => {
        const t = String(time).split(':');
        if (t.length === 3 && parseInt(t[0], 10) < 16000) {
            return parseInt(t[2], 10) + parseInt(t[1], 10) * 1000 + parseInt(t[0], 10) * 60000;
        }
        return 0;
    }

    acLocalMessage = async (data) => {
        // console.log("Process Message")

        var nbc = "#2faae1"
        var nfc = "#ffffff"

        if(data.sessionInfo.sessionTimeLeft > -1){
            this.setState({
                timeRemaining: this.acToTime(data.sessionInfo.sessionTimeLeft*1000)
            })
        } else {
            this.setState({
                timeRemaining: this.acToTime(0)
            })
        }

        var session = this.state.Session
        session.CircuitMap = data.sessionInfo.track
        session.TrackName = data.sessionInfo.track
        session.tn = data.sessionInfo.track
        session.State = data.sessionInfo.flag
        session.Sectors = data.sessionInfo.sectorCount

        if(data.sessionInfo){

            if(data.sessionInfo.sessionType != session.ACSessionType){
                //Clear Chequered flag states
                if(session.Competitors){
                    var i = 0
                    while (i< session.Competitors.length){
                        session.Competitors[i].TakenChequered = false
                        i++
                    }
                }

                this.acServerPing(data.server_ip,data.port)

            }

            switch(data.sessionInfo.sessionType){
                case 0:
                    //0 = practice
                    if(session.nameOveride!= true){session.SessionName = "Practice"}
                    session.SessionType = 'Qual'
                    session.ACSessionType = data.sessionInfo.sessionType
                    break
                case 1:
                    //1 = qualifying
                    if(session.nameOveride!= true){session.SessionName = "Qualifying"}
                    session.SessionType = 'Qual'
                    session.ACSessionType = data.sessionInfo.sessionType
                    break
                case 2:
                    //2 = race
                    if(session.nameOveride!= true){session.SessionName = "Race"}
                    session.SessionType = 'Race'
                    session.ACSessionType = data.sessionInfo.sessionType
                    break
            }
        }

        var competitors = session.Competitors

        if(competitors[0]){
            //append existing competitors list
            if(data.drivers){
                var i = 0
                
                while (i < data.drivers.length){

                    function sortByFastLap(array) {
                        return array.sort((a, b) => {
                            let nameA = a.BestLaptime
                            let nameB = b.BestLaptime

                            if(nameA == 0){
                                nameA = 99999999999999
                            } 

                            if(nameB == 0){
                                nameB = 99999999999999
                            }

                            if (nameA < nameB) {
                                return -1;
                            }
                            if (nameA > nameB) {
                                return 1;
                            }
                    
                            // names must be equal
                            return 0;
                        });
                    }
                    
                    if(session.SessionType == 'Qual'){
                        // this.acServerPing(data.server_ip,data.port)
                        // console.log(serverData)
                        // var qualOrder = sortByFastLap(data.drivers)
                    }

                    var obj = data.drivers[i]
                    var compIndex = competitors.findIndex( x => x.CompetitorId == parseInt(obj.id))

                    if(compIndex > -1){
						if(obj.focusedDriverName == obj.driverName){
							competitors[compIndex].Highlight = true
						} else {
							competitors[compIndex].Highlight = false
						}
                        if(competitors[compIndex].TakenChequered != true || this.state.Session.SessionType == "Qual"){
                            
                            if(this.state.Session.SessionType == "Race"){
                                competitors[compIndex].BestLaptime = obj.fastestLap
                                
                            }
                            
                            if(competitors[compIndex].NumberOfLaps != obj.lapCount){
                                competitors[compIndex].SplineLastUpdate = 0
                                // if(this.state.Session.SessionType == "Qual"){
                                //     this.acServerPing(data.server_ip,data.port)
                                // }
                            }

                            
                            competitors[compIndex].InPit = obj.isCarInPitlane

                            
                            if(obj.driverName && competitors[compIndex].nameOveride != true){
                                competitors[compIndex].CompetitorName = obj.driverName
                            }
                            competitors[compIndex].KPH = obj.speedKPH
                            competitors[compIndex].MPH = obj.speedMPH
                            competitors[compIndex].Gear = obj.gear
                            competitors[compIndex].RPM = obj.RPM
                            competitors[compIndex].Gas = obj.gas
                            competitors[compIndex].Brake = obj.brake
                            competitors[compIndex].Clutch = obj.clutch
                            
                            if(obj.takenChequered == true && competitors[compIndex].TakenChequered != true){
                                competitors[compIndex].Spline = 100
                                competitors[compIndex].FinishedTime = Date.now()
                            }

                            competitors[compIndex].TakenChequered = obj.takenChequered
                            competitors[compIndex].realtimePosition = obj.realtimePosition
                            competitors[compIndex].Spline = parseFloat(obj.spline*100).toFixed(3);
                            competitors[compIndex].isConnected = obj.isConnected
                            // competitors[compIndex].GridPosition = obj.raceStartPos

                            if(competitors[compIndex].NumberOfLaps != obj.lapCount){
                                competitors[compIndex].LastLaptime = obj.lastLap
                            }
                            

                            if(this.state.Session.SessionType == "Qual"){
 
                                //Check if fastest lap in obj is faster than in state but only check if new lap is complete, also udpate lastLap here
                                if(competitors[compIndex].NumberOfLaps != obj.lapCount){
                                    if(obj.fastestLap < competitors[compIndex].BestLaptime || competitors[compIndex].BestLaptime == 0 ){
                                        competitors[compIndex].BestLaptime = obj.fastestLap
                                    }
                                }
                                var compClone = _.cloneDeep(competitors)

                                var qualOrder = sortByFastLap(compClone)

                                var position = qualOrder.findIndex( x => x.CompetitorId == parseInt(obj.id))

                                competitors[compIndex].Position = position+1


                                if(competitors[compIndex].Position != 1){
                                    competitors[compIndex].Behind = competitors[compIndex].BestLaptime - qualOrder[0].BestLaptime
                                    competitors[compIndex].Gap = competitors[compIndex].BestLaptime - qualOrder[position-1].BestLaptime 

                                    if(competitors[compIndex].Behind < 0){
                                        competitors[compIndex].Behind = "No Time"
                                    } else {
                                        competitors[compIndex].Behind = this.msToTime(competitors[compIndex].Behind*1000)
                                    }
                                    if(competitors[compIndex].Gap < 0){
                                        competitors[compIndex].Gap = "0.000"
                                    } else {
                                        competitors[compIndex].Gap = this.msToTime(competitors[compIndex].Gap*1000)
                                    }
                                } else {
                                    competitors[compIndex].Behind = ""
                                    competitors[compIndex].Gap = ""
                                }
                                
                            } else {
                                if(competitors[compIndex].TakenChequered == true){
                                    competitors[compIndex].Position = obj.realtimePosition
                                } else {
                                    competitors[compIndex].Position = obj.leaderboardPosition 
                                }
                                
                            }

                            if(obj.focusedDriverName == obj.driverName){
                                competitors[compIndex].Highlight = true
                            } else {
                                competitors[compIndex].Highlight = false
                            }

                            if(competitors[compIndex].LapData && competitors[compIndex].LastLaptime){
                                var lapCheck = competitors[compIndex].LapData.findIndex( x => x.LapNo == parseInt(competitors[compIndex].NumberOfLaps))
                                if(lapCheck == -1){
                                    var newLap = {
                                        LapNo: competitors[compIndex].NumberOfLaps,
                                        LapTime: competitors[compIndex].LastLaptime
                                    }

                                    competitors[compIndex].LapData.push(newLap)
                                }
                            } else if(competitors[compIndex].LastLaptime){
                                
                                var newLap = {
                                    LapNo: competitors[compIndex].NumberOfLaps,
                                    LapTime: competitors[compIndex].LastLaptime
                                }

                                competitors[compIndex].LapData = [newLap]
                            }

							competitors[compIndex].NumberOfLaps = obj.lapCount
							
                            // Calculate live gap to leader
                            var splineUpdateDist = 2; // Minimum distance for spline updates

                            // Get the competitor data
                            let competitor = competitors[compIndex];
                            let spline = competitor.Spline;
                            
                            // Skip gap calculations for the leader and calculate finish gaps if finished
                            if (competitor.Position != 1 && competitor.TakenChequered != true ) {
                                let leaderIndex = competitors.findIndex(x => x.Position == 1);
                                let infrontIndex = competitors.findIndex(x => x.Position == competitor.Position - 1);
                            
                                // console.log(`LeaderIndex: ${leaderIndex}, InFrontIndex: ${infrontIndex}`);
                            
                                // Initialize SplineLastUpdate if not already set
                                if (!competitor.SplineLastUpdate) {
                                    competitor.SplineLastUpdate = 0;
                                }

                                var nextUpdate = parseInt(competitor.SplineLastUpdate) + splineUpdateDist
                            
                                // Update spline only if conditions are met
                                if (spline > nextUpdate || competitor.SplineLastUpdate == 0) {
                            
                                    competitor.SplineLastUpdate = spline;
                            
                                    if (this.state.Session.SessionType == "Race") {
                                        // Gap to leader calculation
                                        if (leaderIndex > -1) {
                                            let leader = competitors[leaderIndex];
                                            let leaderLaps = leader.NumberOfLaps;
                                            let leaderFinished = leader.TakenChequered;
                            
                                            if (competitor.NumberOfLaps == leaderLaps && !leaderFinished) {
                                                // Calculate time-based gap to leader
                                                let leaderSpline = leader.Spline;
                                                let driverSpline = competitor.Spline;
                                                let leaderLastLap = leader.LastLaptime * 1000;
                            
                                                let leaderTimePos = (leaderSpline / 100) * leaderLastLap;
                                                let driverTimePos = (driverSpline / 100) * leaderLastLap;
                            
                                                let behind = Math.max(0, leaderTimePos - driverTimePos);
                                                competitor.Behind = this.msToTime(behind);
                                            } else if (leaderFinished) {
                                                // Handle case when leader has finished the race
                                                // competitor.Behind = 0; // Optional: customize behavior if needed
                                            } else {
                                                // Lap difference
                                                competitor.Behind = competitor.NumberOfLaps - leaderLaps;
                                                if(competitor.Behind == -1){
                                                    competitor.Behind = competitor.Behind + " Lap"
                                                } else {
                                                    competitor.Behind = competitor.Behind + " Laps"
                                                }
                                            }
                                        } else {
                                            competitor.Behind = 0;
                                        }
                            
                                        // Gap to car in front calculation
                                        if (infrontIndex > -1) {
                                            let infront = competitors[infrontIndex];
                                            let infrontLaps = infront.NumberOfLaps;
                                            let infrontFinished = infront.TakenChequered;
                            
                                            if (competitor.NumberOfLaps == infrontLaps && !infrontFinished) {
                                                // Calculate time-based gap to car in front
                                                let infrontSpline = infront.Spline;
                                                let driverSpline = competitor.Spline;
                                                let infrontLastLap = infront.LastLaptime * 1000;
                            
                                                let infrontTimePos = (infrontSpline / 100) * infrontLastLap;
                                                let driverTimePos = (driverSpline / 100) * infrontLastLap;
                            
                                                let gap = Math.max(0, infrontTimePos - driverTimePos);
                                                competitor.Gap = this.msToTime(gap);
                                            } else if (infrontFinished) {
                                                // Handle case when car in front has finished the race
                                                // competitor.Gap = 0; // Optional: customize behavior if needed
                                            } else {
                                                // Lap difference
                                                competitor.Gap = competitor.NumberOfLaps - infrontLaps;
                                                if(competitor.Gap == -1){
                                                    competitor.Gap = competitor.Gap + " Lap"
                                                } else {
                                                    competitor.Gap = competitor.Gap + " Laps"
                                                }
                                            }
                                        } else {
                                            competitor.Gap = 0;
                                        }
                                    }
                                }
                            } else if (competitor.Position != 1 && competitor.TakenChequered == true && this.state.Session.SessionType == "Race") {
                                let leaderIndex = competitors.findIndex(x => x.Position == 1);
                                let infrontIndex = competitors.findIndex(x => x.Position == competitor.Position - 1);

                            
                                if(competitor.NumberOfLaps == competitors[leaderIndex].NumberOfLaps){
                                    var behindLeader = competitor.FinishedTime - competitors[leaderIndex].FinishedTime
                                } else {
                                    var behindLeader = competitor.NumberOfLaps - competitors[leaderIndex].NumberOfLaps
                                }
                               
                                if(competitor.NumberOfLaps == competitors[infrontIndex].NumberOfLaps){
                                    var behindInfront = competitor.FinishedTime - competitors[infrontIndex].FinishedTime
                                } else {
                                    var behindInfront = competitor.NumberOfLaps - competitors[infrontIndex].NumberOfLaps
                                }

                                if(behindLeader < 0){
                                    if(behindLeader == -1){
                                        behindLeader = behindLeader + " Lap"
                                    } else {
                                        behindLeader = behindLeader + " Laps"
                                    }
                                } else {
                                    competitors[compIndex].Behind  = this.msToTime(behindLeader*1000)
                                }

                                if(behindInfront < 0){
                                    if(behindInfront == -1){
                                        behindInfront = behindInfront + " Lap"
                                    } else {
                                        behindInfront = behindInfront + " Laps"
                                    }
                                } else {
                                    competitors[compIndex].Gap  = this.msToTime(behindInfront*1000)
                                }
                                
                                
                            } else {
								if(this.state.Session.SessionType != "Qual"){
                                    competitors[compIndex].Behind  = 0
                                    competitors[compIndex].Gap  = 0
                                }
                            }

                            
                        }
                    } else {
                        var broadcastIndex = obj.driverName.indexOf("BROADCAST")

                        if(broadcastIndex == -1){
                            var driver = {
                                "CompetitorId": obj.id,
                                "CompetitorNumber": obj.id+1,
                                "CompetitorName": obj.driverName || "",
                                "originalName": obj.driverName || "",
                                "Picture" : "https://alphalive.co.uk/streamphotos/"+series+"/"+obj.driverName.toUpperCase() || "",
                                "CompetitorClass": "",
                                "CompetitorSubClass": "",
                                "teamName": obj.driver_team || "",
                                "vehicle": obj.carName|| "",
                                "DriverName": obj.driver_name || "",
                                "Position": obj.leaderboardPosition || 999,
                                "NumberOfLaps": obj.lapCount,
                                "LastLaptime": "",
                                "LapData": [],
                                "RaceTime": null,
                                "RunningTime": null,
                                "BestLaptime": obj.fastestLap,
                                "BestLapNumber": null,
                                "IsBestLapInRace": false,
                                "Seen": false,
                                "InPit": false,
                                "Retired": false,
                                "GridPosition": 999,
                                "QualifyingTime": "",
                                "PositionChange": 0,
                                "PosChangeClass" : "positionneutral",
                                "posChangeTime" : 0,
                                "Gap": 0,
                                "gd": 999,
                                "Behind": obj.delta,
                                "Split1": 0,
                                "Split2": 0,
                                "Split3": 0,
                                "IsBestSector1": false,
                                "pbs1": 0,
                                "IsBestSector2": false,
                                "pbs2": 0,
                                "IsBestSector3": false,
                                "pbs3": 0,
                                "cs": null,
                                "el": null,
                                "TakenChequered": false,
                                "ocl": null,
                                "clnr": null,
                                "blnr": null,
                                "tt": null,
                                "nat": obj.nat || "",
                                "Gear": 0,
                                "RPM": 0,
                                "Spline": 0,
                                "MPH": 0,
                                "Velocity": {
                                    "x": 0,
                                    "y": 0,
                                    "z": 0
                                },
                                "ps": 0,
                                "lpe": 0,
                                "nbc": nbc,
                                "nfc": nfc,
                                "es1": null,
                                "es2": null,
                                "isConnected": obj.isConnected
                                }
                                
                                drivers.push(driver)
                            }
                    }
                    i++
                }
            }
        } else {
            //create competitors list
            if(data.drivers){
                var i = 0
                var drivers = []
                while (i < data.drivers.length){
                    console.log("driver")
                    var obj = data.drivers[i]

                    var broadcastIndex = obj.driverName.indexOf("BROADCAST")

                    if(broadcastIndex == -1){
                        var driver = {
                            "CompetitorId": obj.id,
                            "CompetitorNumber": obj.id+1,
                            "CompetitorName": obj.driverName || "",
                            "originalName": obj.driverName || "",
                            "Picture" : "https://alphalive.co.uk/streamphotos/"+series+"/"+obj.driverName.toUpperCase() || "",
                            "CompetitorClass": "",
                            "CompetitorSubClass": "",
                            "teamName": obj.driver_team || "",
                            "vehicle": obj.carName|| "",
                            "DriverName": obj.driver_name || "",
                            "Position": obj.leaderboardPosition || 999,
                            "NumberOfLaps": obj.lapCount,
                            "LastLaptime": "",
                            "LapData": [],
                            "RaceTime": null,
                            "RunningTime": null,
                            "BestLaptime": obj.fastestLap,
                            "BestLapNumber": null,
                            "IsBestLapInRace": false,
                            "Seen": false,
                            "InPit": false,
                            "Retired": false,
                            "GridPosition": 999,
                            "QualifyingTime": "",
                            "PositionChange": 0,
                            "PosChangeClass" : "positionneutral",
                            "posChangeTime" : 0,
                            "Gap": obj.delta,
                            "gd": 999,
                            "Behind": obj.delta,
                            "Split1": 0,
                            "Split2": 0,
                            "Split3": 0,
                            "IsBestSector1": false,
                            "pbs1": 0,
                            "IsBestSector2": false,
                            "pbs2": 0,
                            "IsBestSector3": false,
                            "pbs3": 0,
                            "cs": null,
                            "el": null,
                            "TakenChequered": false,
                            "ocl": null,
                            "clnr": null,
                            "blnr": null,
                            "tt": null,
                            "nat": obj.nat || "",
                            "Gear": 0,
                            "RPM": 0,
                            "Spline": 0,
                            "MPH": 0,
                            "Velocity": {
                                "x": 0,
                                "y": 0,
                                "z": 0
                            },
                            "ps": 0,
                            "lpe": 0,
                            "nbc": nbc,
                            "nfc": nfc,
                            "es1": null,
                            "es2": null
                            }
                            
                            drivers.push(driver)
                        }
                    i++
                }
                competitors = drivers
            }
        }

        session.Competitors = competitors

        this.setState({
            Session: session
        })


    }

    acMessage = (data) => {
            var controls = this.state.Controls
            var session = this.state.Session
            var races = this.state.Races
            console.log("AC")
            var obj = data
            switch(data.type){
                case "new_session":
                    session = this.acSessionInfo(obj)
                    var i = 0
                    while(i < 100){
                        dataSocket.emit('get_car_info', i)
                        i++
                    }
                    break
                case "car_info":

                    if(session){
                        if (session.Competitors){
                            var competitorsState = session.Competitors
                            var driverIndex = competitorsState.findIndex( x => x.CompetitorId == obj.car_id)
                            console.log(driverIndex)
                        } 

                        var nbc = "#2faae1"
                        var nfc = "#ffffff"

                        if(this.state.PresetList){
                            var presetList = this.state.PresetList
                            var presetIndex = presetList.findIndex(x => x.inGameName == obj.driver_name)

                            if(presetIndex > -1){
                                var presetGridPos = presetList[presetIndex].GridPos
                                var GraphicName = presetList[presetIndex].GraphicName
                                var NewNumber = presetList[presetIndex].NewNumber
                                var QualTime = presetList[presetIndex].QualTime
                                var TeamName = presetList[presetIndex].TeamName
                                var Vehicle = presetList[presetIndex].Vehicle
                                var inGameName = presetList[presetIndex].inGameName
                                var natCode = presetList[presetIndex].natCode
                            }
                            
                        }                    

                        if(driverIndex == -1 && obj.driver_name){
                            var driver = {
                                "CompetitorId": obj.car_id,
                                "CompetitorNumber": NewNumber || obj.car_id,
                                "CompetitorName": GraphicName || obj.driver_name || "",
                                "originalName": obj.driver_name || "",
                                "Picture" : "https://alphalive.co.uk/streamphotos/"+series+"/"+obj.driver_name.toUpperCase() || "",
                                "CompetitorClass": "",
                                "CompetitorSubClass": "",
                                "teamName": TeamName || obj.driver_team || "",
                                "vehicle": Vehicle || obj.car_model|| "",
                                "DriverName": GraphicName || obj.driver_name || "",
                                "Position": 999, //change back to 999 before final version
                                "NumberOfLaps": 0,
                                "LastLaptime": "",
                                "LapData": [],
                                "RaceTime": null,
                                "RunningTime": null,
                                "BestLaptime": null,
                                "BestLapNumber": null,
                                "IsBestLapInRace": false,
                                "Seen": false,
                                "InPit": false,
                                "Retired": false,
                                "GridPosition": presetGridPos || 999,
                                "QualifyingTime": QualTime || "",
                                "PositionChange": 0,
                                "PosChangeClass" : "positionneutral",
                                "posChangeTime" : 0,
                                "Gap": 0,
                                "gd": 999,
                                "Behind": 0,
                                "Split1": 0,
                                "Split2": 0,
                                "Split3": 0,
                                "IsBestSector1": false,
                                "pbs1": 0,
                                "IsBestSector2": false,
                                "pbs2": 0,
                                "IsBestSector3": false,
                                "pbs3": 0,
                                "cs": null,
                                "el": null,
                                "TakenChequered": false,
                                "ocl": null,
                                "clnr": null,
                                "blnr": null,
                                "tt": null,
                                "nat": natCode || "",
                                "Gear": 0,
                                "RPM": 0,
                                "Spline": 0,
                                "MPH": 0,
                                "Velocity": {
                                    "x": 0,
                                    "y": 0,
                                    "z": 0
                                },
                                "ps": 0,
                                "lpe": 0,
                                "nbc": nbc,
                                "nfc": nfc,
                                "es1": null,
                                "es2": null
                            }
                            //If driver does not exist add new object
                            session.Competitors.push(driver) 
                        } else if (obj.driver_name){
                            driver = session.Competitors[driverIndex]
                            driver.teamName = TeamName || obj.driver_team || ""
                            driver.vehicle = Vehicle || obj.car_model || ""
                            driver.DriverName = GraphicName || obj.driver_name || ""
                            driver.CompetitorName = GraphicName || obj.driver_name

                            //If driver does already exist replace object
                            session.Competitors[driverIndex] = driver  
                        }
                        

                    }
                    break
                case "session_info":
                    console.log("sessionInfo")
                    console.log(obj)
                    if(obj.session_index == obj.current_session_index){
                        session = this.acSessionInfo(obj)
                        var i = 0
                        while(i < 100){
                            dataSocket.emit('get_car_info', i)
                            i++
                        }
                    } else {
                        dataSocket.emit('get_session_info', obj.current_session_index)
                    }
                    
                    break
                case "server_error":
                    break
                case "chat":
                    break
                case "car_update":
                    var competitorsState = session.Competitors
                    var driverIndex = competitorsState.findIndex( x => x.CompetitorId === obj.car_id)

                    if (driverIndex > -1){
                        competitorsState[driverIndex].Gear = obj.gear
                        competitorsState[driverIndex].RPM = obj.engine_rpm
                        var spline = obj.normalized_spline_pos*100
                        var splineUpdateDist = 10
                        competitorsState[driverIndex].Spline = parseFloat(spline).toFixed(2);
                        
                        // Calculate live gap to leader
                        if(competitorsState[driverIndex].Position != 1){
                            var leaderIndex = competitorsState.findIndex( x => x.Position == 1)
                            var infrontIndex = competitorsState.findIndex( x => x.Position == competitorsState[driverIndex].Position-1)

                            console.log(leaderIndex, infrontIndex)

                            if(!competitorsState[driverIndex].SplineLastUpdate){
                                competitorsState[driverIndex].SplineLastUpdate = 0
                            }

                            if(spline > competitorsState[driverIndex].SplineLastUpdate + splineUpdateDist || competitorsState[driverIndex].SplineLastUpdate == 0){
                                competitorsState[driverIndex].SplineLastUpdate = spline

                                if(this.state.Session.SessionType == "Race"){
                                    //Check for same lap
                                    if(leaderIndex > -1){
                                        var leaderLaps = competitorsState[leaderIndex].NumberOfLaps
                                        var leaderFinished = competitorsState[leaderIndex].TakenChequered
                                        if(competitorsState[driverIndex].NumberOfLaps == leaderLaps && leaderFinished == false){
                                            //Time difference - use position on the spline and last lap time to calculate the gap in ms

                                            var leaderSpline = competitorsState[leaderIndex].Spline //% the leader is around the lap
                                            var driverSpline = competitorsState[driverIndex].Spline //% current driver is around the lap
                                            var leaderLastLap = competitorsState[leaderIndex].LastLaptime*1000 //Leader last lap time in ms

                                            var leaderTimePos = (leaderSpline/100)*leaderLastLap
                                            var driverTimePos = (driverSpline/100)*leaderLastLap

                                            var behind = leaderTimePos - driverTimePos //time behind leader in ms

                                            if(behind < 0){
                                                behind = 0
                                            }

                                            competitorsState[driverIndex].Behind= this.msToTime(behind)
                                        } else if (leaderFinished == true) {
                                            
                                        } else {
                                            //Lap difference (create negative number so that graphics recognises it as a lap difference instead of time)
                                            competitorsState[driverIndex].Behind = competitorsState[driverIndex].NumberOfLaps - leaderLaps
                                        }
                                    } else {
                                        competitorsState[driverIndex].Behind = 0
                                    }

                                    //Check for same lap
                                    if(infrontIndex > -1){
                                        var infrontLaps = competitorsState[infrontIndex].NumberOfLaps
                                        var infrontFinished = competitorsState[infrontIndex].TakenChequered
                                        if(competitorsState[driverIndex].NumberOfLaps == infrontLaps && infrontFinished == false){
                                            //Time difference - use position on the spline and last lap time to calculate the gap in ms

                                            var infrontSpline = competitorsState[infrontIndex].Spline //% the leader is around the lap
                                            var driverSpline = competitorsState[driverIndex].Spline //% current driver is around the lap
                                            var infrontLastLap = competitorsState[infrontIndex].LastLaptime*1000 //Leader last lap time in ms

                                            var infrontTimePos = (infrontSpline/100)*infrontLastLap
                                            var driverTimePos = (driverSpline/100)*infrontLastLap


                                            var gap = infrontTimePos - driverTimePos //time behind leader in ms

                                            if(gap < 0){
                                                gap = 0
                                            }

                                            competitorsState[driverIndex].Gap= this.msToTime(gap)
                                        } else if (infrontFinished == true) {
                                            
                                        } else {
                                            //Lap difference (create negative number so that graphics recognises it as a lap difference instead of time)
                                            competitorsState[driverIndex].Gap = competitorsState[driverIndex].NumberOfLaps - infrontLaps
                                        }
                                    } else {
                                        competitorsState[driverIndex].Gap = 0
                                    }
                                }
                                
                            }

                        } else {
                            competitorsState[driverIndex].Behind  = 0
                            competitorsState[driverIndex].Gap  = 0
                        }
                    }
    
                        
                        
    
    
                        break
                case "lap_completed":
                    // Add data to competitor - first build the lap object then find the competitor if not found send command to retreave the competitor data
                    // console.log(data)
                    var competitorsState = session.Competitors
                    var driverIndex = competitorsState.findIndex( x => x.CompetitorId === obj.car_id)
                    var leaderboardIndex = obj.leaderboard.findIndex( x => x.car_id === obj.car_id)
                    console.log(driverIndex,leaderboardIndex)
                    if(leaderboardIndex > -1){
                        var lapObj = {
                            "LapNo": obj.leaderboard[leaderboardIndex].laps,
                            "LapTime": obj.laptime,
                            "PassingTime": data.timestamp,
                            "Position": leaderboardIndex+1
                        }

                        //AddLap to driver array (LapData)
                        competitorsState[driverIndex].LapData.push(lapObj)
                        
                        //Calculate remaining laps in session
                        if(session.DurationType == "Laps"){
                            var sessionSD = parseInt(session.SD.replace("Laps", ""))
                            var currentRemLaps = sessionSD - obj.leaderboard[leaderboardIndex].laps
                            console.log("Current Lap", sessionSD,"-",obj.leaderboard[leaderboardIndex].laps,"=", currentRemLaps)
                            if(lapObj.Position == 1){
                                session.Rd = currentRemLaps + "L"
                                console.log(session.Rd)
                            }
                        }
                    } else {
                        console.log("Not found in leaderboard")
                        break
                    }

                    if (driverIndex > -1){

                        competitorsState[driverIndex].SplineLastUpdate = 0

                        //check for PB lap time
                        if(obj.laptime < competitorsState[driverIndex].BestLaptime || competitorsState[driverIndex].BestLaptime == null){
                            var bestLapTime = obj.laptime
                        } else {
                            var bestLapTime = competitorsState[driverIndex].BestLaptime
                        }

                        //calculate end of lap gap to car infront - Find the car infront if in 1st gap and diff is 0 if not in 1st must check laps are the same
                        //Gap is the gap to the leader - behind is difference between competitors
                        if(leaderboardIndex == 0){
                            var gap = 0
                            var behind = 0
                        } else {
                            var leaderIndex = competitorsState.findIndex( x => x.Position == 1)
                            var infrontIndex = competitorsState.findIndex( x => x.Position == leaderboardIndex)
                            

                            //Calculate gap to leader - check laps 1st
                            if(competitorsState[leaderIndex].NumberOfLaps == lapObj.LapNo){
                                var gap = data.timestamp - competitorsState[leaderIndex].LastPassingTime
                            } else {
                                var lapGap = competitorsState[leaderIndex].NumberOfLaps - lapObj.LapNo

                                if (lapGap > 1){
                                    var text = " Laps"
                                } else {
                                    var text = " Lap"
                                }

                                var gap = lapGap + text
                            }

                            //Calculate gap to car in front - check laps 1st
                            if(competitorsState[infrontIndex].NumberOfLaps == lapObj.LapNo){
                                var behind = data.timestamp - competitorsState[infrontIndex].LastPassingTime
                            } else {
                                var lapBehind = competitorsState[infrontIndex].NumberOfLaps - lapObj.LapNo

                                if (lapGap > 1){
                                    var text = " Laps"
                                } else {
                                    var text = " Lap"
                                }

                                var gap = lapBehind + text
                            }

                        }

                        competitorsState[driverIndex].Position = leaderboardIndex+1
                        competitorsState[driverIndex].NumberOfLaps = lapObj.LapNo
                        competitorsState[driverIndex].TakenChequered = obj.leaderboard[leaderboardIndex].completed
                        competitorsState[driverIndex].BestLaptime = bestLapTime
                        competitorsState[driverIndex].LastLaptime = obj.laptime
                        competitorsState[driverIndex].LastPassingTime = data.timestamp*1000
                        competitorsState[driverIndex].Gap = gap
                        competitorsState[driverIndex].Behind = behind

                        if(competitorsState[driverIndex].TakenChequered == true && competitorsState[driverIndex].Position != 1 && this.state.Session.SessionType != "Qual"){
                            //calculate exact finishing gaps
                            var leaderIndex = competitorsState.findIndex( x => x.Position == 1)
                            var infrontIndex = competitorsState.findIndex( x => x.Position == competitorsState[driverIndex].Position-1)

                            var gap = competitorsState[driverIndex].LastPassingTime - competitorsState[infrontIndex].LastPassingTime //Calculate gap to car infront
                            var behind = competitorsState[driverIndex].LastPassingTime - competitorsState[leaderIndex].LastPassingTime //Calculate gap to car behind

                            competitorsState[driverIndex].Gap = this.msToTime(gap)
                            competitorsState[driverIndex].Behind = this.msToTime(behind)
                        }

                    }

                    // Update positions for all drivers in leaderboard
                    var i = 0
                    var p1Time
                    var infrontTime
                    while (i < obj.leaderboard.length){
                        var driverID = obj.leaderboard[i].car_id
                        var driverIndex = competitorsState.findIndex( x => x.CompetitorId == driverID)
                        
                        if(driverIndex > -1){
                            competitorsState[driverIndex].Position = i+1
                            
                            if(this.state.Session.SessionType == "Qual"){
                                //If this is a timed session, apply gaps for lap times in the leaderboard.
                                if(competitorsState[driverIndex].Position == 1){
                                    competitorsState[driverIndex].Gap = 0
                                    competitorsState[driverIndex].Behind = 0
                                    p1Time = obj.leaderboard[i].laptime*1000
                                    infrontTime = obj.leaderboard[i].laptime*1000
                                } else {
                                    if(obj.leaderboard[i].laptime != 999999999){
                                        var laptime = obj.leaderboard[i].laptime*1000

                                        competitorsState[driverIndex].Gap = this.msToTime(laptime - infrontTime)
                                        competitorsState[driverIndex].Behind = this.msToTime(laptime - p1Time)
                                        competitorsState[driverIndex].BestLapTime = obj.leaderboard[i].laptime

                                        infrontTime = obj.leaderboard[i].laptime*1000
                                    }
                                }
                                competitorsState[driverIndex].NumberOfLaps = obj.leaderboard[i].laps
                            }
                        }

                        i++
                    }

                    break
                case "client_loaded":
                    dataSocket.emit('get_car_info', obj.car_id)
                    break
                case "new_connection":
                    dataSocket.emit('get_car_info', obj.car_id)
                    break
                case "connection_closed":
                    break
            }

            // console.log(session)
            this.setState({
                Controls: controls,
                Session: session,
                Races: races
            })
        
    }

    acSessionInfo = (obj) => {
        var controls = this.state.Controls
        var session = this.state.Session
        var races = this.state.Races
        //Calculate SD (Session Duration)
        var time
        var laps
        if(obj.time && obj.laps){
            time = obj.time
            laps = obj.laps
            if(time >= 60){
                time = time/60 
                time = time + "H"
            } else {
                time = time + "m"
            }

            var sd = time + "+" + laps + "L"
            var rd = time + "+" + laps + "L"
            var durationType = "Time+Laps"

        } else if (obj.time) {
            time = obj.time
            laps = obj.laps
            if(time>60){
                time = time/60 
                time = time + "H"
            } else {
                time = time + "m"
            }
            
            var sd = time
            var rd = time
            var durationType = "Time"

        } else if (obj.laps){
            var sd = obj.laps+"Laps"
            var rd = obj.laps+"L"
            var durationType = "Laps"
        } else {
            var sd = "5M"
            var rd = "5M"
            var durationType = "Time"
        }


        //Calculate session type 1 = practice, 2 = qualifying, 3 = race
        var type
        switch (obj.session_type){
            case 1:
                //practice session but for data needs to read Qual
                type = "Qual"
                break
            case 2:
                type = "Qual"
                break
            case 3:
                type = "Race"
                break
        }

        

        //Create session object
        session = {
                SessionStartTime: 0,
                ResultAvailable: null,
                ShowNotifications: null,
                EventId: obj.server_name,
                ServerTime: 0,
                SessionId: obj.session_index,
                SessionName: obj.name,
                SessionType: type,
                SessionTimeRemaining: 0,
                CircuitMap: obj.track + "-"+obj.track_config,
                TrackLength: null,
                TrackName: obj.track + "-"+obj.track_config,
                tn: obj.track + "-"+obj.track_config,
                Weather: obj.weather_graphics,
                Temperature: obj.ambient_temp,
                TrackCondition: null,
                IsTeamEvent: null,
                MultiCls: null,
                ns: null,
                nst: null,
                bn: null,
                bst: null,
                bet: null,
                officials: {
                    CompetitionSecretary: null,
                    Timekeeper: null,
                },
                dp: null,
                blt: '',
                bs1: null,
                bs2: null,
                bs3: null,
                tt: null,
                LengthTime: time,
                SD: sd ,
                flds: [
                    "gd",
                    "ps",
                    "lpe"
                ],
                nto: -0.04,
                ntst: "2022-11-27T15:33:45.329Z",
                ntip: "132.163.96.2",
                ntsn: "time.nist.gov",
                State: "Running",
                fcy: false,
                Sequence: null,
                Sectors: null,
                RaceTime: 0,
                Rd: rd,
                DurationType: durationType,
                Last: false,
                End: false,
                show_race_time: false,
                TimingSystem: null,
                Time: null,
                Date: null,
                Clear: false,
                Competitors: [],
        }

        if (obj.time){
            var duration_ms = obj.time*60000
            var remainingTime = duration_ms - obj.elapsed_ms
            var waitTime = obj.wait_time
            var startTime = Date.now() - obj.elapsed_ms

            if(obj.elapsed_ms == 0){
                var startTime = startTime + waitTime
            }
            

            console.log(Date.now(), obj.elapsed_ms)

            session.SessionStartTime = startTime*1000
            session.LengthTime = duration_ms*1000

            console.log("Remaining",remainingTime)

            this.remainingTime(remainingTime*1000)

            // this.setState({
                
            //     lastTimeCount: Date.now()
            // })
        }

        // console.log(session)
        return session
}

    acSendMessage = (e) => {
        console.log(e.target)

        if(dataSocket){
            console.log(e.target.getAttribute('attr1'))
            switch(e.target.getAttribute('attr1')){
                case "Next_Session":
                    dataSocket.emit('next_session')
                    break
                case "Car_Info":
                    var i = 0
                    while(i < 100){
                        dataSocket.emit('get_car_info', i)
                        i++
                    }
                    break
                case "Session_Info":
                    dataSocket.emit('get_session_info')
                    break
                case "Restart_Session":
                    dataSocket.emit('restart_session')
                    break
                case "Start_RealTime":
                    dataSocket.emit('enable_realtime_report', 0)
                    break
            }


            //dataSocket.emit('admin_command', command)
            //dataSocket.emit('kick_user', carId)
            //dataSocket.emit('send_message', carId, message)
            //dataSocket.emit('set_session_info', sessionInfo)
        }
        

    }

    alkamelStartGridData = () => {
        console.log("StartGridCall")
        // Starting Grid - This section is duplicated from earlier just incase there's any adjustments to the grid after entries added to session.
        if(this.state.alkamelStartGrid){
            if(this.state.alkamelStartGrid.positions){
                var gridArray = this.state.alkamelStartGrid.positions
                var compArray = this.state.Session.Competitors
                console.log(gridArray)

                for (let key in gridArray) {

                    if(gridArray[key].participant){
                        var compIndex = compArray.findIndex( x => x.CompetitorId == parseInt(gridArray[key].participant))
                    } else {
                        break
                    }

                    if(compIndex != -1){
                        console.log("Found")
                        compArray[compIndex].GridPosition = parseInt(key)
                        compArray[compIndex].qualification = gridArray[key].time
                    } else {
                        console.log(compIndex)
                    }
                }
            } else {
                console.log("positions not found")
            }
        } else {
            console.log("grid not found")
        }
    }
    
    componentWillUnmount() {
        if (series == "msuk"){
            socket.disconnect()
            replaySocket.disconnect()
        } else if(room == "cscc" || room == "tcruk" || room == "msvr" || room == "ror" || room == "brscc" || room == "Darkside" || room == "750mc" || room == "racetv"){
            socket.disconnect()
        } else {
            this.channel.unbind()
            this.pusher.unsubscribe(this.channel)
            this.pusher.unsubscribe(this.channel2)
            this.pusher.disconnect()
            socket.disconnect()
        }
    }

    sessionSelect = (e) => {
        console.log(e.target.value)
        var session = this.state.Session
        var races = this.state.Races
        var raceIndex = races.findIndex( x => x.SessionId == e.target.value)


        
        if(raceIndex > -1){
            // First we find the session place holder in the races list and then replace it with the current session state
            // If the session does not exist we add it to the end of the array unless it is the dummy session
            var sessionIndex = races.findIndex( x => x.SessionId == session.SessionId)
            if(sessionIndex > -1){
                races[sessionIndex] = session
            } else {
                //check if dummy session do not save data if it is dummy session
                if (session.SessionName != 'No Data'){
                    console.log('session saved')
                    races[races.length] = session
                } else {
                    console.log('dummy data - not saved')
                }
            }

            // Now we have saved the current session data we can move the required session into the current session slot.
            session = races[raceIndex]

            this.setState({
                Session: session,
                Races: races
            })

            var messageData = {
                room: room,
                author: "Controller",
                type: "RaceSelect",
                message: raceIndex,
                time:
                new Date(Date.now()).getHours() +
                ":" +
                new Date(Date.now()).getMinutes(),
            };
        
            socket.emit("send_message", messageData); 
        }

    }

    manualRaceStart = (e) => {
        var dropdown = document.getElementById("state")
        dropdown.value = "Running"
        var session = this.state.Session

        session.State = "Running"

        if (!session.StartTime){
            session.StartTime = Date.now()
        }

        var lapsIndex = session.SD.indexOf("Laps")
        if (lapsIndex != -1){
            HSClockInt = setInterval(() => this.HSClock(), 500);
        }

        var messageData = {
            room: room,
            author: "Controller",
            type: "State",
            message: "Running",
            time:
            new Date(Date.now()).getHours() +
            ":" +
            new Date(Date.now()).getMinutes(),
        };

        socket.emit("send_message", messageData); 

        this.setState({
            Session: session
        })
    }

    messageEventHandler = data => {
        var session = this.state.Session
        var controls = this.state.Controls
        // console.log(data)
        if (data.Clear == true){
            console.log("DataClear")
        }

        if (data.blt){
            data.blt.pusher = true
            session.blt = data.blt
        }

        if (data.End == true){
            session.End = true
        }

        if (data.Last == true){

        }

        if (data.RaceTime){
            session.RaceTime = parseInt(data.RaceTime)*1000
        }

        if (data.Rd){
            var index = data.Rd.indexOf("L")
            if (index > -1){
                session.Rd = 0
            } else if (data.Rd == "0") {
                session.Rd = "0"
            } else {
                session.Rd = parseInt(data.Rd)*1000+""
            }

            if(data.Rd == '1L'){
                session.Last = true
            } else {
                session.Last = false
            }
        }

        if (data.SD){
            session.SD = data.SD
        }

        if (data.SessionName){
            session.SessionName = data.SessionName
        }

        if (data.Sectors){
            
        }

        if (data.Sequence){
            
        }

        if (data.TimingSystem){
            
        }

        if (data.State){
            session.State = data.State
        }

        if (data.fcy == true){
            session.fcy = true
        } else if (data.fcy == false) {
            session.fcy = false
        }

        if (data.show_race_time == true){
            
        }

        if (data.SessionId){
            if (data.SessionId != session.SessionId){
                this.getInitialState()
                controls.ResultsMargin = -100
                controls.ComparisonLeadDriver = undefined
            } else {
                session.SessionId = data.SessionId
            }
        }

        if (data.Competitors){
            var i = 0
            var competitorsState = session.Competitors
            while (i < data.Competitors.length){
                var competitorIndex = competitorsState.findIndex( x => x.CompetitorId === data.Competitors[i].CompetitorId)
                if (competitorIndex == -1){
                    competitorsState.push(data.Competitors[i])
                } else {
                    //positionChangeCalcs
                    if (data.Competitors[i].Position != undefined){
                        var PosChangeCalc = parseInt(competitorsState[competitorIndex].Position) - parseInt(data.Competitors[i].Position)
                        // console.log("pos", PosChangeCalc, competitorsState[competitorIndex].Position, data.Competitors[i].Position)
                        if (PosChangeCalc == 0){
                            // var PosChangeClass = "positionneutral"
                        } else if (PosChangeCalc > 0){
                            var PosChangeClass = "positionup"
                        } else if (PosChangeCalc < 0){
                            var PosChangeClass = "positiondown"
                        } else {
                            // var PosChangeClass = "positionneutral"
                        } 
                        var posChangeTime = this.getTimestampInSeconds()
                    } else {
                        // var PosChangeClass = "positionneutral"
                    }
                    // console.log(competitorsState[competitorIndex].CompetitorName, data.Competitors[i].IsBestLapInRace)
                    competitorsState[competitorIndex] = {
                        "CompetitorId": data.Competitors[i].CompetitorId || competitorsState[competitorIndex].CompetitorId,
                        "CompetitorNumber": data.Competitors[i].CompetitorNumber || competitorsState[competitorIndex].CompetitorNumber,
                        "CompetitorName": data.Competitors[i].CompetitorName || competitorsState[competitorIndex].CompetitorName,
                        "CompetitorClass": data.Competitors[i].CompetitorClass || competitorsState[competitorIndex].CompetitorClass,
                        "CompetitorSubClass": data.Competitors[i].CompetitorSubClass || competitorsState[competitorIndex].CompetitorSubClass,
                        "DriverName": data.Competitors[i].DriverName || competitorsState[competitorIndex].DriverName,
                        "Position": data.Competitors[i].Position || competitorsState[competitorIndex].Position,
                        "NumberOfLaps": data.Competitors[i].NumberOfLaps || competitorsState[competitorIndex].NumberOfLaps,
                        "LastLaptime": data.Competitors[i].LastLaptime || competitorsState[competitorIndex].LastLaptime,
                        "LapData": competitorsState[competitorIndex].LapData,
                        "RaceTime": data.Competitors[i].RaceTime || competitorsState[competitorIndex].RaceTime,
                        "RunningTime": data.Competitors[i].RunningTime || competitorsState[competitorIndex].RunningTime,
                        "BestLaptime": data.Competitors[i].BestLaptime || competitorsState[competitorIndex].BestLaptime,
                        "BestLapNumber": data.Competitors[i].BestLapNumber || competitorsState[competitorIndex].BestLapNumber,
                        "IsBestLapInRace": data.Competitors[i].IsBestLapInRace || competitorsState[competitorIndex].IsBestLapInRace,"Seen": data.Competitors[i].Seen || competitorsState[competitorIndex].Seen,
                        "InPit": data.Competitors[i].InPit || false,
                        "Retired": data.Competitors[i].Retired || competitorsState[competitorIndex].Retired,
                        "GridPosition": data.Competitors[i].GridPosition || competitorsState[competitorIndex].GridPosition,
                        "PositionChange": data.Competitors[i].PositionChange || competitorsState[competitorIndex].PositionChange || 0,
                        "PosChangeClass" : PosChangeClass || competitorsState[competitorIndex].PosChangeClass || "positionneutral",
                        "posChangeTime" : posChangeTime || competitorsState[competitorIndex].posChangeTime || 0,
                        "Gap": data.Competitors[i].Gap || competitorsState[competitorIndex].Gap,
                        "gd": data.Competitors[i].gd || competitorsState[competitorIndex].gd,
                        "Behind": data.Competitors[i].Behind || competitorsState[competitorIndex].Behind,
                        "Split1": data.Competitors[i].Split1 || competitorsState[competitorIndex].Split1,
                        "Split2": data.Competitors[i].Split2 || competitorsState[competitorIndex].Split2,
                        "Split3": data.Competitors[i].Split3 || competitorsState[competitorIndex].Split3,
                        "IsBestSector1": data.Competitors[i].IsBestSector1 || competitorsState[competitorIndex].IsBestSector1,
                        "pbs1": data.Competitors[i].pbs1 || competitorsState[competitorIndex].pbs1,
                        "IsBestSector2": data.Competitors[i].IsBestSector2 || competitorsState[competitorIndex].IsBestSector2,
                        "pbs2": data.Competitors[i].pbs2 || competitorsState[competitorIndex].pbs2,
                        "IsBestSector3": data.Competitors[i].IsBestSector3 || competitorsState[competitorIndex].IsBestSector3,
                        "pbs3": data.Competitors[i].pbs3 || competitorsState[competitorIndex].pbs3,
                        "cs": data.Competitors[i].cs || competitorsState[competitorIndex].cs,
                        "el": data.Competitors[i].el || competitorsState[competitorIndex].el,
                        "TakenChequered": data.Competitors[i].TakenChequered || competitorsState[competitorIndex].TakenChequered,
                        "ocl": data.Competitors[i].ocl || competitorsState[competitorIndex].ocl,
                        "clnr": data.Competitors[i].clnr || competitorsState[competitorIndex].clnr,
                        "blnr": data.Competitors[i].blnr || competitorsState[competitorIndex].blnr,
                        "tt": data.Competitors[i].tt || competitorsState[competitorIndex].tt,
                        "nat": data.Competitors[i].nat || competitorsState[competitorIndex].nat,
                        "ps": data.Competitors[i].ps || competitorsState[competitorIndex].ps,
                        "lpe": data.Competitors[i].lpe || competitorsState[competitorIndex].lpe,
                        "nbc": data.Competitors[i].nbc || competitorsState[competitorIndex].nbc,
                        "nfc": data.Competitors[i].nfc || competitorsState[competitorIndex].nfc,
                        "es1": data.Competitors[i].es1 || competitorsState[competitorIndex].es1,
                        "es2": data.Competitors[i].es2 || competitorsState[competitorIndex].es2
                    }

                    if (data.Competitors[i].IsBestLapInRace == false && competitorsState[competitorIndex].IsBestLapInRace == true){
                        competitorsState[competitorIndex].IsBestLapInRace = false
                    } else if (data.Competitors[i].IsBestLapInRace == undefined && competitorsState[competitorIndex].IsBestLapInRace == true){
                        competitorsState[competitorIndex].IsBestLapInRace = true
                    } else {
                        competitorsState[competitorIndex].IsBestLapInRace = data.Competitors[i].IsBestLapInRace
                    } 

                    if (data.Competitors[i].LastLaptime){
                        var newLapObj = {
                            "LapNo": competitorsState[competitorIndex].NumberOfLaps,
                            "LapTime": data.Competitors[i].LastLaptime*1000,
                            "Position": competitorsState[competitorIndex].Position,
                        }
                        if (competitorsState[competitorIndex].LapData){
                            competitorsState[competitorIndex].LapData.push(newLapObj)
                        } else {
                            competitorsState[competitorIndex].LapData = []
                            competitorsState[competitorIndex].LapData.push(newLapObj)
                        }
                    }
                    
                }
                i++
            }
            session.Competitors = competitorsState
        }

        if (data.SessionStartTime){
            session.SessionStartTime = data.SessionStartTime
        }

        this.setState({
            Session: session,
            Controls: controls
        })
    }

    notificationHandler = data => {
        // console.log(data)
        var notifications = this.state.Notifications
        //Check exists START
        if (data.m[0]){
            var index = notifications.findIndex(x => x[0].id == data.m[0].id);
        } else {
            var index = 1
        }
            
        //Check exists ENDS
        
        if (index == -1){
            this.setState({
                NotificationSequence: data.s
            })
            if (data.m.length > 0){
                if (data.m[0].nt == 1 || data.m[0].nt == 2 ||data.m[0].nt == 3 ||data.m[0].nt == 4 ||data.m[0].nt == 5 ||data.m[0].nt == 6 ||data.m[0].nt == 9 ||data.m[0].nt == 10 ||data.m[0].nt == 27 ||data.m[0].nt == 28 ||data.m[0].nt == 36 ||data.m[0].nt == 37){
                    if(data.m[0].t){
                        data.m[0].t = " - " + data.m[0].t
                    } else {
                        data.m[0].t = ""
                    }
                    switch(data.m[0].nt){
                        case 1: 
                            data.m[0].nt = "Cone warning";
                            data.m[0].displayed = false
                            break
                        case 2: 
                            data.m[0].nt = "Kerb warning";
                            data.m[0].displayed = false
                            break
                        case 3: 
                            data.m[0].nt = "Contact warning";
                            data.m[0].displayed = false
                            break
                        case 4: 
                            data.m[0].nt = "Warning";
                            data.m[0].displayed = false
                            break
                        case 5: 
                            data.m[0].nt = "Black flag";
                            data.m[0].displayed = false
                            break
                        case 6: 
                            data.m[0].nt = "Cone Penalty";
                            data.m[0].displayed = false
                            break
                        case 9: 
                            data.m[0].nt = "Penalty";
                            data.m[0].displayed = false
                            break
                        case 10: 
                            data.m[0].nt = "Mechanical flag";
                            data.m[0].displayed = false
                            break
                        case 27: 
                            data.m[0].nt = "Knob warning";
                            data.m[0].displayed = false
                            break
                        case 28: 
                            data.m[0].nt = "Visor warning";
                            data.m[0].displayed = false
                            break
                        case 36: 
                            data.m[0].nt = "Drive through penalty";
                            data.m[0].displayed = false
                            break
                        case 37: 
                            data.m[0].nt = "Track limit warning";
                            data.m[0].displayed = false
                            break
                        case 101: 
                            data.m[0].nt = "Red Flag";
                            data.m[0].displayed = true
                            break
                        case 102: 
                            data.m[0].nt = "Restart"
                            data.m[0].displayed = true
                            break
                        case 103: 
                            data.m[0].nt = "Race Start";
                            data.m[0].displayed = true
                            break
                        case 104: 
                            data.m[0].nt = "False Start";
                            data.m[0].displayed = true
                            break
                        case 105: 
                            data.m[0].nt = "Chequered Flag";
                            data.m[0].displayed = true
                            break
                        case 107: 
                            data.m[0].nt = "Full Course Yellow";
                            data.m[0].displayed = true
                            break
                        case 108: 
                            data.m[0].nt = "Full Course Yellow END";
                            data.m[0].displayed = true
                            break
                        case 109:
                            data.m[0].nt = data.m[0].nt
                            data.m[0].displayed = true
                    }
                    notifications.push(data.m)
                    this.setState({
                        Notifications: notifications,
                    })
                }
                
            }
        }
    }

    apexMessage = data => {
        // console.log(data)
        var session = this.state.Session
        var controls = this.state.Controls
        var notifications = this.state.Notifications

        var i = 0 
        while (i < data.length){
            if (data[i].length > 0){
                var split = JSON.parse(data[i])
                console.log(split.type)

                if (split.type == "init"){
                    if (parseInt(split.value) == 1){
                        //Reset Session Data to blank
                        session = {
                            SessionStartTime: null,
                            ResultAvailable: null,
                            ShowNotifications: null,
                            EventId: "",
                            SessionId: null,
                            SessionName: "",
                            SessionType: null,
                            CircuitMap: null,
                            TrackLength: null,
                            TrackName: null,
                            Weather: null,
                            Temperature: null,
                            TrackCondition: null,
                            IsTeamEvent: null,
                            MultiCls: null,
                            ns: null,
                            nst: null,
                            bn: null,
                            bst: null,
                            bet: null,
                            officials: {
                                CompetitionSecretary: null,
                                Timekeeper: null,
                            },
                            dp: null,
                            blt: null,
                            bs1: null,
                            bs2: null,
                            bs3: null,
                            tt: null,
                            SD: "10M+1L",
                            flds: [
                                "gd",
                                "ps",
                                "lpe"
                            ],
                            nto: -0.04,
                            ntst: "2022-11-27T15:33:45.329Z",
                            ntip: "132.163.96.2",
                            ntsn: "time.nist.gov",
                            State: null,
                            fcy: false,
                            Sequence: null,
                            Sectors: null,
                            RaceTime: 0,
                            Rd: null,
                            Last: false,
                            End: false,
                            show_race_time: false,
                            TimingSystem: null,
                            Time: null,
                            Date: null,
                            Clear: false,
                            Competitors: [],
                        }

                        //Reset Control Data to default
                        controls = {
                            AutoCol: false,
                            IncludePs: false,
                            ClockShow: true,
                            TowerShow: true,
                            ResultsShow: false,
                            NotificationShow: false,
                            FastestLapShow: false,
                            ChampionshipShow: true,
                            ChampionshipSelect: 0,
                            ComparisonShow: false,
                            ComparisonLeadDriver: undefined,
                            TowerMargin: [0,0,35,70,105,140,175,210,245,280,315,350,385,420,455,490,525,560,595,630,665,700,735,770,805,840,875,910,945,980,1015,1050,1085,1120,1155,1190,1225,1260,1295,1330,1365,1400,1435,1470,1505,1540,1575,1610,1645,1680,1715,1750,1785,1820,1855,1890,1925,1960,1995,2030,2065,2100,2135,2170,2205,2240,2275,2310,2345,2380,2415,2450,2485,2520,2555,2590,2625,2660,2695,2730,2765,2800,2835,2870,2905,2940,2975,3010,3045,3080,3115,3150,3185,3220],
                            ResultsMargin: -100,
                            TowerPagesMargin: [-385,-350,-315,-280,-245,-210,-175,-140,-105,-70,-35,0,35,70,105,140,175,210,245,280,315,350,385,420,455,490,525,560,595,630,665,700,735,770,805,840,875,910,945,980,1015,1050,1085,1120,1155,1190,1225,1260,1295,1330,1365,1400,1435,1470,1505,1540,1575,1610,1645,1680,1715,1750,1785,1820,1855,1890,1925,1960,1995,2030,2065,2100,2135,2170,2205,2240,2275,2310,2345,2380,2415,2450,2485,2520,2555,2590,2625,2660,2695,2730,2765,2800,2835,2870,2905,2940,2975,3010,3045,3080,3115,3150,3185,3220],
                            CurrentPage: 0,
                            BattleShow: false,
                            BattleDrivers: [],
                            BattlePosition: undefined,
                            ProgressionShow: false,
                            ProgressionPosition: undefined,
                            Col4: "gap",
                            LiveChampsTitle: "LIVE CHAMPIONSHIP",
                            LiveChampsDisplay: "liveChampHide",
                            GridPage: 1
                        }

                        //Reset Notifications Data to  blank
                        notifications = [[{
                            initial : true,
                            cna : "",
                            cnu : "",
                            t : "",
                            nt: "",
                            displayed: true
                        }]]
                    } else {

                    }
                }
            
                if (split.type == "sync"){
                    // console.log(split)
                    session.Date = split.date || null
                    session.ServerTime = parseInt(split.time) || null

                        var decal = 0;
                        if (split.time != 0)
                        {
                        decal = (new Date().getTime()) - split.time;
                        } 
                        
                        session.ServerTime = (new Date().getTime() - decal)
                }
            
                if (split.type == "event"){
                    session.EventId = split.title || ""
                    session.tn = split.trackName || null
                    session.TrackLength = split.trackLength || null
                }
            
                if (split.type == "heat"){
                    session.SessionName = split.category + " " + split.title || ""
                    session.SessionType = split.race || 0
                    //Work out race duration
                    if (split.duration){
                        session.Duration = split.duration
                        session.SD = this.msRacetime(split.duration)
                        // console.log(split.laps)
                        if (parseInt(split.laps)>0){
                            session.SD = session.SD + "+" + split.laps + "L"
                        }
                    } else if (split.laps){
                        session.SD = split.laps+"L"
                    } else {
                        session.SD = 0
                    }

                    if (session.Rd == null){
                        session.Rd = split.duration
                    }
                    // console.log(session.SD)
                }

                if (split.type == "weather"){
                //Not Done yet - complete when weather is useful in graphics
                }

                if (split.type == "driver"){
                    console.log(split)
                    if (split.color){
                        if(split.color == "#80FFFF"){
                            var nbc = "#212121"
                            var nfc = "#ffffff"
                        } else if(split.color == "#000000") {
                            var nbc = "#"+this.state.ClientStates.PrimaryColour
                            var nfc = "#"+this.state.ClientStates.SecondaryColour
                        } else {
                            // var nbc = "#2faae1"
                            // var nfc = "#ffffff"
                            var nbc = "#"+this.state.ClientStates.PrimaryColour
                            var nfc = "#"+this.state.ClientStates.SecondaryColour
                        }
                    }

                    var driver = {
                        "CompetitorId": split.id || "",
                        "CompetitorNumber": split.no || "",
                        "CompetitorName": split.firstName + " " + split.lastName || "",
                        "Picture" : "https://www.motorsport-timing.co.uk/media.motorsport-timing.co.uk/stream/"+split.picture || "",
                        "CompetitorClass": "",
                        "CompetitorSubClass": "",
                        "teamName": split.entrant || "",
                        "vehicle": split.carFrame || "",
                        "DriverName": split.shortName || "",
                        "Position": i,
                        "NumberOfLaps": 0,
                        "LastLaptime": "",
                        "LapData": [],
                        "RaceTime": null,
                        "RunningTime": null,
                        "BestLaptime": null,
                        "BestLapNumber": null,
                        "IsBestLapInRace": false,
                        "Seen": false,
                        "InPit": false,
                        "Retired": false,
                        "GridPosition": 999,
                        "PositionChange": 0,
                        "PosChangeClass" : "positionneutral",
                        "posChangeTime" : 0,
                        "Gap": 0,
                        "gd": 999,
                        "Behind": 0,
                        "Split1": 0,
                        "Split2": 0,
                        "Split3": 0,
                        "IsBestSector1": false,
                        "pbs1": 0,
                        "IsBestSector2": false,
                        "pbs2": 0,
                        "IsBestSector3": false,
                        "pbs3": 0,
                        "cs": null,
                        "el": null,
                        "TakenChequered": false,
                        "ocl": null,
                        "clnr": null,
                        "blnr": null,
                        "tt": null,
                        "nat": split.nation,
                        "ps": 0,
                        "lpe": 0,
                        "nbc": nbc,
                        "nfc": nfc,
                        "es1": null,
                        "es2": null,
                        "ChampPosition": null,
                        "ChampPoints": null,
                        "ChampPointsChange": null,
                        "ChampPositionChange": null
                    }
                    session.Competitors.push(driver)
                }

                if (split.type == "driverLive"){
                    var competitorsState = session.Competitors
                    var driverIndex = competitorsState.findIndex( x => x.CompetitorId == split.id)

                    if (driverIndex > -1){
                        competitorsState[driverIndex].NumberOfLaps = split.laps
                        // competitorsState[driverIndex].Gap = 
                        competitorsState[driverIndex].Behind = split.r2
                        competitorsState[driverIndex].PosChangeClass = "positionneutral";

                        switch (split.st){
                            case "finish":
                                competitorsState[driverIndex].TakenChequered = true;
                                // if(competitorsState[driverIndex].Position == 1 && this.state.ClientStates.DriverPhotos == true){
                                //     controls.Expand = true,
                                //     controls.ExpandDriverID = split.id,
                                //     controls.ExpandIndex = driverIndex
                                // }
                                break;
                            case "up":
                                competitorsState[driverIndex].PosChangeClass = "positionup";
                                break;
                            case "down":
                                competitorsState[driverIndex].PosChangeClass = "positiondown";
                                break;

                        }
                    }
                    

                }

                if (split.type == "driverFlag"){
                    var competitorsState = session.Competitors
                    var driverIndex = competitorsState.findIndex( x => x.CompetitorId === split.id)
                    if(driverIndex > -1){
                        switch(split.flag){
                            case "tech": 
                                split.t = ""
                                split.nt = "Mechanical Failure";
                                split.cnu = competitorsState[driverIndex].CompetitorNumber
                                split.cna = competitorsState[driverIndex].CompetitorName
                                split.displayed = false
                                break
                            case "warn": 
                                split.t = ""
                                split.nt = "Warning";
                                split.displayed = false;
                                split.cnu = competitorsState[driverIndex].CompetitorNumber
                                split.cna = competitorsState[driverIndex].CompetitorName
                                break
                            case "black": 
                                split.t = ""
                                split.nt = "Black Flag";
                                split.displayed = false;
                                split.cnu = competitorsState[driverIndex].CompetitorNumber
                                split.cna = competitorsState[driverIndex].CompetitorName
                                break
                            case "invest":
                                split.t = "" 
                                split.nt = "Investigation";
                                split.displayed = false;
                                split.cnu = competitorsState[driverIndex].CompetitorNumber
                                split.cna = competitorsState[driverIndex].CompetitorName
                                break
                            case "+5seconds":
                                split.t = "" 
                                split.nt = "+5 Seconds Penalty";
                                split.displayed = false;
                                split.cnu = competitorsState[driverIndex].CompetitorNumber
                                split.cna = competitorsState[driverIndex].CompetitorName
                                break
                            case "+10seconds":
                                split.t = "" 
                                split.nt = "+10 Seconds Penalty";
                                split.displayed = false;
                                split.cnu = competitorsState[driverIndex].CompetitorNumber
                                split.cna = competitorsState[driverIndex].CompetitorName
                                break
                    }
                    notifications.push([split])
                    }

                    
                }

                if (split.type == "list" || split.type == "rnk"){
                    console.log(split)
                    var listInt = 0
                    var competitorsState = session.Competitors
                    var list = split.list.split(",")
                    while (listInt < list.length){
                        var driverIndex = competitorsState.findIndex( x => x.CompetitorId == list[listInt])
                        
                        if (driverIndex > -1){
                            console.log("here")
                            competitorsState[driverIndex].Position = listInt+1
                            if(competitorsState[driverIndex].GridPosition == 999){
                                competitorsState[driverIndex].GridPosition = listInt
                                competitorsState[driverIndex].gd = listInt
                                competitorsState[driverIndex].Gap = ""
                                }

                            if(session.State == "ChequeredFlag"){
                                if(listInt == 0){
                                    controls.Expand = true
                                    controls.ExpandDriverID = list[listInt]
                                    controls.ExpandIndex = driverIndex
                                } 
                            }
                        }
                        listInt++
                    }
                }

                if (split.type == "autoMode"){

                }

                if (split.type == "timing"){
                    // console.log(split)
                    if (split.startTime){
                        this.setState({
                            startTime: parseInt(split.startTime)
                        })
                    }

                    if (parseInt(split.lastLap) != 0){
                        session.Rd = "1L"
                        session.Last = true
                    } else {
                        session.Last = false
                    }
                    
                    switch (split.flag){
                        case "start":
                            session.State = "Running"
                            session.fcy = false
                            break
                        case "green":
                            session.State = "Running"
                            session.fcy = false
                            break
                        case "finish":
                            session.End = true
                            session.State = "ChequeredFlag"
                            session.fcy = false
                            break
                        case "yellow":
                            session.State = "Yellow"
                            session.fcy = true
                            break
                        case "red":
                            session.State = "RedFlag"
                            session.fcy = false
                            break
                        case "none":
                            session.State = "Formation"
                            session.fcy = false
                            break
                    }
                }

                if (split.type == "bestLap"){
                    var competitorsState = session.Competitors
                    var driverIndex = competitorsState.findIndex( x => x.CompetitorId === split.id)

                    if (driverIndex > -1){
                        session.blt = {
                            tm: split.time,
                            cn: competitorsState[driverIndex].CompetitorNumber,
                            cid: split.id,
                            ln: split.lap,
                            pusher: true,
                            shown: false
                        }

                    var compIndex = 0;
                    while (compIndex< competitorsState.length){
                            if (compIndex == driverIndex){
                                competitorsState[compIndex].IsBestLapInRace = true
                            } else {
                                competitorsState[compIndex].IsBestLapInRace = false
                            }
                            compIndex++
                        }
                    }
                }

                if (split.type == "driverMerge"){
                    var competitorsState = session.Competitors
                    var driverIndex = competitorsState.findIndex( x => x.CompetitorId === split.id)
                    if (driverIndex > -1){
                        // competitorsState[driverIndex].ChampPosition = split.rnk
                        competitorsState[driverIndex].ChampPoints = split.r1
                        competitorsState[driverIndex].ChampPointsChange = 0;
                        if (split.st == 'better'){
                            competitorsState[driverIndex].ChampPositionChange = 1
                        } else if (split.st == 'worse'){
                            competitorsState[driverIndex].ChampPositionChange = -1
                        } else {
                            competitorsState[driverIndex].ChampPositionChange = 0
                        }                    
                    }
                }

                if (split.type == "rnkMerge"){
                    var compIndex = 0
                    var competitorsState = session.Competitors
                    var mergeArray = split.list.split(",")
                    if (split.title == "Intermediate Classification"){
                        controls.LiveChampsTitle = "INTERMEDIATE CLASSIFICATION"
                    } else {
                        controls.LiveChampsTitle = "LIVE CHAMPIONSHIP"
                    }
                    while (compIndex < competitorsState.length){
                        var compId = competitorsState[compIndex].CompetitorId
                        var driverIndex = mergeArray.indexOf(compId)
                        if (driverIndex == -1){
                            competitorsState[compIndex].ChampPosition = null
                            competitorsState[compIndex].ChampPoints = null
                            competitorsState[compIndex].ChampPointsChange =  null
                            competitorsState[compIndex].ChampPositionChange = null
                        } else {
                            competitorsState[compIndex].ChampPosition = driverIndex+1
                        }
                        compIndex++
                    }
                }
            }
            i++
        }

        this.setState({
            Session: session,
            Controls: controls,
            Notifications: notifications
        })
    }

    msRacetime = (ms) => {
        //Converts ms to Alpha style race duration
        var milli = ms
        var seconds = Math.floor((milli / 1000) % 60)
        var minutes = Math.floor((milli / (1000 * 60)) % 60)
        var hours = Math.floor((milli / (1000 * 60 * 60)) % 24)
    
        if (hours == 0){
            var duration = minutes+"M"
        } else if (hours > 0 && minutes == 0) {
            var duration = hours+"H"
        } else {
            var duration = hours + "H" + minutes + "M"
        }
        return duration
    }
    
    rMonitorTimeStamp = (timestamp) => {
        const [hours, minutes, secondsWithMs] = timestamp.split(':');
        const [seconds, milliseconds] = secondsWithMs.split('.');

        return (
            parseInt(hours, 10) * 60 * 60 * 1000 +
            parseInt(minutes, 10) * 60 * 1000 +
            parseInt(seconds, 10) * 1000 +
            parseInt(milliseconds, 10)
        );
    }

    render() {
        return <div>
        <Router>
            <Switch>
            <Route exact path ="/graphics" render={(props) => <Graphics  {...this.state} onboardConnection={this.onboardConnection.bind(this)} imageError={this.imageError.bind(this)} msToTime={this.msToTime.bind(this)} controlSend={this.controlSend.bind(this)} battleCalc={this.battleCalc.bind(this)}/>}/>
            <Route exact path ="/championships" render={(props) => <LiveChampionshipsPage  {...this.state} imageError={this.imageError.bind(this)} msToTime={this.msToTime.bind(this)} controlSend={this.controlSend.bind(this)} battleCalc={this.battleCalc.bind(this)}/>}/>
            <Route exact path ="/" render={(props) => <ControllerPage {...this.state} handleSessionNameChange={this.handleSessionNameChange.bind(this)} acSendMessage={this.acSendMessage.bind(this)} clearLT={this.clearLT.bind(this)} ltUpdate={this.ltUpdate.bind(this)} changeLT={this.changeLT.bind(this)} sessionSelect={this.sessionSelect.bind(this)} changeDuration={this.changeDuration.bind(this)} manualRaceStart={this.manualRaceStart.bind(this)} changeSessionState={this.changeSessionState.bind(this)} changeChamps={this.changeChamps.bind(this)} HSPage={this.HSPage.bind(this)} urlSend={this.urlSend.bind(this)} submitClientStates={this.submitClientStates.bind(this)} controllerImageCheck={this.controllerImageCheck.bind(this)} imageError={this.imageError.bind(this)} msToTime={this.msToTime.bind(this)} controlSend={this.controlSend.bind(this)} battleCalc={this.battleCalc.bind(this)} battleCalcVoice={this.battleCalcVoice.bind(this)}/>}/>
            <Route exact path ="/qualifying" render={(props) => <QualifyingPage {...this.state} imageError={this.imageError.bind(this)} msToTime={this.msToTime.bind(this)} controlSend={this.controlSend.bind(this)} battleCalc={this.battleCalc.bind(this)}/>}/>
            <Route exact path ="/onboard" render={(props) => <OnboardView/>}/>
            <Route exact path ="/webCam" render={(props) => <WebRTCStreamer/>}/>
            <Route exact path ="/editor" render={(props) => <Editor {...this.state} handleNameChange={this.handleNameChange.bind(this)} handleTeamNameChange={this.handleTeamNameChange.bind(this)} handleSubmit={this.handleSubmit.bind(this)}/>}/>
            <Route exact path ="/setup" render={(props) => <Setup {...this.state} msToTime={this.msToTime.bind(this)} acAddPresetCompetitor={this.acAddPresetCompetitor.bind(this)} handleChange={this.handleChange.bind(this)} handleTeamNameChange={this.handleTeamNameChange.bind(this)} handleSubmit={this.handleSubmit.bind(this)}/>}/>
            <Route exact path ="/msukgrid" render={(props) => <MsukGridPage  {...this.state} handleKeyPress={this.handleKeyPress.bind(this)} imageError={this.imageError.bind(this)} msToTime={this.msToTime.bind(this)}/>}/>
            <Route exact path ="/rotaxGrid" render={(props) => <RotaxGridPage  {...this.state} handleKeyPress={this.handleKeyPress.bind(this)} imageError={this.imageError.bind(this)} msToTime={this.msToTime.bind(this)}/>}/>
            <Route exact path ="/grid" render={(props) => <GridPage  {...this.state} handleKeyPress={this.handleKeyPress.bind(this)} imageError={this.imageError.bind(this)} msToTime={this.msToTime.bind(this)}/>}/>
            <Route exact path ="/driver" render ={(props) => <DriverPage  {...this.state} imageError={this.imageError.bind(this)} msToTime={this.msToTime.bind(this)}/>}/>
            <Route exact path ="/nametest" render={(props) => <NameTest {...this.state}/>}/>
            <Route exact path ="/stats" render={(props) => <StatsPage {...this.state}/>}/>
            </Switch>
        </Router>

        </div>
    }

    handleChange = (event, competitorId, index) => {
        // Handle the change in competitor name here
        var control = event.target.getAttribute('Attr1')
        console.log('New '+control+' for', competitorId, ':', event.target.value);

        var session = this.state.Session
        var compArray = session.Competitors


        switch(control){
            case 'GridPosition':

                if(compArray[index].gridPositionOveride != true){
                    compArray[index].originalGrid = compArray[index].GridPosition
                }

                compArray[index].GridPosition = parseInt(event.target.value)
                compArray[index].gridPositionOveride = true

                console.log(compArray)

                break
            case 'CompetitorNumber':

                if(compArray[index].numberOveride != true){
                    compArray[index].originalNumber = compArray[index].CompetitorNumber
                }

                compArray[index].CompetitorNumber = parseInt(event.target.value)
                compArray[index].numberOveride = true

                console.log(compArray)

                break
            case 'CompetitorName':    

                if(compArray[index].nameOveride != true){
                    compArray[index].originalName = compArray[index].CompetitorName
                }

                compArray[index].CompetitorName = event.target.value
                compArray[index].DriverName = event.target.value
                compArray[index].nameOveride = true

                console.log(compArray)

                break
            case 'teamName':
                if(compArray[index].teamNameOveride != true){
                    compArray[index].originalTeamName = compArray[index].teamName
                }

                compArray[index].teamName = event.target.value
                compArray[index].teamNameOveride = true

                console.log(compArray)

                break
            case 'vehicle':
                if(compArray[index].vehicleOveride != true){
                    compArray[index].originalvehicle = compArray[index].vehicle
                }

                compArray[index].vehicle = event.target.value
                compArray[index].vehicleOveride = true

                console.log(compArray)
                break
            case 'nat':
                if(compArray[index].natOveride != true){
                    compArray[index].originalnat = compArray[index].nat
                }

                compArray[index].nat = event.target.value
                compArray[index].natOveride = true

                console.log(compArray)
                break
            case 'q-time':
                if(compArray[index].qTimeOveride != true){
                    compArray[index].originalQualTime = compArray[index].qualifyingTime
                }

                compArray[index].qualifyingTime = event.target.value
                compArray[index].qTimeOveride = true

                console.log(compArray)
                break
        }

        session.Competitors = compArray

        this.setState({
            Session: session
        })
    }

    acAddPresetCompetitor = (id, index) => {
        console.log(id)

        var session = this.state.Session
        var compArray = session.Competitors

        if(id == "new"){
            var obj = {
                inGameName: document.getElementById('inGameName').value,
                GraphicName: document.getElementById('GraphicName').value,
                NewNumber: document.getElementById('NewNumber').value,
                GridPos: document.getElementById('GridPos').value,
                TeamName: document.getElementById('TeamName').value,
                Vehicle: document.getElementById('Vehicle').value,
                natCode: document.getElementById('natCode').value,
                QualTime: document.getElementById('QualTime').value
            }
        } else {
            if(compArray[index].originalName){
                var inGameName = compArray[index].originalName
            } else {
                var inGameName = compArray[index].CompetitorName
            }
            var obj = {
                inGameName: inGameName,
                GraphicName: document.getElementById('CompetitorName-'+index).value,
                NewNumber: document.getElementById('CompetitorNumber-'+index).value,
                GridPos: document.getElementById('GridPosition-'+index).value,
                TeamName: document.getElementById('teamName-'+index).value,
                Vehicle: document.getElementById('vehicle-'+index).value,
                natCode: document.getElementById('natCode-'+index).value,
                QualTime: document.getElementById('q-time-'+index).value
            }
        }

        var presetList = this.state.PresetList

        if(presetList){
            var indexOfPreset = presetList.findIndex(x => x.inGameName == obj.inGameName)
            if(indexOfPreset > -1){
                presetList[indexOfPreset] = obj
            } else {
                presetList.push(obj)
            }
            
        } else {
            presetList = [obj]
        }

        this.setState({
            PresetList: presetList
        })

        var messageData = {
            room: room,
            author: "Controller",
            type: "PresetList",
            message: presetList,
            time:
            new Date(Date.now()).getHours() +
            ":" +
            new Date(Date.now()).getMinutes(),
        };

        socket.emit("send_message", messageData);

    }

    handleSessionNameChange = (event, index) => {
        // Handle the change in competitor name here
        console.log('New session name', ':', event.target.value);

        var session = this.state.Session

        session.nameOveride = true

        session.SessionName = event.target.value

        this.setState({
            Session: session
        })
    }

    handleNameChange = (event, competitorId, index) => {
        // Handle the change in competitor name here
        console.log('New name for', competitorId, ':', event.target.value);

        var session = this.state.Session
        var compArray = session.Competitors

        if(compArray[index].nameOveride != true){
            compArray[index].originalName = compArray[index].CompetitorName
        }

        compArray[index].CompetitorName = event.target.value
        compArray[index].nameOveride = true

        console.log(compArray)

        session.Competitors = compArray

        this.setState({
            Session: session
        })
    }

    handleTeamNameChange = (event, competitorId, index) => {
        // Handle the change in team name here
        console.log('New team name for', competitorId, ':', event.target.value);

        var session = this.state.Session
        var compArray = session.Competitors

        if(compArray[index].teamNameOveride != true){
            compArray[index].originalTeamName = compArray[index].teamName
        }

        compArray[index].teamName = event.target.value
        compArray[index].teamNameOveride = true

        session.Competitors = compArray
        this.setState({
            Session: session
        })
    }

    handleSubmit = (index) => {
        // Handle the submit action here
        console.log('Submit for', this.state.Session.Competitors[index]);

        var messageData = {
            room: room,
            author: "Controller",
            type: "CompetitorUpdate",
            message1: index,
            message2: this.state.Session.Competitors[index],
            time:
            new Date(Date.now()).getHours() +
            ":" +
            new Date(Date.now()).getMinutes(),
        };

        socket.emit("send_message", messageData);
    }

    ltUpdate = (e) => {
        console.log(e.target.attributes[3].value)

        var LT = JSON.parse(e.target.attributes[3].value)

        this.setState({
            LT: LT
        })
    }

    changeLT = (e) => {
        console.log(e.target.attributes[2].value, e.target.value)
        let LT = this.state.LT
        switch (e.target.attributes[2].value){
            case "pos":
                LT.Position = e.target.value
                break
            case "num":
                LT.Number = e.target.value
                break
            case "upper":
                LT.Name = e.target.value
                break
            case "lower":
                LT.AdditionalInfo = e.target.value
                break
        }

        this.setState({
            LT: LT
        })
    }

    clearLT = () => {
        var LTInfo = {
            Left: '1300px',
            Top: '900px',
            Position: "",
            Number: "",
            Name: "",
            AdditionalInfo: "",
            DriverID: "",
            nbc: "",
            nfc: "",
            nat: ""
        }

        this.setState({
            LT: LTInfo
        })
    }

    onboardConnection = (connection) => {
        console.log('connection',connection)
        if(connection === "connected"){
            console.log('true')
            this.setState({
                OnboardConnection: true
            })
        } else {
            console.log('false')
            this.setState({
                OnboardConnection: false
            })
        }
    }

    HSClock = (start) => {
        // console.log(start)
        var sessionDurationMin = parseInt(this.state.Session.SD)
        var sessionDurationMil = (sessionDurationMin * 60) * 1000
        if(sessionDurationMil < 0){
            sessionDurationMil = 0
        }
        if(this.state.Session.State == "Starting" || start == true){
            this.remainingTime(sessionDurationMil*1000)
        } else {
            var timeElapsed = Date.now() - this.state.Session.StartTime

            var timeRemaining = sessionDurationMil - timeElapsed

            // this.setState({
            //     timeRemaining: timeElapsed
            // })
            this.remainingTime(timeRemaining*1000)
        }
        
        

    }

    changeDuration = (e) => {
        var session = this.state.Session

        session.SD = e.target.value

        // console.log(session.SD)

        this.setState({
            Session: session
        })
    }

    changeSessionState = (e) => {
        // console.log(e.target.value)

        var session = this.state.Session

        session.State = e.target.value

        var messageData = {
            room: room,
            author: "Controller",
            type: "State",
            message: e.target.value,
            time:
            new Date(Date.now()).getHours() +
            ":" +
            new Date(Date.now()).getMinutes(),
        };

        if (e.target.value == "Running" && HSClockInt == null){
            if (!session.StartTime){
                session.StartTime = Date.now()
            }
            // HSClockInt = setInterval(() => this.HSClock(), 500);
            session.Last = false
            session.End = false
        } else if (e.target.value == "RedFlag" && HSClockInt != null){
            clearInterval(HSClockInt)
            HSClockInt = null
            session.Last = false
            session.End = false
        } else if (e.target.value == "ChequeredFlag" && HSClockInt != null){
            clearInterval(HSClockInt)
            HSClockInt = null
            session.Last = false
            session.End = true
        } else if (e.target.value == "LastLap" && HSClockInt != null){
            clearInterval(HSClockInt)
            HSClockInt = null
            session.Last = true
            session.End = false
        } else if (e.target.value == "Starting" && HSClockInt == null){
            // this.HSClock()
            session.Last = false
            session.End = false
        }
        this.setState({
            Session: session
        })
        socket.emit("send_message", messageData); 

    }

    changeChamps = (e) => {
        // console.log(e.target.value)

        var controls = this.state.Controls

        controls.ChampionshipSelect = parseInt(e.target.value)

        var messageData = {
            room: "TSL",
            author: "Controller",
            type: "ChampionshipSelect",
            message: e.target.value,
            time:
            new Date(Date.now()).getHours() +
            ":" +
            new Date(Date.now()).getMinutes(),
        };

        this.setState({
            Controls: controls
        })

        socket.emit("send_message", messageData); 
    }

    HSPage = (e) => {
        // console.log(e.target.value)

        if (this.isValidUrl(e.target.value)) {
            console.log("Valid URL");

            var messageData = {
                room: room,
                author: "Controller",
                type: "HSPage",
                message: e.target.value,
                time:
                new Date(Date.now()).getHours() +
                ":" +
                new Date(Date.now()).getMinutes(),
            };

            socket.emit("send_message", messageData); 

        } else {
            console.log("Invalid URL");
        }

    }

    isValidUrl = (url) => {
        const pattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\/\w.-]*)*\/?$/;
        return pattern.test(url);
    }

    submitClientStates = (e) => {
        var obj = {
            CountryFlags: document.getElementById("Flags").checked,
            DefaultProgression: document.getElementById("Advance").value,
            DriverPhotos: document.getElementById("Photos").checked,
            FlSponsor: document.getElementById("flSponsor").checked,
            PrimaryColour: "",
            ResultsSponsor: document.getElementById("resultSponsor").checked,
            SecondaryColour: "",
            TimingName: document.getElementById("TimingName").value,
            TimingSystem: document.getElementById("TimingSystem").value,
            TowerHeadHeight: document.getElementById("TowerHeight").value + "px",
            TowerSponsor: document.getElementById("towerSponsor").checked,
            TowerSponsorHeight: document.getElementById("TowerSponsorHeight").value + "px",
            TowerSponsorLogo: document.getElementById("TowerLogo").value,
            TowerSponsorWidth: document.getElementById("TowerWidth").value + "px"
        }
        
        this.setState({
            ClientStates: obj
        })

        var messageData = {
            room: room,
            author: "Controller",
            type: "Database",
            message: obj,
            time:
            new Date(Date.now()).getHours() +
            ":" +
            new Date(Date.now()).getMinutes(),
        };

        socket.emit("send_message", messageData);

        console.log(room,obj)
    }

    imageError = (e) => {
        e.target.src = "/images/Blank.png"
    } 

    controllerImageCheck = (e) => {
        var compId = e.target.getAttribute("attr1")
        console.log("imageError",compId)
        document.getElementById(compId+"Expand").disabled = true
    }

    automaticCol = () => {
        var controls = this.state.Controls
        if (controls.AutoCol == true){
            var col = this.state.Controls.Col4

            if (col == "gap" && controls.IncludePs == true){
                var colData = "pits"
            } else if (col == "gap" ){
                var colData = "diff"
            } else if (col == "pits" ){
                var colData = "diff"
            } else if (col == "diff"){
                var colData = "positions"
            } else if (col == "positions"){
                var colData = "gap"
            } else {
                var colData = "gap"
            }

            controls.Col4 = colData
            this.setState({
                Controls: controls
            })
        } else {
            
        }
    }

    battleCalcVoice = (pos) => {
        console.log("pos", pos)
        var driverIndex = this.state.Session.Competitors.findIndex(x => x.Position == pos)
        var nextDriverIndex = this.state.Session.Competitors.findIndex(x => x.Position == pos+1)
        // console.log(e.target.getAttribute("attr2"), driverIndex)
        var controls = this.state.Controls
        var battleDrivers = controls.BattleDrivers
        var battleIndex = battleDrivers.indexOf(parseInt(this.state.Session.Competitors[driverIndex].CompetitorId))

        if (battleIndex == -1){
        battleDrivers.push(parseInt(this.state.Session.Competitors[driverIndex].CompetitorId))
        var battlePosition = this.state.Controls.BattlePosition
            if (parseInt(battlePosition) > parseInt(this.state.Session.Competitors[driverIndex].Position) || battlePosition == undefined){
                console.log("here")
                controls.BattlePosition = this.state.Session.Competitors[driverIndex].Position
            }

        } else {
            var remove = battleDrivers.splice(battleIndex,1)
            if (parseInt(this.state.Session.Competitors[driverIndex].Position) == parseInt(this.state.Controls.BattlePosition)){
                controls.BattlePosition = undefined
                controls.BattleDrivers = []
            }
        } 

        this.setState({
            Controls: controls
        })
    }

    battleCalc = (e) => {
        
        var driverIndex = this.state.Session.Competitors.findIndex(x => x.CompetitorId == e.target.getAttribute("attr2"))
        // console.log(e.target.getAttribute("attr2"), driverIndex)
        var controls = this.state.Controls
        var battleDrivers = controls.BattleDrivers
        var battleIndex = battleDrivers.indexOf(parseInt(this.state.Session.Competitors[driverIndex].CompetitorId))

        if (battleIndex == -1){
        battleDrivers.push(parseInt(this.state.Session.Competitors[driverIndex].CompetitorId))
        var battlePosition = this.state.Controls.BattlePosition
            if (parseInt(battlePosition) > parseInt(this.state.Session.Competitors[driverIndex].Position) || battlePosition == undefined){
                console.log("here")
                controls.BattlePosition = this.state.Session.Competitors[driverIndex].Position
            }

        } else {
            var remove = battleDrivers.splice(battleIndex,1)
            if (parseInt(this.state.Session.Competitors[driverIndex].Position) == parseInt(this.state.Controls.BattlePosition)){
                controls.BattlePosition = undefined
                controls.BattleDrivers = []
            }
        } 

        this.setState({
            Controls: controls
        })
    }

    urlSend = (e) => {
    }

    controlSend = (e) => {
        console.log(e.target.getAttribute("attr1"))
        if (this.state.ClientStates.TimingSystem == "TSL"){
            var room = "TSL"
        } else if (this.state.ClientStates.TimingSystem == "TimeService"){
            var room = "TimeService"
        } else if (this.state.ClientStates.TimingSystem == "Alkamel"){
            var room = "Alkamel"
        } else {
            var room = series
        }

        if(e.target.getAttribute("attr1") == 'LTShow' || e.target.getAttribute("attr1") == 'LTDataShow'){
            var message2 = this.state.LT
        }

        if(e.target.getAttribute("attr1") == 'AC_SET_GRID'){
            var message2 = this.state.Session.Competitors
        }

        if(e.target.getAttribute("attr1") == 'SessionNameChange'){
            var message2 = this.state.Session.SessionName
        }

        var messageData = {
            room: room,
            author: "Controller",
            type: "Graphics",
            message: e.target.getAttribute("attr1"),
            message2: e.target.getAttribute("attr2") || message2,
            time:
            new Date(Date.now()).getHours() +
            ":" +
            new Date(Date.now()).getMinutes(),
        };

        if(e.target.getAttribute("attr1") == "battleShow"){
            var messageData = {
                room: room,
                author: "Controller",
                type: "Graphics",
                message: e.target.getAttribute("attr1"),
                message2: this.state.Controls.BattlePosition,
                message3: this.state.Controls.BattleDrivers,
                time:
                new Date(Date.now()).getHours() +
                ":" +
                new Date(Date.now()).getMinutes(),
            };
        }

        if(e.target.getAttribute("attr1") == "compare"){
            var controls = this.state.Controls
            controls.ComparisonLeadDriver = e.target.getAttribute("attr2")
            this.setState({
                Controls: controls
            })
        }

        if(e.target.getAttribute("attr1") == "expand"){
            var messageData = {
                room: room,
                author: "Controller",
                type: "Graphics",
                message: e.target.getAttribute("attr1"),
                message2: e.target.getAttribute("attr2"),
                time:
                new Date(Date.now()).getHours() +
                ":" +
                new Date(Date.now()).getMinutes(),
            };
        }

        if(e.target.getAttribute("attr1") == "onboard"){
            var messageData = {
                room: room,
                author: "Controller",
                type: "Graphics",
                message: e.target.getAttribute("attr1"),
                message2: e.target.getAttribute("attr2"),
                time:
                new Date(Date.now()).getHours() +
                ":" +
                new Date(Date.now()).getMinutes(),
            };

            var controls = this.state.Controls
            if(controls.Onboard != true){
                controls.Onboard = true
                controls.OnboardDriverID = e.target.getAttribute("attr2")
            } else {
                controls.Onboard = false
                controls.OnboardDriverID = null
            }

            this.setState({
                controls: controls
            })

        }

        if(e.target.getAttribute("attr1") == "NAME-TEAM"){
            var controls = this.state.Controls
            controls.Name = "TEAM"
            this.setState({
                controls: controls
            })
        }

        if(e.target.getAttribute("attr1") == "NAME-DRIVER"){
            var controls = this.state.Controls
            controls.Name = "DRIVER"
            this.setState({
                controls: controls
            })
        }

        socket.emit("send_message", messageData); 
    }

    fastestLapCheck = () => {
        var controls = this.state.Controls
        var session = this.state.Session
        var blt = session.blt

        if (controls.NotificationShow == false){
            if (blt){
                if (controls.FastestLapShow == false && blt.shown != true && parseInt(blt.ln) > 2 && blt.pusher == true){
                    console.log(true)    
                    controls.FastestLapShow = true
                    session.blt.shown = true
                } else {
                    console.log(false)
                    controls.FastestLapShow = false
                } 
            }
        } else {
            controls.FastestLapShow = false
        }

        this.setState({
            Controls: controls,
            Session: session
        })
    }

    notificationCheck = () => {
        var notifications = this.state.Notifications
        var controls = this.state.Controls
        var i = this.state.NotificationSelected
        if (controls.NotificationShow == true){
            controls.NotificationShow = false
            this.setState({
                Controls: controls,
                Notifications: notifications
            })
        } else {
            while (i < notifications.length){
                if (notifications[i].displayed != true){
                    controls.NotificationShow = true
                    notifications[i].displayed = true
                    this.setState({
                        Controls: controls,
                        Notifications: notifications,
                        NotificationSelected: i
                    })
                    break
                }
                i++
            }
        }
            
    }

    resetPosChange = () => {
        var session = this.state.Session
        var i = 0
        var competitors = session.Competitors
		if(competitors){
			while (i < competitors.length){
            var currentTime = this.getTimestampInSeconds()
            var timeSince = currentTime - competitors[i].posChangeTime
            // console.log(timeSince, currentTime, competitors[i].posChangeTime)
            if (timeSince > 5){
                competitors[i].PositionChange = 0
                competitors[i].PosChangeClass = "positionneutral"
                competitors[i].PosResetOn = competitors[i].NumberOfLaps
                competitors[i].PosChangeTime = 0
            }
            i++
			}
		}
        

        this.setState({
            Session: session
        })

    }

    getInitialState = () => {
        request("https://live.alphatiming.co.uk/"+series+".json", (error, response, json) => {
            var json = JSON.parse(json)
            json.Rd = json.Rd*1000+""
            var controls = {
                AutoCol: false,
                IncludePs: false,
                ClockShow: true,
                TowerShow: true,
                ResultsShow: false,
                NotificationShow: false,
                FastestLapShow: false,
                ChampionshipShow: true,
                ChampionshipSelect: 0,
                ComparisonShow: false,
                ComparisonLeadDriver: undefined,
                TowerMargin: [0,0,35,70,105,140,175,210,245,280,315,350,385,420,455,490,525,560,595,630,665,700,735,770,805,840,875,910,945,980,1015,1050,1085,1120,1155,1190,1225,1260,1295,1330,1365,1400,1435,1470,1505,1540,1575,1610,1645,1680,1715,1750,1785,1820,1855,1890,1925,1960,1995,2030,2065,2100,2135,2170,2205,2240,2275,2310,2345,2380,2415,2450,2485,2520,2555,2590,2625,2660,2695,2730,2765,2800,2835,2870,2905,2940,2975,3010,3045,3080,3115,3150,3185,3220],
                ResultsMargin: -100,
                TowerPagesMargin: [-385,-350,-315,-280,-245,-210,-175,-140,-105,-70,-35,0,35,70,105,140,175,210,245,280,315,350,385,420,455,490,525,560,595,630,665,700,735,770,805,840,875,910,945,980,1015,1050,1085,1120,1155,1190,1225,1260,1295,1330,1365,1400,1435,1470,1505,1540,1575,1610,1645,1680,1715,1750,1785,1820,1855,1890,1925,1960,1995,2030,2065,2100,2135,2170,2205,2240,2275,2310,2345,2380,2415,2450,2485,2520,2555,2590,2625,2660,2695,2730,2765,2800,2835,2870,2905,2940,2975,3010,3045,3080,3115,3150,3185,3220],
                CurrentPage: 0,
                BattleShow: false,
                BattleDrivers: [],
                BattlePosition: undefined,
                ProgressionShow: false,
                ProgressionPosition: undefined,
                Col4: "gap",
            }

            this.setState({
                Session:json,
                Controls:controls,
                Notifications : [],
                NotificationSequence : undefined
            })
        })
    }

    pages = () => {
        var CurrentPage = this.state.Controls.CurrentPage
        var controls = this.state.Controls
        var defaultMargin = [-385,-350,-315,-280,-245,-210,-175,-140,-105,-70,-35,0,35,70,105,140,175,210,245,280,315,350,385,420,455,490,525,560,595,630,665,700,735,770,805,840,875,910,945,980,1015,1050,1085,1120,1155,1190,1225,1260,1295,1330,1365,1400,1435,1470,1505,1540,1575,1610,1645,1680,1715,1750,1785,1820,1855,1890,1925,1960,1995,2030,2065,2100,2135,2170,2205,2240,2275,2310,2345,2380,2415,2450,2485,2520,2555,2590,2625,2660,2695,2730,2765,2800,2835,2870,2905,2940,2975,3010,3045,3080,3115,3150,3185,3220]

        var index = this.state.Session.Competitors.findIndex(x => x.NumberOfLaps > 1)
        
        if(CurrentPage != undefined && index != -1){
            if(this.state.Session){
                
                const lapsGreaterThanZero = obj => obj.NumberOfLaps > 0;

                var totCompetitors = this.state.Session.Competitors.filter(lapsGreaterThanZero).length;
                var numOfPages = Math.ceil((totCompetitors/10)-2)

                // var totCompetitors = this.state.Session.Competitors.length 
                // var numOfPages = Math.ceil((totCompetitors/10)-2)
                console.log("numOfPages",this.state.Session.Competitors.length,totCompetitors,numOfPages)

                if(this.state.Controls.Onboard == true){
                    var onboardPosition = this.state.Session.Competitors[this.state.Controls.OnboardIndex].Position
                } else {
                    var onboardPosition = 0
                }

                if(CurrentPage < numOfPages || this.state.Controls.BattleShow == true || this.state.Controls.Expand == true || this.state.Controls.Onboard == true){
                    CurrentPage++
                    //Check if battle is active to select page position
                    if ((this.state.Controls.BattleShow == true && this.state.Session.Competitors.length > 20 && this.state.Controls.BattlePosition > 12)){
                        //Works out the fixed location for bottom half of tower
                        CurrentPage = this.state.Controls.BattlePosition/10-1
                        var multiplyer = CurrentPage*10
                        var marginOffset = (this.state.Controls.BattlePosition - 13)*35
                        // console.log(marginOffset)
                        pagesMargin = defaultMargin
                        var i = 0
                        while(i < pagesMargin.length){
                            pagesMargin[i] = pagesMargin[i]-marginOffset
                            i++
                        }
                    } else if ((this.state.Controls.Onboard == true && this.state.Session.Competitors.length > 20 && onboardPosition > 10)){
                        //Get Onboard Driver Position
                        var driverPosition = this.state.Session.Competitors[this.state.Controls.OnboardIndex].Position

                        //Works out the fixed location for bottom half of tower
                        if (parseInt(driverPosition) == 11 || parseInt(driverPosition) == 12 ){
                            CurrentPage = 0
                            var marginOffset = 0
                        } else {
                            CurrentPage = parseInt(driverPosition)/10-1
                            var multiplyer = CurrentPage*10
                            var marginOffset = (driverPosition - 13)*35
                        }
                        
                        
                        
                        // console.log(marginOffset)
                        pagesMargin = defaultMargin
                        var i = 0
                        while(i < pagesMargin.length){
                            pagesMargin[i] = pagesMargin[i]-marginOffset
                            i++
                        }
                    } else if (this.state.Controls.Expand == true && this.state.Session.Competitors.length > 20 && 12 < this.state.Session.Competitors[this.state.Controls.ExpandIndex].Position ){
                        //Works out the fixed location for bottom half of tower
                        CurrentPage = this.state.Session.Competitors[this.state.Controls.ExpandIndex].Position/10-1
                        var multiplyer = CurrentPage*10
                        var marginOffset = (this.state.Session.Competitors[this.state.Controls.ExpandIndex].Position - 13)*35
                        // console.log(marginOffset)
                        pagesMargin = defaultMargin
                        var i = 0
                        while(i < pagesMargin.length){
                            pagesMargin[i] = pagesMargin[i]-marginOffset
                            i++
                        }
                    } else if (this.state.Controls.Expand == true && this.state.Session.Competitors.length > 20 && 10 < this.state.Session.Competitors[this.state.Controls.ExpandIndex].Position) {
                        //Works out the fixed location for bottom half of tower
                        CurrentPage = 0
                        // console.log(marginOffset)
                        pagesMargin = defaultMargin
                    } else {
                        //Continues rotating as normal
                        var pagesMargin = this.state.Controls.TowerPagesMargin
                        var i = 0
                        if (CurrentPage > numOfPages){
                            pagesMargin = defaultMargin
                            CurrentPage = 0
                        } else {
                            while(i < pagesMargin.length){
                                pagesMargin[i] = pagesMargin[i]-350
                                i++
                            }
                        }
                    }

                    controls.TowerPagesMargin = pagesMargin
                    controls.CurrentPage = CurrentPage
                    this.setState({
                        Controls: controls
                    })
                } else {
                    CurrentPage = 0
                    controls.TowerPagesMargin = defaultMargin
                    controls.CurrentPage = CurrentPage
                    this.setState({
                        Controls: controls
                    })
                }
            } else {
                console.log("no Session")
            }
        } else {
            CurrentPage = 0
            controls.TowerPagesMargin = defaultMargin
            controls.CurrentPage = CurrentPage
            this.setState({
                Controls: controls
            })
        }
    }

    acToTime = (timestamp) => {
        function pad(n, z = 2) {
            return ('00' + n).slice(-z);
        }

        if (timestamp === 9223372036854776000) {
            return "";
        }

        let ms = Math.floor(timestamp / 1000) % 1000;
        let seconds = Math.floor(timestamp / (1000 * 1000)) % 60;
        let minutes = Math.floor(timestamp / (1000 * 1000 * 60)) % 60;
        let hours = Math.floor(timestamp / (1000 * 1000 * 60 * 60));

        if (hours === 0 && minutes === 0 && seconds === 0) {
            return "00:00"
        } else if (hours === 0 && minutes === 0 && seconds < 10) {
            return "00:0"+seconds
        } else if (hours === 0 && minutes === 0) {
            return "00:"+pad(seconds)
        } else if (hours === 0 && minutes < 10) {
            return "0"+minutes + ':' + pad(seconds)
        } else if (hours === 0) {
            return pad(minutes) + ':' + pad(seconds)
        } else if (hours === 2562047788){
            return ""
        } else {
            return hours + ':' + pad(minutes) + ':' + pad(seconds) + '.' + pad(ms, 3)
        }
    }

    msToTime = (timestamp) => {
        function pad(n, z = 2) {
            return ('00' + n).slice(-z);
        }

        if (timestamp === 9223372036854776000) {
            return "";
        }

        let ms = Math.floor(timestamp / 1000) % 1000;
        let seconds = Math.floor(timestamp / (1000 * 1000)) % 60;
        let minutes = Math.floor(timestamp / (1000 * 1000 * 60)) % 60;
        let hours = Math.floor(timestamp / (1000 * 1000 * 60 * 60));

        if (hours === 0 && minutes === 0 && seconds === 0) {
            return "0." + pad(ms, 3)
        } else if (hours === 0 && minutes === 0 && seconds < 10) {
            return seconds + '.' + pad(ms, 3)
        } else if (hours === 0 && minutes === 0) {
            return pad(seconds) + '.' + pad(ms, 3)
        } else if (hours === 0 && minutes < 10) {
            return minutes + ':' + pad(seconds) + '.' + pad(ms, 3)
        } else if (hours === 0) {
            return pad(minutes) + ':' + pad(seconds) + '.' + pad(ms, 3)
        } else if (hours === 2562047788){
            return ""
        } else {
            return hours + ':' + pad(minutes) + ':' + pad(seconds) + '.' + pad(ms, 3)
        }
    }

    clockOn = () => {

        if(this.state.Session){
            var session = this.state.Session
            if(session.ServerTime && this.state.ClientStates.TimingSystem == "Apex"){
                session.ServerTime = session.ServerTime + 500
            }

            if(session.State == "Running" || session.State == "Yellow"){
                if(session.SD){
                    var addLaps = session.SD.indexOf("L")
                    if (addLaps > -1){
                        this.setState({
                            additionalLaps: parseInt(session.SD[session.SD.length-2])
                        })
                    }

                    if(session.Rd){
                    //var index = session.Rd.indexOf("L") 
                        var index = -1
                    } else {
                        var index = -1
                    }
                    
                    if (index > -1){

                    } else {
                        if (this.state.startTime){
                            //Apex
                            var currentTime = session.ServerTime
                            var millis = currentTime - this.state.startTime

                            var totalDurationMS = parseInt(session.Duration)

                            session.Rd = totalDurationMS-millis+""

                            millis = session.Rd
                            
                        } else {
                            //Alpha
                            var millis = session.Rd-500
                            session.Rd = millis+""
                        }
                        
                        this.setState({
                            Session: session
                        })

                        // console.log("millis",millis)
                        if (millis < 1000 || millis == NaN) {
                        millis = 0
                        }
                        var micro = millis
                        // console.log("micro",micro)

                        if (micro == "NaN" || micro == NaN){
                            micro = 0
                        }

                        this.remainingTime(micro*1000)
                        }
                }
            }
            
        }
    }

    timeServiceClockOn = () => {
        // Check if there's a session ongoing
        if(this.state.Session && this.state.TimeService) {
            // Check if the session is in a 'Running' state
            if(this.state.Session.State == "Running" && this.state.TimeService.TimeOffset) {
                if(this.state.Session.SD){

                }

                // Get the current time in microseconds since 1 January 1970 00:00:00 UTC
                var time = Date.now() * 1000;

                // Offset the current time to the calculated time offset to the servers time
                var offset = this.state.TimeService.TimeOffset
                var offsetTime = time - offset

                // Get the start time of the session in micro seconds since 1 January 1970 00:00:00 UTC, rounding up if needed
                var start = Math.ceil(this.state.Session.SessionStartTime);

                // Get the planned length of the session
                var duration = this.state.Session.LengthTime;

                // Get time delay
                var timeDelay = this.state.Session.ClockHaltedDuration

                // Compute how long the session has been running
                var runningTime =  offsetTime - start - timeDelay;
                console.log (runningTime, offsetTime, start, duration)

                // Compute the remaining time in micro seconds
                var micro = Math.ceil(duration) - runningTime;

                // Ensure that the remaining time cannot be less than 1 second
                if (micro < 1000) {
                    micro = 0;
                }

                // Call the function to handle the remaining time and print it to the console
                this.remainingTime(micro);
                console.log(micro)
            }
        }
    }

    tslClockOn = () => {
        console.log("Clock")
        // Check if there's a session ongoing
        if(this.state.Session) {
            // Check if the session is in a 'Running' state
            if(this.state.Session.State == "Running") {
                if(this.state.Session.SD){

                }
                // Store the last time count
                var last = this.state.lastTimeCount;
                
                // Get the current time in milliseconds since 1 January 1970 00:00:00 UTC
                var time = Date.now();

                // Compute the time elapsed since the last time count
                var timeSinceLast = time - last;

                // Get the start time of the session in seconds since 1 January 1970 00:00:00 UTC, rounding up if needed
                var start = Math.ceil(this.state.Session.SessionStartTime/1000);

                // Get the planned length of the session
                var duration = this.state.Session.LengthTime;

                // Get the current time in milliseconds since 1 January 1970 00:00:00 UTC (same as the variable 'time' defined earlier)
                var now = Date.now();

                // Compute how long the session has been running
                var runningTime =  now - start;
                console.log(now, start, runningTime)

                // Compute the remaining time in milliseconds, taking into account a predefined offset of 3600000 milliseconds (1 hour)
                var millis = Math.ceil(duration/1000) - runningTime;
                var millis2 = Math.ceil(millis - 3600000);

                if(this.state.ClientStates.TimingSystem == "AC"){
                    var millis2 = Math.ceil(millis);
                }
                

                // Ensure that the remaining time cannot be less than 1 second
                if (millis2 < 1000) {
                    millis2 = 0;
                }

                // Convert the remaining time to microseconds
                var micro = millis2*1000;

                // Call the function to handle the remaining time and print it to the console
                this.remainingTime(micro);
                // console.log(micro);
            }
        }

    }

    remainingTime = (micro) => {


        this.setState({microRemaining: micro})
        // console.log("remainingMicro",micro)
        var milli = micro/1000

        if(this.state.ClientStates.TimingSystem == "AC" && this.state.Session.SessionStartTime > (Date.now()*1000)){
            milli = this.state.Session.LengthTime/1000
        }
        
        var seconds = Math.floor((milli / 1000) % 60)
        var minutes = Math.floor((milli / (1000 * 60)) % 60)
        var hours = Math.floor((milli / (1000 * 60 * 60)) % 24)
    
        if (seconds < 10){
        seconds = "0"+seconds
        }
    
        if (minutes < 10){
        minutes = "0"+minutes
        }
    

        if (hours == 0 && minutes == 0 && seconds == 0){
            var remaining = "00:00"
        } else {
            if (hours == 0){
                var remaining = minutes + ":" + seconds
            } else {
                var remaining = hours + ":" + minutes + ":" + seconds
            }   
        }

        if(this.state.Session.State == "Ended"){

        } else if(this.state.Session.Rd == "1L"){
            
        } else {
            this.setState({
                timeRemaining: remaining,
                lastTimeCount: Date.now()
            })
        }
        

    }

    getTimestampInSeconds = () => {
        return Math.floor(Date.now() / 1000)
    }
}

export default App;
