import React, { PureComponent } from 'react';
import LiveChampsRows from './liveChampsRows';
import './stylesheets/liveChamps.css';

class LiveChampStandings extends PureComponent { 

    render() { 
        var show = "liveChampHide"
        if (this.props.champsPage == true){
            show = "champPage"
        } else if (this.props.controller == true){
            show = "liveChampController"
        } else { 
            show = this.props.Controls.LiveChampsDisplay
        }

        if(this.props.champIndex > -1){
            var champSelect = this.props.champIndex
        } else {
            var champSelect = this.props.Controls.ChampionshipSelect
        }


        if (this.props.ClientStates.TimingSystem == "TSL" && this.props.championship){
            if (this.props.championship[champSelect]){
                return (
                    <div className={show}>
                        <div className="liveChampHeader">
                            <div className="liveChampHeaderText">LIVE CHAMPIONSHIP</div>
                        </div>
                        <div className="liveChampHeader">
                            <div className="liveChampHeaderText">{this.props.championship[champSelect].Name.toUpperCase()}</div>
                        </div>
                        {this.props.championship[champSelect].Positions.map((driver, index) => (
                            <LiveChampsRows boost={true} pages={false} driver={driver} indexRow={index} margin={this.props.Controls.TowerMargin} {...this.props} key={index}/>
                        ))}
                        
                    </div>
                )
            } else {
                return ( 
                    <div className={show}>      
                    </div>
                );
            }
             
        } else if (this.props.ClientStates.TimingSystem == "Apex" || this.props.ClientStates.TimingSystem == "ApexTest"){
           return ( 
                <div className={show}>
                    <div className="liveChampHeader">
                        <div className="liveChampHeaderText">{this.props.Controls.LiveChampsTitle}</div>
                    </div>
                    {this.props.Session.Competitors.map((driver, index) => (
                        <LiveChampsRows boost={false} pages={false} driver={driver} indexRow={index} margin={this.props.Controls.TowerMargin} {...this.props} key={index}/>
                    ))}
                    
                </div>
            ); 
        } else {
            return(
                <div></div>
            )
        }
            
        }
    }
export default LiveChampStandings ;
