import React, { PureComponent } from 'react';
import BasicResultsRow from './basicResultsRow';
import '../stylesheets/basicResults.css';
import '../stylesheets/basicResultsRow.css';

class BasicResults extends PureComponent { 

    render() { 

            var fontMedium = {
                fontFamily: 'basic-Medium',
                fontSize: '40px'
            }

            var fontMediumSmall = {
                fontFamily: 'basic-Medium',
                fontSize: '30px'
            }

            var fontBold = {
                fontFamily: 'basic-Bold',
                fontSize: '40px'
            }

            var fontSmall = {
                fontSize: '30px'
            }

            if(this.props.ClientStates.Skin){
                fontMedium = {
                    fontFamily: this.props.ClientStates.Skin+'-Medium',
                    fontSize:  parseInt(this.props.ClientStates.FontSize)+22+'px'
                }
    
                fontMediumSmall = {
                    fontFamily: this.props.ClientStates.Skin+'-Medium',
                    fontSize: parseInt(this.props.ClientStates.FontSize)+10+'px'
                }
    
                fontBold = {
                    fontFamily: this.props.ClientStates.Skin+'-Bold',
                    fontSize: parseInt(this.props.ClientStates.FontSize)+25+'px'
                }
    
                fontSmall = {
                    fontSize: parseInt(this.props.ClientStates.FontSize)+10+'px'
                }
            }

            if(this.props.Controls.ResultsShow == true){
                var resultsClass = "resultsDiv_show"
            } else {
                var resultsClass = "resultsDiv_hide"
            }
            
            if(this.props.Session.Competitors){
                let indices = [];
                if(this.props.Session.TSLGrouped == 1){
                    var index = this.props.Session.Competitors.findIndex(x => parseInt(x.GroupPosition) == 1)
                } else {
                    indices = this.props.Session.Competitors.filter(x => parseInt(x.Position) === 1);
                    if (indices.length > 1){
                        var winnerIndex = indices.findIndex(x => parseInt(x.NumberOfLaps) != 0)
                        if(winnerIndex > -1){
                            var index = this.props.Session.Competitors.findIndex(x => x.CompetitorNumber == indices[winnerIndex].CompetitorNumber)
                        } else {
                            var index = this.props.Session.Competitors.findIndex(x => parseInt(x.Position) == 1)
                        }
                    } else {
                        var index = this.props.Session.Competitors.findIndex(x => parseInt(x.Position) == 1)
                    }
                    
                }





                //BP 24hr Classes
                if (index != -1){
                   var assName = this.props.Session.Competitors[index].CompetitorName.indexOf('* ')
                    if (assName != -1){
                        var compName = this.props.Session.Competitors[index].CompetitorName.replace('* ', '');
                        var star_nbc = "#2faae1"
                        var star_nfc = "#ffffff"
                    } 
                }
                
            } else {
                var index = 0
            }

            if(this.props.Session.State == "ChequeredFlag" || this.props.Session.State == "Ended" ||  this.props.Session.End == true){
                var resultText = "Result"
            } else {
                var resultText = "Current Standings"
            }

            //Country Flags
            if (this.props.ClientStates.CountryFlags == true){
                if (this.props.Session.Competitors[index]){
                    var nationFlag = this.props.Session.Competitors[index].nat 
                    var nationFlagClass = "resultNationFlag"
                } else {
                    var nationFlag = "blank"
                    var nationFlagClass = "resultNationFlag"
                }
                
            } else {
                var nationFlag = "blank"
                var nationFlagClass = "resultNoNationFlag"
            }

            
            
            if(this.props.Session.Competitors[index] && this.props.ClientStates.DriverPhotos == false){
                //Check for vehicle information
                var extraInfo = <div></div>
                var nameWidth = "722px"
                if (this.props.Session.Competitors[index].vehicle){
                    nameWidth = "459px"
                    extraInfo = <div className='extraInfo'><div className='extraInfoText'>{this.props.Session.Competitors[index].vehicle}</div></div>
                }

                //Check for team information
                if (this.props.Session.Competitors[index].teamName){
                    nameWidth = "459px"
                    extraInfo = <div className='extraInfo'><div className='extraInfoText'>{this.props.Session.Competitors[index].teamName}</div></div>
                }

                if(this.props.Session.TSLGrouped == 1){
                    return (
                            <div className={resultsClass} id="Grouped">
                                <div className='resultsHead_Narrow'>
                                    <div className='resultsHeadDiv_Narrow'>
                                        <div className='upperText_Narrow' style={fontMedium}>
                                            {this.props.Session.SessionName}
                                        </div>  
                                    </div>
                                    <div className='resultsHeadDiv_Narrow'>
                                        <div className='lowerText' style={fontBold}>
                                            {resultText}
                                        </div>
                                    </div>
                                </div>
                                <div className='firstPlaceRow'>
                                    <div className='resultPositionBox'>
                                        <div className='resultPositionText'>
                                            1
                                        </div>
                                    </div>
                                    <div className='resultNumberBox' style={{backgroundColor: star_nbc || this.props.Session.Competitors[index].nbc || "#2faae1", color: star_nfc || this.props.Session.Competitors[index].nfc || "#fff"}}>
                                        <div className='numberText'>
                                            {this.props.Session.Competitors[index].CompetitorNumber}
                                        </div>
                                    </div>
                                    <div className='resultNameBox' style={{width: nameWidth}}>
                                        <img className={nationFlagClass} src={"flags/"+nationFlag+".png"} onError={this.props.imageError}></img> 
                                        <div className='resultNameText'>
                                            {compName || this.props.Session.Competitors[index].CompetitorName}
                                        </div>
                                    </div>
                                    {extraInfo}
                                    <div className='resultGapBox'>
                                        <div className='resultGapText'>
                                            {this.props.Session.Competitors[index].GroupNumberOfLaps + " LAPS"}
                                        </div>
                                    </div>
                                </div>
        
                                <div className='resultsList'>
                                    {this.props.Session.Competitors.map((driver, index) => (
                                        <BasicResultsRow Grouped={true} driver={driver} indexRow={index} {...this.props} key={index}/>
                                    ))}
                                </div>
                            </div>
                    )
                } else {
                    return (
                        <div className={resultsClass} id="TSL-Not Grouped">
                            <div className='basicResultsHead_Narrow'>
                                <div className='basicResultsHeadDiv_Narrow'>
                                    <div className='basicUpperText_Narrow'  style={fontMedium}>
                                        {this.props.Session.SessionName}
                                    </div>  
                                </div>
                                <div className='basicResultsHeadDiv_Narrow'>
                                    <div className='lowerText' style={fontBold}>
                                        {resultText}
                                    </div>
                                </div>
                            </div>
                            <div className='basicResultsList'>
                                {this.props.Session.Competitors.map((driver, index) => (
                                    <BasicResultsRow driver={driver} indexRow={index} {...this.props} key={index}/>
                                ))}
                            </div>
                        </div>
                    )
                }
                
            } else if (this.props.Session.Competitors[index] && this.props.ClientStates.DriverPhotos == true && this.props.Session.SessionType == "Qual") {
                return ( 
                    <div className={resultsClass} id="1">
                        <div className='resultsHead'>
                            <div className='resultsHeadDiv'>
                                <div className='upperText'  style={fontMedium}>
                                    {this.props.Session.SessionName}
                                </div>  
                            </div>
                            <div className='resultsHeadDiv'>
                                <div className='lowerText' style={fontBold}>
                                    RESULTS
                                </div>
                            </div>
                        </div>
                        <div className='resultsWinner'>
                            <div className='winnerText'>POLE POSITION</div>
                        </div>
                        <div className='firstPlaceBox'>
                            <div className='firstPlaceNumberPlate' style={{backgroundColor: this.props.Session.Competitors[index].nbc || "#2faae1", color: this.props.Session.Competitors[index].nfc || "#fff"}}>
                                <div className='firstPlaceNumberText'>{this.props.Session.Competitors[index].CompetitorNumber}</div>
                            </div>
                            <div className='firstPlaceNameText'>{this.props.Session.Competitors[index].CompetitorName}</div>
                            <div className="resultWinnerSubText" style={{marginTop: "80px", ...fontMediumSmall}}>{this.props.Session.Competitors[index].teamName}</div>
                            <div className="resultWinnerSubText" style={fontMediumSmall}>{this.props.Session.Competitors[index].vehicle}</div>
                            <div className="resultWinnerLaps" style={fontSmall}>{this.props.msToTime(this.props.Session.Competitors[index].BestLaptime*1000)}</div>
                            <img className="resultPoleImage" src={this.props.Session.Competitors[index].Picture + "-7.png"} onError={this.props.imageError}></img>
                        </div>

                        <div className='resultsListPhoto'>
                            {this.props.Session.Competitors.map((driver, index) => (
                                <BasicResultsRow driver={driver} indexRow={index} {...this.props} key={index}/>
                            ))}
                        </div>
                    </div>
                );
            } else if (this.props.Session.Competitors[index] && this.props.ClientStates.DriverPhotos == true) {
                if(this.props.Session.Competitors[index].CompetitorName.length > 16){
                    var winnerNameTextClass = "firstPlaceLongNameText"
                } else {
                    var winnerNameTextClass = "firstPlaceNameText"
                }
                return ( 
                    <div className={resultsClass} id="2">
                        <div className='resultsHead'>
                            <div className='resultsHeadDiv'>
                                <div className='upperText'  style={fontMedium}>
                                    {this.props.Session.SessionName}
                                </div>  
                            </div>
                            <div className='resultsHeadDiv'>
                                <div className='lowerText' style={fontBold}>
                                    RESULTS
                                </div>
                            </div>
                        </div>
                        <div className='resultsWinner'>
                            <div className='winnerText'>WINNER</div>
                        </div>
                        <div className='firstPlaceBox'>
                            <div className='firstPlaceNumberPlate' style={{backgroundColor: this.props.Session.Competitors[index].nbc || "#2faae1", color: this.props.Session.Competitors[index].nfc || "#fff"}}>
                                <div className='firstPlaceNumberText'>{this.props.Session.Competitors[index].CompetitorNumber}</div>
                            </div>
                            <div className={winnerNameTextClass}>{this.props.Session.Competitors[index].CompetitorName}</div>
                            <img className="resultWinnerImage" src={this.props.Session.Competitors[index].Picture + "-4.png"} onError={this.props.imageError}></img>
                            <img className="resultWinnerNationFlag" src={"flags/"+nationFlag+".png"} onError={this.props.imageError}></img> 
                            <div className="resultWinnerSubText" style={{marginTop: "80px", ...fontMediumSmall}}>{this.props.Session.Competitors[index].teamName}</div>
                            <div className="resultWinnerSubText" style={fontMediumSmall}>{this.props.Session.Competitors[index].vehicle}</div>
                            <div className="resultWinnerLaps" style={fontSmall}>{this.props.Session.Competitors[index].NumberOfLaps + " LAPS"}</div>
                
                        </div>

                        <div className='resultsListPhoto'>
                            {this.props.Session.Competitors.map((driver, index) => (
                                <BasicResultsRow driver={driver} indexRow={index} {...this.props} key={index}/>
                            ))}
                        </div>
                    </div>
                );
            } else {
                return (
                <div className={resultsClass} id="3">
                        <div className='resultsHead'>
                            <div className='resultsHeadDiv'>
                                <div className='upperText'  style={fontMedium}>
                                    {this.props.Session.SessionName}
                                </div>  
                            </div>
                            <div className='resultsHeadDiv'>
                                <div className='lowerText' style={fontBold}>
                                    RESULTS
                                </div>
                            </div>
                        </div>
                        <div className='resultsWinner'>
                            <div className='winnerText'>WINNER</div>
                        </div>
                        <div className='firstPlaceBox'>
                            <div className='firstPlaceNumberPlate' style={{backgroundColor: "transparent", color: "transparent"}}>
                                <div className='firstPlaceNumberText'></div>
                            </div>
                            <div className='firstPlaceNameText'></div>
                            <img className="resultWinnerImage" src={"images/Blank.png"}></img>
                            <div className="resultWinnerSubText" style={{marginTop: "80px", ...fontMediumSmall}}></div>
                            <div className="resultWinnerSubText" style={fontMediumSmall}></div>
                            <div className="resultWinnerLaps" style={fontSmall}>LAPS</div>
                
                        </div>

                        <div className='resultsListPhoto'>
                            {this.props.Session.Competitors.map((driver, index) => (
                                <BasicResultsRow driver={driver} indexRow={index} {...this.props} key={index}/>
                            ))}
                        </div>
                    </div>
                );
            }   
        }        
    }
export default BasicResults ;