import React from 'react';
import './editor.css'; // Importing the CSS file

class Editor extends React.Component {
    // Assuming your state structure is like this:
    // this.state = { 
    //     Session: { 
    //         Competitors: [{CompetitorId: 1, CompetitorNumber: 101, CompetitorName: 'Name1', CompetitorClass: 'Class1'}, {...}]
    //     }
    // };

    renderCompetitor = (competitor, index) => {
        return (
            <div className="competitor-row" key={competitor.CompetitorId}>
                <span className="competitor-cell">{competitor.CompetitorId}</span>
                <span className="competitor-cell">{competitor.GridPosition}</span>
                <span className="competitor-cell">{competitor.CompetitorNumber}</span>
                <input 
                    className="competitor-cell"
                    type="text" 
                    defaultValue={competitor.CompetitorName}
                    onChange={(e) => this.props.handleNameChange(e, competitor.CompetitorId, index)}
                />
                <input 
                    className="competitor-cell"
                    type="text" 
                    defaultValue={competitor.teamName}
                    onChange={(e) => this.props.handleTeamNameChange(e, competitor.CompetitorId, index)}
                />
                <span className="competitor-cell">{competitor.vehicle}</span>
                <span className="competitor-cell">{competitor.CompetitorClass}</span>
                <button className="competitor-cell" onClick={() => this.props.handleSubmit(index)}>Submit</button>
            </div>
        );
    }

    render() {
        document.title = 'Alpha Live Graphics - Editor';
        if(this.props.Session.Competitors){
            const { Competitors } = this.props.Session;

            return (
                <div>
                    {Competitors.map(this.renderCompetitor)}
                </div>
            );
        } else {
            return(
                <div>No Competitors</div>
            )
        }
        
    }
}

export default Editor;
