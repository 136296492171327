import React, { PureComponent } from 'react';

class ResultsRow extends PureComponent { 

    

    render() { 
        if (this.props.driver.seen == false || this.props.driver.Behind == "+DNS") {
            var opacity = 0
        } else {
            var opacity = 1
        }
		
		if (this.props.driver.NumberOfLaps < 1){
            var opacity = 0
        } else {
			var opacity = 1
		}

        if (this.props.driver.Behind){
            var behind = this.props.driver.Behind.replace("+","")
        }

        var extraInfo = <div></div>
        var nameWidth = "722px"
        //Check for vehicle information
        if (this.props.driver.vehicle){
            nameWidth = "462px"
            extraInfo = <div className='ginettaExtraInfo'><div className='extraInfoText'>{this.props.driver.vehicle}</div></div>
        }

        //Check for team information
        if (this.props.driver.teamName){
            nameWidth = "462px"
            extraInfo = <div className='ginettaExtraInfo'><div className='extraInfoText'>{this.props.driver.teamName}</div></div>
        }

        //BP 24hr Classes
        var assName = this.props.driver.CompetitorName.indexOf('* ')
        if (assName != -1){
            var compName = this.props.driver.CompetitorName.replace('* ', '');
            var star_nbc = "#2faae1"
            var star_nfc = "#ffffff"
        }

        //Country Flags
        if (this.props.ClientStates.CountryFlags == true){
            if (this.props.driver.nat){
                var nationFlag = this.props.driver.nat   
            } else{
                var nationFlag = "blank"
            }
            var nationFlagClass = "resultNationFlag"
        } else {
            var nationFlag = "blank"
            var nationFlagClass = "resultNoNationFlag"
        }

        
        
            return ( 
                <div className='resultRow AnimateMargin' style={{marginTop: this.props.Controls.ResultsMargin + parseInt(this.props.driver.Position)*50, opacity: opacity }}>
                    <img className='ginettaResultsVehicleLogo' src="images/GinettaLargeIcon.png"></img>
                    <div className='ginettaResultPositionBox'>
                        <div className='resultPositionText'>
                            {this.props.driver.Position}
                        </div>
                    </div>
                    
                    <div className='ginettaResultNameBox' style={{width: nameWidth}}>
                        
                        <div className='resultNameText'>
                            {compName || this.props.driver.CompetitorName}
                        </div>
                    </div>
                    <div className='ginettaResultNumberBox' style={{backgroundColor: star_nbc || this.props.driver.nbc || "#2faae1", color: star_nfc || this.props.driver.nfc || "#fff" }}>
                        <div className='ginettaNumberText'>
                            {this.props.driver.CompetitorNumber}
                        </div>
                    </div>
                    <img className={nationFlagClass} src={"flags/"+nationFlag+".png"} onError={this.props.imageError}></img> 
                    {extraInfo}
                    <div className='ginettaResultGapBox'>
                        <div className='resultGapText'>
                            +{behind}
                        </div>
                    </div>
                </div>
            );
        }        
    }
export default ResultsRow ;