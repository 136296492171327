import React, { useEffect, useRef, useState } from 'react';
import io from 'socket.io-client';

const WebRTCStreamReceiver = ({ onboardConnection }) => {
    const videoRef = useRef(null);
    const socketRef = useRef(null);
    const pcRef = useRef(null);
    const [connectionStatus, setConnectionStatus] = useState('red');

    useEffect(() => {
        // Initialize socket only if it doesn't already exist
        if (!socketRef.current) {
            socketRef.current = io("https://controls.alphalive.co.uk");
        }

        // Initialize RTCPeerConnection with STUN server
        if (!pcRef.current) {
            pcRef.current = new RTCPeerConnection({
                iceServers: [
                    { urls: 'stun:stun.l.google.com:19302' },
                    {
                        urls: 'turn:77.68.88.157:3488', // Replace with your TURN server URL
                        username: 'alpha',    // Replace with your TURN server username
                        credential: 'knights' // Replace with your TURN server credential
                    }]
            });
        }

        const pc = pcRef.current;
        const socket = socketRef.current;

        pc.onicecandidate = event => {
            console.log("ICE candidate event:", event);
            // ... existing logic
        };

        pc.oniceconnectionstatechange = () => {
            console.log("ICE Connection State Change:", pc.iceConnectionState);
            onboardConnection(pc.iceConnectionStatetus)
        };

        // Handling the track event
        pc.ontrack = event => {
            console.log("Track event received:", event);
            if (videoRef.current && event.streams[0]) {
                videoRef.current.srcObject = event.streams[0];
            }
        };

        // Handling the WebRTC offer
        socket.on('webrtc_offer', async (data) => {
            const pc = pcRef.current;
            if (pc && pc.signalingState === 'stable') {
                try {
                    await pc.setRemoteDescription(new RTCSessionDescription(data.offer));
                    const answer = await pc.createAnswer();
                    await pc.setLocalDescription(answer);
                    socket.emit('webrtc_answer', { 'answer': answer });
                } catch (error) {
                    console.error('Error during offer handling:', error);
                }
            }
        });

        // socket.on('webrtc_offer', async (data) => {
        //     try {
        //         await pc.setRemoteDescription(new RTCSessionDescription(data.offer));
        //         const answer = await pc.createAnswer();
        //         await pc.setLocalDescription(answer);
        //         socket.emit('webrtc_answer', { 'answer': answer });
        //     } catch (error) {
        //         console.error('Error during offer handling:', error);
        //     }
        // });

        // Handling ICE candidates
        socket.on('webrtc_ice_candidate', async (data) => {
            if (data.candidate) {
                try {
                    await pc.addIceCandidate(new RTCIceCandidate(data.candidate));
                } catch (error) {
                    console.error('Error adding received ICE candidate:', error);
                }
            }
        });

        const getConnectionStatus = (state) => {
            let status;
            console.log('getConnectionStatus')
            switch(state) {
                case 'connected':
                    status = 'connected';
                    break;
                default:
                    status = 'no connection';
                    break;
            }
            onboardConnection(status); // Notify parent component about the status
            return status;
        };

        pc.onconnectionstatechange = () => {
            console.log("Connection State Change:", pc.connectionState);
            setConnectionStatus(getConnectionStatus(pc.connectionState));
        };

        // Cleanup function
        return () => {
            if (pc) {
                pc.close();
            }
            if (socket) {
                socket.disconnect();
            }
        };
    }, []); // Dependency array

    return (<video ref={videoRef} autoPlay playsInline style={{ width: '100%' }} />);
};

export default WebRTCStreamReceiver;
