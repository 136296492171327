import React, { PureComponent } from 'react';
import LiveChampStandings from '../Graphics/liveChampionship';
import './stylesheets/preview.css';

class Championship extends PureComponent {
    state = { 

     }

    render() {
        if (this.props.ClientStates.TimingSystem == "TSL" && this.props.championship){
            return(         
                <div className='box' style={{backgroundColor: this.props.color}}>
                    <div className='boxHeader' onClick={this.props.collapse}>
                        CHAMPIONSHIP PREVIEW
                    </div>
                    <div className='championshipSelect'>
                        <select id='champSelect' onChange={this.props.changeChamps}>
                            {this.props.championship.map((champs, index) => (
                                <option key={index} value={index}>{champs.Name}</option> ))}
                        </select>
                    </div>
                    <LiveChampStandings {...this.props} controller={true}/>
                    <button attr1= 'champStandingsShow' className='previewButton' onClick={this.props.controlSend}>Show</button>
                    <button attr1= 'champStandingsHide' className='previewButton' onClick={this.props.controlSend}>Hide</button>
                    
                    
                </div>
            )
        } else {
           return(         
                <div className='box' style={{backgroundColor: this.props.color}}>
                    <div className='boxHeader' onClick={this.props.collapse}>
                        CHAMPIONSHIP PREVIEW
                    </div>
                    <LiveChampStandings {...this.props} controller={true}/>
                    <button attr1= 'champStandingsShow' className='previewButton' onClick={this.props.controlSend}>Show</button>
                    <button attr1= 'champStandingsHide' className='previewButton' onClick={this.props.controlSend}>Hide</button>
                    
                </div>
            ) 
        }
        
    }
}

export default Championship;