import React, { Component } from 'react';
import TowerHeader from './Graphics/towerHeader';
import Tower from './Graphics/tower';
import FastestLap from './Graphics/fastestLap';
import RaceControl from './Graphics/raceControl';
import Comparison from './Graphics/comparison';
import Results from './Graphics/Results/resultsView';
import GinettaResults from './Graphics/Results/ginettaResultsView';
import GinettaFastestLap from './Graphics/ginettaFastestLap'
import LiveChampStandings from './Graphics/liveChampionship';

class Graphics extends Component {

    render() { 
            return ( 
                <div className="ChampsApp">
                    <LiveChampStandings champsPage={true} champIndex={0} {...this.props}/>
                    <LiveChampStandings champsPage={true} champIndex={1} {...this.props}/>
                    <LiveChampStandings champsPage={true} champIndex={2} {...this.props}/>
                    <LiveChampStandings champsPage={true} champIndex={3} {...this.props}/>             
                </div>
            );
        }
            
}
export default Graphics ;
