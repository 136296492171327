import React, { PureComponent } from 'react';
import MSUKResultsRow from './msukResultsRow';
import '../stylesheets/WSResults.css';
import '../stylesheets/WSResultsRow.css';

class Results extends PureComponent { 

    render() { 

        var header = {
            position: "relative",
            marginTop: "108px",
            marginLeft: "319px",
            height: "136px",
            width: "1289px",
            backgroundColor: "RGB(0, 168, 132)",
            // clipPath: "polygon(0% 100%, 0% 0%, 100% 0%, 96% 100%)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
        }

        var startingGridHead = {
            position: "absolute",
            marginTop: "-75px",
            // backgroundColor: "RGB(0, 168, 132)",
            lineHeight: '1.2',
            width: "100%",
            height: "65px",
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontFamily: "msuk-Head",
            fontSize: "48px"
        }

        var raceNameHead = {
            // backgroundColor: "RGB(0, 168, 132)",
            marginTop: "20px",
            position: "absolute",
            width: "100%",
            height: "37.5px",
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontFamily: "msuk-Main",
            fontSize: "30px",
            fontWeight: "bold",
			textTransform: "uppercase"
        }

        var circuitNameHead = {
            // backgroundColor: "RGB(0, 168, 132)",
            marginTop: "90px",
            position: "absolute",
            width: "100%",
            height: "37.5px",
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontFamily: "msuk-Main",
            fontSize: "30px",
            fontWeight: "bold",
			textTransform: "uppercase"
        }

        var leftDiv = {
            position: "absolute",
            backgroundColor: "transparent",
            height: "800px",
            width: "800px",
            marginLeft: "-200px",
            top: '-90px'
        }

        var rightDiv = {
            position: "absolute",
            overflow: "hidden",
            backgroundColor: "transparent",
            height: "590px",
            width: "730px",
            top: '20px',
            marginLeft: "531px"
        }

        var mainBG = {
            position: "absolute",
            backgroundColor: "rgba(33, 33, 33, 0.831)",
            height: "726px",
            width: "1289px",
            marginLeft: "319px",
            // overflow: "hidden"
        }

        var logo = {
            height: '61px',
            position: "absolute",
            marginTop: "642px",
            marginLeft: "531px"
        }

        var bottomRightLogo = {
            position: "absolute",
            marginTop: "642px",
            height: '61px',
            marginLeft: "1150px"
        }

        var bottomBar = {
            position: "absolute",
            backgroundColor: 'rgb(0, 168, 132)',
            // left: '320px',
            top: '710px',
            width: '1289px',
            height: '16px',
        }

        if(this.props.Controls.ResultsShow == true){
            var resultsClass = "resultsDiv_show"
        } else {
            var resultsClass = "resultsDiv_hide"
        }

        if(this.props.Session.State == "ChequeredFlag" || this.props.Session.State == "Ended" ||  this.props.Session.End == true){
            var resultText = "Result"
        } else {
            var resultText = "Current Standings"
        }

        var posNumbersStyle = {
            fontFamily: "",
            color: "",
            backgroundColor: "",
        }

        if (this.props.ClientStates.Skin){
            if (this.props.ClientStates.Skin == "rotax"){
                posNumbersStyle = {
                    fontFamily: "rotax-Bold",
                    color: "#"+this.props.ClientStates.PrimaryColour,
                    backgroundColor: "#ffffffff",
                }
            }
        }

        var wsNarrowHead = {
            width: '1420px',
            left: '-228px'
        }

        var wsResultList = {
            top: '-53px',
            height: '500px',
            left: '-228px',
            width: '1420px',
        }

        var winnerImg = {
            marginTop: '0px',
            marginLeft: '0px',
            width: '800px'
        }



        var index = this.props.Session.Competitors.findIndex(x => parseInt(x.Position) == 1)

            if (this.props.Session.Competitors[index]) {

                return (
                    <div className={resultsClass}>
                        <div style={header}>
                            <div style={startingGridHead}>RESULTS</div>
                            <div style={raceNameHead}>{this.props.Session.SessionName}</div>
                            <div style={circuitNameHead}>{this.props.Session.tn}</div>
                        </div>
                    
                        <div style={mainBG}>
                            <div style={bottomBar}></div>

                            <div style={leftDiv}>
                                <img style={winnerImg} src={this.props.Session.Competitors[index].Picture+"-4.png"} onError={this.props.imageError}></img>
                            </div>

                            <div style={rightDiv}>
                                {this.props.Session.Competitors.map((driver, index) => (
                                    <MSUKResultsRow driver={driver} indexRow={index} {...this.props} key={index}/>
                                ))}
                            </div>

                            <img style={logo} src={"./images/MSUK_BKC_Logo_Long_MONO_NEG.png"} onError={this.props.imageError}></img>
                            <img style={bottomRightLogo} src={"./images/FIA-Karting2.png"} onError={this.props.imageError}></img>

                        </div>
                    
                    
                    </div>
                )
            } else {
                return (
                    <div className={resultsClass}>
                    <div style={header}>
                        <div style={startingGridHead}>RESULTS</div>
                        <div style={raceNameHead}>{this.props.Session.SessionName}</div>
                        <div style={circuitNameHead}>{this.props.Session.tn}</div>
                    </div>
                    
                    <div style={mainBG}>
                    <div style={bottomBar}></div>
                    <div style={leftDiv}>

                    </div>

                    <div style={rightDiv}>

                    </div>
                    <img style={logo} src={"./images/MSUK_BKC_Logo_Long_MONO_NEG.png"} onError={this.props.imageError}></img>
                    <img style={bottomRightLogo} src={"./images/FIA-Karting2.png"} onError={this.props.imageError}></img>
                    </div>
                    
                    
                </div>
                )
            }   
        }
    }
export default Results ;