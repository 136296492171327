import React, { PureComponent } from 'react';
import DataView from '../Graphics/onboardData';
import './stylesheets/preview.css';

class DataPreview extends PureComponent {
    state = { 

     }

    render() {
        var showButton = <button attr1='LTDataShow' className='previewButton' onClick={this.props.controlSend}>Show</button>
        var LTQuestion = this.props.LT.Name

        if (LTQuestion == undefined){
            showButton = <button disabled attr1='LTDataShow' className='previewButton' onClick={this.props.controlSend}>Show</button>
        }

        return(         
            <div className='box' style={{backgroundColor: this.props.color}}>
                <div className='boxHeader' attr='200px' onClick={this.props.collapse}>
                    ONBOARD DATA PREVIEW
                </div>
                <div style={{width: '50px'}}>
                    {showButton}
                    <button attr1='LTDataHide' className='previewButton' onClick={this.props.controlSend}>Hide</button>
                    <button attr1='LTClear' className='previewButton'  onClick={this.props.clearLT}>Clear</button> 
                </div>
                <DataView {...this.props} Controller={true}/>
            </div>
        )
    }
}

export default DataPreview;