import React, { PureComponent } from 'react';

class rotaxGridTile extends PureComponent {

    render() { 

        var rowStyle = {
            position: 'absolute',
            marginTop: '-25px',
            fontFamily: 'rotax-bold',
            fontSize: '20px',
            color: '#fff'
        }

        if (this.props.position % 2 === 0) {
            var side = '1010px';
            var offset = 100
            var rowHTML = <div style={rowStyle}></div>
            var delay = '150ms'
        } else {
            var side = '190px';
            var offset = 170
            var rowHTML = <div id="rowNum" style={rowStyle}>ROW {(this.props.position+1)/2}</div>
            var delay = '0ms'
        }

        //Calculates the margin shift for the current page to be visible
        var boost = ((this.props.Controls.GridPage-1)*-214)+offset
        var opacity = 1

        //Calculates if the tile should be below the current page (if highest number on page is lower than the tile position = boost the margin to ensure outside)
        if (parseInt(this.props.Controls.GridPage)*2 < parseInt(this.props.position)){
            boost = boost + 200
        }
        
        //Calculates if the tile should be above the current page (if lowest number on page is higher than the tile position = reduce the margin to ensure outside)
        if((parseInt(this.props.Controls.GridPage)-1)*2 >= parseInt(this.props.position)){
            boost = boost - 200
            opacity = 0
        }
        
        var rowHeight = 80
        var rowWidth = 715

        var tileRow = {
            position: 'absolute',
            width: rowWidth + "px",
            height: rowHeight + 'px',
            top: ((this.props.position-1)*107)+boost + "px",
            marginLeft: side,
            backgroundColor: "transparent",
            opacity: opacity,
            transitionDelay: delay
        }

        var leftPos ={
            float: 'left',
            height: '100%',
            aspectRatio: '1',
            color: '#'+this.props.ClientStates.PrimaryColour,
            backgroundColor: '#fff',
            fontFamily: 'rotax-bold',
            fontSize: '40px',
            textAlign: 'center',
            lineHeight: rowHeight + 'px'
        }

        var leftNum ={
            float: 'left',
            height: '100%',
            aspectRatio: '1',
            color: '#fff',
            backgroundColor: this.props.driver.nbc || "black",
            fontFamily: 'rotax-bold',
            fontSize: '40px',
            textAlign: 'center',
            lineHeight: rowHeight-2 + 'px'
        }

        var leftName ={
            float: 'left',
            height: '100%',
            width: (rowWidth-(rowHeight*2)-10)+'px',
            color: '#fff',
            paddingLeft: '10px',
            backgroundColor: "rgb(0 0 0 / 95%)",
            fontFamily: 'rotax',
            fontSize: '30px',
            textAlign: 'left',
            lineHeight: rowHeight + 'px'
        }

        var driverImg = {
            height: "160px",
            position: "absolute",
			marginLeft: "30px"
        }

        var driverContainer = {
            position: "absolute",
            marginTop: "-60px",
            marginLeft: "513px",
            overflow: "hidden",
            height: "140px",
            width: "200px"
        }

        var flag = {
            position: "absolute",
            width: "45px",
            height: "27px",
            marginTop: "63px",
            marginLeft: "154px",
        }

            return ( 
                <div id="tileRow" style={tileRow} className="AnimateRotaxGrid">
                    {rowHTML}
                    <div id="LeftPos" style={leftPos}>{this.props.position}</div>
                    <div id="LeftNum" style={leftNum}>{this.props.driver.CompetitorNumber}</div>
                    <div id="LeftName" style={leftName}>{this.props.driver.CompetitorName.toUpperCase()}</div>
                    <div style={driverContainer}>
                        <img style={flag} src={"./flags/"+this.props.driver.nat+".png"} onError={this.props.imageError}></img>
                        <img style={driverImg} src={this.props.driver.Picture+".png"} onError={this.props.imageError}></img>
                    </div>
                </div>
            );
        }
            
}
export default rotaxGridTile ;
