import React, { PureComponent } from 'react';

class ResultsRow extends PureComponent { 

    

    render() { 
        if (this.props.driver.seen == false || this.props.driver.Behind == "+DNS") {
            var opacity = 0
        } else {
            var opacity = 1
        }
		
		if (this.props.driver.NumberOfLaps < 1 && this.props.driver.GroupNumberOfLaps < 1){
            var opacity = 0
        } else if (this.props.Session.TSLGrouped == 1 && this.props.driver.GroupNumberOfLaps > 1) {
			var opacity = 1
		} else if (this.props.Session.State == "Running" && this.props.driver.NumberOfLaps < 1) {
			var opacity = 0
		} else if (this.props.Session.State == "ChequeredFlag" && this.props.driver.NumberOfLaps < 1) {
			var opacity = 0
		} else {
			var opacity = 1
		}

        if (this.props.driver.Behind == 0){
            var opacity = 0
        }

        
            if(this.props.Grouped == true){
                if(this.props.driver.GroupBehind){
                    var behind = this.props.driver.GroupBehind.replace(/\+/g,"")
                    var behind = this.props.driver.GroupBehind.replace(/-/g,"")
                } else if(this.props.driver.Behind){
                    var behind = this.props.driver.Behind.replace(/\+/g,"")
                    var behind = this.props.driver.Behind.replace(/-/g,"")
                }
            } else if(this.props.driver.Behind){
                var behind = this.props.driver.Behind.replace(/\+/g,"")
                var behind = this.props.driver.Behind.replace(/-/g,"")
            } else {
                var behind = ""
            }

        var extraInfo = <div></div>
        var nameWidth = "722px"
        //Check for vehicle information
        if (this.props.driver.vehicle){
            nameWidth = "644px"
            // nameWidth = "340px"
			//Comment out the row above when not using photos
			
            extraInfo = <div style={{left:'185px', width: '489px'}} className='extraInfo'><div className='extraInfoText' style={{fontSize: '25px'}}>{this.props.driver.vehicle}</div></div>
            // extraInfo = <div style={{left:'-122px', width: '360px'}} className='extraInfo'><div className='extraInfoText' style={{fontSize: '25px'}}>{this.props.driver.vehicle}</div></div>
			//Comment out the row above when not using photos
        }

        //Check for team information
        if (this.props.driver.teamName){
            nameWidth = "644px"
            // nameWidth = "340px"
			//Comment out the row above when not using photos
			
            extraInfo = <div style={{left:'185px', width: '489px'}} className='extraInfo'><div className='extraInfoText' style={{fontSize: '25px'}}>{this.props.driver.teamName}</div></div>
            // extraInfo = <div style={{left:'-122px', width: '360px'}} className='extraInfo'><div className='extraInfoText' style={{fontSize: '25px'}}>{this.props.driver.teamName}</div></div>
			//Comment out the bottom row when not using photos
        }

        //BP 24hr Classes
        var assName = this.props.driver.CompetitorName.indexOf('* ')
        if (assName != -1){
            var compName = this.props.driver.CompetitorName.replace('* ', '');
            var star_nbc = "#2faae1"
            var star_nfc = "#ffffff"
        }

        //Check Competitor Name
        var competitorName = this.props.driver.CompetitorName
        if(this.props.Controls.Name == "TEAM"){
            competitorName = this.props.driver.teamName
        }

        //Country Flags
        if (this.props.ClientStates.CountryFlags == true){
            if (this.props.driver.nat){
                var nationFlag = this.props.driver.nat   
            } else{
                var nationFlag = "blank"
            }
            var nationFlagClass = "resultNationFlag"
        } else {
            var nationFlag = "blank"
            var nationFlagClass = "resultNoNationFlag"
        }

        var posNumbersStyle = {
            fontFamily: "",
            color: "",
            backgroundColor: "",
        }

        if (this.props.ClientStates.Skin){
            if (this.props.ClientStates.Skin == "rotax"){
                posNumbersStyle = {
                    fontFamily: "rotax-Bold",
                    color: "#"+this.props.ClientStates.PrimaryColour,
                    backgroundColor: "#ffffffff",
                }
            }
        }

        var posStyle = {
            fontSize: '30px'
        }

        var numberStyle = {
            fontSize: '30px'
        }

        var extraInfoStyle = {
            fontSize: '25px',
            marginLeft: '747px'
        }

        var nameStyle = {
            fontSize: '30px'
        }


        if(this.props.driver.Position == 1){
            var gapBox = <div className='resultGapBox' style={{width:'180px'}}><div className='resultGapText' style={{fontSize:'30px'}}></div></div>
            // var gapBox = <div className='resultGapBox' style={{width:'155px'}}><div className='resultGapText' style={{fontSize:'30px'}}></div></div>
			//Comment out the bottom row when not using photos
        } else {
            var gapBox = <div className='resultGapBox' style={{width:'180px'}}><div className='resultGapText' style={{fontSize:'30px'}}>+{behind.replace(/\+/g,"")}</div></div>
            // var gapBox = <div className='resultGapBox' style={{width:'155px'}}><div className='resultGapText' style={{fontSize:'30px'}}>+{behind.replace(/\+/g,"")}</div></div>
			//Comment out the bottom row when not using photos
        }

        if(this.props.Session){
            if (this.props.Grouped == true){
                return ( 
                    <div className='resultRow AnimateMargin' style={{marginTop: this.props.Controls.ResultsMargin + parseInt(this.props.driver.GroupPosition)*50, opacity: opacity }}>
                        <div className='resultPositionBox'>
                            <div className='resultPositionText'>
                                {this.props.driver.GroupPosition}
                            </div>
                        </div>
                        <div className='resultNumberBox' style={{backgroundColor: star_nbc || this.props.driver.nbc || "#2faae1", color: star_nfc || this.props.driver.nfc || "#fff" }}>
                            <div className='numberText'>
                                {this.props.driver.CompetitorNumber}
                            </div>
                        </div>
                        <div className='resultNameBox' style={{width: nameWidth}}>
                            <img className={nationFlagClass} src={"flags/"+nationFlag+".png"} onError={this.props.imageError}></img>
                            <div className='resultNameText'>
                                {compName || competitorName || this.props.driver.CompetitorName}
                            </div>
                        </div>
                        {extraInfo}
                        <div className='resultGapBox'>
                            <div className='resultGapText'>
                                +{behind.replace(/\+/g,"")}
                            </div>
                        </div>
                    </div>
                );
            } else {
                return ( 
                    <div className='resultRow AnimateMargin' style={{marginTop: this.props.Controls.ResultsMargin + (parseInt(this.props.driver.Position)*50)+50, opacity: opacity }}>
                        <div className='resultPositionBox' style={posStyle}>
                            <div className='resultPositionText'>
                                {this.props.driver.Position}
                            </div>
                        </div>
                        <div className='resultNumberBox' style={{backgroundColor: star_nbc || this.props.driver.nbc || "#2faae1", color: star_nfc || this.props.driver.nfc || "#fff" }}>
                            <div className='numberText' style={numberStyle}>
                                {this.props.driver.CompetitorNumber}
                            </div>
                        </div>
                        <div className='resultNameBox' style={{width: nameWidth, fontSize: '30px'}}>
                            <img className={nationFlagClass} src={"flags/"+nationFlag+".png"} onError={this.props.imageError}></img> 
                            <div className='resultNameText'>
                                {compName || competitorName || this.props.driver.CompetitorName}
                            </div>
                        </div>
                        {extraInfo}
                        {gapBox}
                    </div>
                );
            }  
        }        
        }
        
    }
export default ResultsRow ;