import React, { PureComponent } from 'react';

class GridTile extends PureComponent {

    render() { 

        var gridPosition = this.props.position
        if (this.props.ClientStates.TimingSystem == "Apex"){
            gridPosition = this.props.position
        }


        if(this.props.Session){
            if(this.props.Session.PoleSide == "L"){
                if (gridPosition % 2 === 0) {
                    var side = '585px';
                } else {
                    var side = '0px';
                }
            } else {
                if (gridPosition % 2 === 0) {
                    var side = '0px';
                } else {
                    var side = '585px';
                }
            }
        }

        //Calculates the margin shift for the current page to be visible
        var boost = (this.props.Controls.GridPage-1)*-640

        //Calculates if the tile should be below the current page (if highest number on page is lower than the tile position = boost the margin to ensure outside)
        if (parseInt(this.props.Controls.GridPage)*8 < parseInt(gridPosition)){
            boost = boost + 200
        }
        
        //Calculates if the tile should be above the current page (if lowest number on page is higher than the tile position = reduce the margin to ensure outside)
        if((parseInt(this.props.Controls.GridPage)-1)*8 >= parseInt(gridPosition)){
            boost = boost - 200
        }
        
        var tileSlot = {
            width: "656px",
            height: "150px",
            backgroundColor: "transparent",
            position: "absolute",
            overflow: "hidden",
            top: ((gridPosition-1)*80)+boost + "px",
            marginLeft: side,
        }

        var posBG = {
            position: "absolute",
            marginTop: "30px",
            marginLeft: "40px",
            width: "80px",
            height: "80px",
            backgroundColor: "RGBA(0,0,0)",
            borderWidth: "1px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            fontSize: "50px",
            fontFamily: "Montserrat-Bold",
        }

        var flag = {
            position: "absolute",
            height: "27px",
            marginTop: "34px",
            marginLeft: "170px",
        }

        var driverNumber = {
            position: "absolute",
            marginTop: "30px",
            marginLeft: "120px",
            width: "45px",
            height: "35px",
            backgroundColor: this.props.driver.nbc,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: this.props.driver.nfc,
            fontSize: "22px",
            fontFamily: "Montserrat-Bold"
        }

        var driverName = {
            position: "absolute",
            marginLeft: "100px",
            marginTop: "30px",
            height: "35px",
            width: "450px",
            backgroundColor: "RGBA(0,0,0,0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "right",
            paddingRight: "5px",
            fontSize: "22px",
            fontFamily: "Montserrat-Bold",
            color: "white",
        }

        var qualifyTime = {
            position: "absolute",
            marginLeft: "100px",
            marginTop: "65px",
            height: "25px",
            width: "450px",
            backgroundColor: "RGBA(0,0,0,0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "right",
            paddingRight: "5px",
            fontSize: "18px",
            fontFamily: "Montserrat-Medium",
            color: "white",
        }

        var teamName = {
            position: "absolute",
            marginLeft: "100px",
            marginTop: "90px",
            height: "20px",
            width: "450px",
            background: "RGBA(0,0,0,0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "right",
            paddingRight: "5px",
            fontSize: "12px",
            fontFamily: "Montserrat-Medium",
            color: "white",
            letterSpacing: "1px"
        }

            return ( 
                <div style={tileSlot} className="AnimateMargin">
                    
                    <div style={driverName}>
                        <div >{this.props.driver.CompetitorName.toUpperCase()}</div>
                    </div>
                    <div style={driverNumber}>
                        <div >{this.props.driver.CompetitorNumber}</div>
                    </div>
                    <div style={qualifyTime}>
                        <div >{this.props.driver.qualification}</div>
                    </div>
                    <div style={teamName}>
                        <div>{this.props.driver.teamName.toUpperCase() + " - " + this.props.driver.vehicle.toUpperCase()}</div>
                    </div>
                    <img style={flag} src={"./flags/"+this.props.driver.nat+".png"} onError={this.props.imageError}></img>
                    <div style={posBG}>{gridPosition}</div>
                   
                </div>
            );
        }
            
}
export default GridTile ;
