import React, { Component } from 'react';
import MsukGridTile from './Graphics/Grids/msukGridTile';

class msukGrid extends Component {
    componentDidMount() {
        // Add event listener when the component mounts         
        window.addEventListener('keydown', this.props.handleKeyPress);
    }

        
    componentWillUnmount() {
        // Remove event listener when the component unmounts   
        window.removeEventListener('keydown', this.props.handleKeyPress);
    }

    render() { 
        document.title = 'Alpha Live Graphics - MSUK Grid';
        var header = {
            position: "relative",
            marginTop: "108px",
            marginLeft: "319px",
            height: "136px",
            width: "1289px",
            backgroundColor: "RGB(0, 168, 132)",
            // clipPath: "polygon(0% 100%, 0% 0%, 100% 0%, 96% 100%)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
        }

        var startingGridHead = {
            position: "absolute",
            marginTop: "-75px",
            // backgroundColor: "RGB(0, 168, 132)",
            lineHeight: '1.2',
            width: "100%",
            height: "65px",
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontFamily: "msuk-Head",
            fontSize: "48px"
        }

        var raceNameHead = {
            // backgroundColor: "RGB(0, 168, 132)",
            marginTop: "20px",
            position: "absolute",
            width: "100%",
            height: "37.5px",
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontFamily: "msuk-Main",
            fontSize: "30px",
            fontWeight: "bold",
			textTransform: "uppercase"
        }

        var circuitNameHead = {
            // backgroundColor: "RGB(0, 168, 132)",
            marginTop: "90px",
            position: "absolute",
            width: "100%",
            height: "37.5px",
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontFamily: "msuk-Main",
            fontSize: "30px",
            fontWeight: "bold",
			textTransform: "uppercase"
        }

        var leftDiv = {
            position: "absolute",
            backgroundColor: "RGB(103,189,146)",
            height: "710px",
            width: "140px",
            marginLeft: "298px"
        }

        var mainBG = {
            position: "absolute",
            backgroundColor: "rgba(33, 33, 33, 0.831)",
            height: "726px",
            width: "1289px",
            marginLeft: "319px",
            overflow: "hidden"
        }

        var logo = {
            position: "absolute",
            marginTop: "632px",
            marginLeft: "694px"
        }

        var bottomRightLogo = {
            position: "absolute",
            marginTop: "653px",
            marginLeft: "1500px"
        }

        var bottomBar = {
            position: "absolute",
            backgroundColor: 'rgb(0, 168, 132)',
            // left: '320px',
            top: '710px',
            width: '1289px',
            height: '16px',
        }
            return ( 
                <div className="GridsApp">
                    <div style={header}>
                        <div style={startingGridHead}>STARTING GRID</div>
                        <div style={raceNameHead}>{this.props.Session.SessionName}</div>
                        <div style={circuitNameHead}>{this.props.Session.tn}</div>
                    </div>
                    {/* <div style={leftDiv}>

                    </div> */}
                    <div style={mainBG}>
                    <div style={bottomBar}></div>
                    {this.props.Session.Competitors.map((driver, index) => (
                        <MsukGridTile  {...this.props} key={index} driver={driver} position={driver.GridPosition+1}/>
                    ))}
                    </div>
                    
                    <img style={logo} src={"./images/MSUK_BKC_Logo_Long_MONO_NEG.png"} onError={this.props.imageError}></img>
                    <img style={bottomRightLogo} src={"./images/FIA-Karting.png"} onError={this.props.imageError}></img>
                </div>
            );
        }
            
}
export default msukGrid ;
