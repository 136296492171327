import React, { PureComponent } from 'react';
import DefaultRow from './TowerRowStates/default';
import GroupedRow from './TowerRowStates/grouped';
import GinettaRow from './TowerRowStates/ginetta';
import Gb3Row from './TowerRowStates/gb3';
import HyundaiRow from './TowerRowStates/hyundai';
import RotaxRow from './TowerRowStates/rotax';
import BasicRow from './TowerRowStates/basic';



class TowerRows extends PureComponent {
    state = { 

     }
    render() { 
            if (this.props.ClientStates.Skin == "Ginetta"){
                return ( 
                    <GinettaRow {...this.props}/>
                );
            } else if (this.props.ClientStates.Skin == "gb3"){
                return ( 
                    <Gb3Row {...this.props}/>
                );
            } else if (this.props.ClientStates.Skin == "basic"){
                return ( 
                    <BasicRow {...this.props}/>
                );
            } else if (this.props.ClientStates.Skin == "Hyundai"){
                return ( 
                    <HyundaiRow {...this.props}/>
                );
            } else if (this.props.ClientStates.Skin == "rotax"){
                return ( 
                    <RotaxRow {...this.props}/>
                );
            } else if (this.props.grouped == true) {
                return ( 
                    <GroupedRow {...this.props}/>
                );
            } else {
                return ( 
                    <DefaultRow {...this.props}/>
                );
            }
        } 
        
     }


 
export default TowerRows;