import React, { Component } from 'react';
import GridTile from './Graphics/Grids/gridTile';

class Grid extends Component {
    componentDidMount() {
        // Add event listener when the component mounts         
        window.addEventListener('keydown', this.props.handleKeyPress);
    }

        
    componentWillUnmount() {
        // Remove event listener when the component unmounts   
        window.removeEventListener('keydown', this.props.handleKeyPress);
    }

    render() { 
        document.title = 'Grids - Alpha Live Graphics';
        var header = {
            position: "relative",
            marginTop: "130px",
            marginLeft: "275px",
            height: "64px",
            width: "1187px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
        }

        var startingGridHead = {
            position: "absolute",
            marginTop: "-75px",
            backgroundColor: "RGBA(0,0,0,0.85)",
            width: "100%",
            height: "65px",
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontFamily: "Montserrat-Bold",
            fontSize: "40px"
        }

        var raceNameHead = {
            backgroundColor: "RGBA(0,0,0,0.85)",
            marginTop: "28px",
            position: "absolute",
            width: "100%",
            height: "37.5px",
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontFamily: "Montserrat",
            fontSize: "25px",
            fontWeight: "bold"
        }

        var mainBG = {
            position: "absolute",
            backgroundColor: "RGBA(0,0,0,0.4)",
            height: "710px",
            width: "1187px",
            marginLeft: "275px",
            overflow: "hidden"
        }

            return ( 
                <div className="GridsApp">
                    <div style={header}>
                        <div style={startingGridHead}>STARTING GRID</div>
                        <div style={raceNameHead}>{this.props.Session.SessionName}</div>
                        </div>
                    <div style={mainBG}>
                    {this.props.Session.Competitors.map((driver, index) => (
                        <GridTile  {...this.props} key={index} driver={driver} position={driver.GridPosition}/>
                    ))}
                    </div>
                </div>
            );
        }
            
}
export default Grid ;
