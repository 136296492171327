import React, { PureComponent } from 'react';
import DefaultRow from './Graphics/TowerRowStates/default';

class NameTest extends PureComponent {


    state = {
        driver :{
            "CompetitorId": 102201,
            "CompetitorNumber": "999",
            "CompetitorName": "TEST DRIVER NAME",
            "CompetitorClass": "JM",
            "CompetitorSubClass": "",
            "DriverName": null,
            "Position": 1,
            "NumberOfLaps": 17,
            "LastLaptime": 46360,
            "RaceTime": 803570,
            "RunningTime": 803570,
            "BestLaptime": 46360,
            "BestLapNumber": 17,
            "IsBestLapInRace": false,
            "IsCurrentLapPBestLap": true,
            "Seen": true,
            "InPit": false,
            "Retired": false,
            "GridPosition": 1,
            "PositionChange": 0,
            "Gap": "1.70",
            "gd": 140,
            "Behind": "1.70",
            "Split1": 15250,
            "Split2": null,
            "Split3": null,
            "IsBestSector1": false,
            "pbs1": false,
            "IsBestSector2": false,
            "pbs2": false,
            "IsBestSector3": false,
            "pbs3": false,
            "cs": 2,
            "el": 46450,
            "TakenChequered": false,
            "ocl": true,
            "clnr": false,
            "blnr": false,
            "tt": null,
            "nat": null,
            "ps": 1,
            "lpe": 0,
            "nbc": "#000000",
            "nfc": "#FFFFFF",
            "es1": 18810,
            "es2": 33700
        }
    }

    checkName = (event) => {
        // Create a new object for the driver with the updated CompetitorName
        const updatedDriver = {
            ...this.state.driver,
            CompetitorName: event.target.value
        };

        // Update the state with the new object
        this.setState({ driver: updatedDriver });
    }

    render() {
        document.title = 'Alpha Live Graphics - Name Testing';
        return (
            <div>
                <input style={{position: "absolute", width: "350px", marginTop: "50px"}} onChange={this.checkName}></input>
                <div style={{position: "absolute", width: "350px", marginTop: "100px"}}><DefaultRow grouped={false} pages={false} margin={"40px"} driver={this.state.driver} {...this.props}/></div>
                
            </div>
        );
        
    }
}

export default NameTest;
