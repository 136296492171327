import React, { PureComponent } from 'react';

class RotaxResultsRow extends PureComponent { 

    

    render() { 
        if (this.props.driver.seen == false || this.props.driver.Behind == "+DNS") {
            var opacity = 0
        } else {
            var opacity = 1
        }
		
		if (this.props.driver.NumberOfLaps < 1 && this.props.driver.GroupNumberOfLaps < 1){
            var opacity = 0
        } else if (this.props.Session.TSLGrouped == 1 && this.props.driver.GroupNumberOfLaps > 1) {
			var opacity = 1
		} else if (this.props.Session.State == "Running" && this.props.driver.NumberOfLaps < 1) {
			var opacity = 0
		} else if (this.props.Session.State == "ChequeredFlag" && this.props.driver.NumberOfLaps < 1) {
			var opacity = 0
		} else {
			var opacity = 1
		}

        // if (this.props.driver.Behind == 0){
        //     var opacity = 0
        // }

        
            if(this.props.Grouped == true){
                if(this.props.driver.GroupBehind){
                    var behind = this.props.driver.GroupBehind.replace(/\+/g,"")
                    var behind = this.props.driver.GroupBehind.replace(/-/g,"")
                } else if(this.props.driver.Behind){
                    var behind = this.props.driver.Behind.replace(/\+/g,"")
                    var behind = this.props.driver.Behind.replace(/-/g,"")
                }
            } else if(this.props.driver.Behind){
                // var behind = this.props.driver.Behind.replace(/\+/g,"")
                // var behind = this.props.driver.Behind.replace(/-/g,"")
                var behind = "+"+this.props.driver.Behind
            } else {
                var behind = "WINNER"
            }

        var extraInfo = <div></div>
        var nameWidth = "722px"
        //Check for vehicle information
        if (this.props.driver.vehicle){
            nameWidth = "459px"
            extraInfo = <div className='extraInfo'><div className='extraInfoText'>{this.props.driver.vehicle}</div></div>
        }

        //Check for team information
        if (this.props.driver.teamName){
            nameWidth = "459px"
            extraInfo = <div className='extraInfo'><div className='extraInfoText'>{this.props.driver.teamName}</div></div>
        }

        //BP 24hr Classes
        var assName = this.props.driver.CompetitorName.indexOf('* ')
        if (assName != -1){
            var compName = this.props.driver.CompetitorName.replace('* ', '');
            var star_nbc = "#2faae1"
            var star_nfc = "#ffffff"
        }

        //Check Competitor Name
        var competitorName = this.props.driver.CompetitorName
        if(this.props.Controls.Name == "TEAM"){
            competitorName = this.props.driver.teamName
        }

        //Country Flags
        if (this.props.ClientStates.CountryFlags == true){
            if (this.props.driver.nat){
                var nationFlag = this.props.driver.nat   
            } else{
                var nationFlag = "blank"
            }
            var nationFlagClass = "resultNationFlag"
        } else {
            var nationFlag = "blank"
            var nationFlagClass = "resultNoNationFlag"
        }

        var posNumbersStyle = {
            fontFamily: "",
            color: "",
            backgroundColor: "",
        }

        if (this.props.ClientStates.Skin){
            if (this.props.ClientStates.Skin == "rotax"){
                posNumbersStyle = {
                    fontFamily: "rotax-Bold",
                    color: "#"+this.props.ClientStates.PrimaryColour,
                    backgroundColor: "#ffffffff",
                }
            }
        }

        if(this.props.Session){
            return ( 
                <div className='rotaxResultRow AnimateMargin' style={{marginTop: (this.props.Controls.ResultsMargin + parseInt(this.props.driver.Position)*75)+32, opacity: opacity }}>
                    <div className='rotaxResultPosition'>{this.props.driver.Position}</div>
                    <div className='rotaxResultName'>{this.props.driver.CompetitorName}</div>
                    <div className='rotaxResultNumber'>{this.props.driver.CompetitorNumber}</div>
                    <img className='rotaxResultFlag' src='/flags/kor.png'/>
                    <div className='rotaxResultGap'>{behind}</div>

                </div>
                );
            }     
        }
        
    }
export default RotaxResultsRow ;