import React, { PureComponent } from 'react';

const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});

let alpha = params.alpha;

class HyundaiGridItem  extends PureComponent { 
    render() {


        //Opacity Calculations START
        var delay = 100 * this.props.id;
        if (this.props.Controls.GridShow == true){
            var opacity = 1;
            var delay = 200 * parseInt(this.props.id) +1000;
        } else {
            var opacity = 0;
            var delay = 200 * parseInt(this.props.id) +1000;
        }
        
        if (!this.props.driver.Position && !this.props.driver.gridPosition){
            opacity = 0
        }
        //Opacity Calculations END

        //Smart Naming Calculations START
        if(this.props.driver.name || this.props.driver.CompetitorName){
        if(alpha == "true"){var driverName = this.props.driver.name}else{var driverName = this.props.driver.CompetitorName}}
        if(driverName){
            var assName = driverName.indexOf('* ')
            if (assName != -1){
                var compName = driverName.replace('* ', '');
                var star_nbc = "#2faae1"
                var star_nfc = "#ffffff"
            }
            if (driverName){
                var competitorName = compName || driverName
                var run = 0
                while (run == 0){
                    var nameArray = competitorName.split(",").join(" ").split(" ")
                    if (run > 0){
                        var nameArray = competitorName.split("-").join(" ").split(" ")
                        var nameStart = nameArray[0]
                        var firstName = nameStart.substring(0,2*run+1)
                    } else {
                        var firstName = nameArray[0][0]
                        var nameStart = nameArray[0]
                    }
                    nameArray.shift()
                    var competitorName = firstName+". "+nameArray
                    var remainingName = " " + nameArray
                    run++
                    competitorName = competitorName.replace(/,/g, ' ');
                    remainingName = remainingName.replace(/,/g, ' ');
                    if (run > 5){ break }
                }

            } else {
                var competitorName = compName || driverName
            }
        } else {
            var competitorName = "undefined"
        }
        //Smart Naming Calculations END
          
          const positionStyle = {
            position: 'relative',
            fontFamily: 'Hyundai-Bold',
            lineHeight: '40px',
            marginLeft: '-10px',
            textAlign: 'centre',
            width: '55px',
            height: '40px',
            background: 'linear-gradient( -180deg, rgb(170,202,230) 0%, rgb(131,176,213) 100%)',
            transform: 'skew(-25deg)'
          };

          const positionTextStyle= {
            position: 'relative',
            fontSize: '20px',
            marginLeft: '5px',
            width: '55px',
            transform: 'skew(25deg)',
            color: 'rgb(12 34 51)',
            textAlign: 'center'
          }

          const numberStyle = {
              position: 'relative',
              float: 'right',
              marginRight: '5px',
              width: '40px',
              textAlign: 'center',
              color: 'rgb(170 202 230)',
              top: '-70px',
              lineHeight: '30px',
              fontSize: '20px',
          };
           
        
          const timeStyles = {
            fontWeight: 'bold',
            width: '185px',
            fontSize: '25px',
            textAlign: 'center',
            marginRight: '7px',
            fontFamily: 'Hyundai',
          };  

          var marginTop = 85 * this.props.id
          marginTop = marginTop //+ parseInt(this.props.Controls.GridsMargin)

          var marginLeft = 0
          if (this.props.side == 'right'){
            marginTop = marginTop + 30
            marginLeft = 100
          }

          var spanStyles ={
            position: 'absolute',
            top: marginTop +"px",
            opacity: opacity,
            transition: "opacity 200ms ease-in-out, top 1000ms ease-in-out",
            transitionDelay: delay+"ms"
          }

          const nameStyle = {
            position: 'relative',
            fontSize: '22px',
            fontFamily: 'Hyundai-Bold',
            textTransform: 'uppercase',
            lineHeight: '35px',
            marginLeft: '0px',
            textAlign: 'center',
            width: '480px',
            top: -35,
            
          }
        
          const mainBox = {
            backgroundColor: 'rgb(4 11 20)',
            opacity: '0.8',
            width: '480px',
            height: '65px',
            marginLeft: marginLeft,
            overflow: 'hidden'
          }

          


          return (
            <div className={"AnimateMargin"} style={spanStyles}>
              
                <div style={mainBox}>
                  <div style={positionStyle}>
                    <div style={positionTextStyle}>{this.props.driver.GridPosition}</div>
                  </div>
                  <div className='hyundaiFade' style={{position:'absolute', width:'100%', height: '40px', top: '0px', opacity: '0.25'}}></div>
                  <div style={nameStyle}>{nameStart[0]+"."+remainingName.toUpperCase()}</div>
                  <div style={numberStyle}>{this.props.driver.CompetitorNumber}</div>
                </div>
              
            </div>
        );
          
    }
}

export default HyundaiGridItem ;