import React, { Component } from 'react';
import MsukGridTile from './Graphics/Grids/msukGridTile';

class msukGrid extends Component {
    componentDidMount() {
        // Add event listener when the component mounts         
        window.addEventListener('keydown', this.props.handleKeyPress);
    }

        
    componentWillUnmount() {
        // Remove event listener when the component unmounts   
        window.removeEventListener('keydown', this.props.handleKeyPress);
    }

    render() { 
        document.title = 'Alpha Live Graphics - MSUK Grid';
        var header = {
            position: "relative",
            marginTop: "96px",
            marginLeft: "298px",
            height: "140px",
            width: "1382px",
            clipPath: "polygon(0% 100%, 0% 0%, 100% 0%, 96% 100%)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
        }

        var startingGridHead = {
            position: "absolute",
            marginTop: "-75px",
            backgroundColor: "RGB(103,189,146)",
            width: "100%",
            height: "65px",
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontFamily: "msuk-Bold-Oblique",
            fontSize: "40px"
        }

        var raceNameHead = {
            backgroundColor: "RGB(114,110,109)",
            marginTop: "28px",
            position: "absolute",
            width: "100%",
            height: "37.5px",
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontFamily: "msuk-Italic",
            fontSize: "22px",
            fontWeight: "bold"
        }

        var circuitNameHead = {
            backgroundColor: "RGB(114,110,109)",
            marginTop: "102px",
            position: "absolute",
            width: "100%",
            height: "37.5px",
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontFamily: "msuk-Italic",
            fontSize: "22px",
            fontWeight: "bold"
        }

        var leftDiv = {
            position: "absolute",
            backgroundColor: "RGB(103,189,146)",
            height: "710px",
            width: "140px",
            marginLeft: "298px"
        }

        var mainBG = {
            position: "absolute",
            backgroundColor: "RGBA(103,189,146,0.5)",
            height: "710px",
            width: "1187px",
            marginLeft: "438px",
            overflow: "hidden"
        }

        var logo = {
            position: "absolute",
            marginTop: "-165px",
            marginLeft: "240px"
        }

            return ( 
                <div className="GridsApp">
                    <div style={header}>
                        <div style={startingGridHead}>STARTING GRID</div>
                        <div style={raceNameHead}>{this.props.Session.SessionName}</div>
                        <div style={circuitNameHead}>{this.props.Session.tn}</div>
                    </div>
                    <div style={leftDiv}>

                    </div>
                    <div style={mainBG}>
                    {this.props.Session.Competitors.map((driver, index) => (
                        <MsukGridTile  {...this.props} key={index} driver={driver} position={driver.GridPosition+1}/>
                    ))}
                    </div>
                    <img style={logo} src={"./images/msukGridLogo.png"} onError={this.props.imageError}></img>
                </div>
            );
        }
            
}
export default msukGrid ;
