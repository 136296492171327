import React, { PureComponent } from 'react';
import '../stylesheets/RotaxRows.css';

class rotaxRow extends PureComponent {
    
    render() {
        var marginBoost = 0
        var photoClass = "photo-off"
        var winnerNum = ""
        //Opacity Calculations START
        if (this.props.driver.NumberOfLaps < 1){
            var opacity = 0
            marginBoost = 1000
        } else if (this.props.driver.seen == false) {
            var opacity = 0
            marginBoost = 10000000000
        } else {
            var opacity = 1
        }
        //Opacity Calculations END

        if (this.props.driver.Expanded == true){
            var height = "75px" 
        } else {
            var height = "35px" 
        }

        //Battle Calculations START
        if (this.props.Controls.BattleShow == true){
            var battleQuestion = this.props.Controls.BattleDrivers.indexOf(parseInt(this.props.driver.CompetitorId))
            if (battleQuestion != -1 && this.props.pages == false){
                var nameBG = "rgb(222 222 222 / 95%)"
                var nameText = "#000000"
                marginBoost = 25
            } else if (battleQuestion != -1 && this.props.pages == true && parseInt(this.props.Controls.BattlePosition) > 12) {
                var nameBG = "rgb(222 222 222 / 95%)"
                var nameText = "#000000"
                marginBoost = 25
            } else if (battleQuestion == -1 && this.props.pages == true && parseInt(this.props.driver.Position) >= parseInt(this.props.Controls.BattlePosition) && parseInt(this.props.Controls.BattlePosition) > 12) {
                var nameBG = "rgb(0 0 0 / 95%)"
                var nameText = "#fff"
                marginBoost = 27
            } else if (parseInt(this.props.driver.Position) >= parseInt(this.props.Controls.BattlePosition) && this.props.pages == false ) {
                var nameBG = "rgb(0 0 0 / 95%)"
                var nameText = "#fff"
                marginBoost = 27
            } else {
                var nameBG = "rgb(0 0 0 / 95%)"
                var nameText = "#fff"
            }
        } else {
            var nameBG = "rgb(0 0 0 / 95%)"
            var nameText = "#fff"
        }
        //Battle Calculations END

//Smart Naming Calculations START - MODIFIED FOR rotax TO LOCK TO Initial.Lastname
if(this.props.driver.CompetitorName) {
    var compName = this.props.driver.CompetitorName;
    
    // Remove any '*' characters if present
    var assNameIndex = compName.indexOf('* ');
    if (assNameIndex !== -1) {
        compName = compName.replace('* ', '');
    }

    // Split the name into parts
	compName = compName.replace('-', ' ');
    var nameArray = compName.split(" ");

    if (nameArray.length >= 3) {
        // Handle names with 3 or more parts (First, Middle, Last)
        var firstNameInitial = nameArray[0][0]; // First name initial
        var middleNameInitial = nameArray[1][0]; // Middle name initial
        var lastName = nameArray.slice(-1)[0]; // Last name (always keep full)

        // Form the abbreviated name with initials
        var competitorName = `${firstNameInitial}.${middleNameInitial} ${lastName}`;

        // Check if the name still exceeds 12 characters
        if (competitorName.length > 12) {
            // If it's still too long, truncate the middle initial
            competitorName = `${firstNameInitial}. ${lastName}`;
        }

        console.log(competitorName);
    } else if (nameArray.length == 2) {
        // Handle two-part names (First Last)
        var firstNameInitial = nameArray[0][0];
        var lastName = nameArray[1];
        var competitorName = `${firstNameInitial}. ${lastName}`;

        // Check if the abbreviation is longer than 12 characters
        if (competitorName.length > 12) {
            // If the last name is shorter than 12 characters, use only the last name
            if (lastName.length <= 12) {
                competitorName = lastName;
            } else {
                // If the last name is longer than 12 characters, abbreviate both
                competitorName = `${firstNameInitial}.${lastName[0]}.`;
            }
        }

        console.log(competitorName);
    } else {
        // Handle single-part names (just one name)
        var competitorName = compName;
        console.log(competitorName);
    }
} else {
    var competitorName = "undefined";
    console.log(competitorName);
}
//Smart Naming Calculations END




        //Position Positive/Negative START
        if (this.props.driver.PositionChange > 0){
            var positionClass = undefined;
        } else if (this.props.driver.PositionChange < 0){
            var positionClass = undefined;
        } else {
            var positionClass = undefined;
        }
        //Position Positive/Negative END

        //extraBox & position class Calculations START
        var extraBoxClass = "extraBox transparent"
        var extraBoxContent = ""
        var extraBoxImgClass = "extraBoxImgHide"
        var tintMargin = 40
       if (this.props.driver.IsBestLapInRace == true && this.props.driver.TakenChequered == true){
            extraBoxClass = "extraBox Black"
            positionClass = "fastestPink positionneutral"
            extraBoxImgClass = "extraBoxImgShow"
            tintMargin = "5px"
        } else if (this.props.driver.TakenChequered == true){
            extraBoxClass = "extraBox Black"
            positionClass = "positionneutral"
            extraBoxImgClass = "extraBoxImgShow"
            tintMargin = 5
        } else if (this.props.driver.InPit == true){
            extraBoxClass = "extraBox StreamBlue"
            positionClass = "StreamBlue positionneutral"
            extraBoxContent = "PIT"
            tintMargin = 5
        } else if (this.props.driver.IsBestLapInRace == true && this.props.driver.NumberOfLaps > 1 && this.props.Session.SessionType != 'Qual' && this.props.Session.SessionType != 'Qualifying' && this.props.Session.SessionType != 'Practice'){
            extraBoxClass = "extraBox fastestPink"
            positionClass = "fastestPink positionneutral"
            extraBoxContent = "FL"
            tintMargin = 5
        }
        //extraBox & position class Calculations END

        //col4 content calculations START
        var col4Content = ""
        var col4BGColor = "rgb(0 0 0 / 85%)"
        var tintWidth = 300
        if (this.props.Controls.Col4 == "gap"){
            if (this.props.driver.Position == 1 && this.props.Session.SessionType != "Qual"){
                col4Content = "Gap"
            } else if (this.props.driver.Position == 1 && this.props.Session.SessionType == "Qual") {
                col4Content = this.props.msToTime(this.props.driver.BestLaptime*1000)
            } else if (this.props.Session.SessionType == "Qual") {
                col4Content = "+" + this.props.driver.Behind
            } else {
                col4Content = this.props.driver.Behind
            }
            tintWidth = tintWidth + ( 40 - tintMargin)
        } else if (this.props.Controls.Col4 == "diff"){
            if (this.props.driver.Position == 1){
                col4Content = "Diff"
            } else {
                col4Content = this.props.driver.Gap
            }
            tintWidth = tintWidth + ( 40 - tintMargin)
        } else if (this.props.Controls.Col4 == "pits"){
            col4Content = this.props.driver.ps
            tintWidth = 265 + ( 40 - tintMargin)
        } else if (this.props.Controls.Col4 == "positions"){
            if (this.props.ClientStates.TimingSystem == "TSL" || this.props.ClientStates.TimingSystem == "rMonitor"){
                col4Content = this.props.driver.GridPosition - this.props.driver.Position
            } else {
                col4Content = (this.props.driver.GridPosition+1) - this.props.driver.Position
            }
            
            tintWidth = 265 + ( 40 - tintMargin)
            if (col4Content > 0){
                col4BGColor = "rgb(0 146 69 / 85%)"
                col4Content = "▲" + col4Content
            } else if (col4Content < 0){
                col4BGColor = "rgb(193 39 45 / 85%)"
                col4Content = "▼" + Math.abs(col4Content)
            } else {
                col4Content = "◀▶"
            }
        } else if (this.props.Controls.Col4 == "none"){
            tintWidth = 265
        }
        //col4 class calculations END

        //Retired Settings START
        if (this.props.driver.Retired == true){
            var rowTint = "rgb(0 0 0 / 85%)"
        } else {
            var rowTint = "transparent"
        }
        //Retired Settings END

        //Progression Settings Start
        var progressionNo = this.props.Controls.ProgressionPosition || this.props.ClientStates.DefaultProgression
        if (this.props.Controls.ProgressionShow == true && this.props.pages == false){
            if (parseInt(this.props.driver.Position) > parseInt(progressionNo)){
                marginBoost = marginBoost + 9
            } else {
                marginBoost = marginBoost + 0
            }
        }
        //Progression Settings End

        //Expand Content Start - Add back in for race day when pictures are active
        // if (this.props.driver.Position == 1 && this.props.driver.TakenChequered == true && this.props.ClientStates.DriverPhotos == true){
            // //Winner Data
            // // var photoURL = this.props.driver.Picture + "-4.png"+`?t=${Date.now()}`
            // var photoURL = this.props.driver.Picture + "-4.png"
            // var firstSpaceIndex = this.props.driver.CompetitorName.indexOf(" ");
            // var firstName = this.props.driver.CompetitorName.substring(0, firstSpaceIndex);
            // var remainingName = this.props.driver.CompetitorName.substring(firstSpaceIndex + 1);
            // var expandedHTML = <div className='expandedTextBox'><div className='winnerFirstName'>{firstName}</div><div className='winnerRemainingName'>{remainingName}</div><div className='winnerTeam'>{this.props.driver.teamName}</div><div className='winnerVehicle'>{this.props.driver.vehicle}</div></div>
            // competitorName = ""
            // positionClass = "winnerPosition"
            // winnerNum = "rotaxWinnerNum"
        // } else if (this.props.driver.Position == 1 ){
            // //1st Position Data
            // // var photoURL = this.props.driver.Picture + "-3.png"+`?t=${Date.now()}`
            // var photoURL = this.props.driver.Picture + "-3.png"
            // var expandedHTML = <div className='expandedTextBox'><div className='winnerTeam'>{this.props.driver.teamName}</div><div className='winnerVehicle'>{this.props.driver.vehicle}</div></div>
        // } else {
            //All other Positions Data
            // var photoURL = this.props.driver.Picture + "-3.png"+`?t=${Date.now()}`
            var photoURL = this.props.driver.Picture + ".png"
            var expandedHTML = <div className='expandedTextBox'><div className='winnerTeam'></div></div>
        // }
        //Expand Content End

        //Expand Check Start
        if (this.props.Controls.Expand == true){
            if (this.props.Controls.ExpandDriverID == this.props.driver.CompetitorId && this.props.pages == false){
                //Expand top 10
                var expandClass = "rotaxExpand-on"
                col4BGColor = "rgb(222 222 222 / 95%)"
				var nameBG = "rgb(222 222 222 / 95%)"
				var nameText = "#000000"
                photoClass = "photo-on"
                col4Content = ""
            } else if (this.props.Controls.ExpandDriverID == this.props.driver.CompetitorId && this.props.pages == true && this.props.driver.Position > 10){
                //Expand after top 10
                var expandClass = "rotaxExpand-on"
                col4BGColor = "rgb(222 222 222/ 95%)"
				var nameBG = "rgb(222 222 222 / 95%)"
				var nameText = "#000000"
                col4Content = ""
                photoClass = "photo-on"
            } else {
                //Drivers after the expanded driver
                var expandClass = "rotaxExpand-off"
                if (this.props.driver.Position > this.props.Session.Competitors[this.props.Controls.ExpandIndex].Position && this.props.pages == false){
                    marginBoost = marginBoost + 72
                } else if (this.props.Session.Competitors[this.props.Controls.ExpandIndex].Position > 10 && this.props.driver.Position > this.props.Session.Competitors[this.props.Controls.ExpandIndex].Position && this.props.pages == true){
                    marginBoost = marginBoost + 72
                }
                
            }
        } else if (this.props.Controls.Onboard == true){
            if (this.props.Controls.OnboardDriverID == this.props.driver.CompetitorId && this.props.pages == false){
                //Expand top 10
                var expandClass = "rotaxExpand-off"
                var nameBG = "rgb(222 222 222 / 95%)"
                var nameText = "#000000"
            } else if (this.props.Controls.OnboardDriverID == this.props.driver.CompetitorId && this.props.pages == true && this.props.driver.Position > 10){
                //Expand after top 10
                var expandClass = "rotaxExpand-off"
                var nameBG = "rgb(222 222 222 / 95%)"
                var nameText = "#000000"
            } else {
                //Drivers after the expanded driver
                var expandClass = "rotaxExpand-off"
                if (parseInt(this.props.driver.Position) > parseInt(this.props.Session.Competitors[this.props.Controls.OnboardIndex].Position) && this.props.pages == false){
                    marginBoost = marginBoost + 203
                } else if (parseInt(this.props.Session.Competitors[this.props.Controls.OnboardIndex].Position) > 10 && parseInt(this.props.driver.Position) > parseInt(this.props.Session.Competitors[this.props.Controls.OnboardIndex].Position) && this.props.pages == true){
                    marginBoost = marginBoost + 203
                }
                
            }
        } else {
            var expandClass = "rotaxExpand-off"
        }
        //Expand Check End

        if (!this.props.driver.Position){
            opacity = 0
        }

        var font = {
            fontSize: "14px"
        }

        if (this.props.ClientStates.Skin && this.props.ClientStates.FontSize){
            font = {
                fontFamily: this.props.ClientStates.Skin,
                fontSize: this.props.ClientStates.FontSize
            }
        } else if (this.props.ClientStates.Skin ) {
            font = {
                fontFamily: this.props.ClientStates.Skin,
                fontSize: "17px"
            }
        }

            return ( 
                // <span attr2="2" onClick={() => this.props.expandDriver(this.props.indexRow,"click")}>
                <span>
                    <div className={"AnimateMargin"} style={{position: "absolute",  top: this.props.margin[parseInt(this.props.driver.Position)]+marginBoost+"px", height: height, opacity: opacity, ...font}}>
                        {/* expand div below*/}
                        <div className={expandClass} style={{top: this.props.margin+2+"px"}}>
                            <div className={"imageContainer "+ photoClass}>
                                {expandedHTML}
                                <img className="rotaxDriverTowerImage" src={photoURL} onError={this.props.imageError}></img>
                            </div>
                            
                            {/* <div className="expandedDiff">{col4Content}</div> */}
                        </div>
                        <div className={extraBoxClass}>
                            <div className='extraBoxText'>{extraBoxContent}</div>
                            <img className={extraBoxImgClass}src="images/CheckeredFlag.png"></img>
                        </div>
                        <div id={this.props.indexRow + "_pos"} style={{color: "#"+this.props.ClientStates.PrimaryColour}} className={"rotaxCol1 " + winnerNum}>
                            {this.props.driver.Position}
                        </div>

                        <div className="rotaxCol2">
                            <div className={"rotaxCatBG "+ winnerNum} style={{backgroundColor: this.props.driver.nbc || "#2faae1", color: this.props.driver.nfc || "#ffffff"}}>
                                {this.props.driver.CompetitorNumber}
                            </div>
                            {/* <img className='rotaxFlag' src={'/flags/kor.png'}></img> */}
                        </div>

                        <div className="rotaxCol3" style={{backgroundColor: nameBG, color: nameText}}>
                            {competitorName}
                            <img className='rotaxFlag' src={'/flags/'+this.props.driver.nat+'.png'}></img>
                        </div>

                        <div className={"rotaxCol4 " + this.props.Controls.Col4} style={{backgroundColor: col4BGColor}}>
                            <div className="gapText">{col4Content}</div>
                        </div>                        
                    
                        <div className='tint' style={{backgroundColor: rowTint, width: tintWidth, marginLeft: tintMargin}}>

                        </div>
                        
                        
                    </div>
                </span>
            );
        } 

}


 
export default rotaxRow;