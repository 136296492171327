import React, { Component } from 'react';
import RotaxGridTile from './Graphics/Grids/rotaxGridTile';

class rotaxGrid extends Component {
    componentDidMount() {
        // Add event listener when the component mounts         
        window.addEventListener('keydown', this.props.handleKeyPress);
    }

        
    componentWillUnmount() {
        // Remove event listener when the component unmounts   
        window.removeEventListener('keydown', this.props.handleKeyPress);
    }

    render() { 
        document.title = 'Alpha Live Graphics - RMC Grids';
        var ltGridDiv = {
            position: "absolute",
            width: "100%",
            height: "500px",
            marginTop: "579px",
            backgroundColor: "transparent",
            overflow: "hidden"
        }

            return ( 
                <div className="GridsApp">
                    <div id="LTGridDiv" style={ltGridDiv}>
                        {this.props.Session.Competitors.map((driver, index) => (
                            <RotaxGridTile  {...this.props} key={index} driver={driver} position={driver.GridPosition+1}/>
                        ))}
                    </div>
                </div>
            );
        }
            
}
export default rotaxGrid ;
