import React, { PureComponent } from 'react';
import RotaxResultsRow from './rotaxResultsRow';
import '../stylesheets/rotaxResults.css';
import '../stylesheets/rotaxResultsRow.css';

class RotaxResults extends PureComponent { 

    render() { 

            if(this.props.Controls.ResultsShow == true){
                var resultsClass = "rotaxResultsDiv_show"
            } else {
                var resultsClass = "rotaxResultsDiv_hide"
            }
            
            if(this.props.Session.Competitors){
                let indices = [];
                if(this.props.Session.TSLGrouped == 1){
                    var index = this.props.Session.Competitors.findIndex(x => parseInt(x.GroupPosition) == 1)
                } else {
                    indices = this.props.Session.Competitors.filter(x => parseInt(x.Position) === 1);
                    if (indices.length > 1){
                        var winnerIndex = indices.findIndex(x => parseInt(x.NumberOfLaps) != 0)
                        if(winnerIndex > -1){
                            var index = this.props.Session.Competitors.findIndex(x => x.CompetitorNumber == indices[winnerIndex].CompetitorNumber)
                        } else {
                            var index = this.props.Session.Competitors.findIndex(x => parseInt(x.Position) == 1)
                        }
                    } else {
                        var index = this.props.Session.Competitors.findIndex(x => parseInt(x.Position) == 1)
                    }
                    
                }


                //BP 24hr Classes
                if (index != -1){
                   var assName = this.props.Session.Competitors[index].CompetitorName.indexOf('* ')
                    if (assName != -1){
                        var compName = this.props.Session.Competitors[index].CompetitorName.replace('* ', '');
                        var star_nbc = "#2faae1"
                        var star_nfc = "#ffffff"
                    } 
                }
                
            } else {
                var index = 0
            }

            if(this.props.Session.State == "ChequeredFlag" || this.props.Session.State == "Ended" ||  this.props.Session.End == true){
                var resultText = "Result"
            } else {
                var resultText = "Current Standings"
            }

            //Country Flags
            if (this.props.ClientStates.CountryFlags == true){
                if (this.props.Session.Competitors[index]){
                    var nationFlag = this.props.Session.Competitors[index].nat 
                    var nationFlagClass = "resultNationFlag"
                } else {
                    var nationFlag = "blank"
                    var nationFlagClass = "resultNationFlag"
                }
                
            } else {
                var nationFlag = "blank"
                var nationFlagClass = "resultNoNationFlag"
            }

            var posNumbersStyle = {
                fontFamily: "",
                color: "",
                backgroundColor: "",
            }
    
            if (this.props.ClientStates.Skin){
                if (this.props.ClientStates.Skin == "rotax"){
                    posNumbersStyle = {
                        fontFamily: "rotax-Bold",
                        color: "#"+this.props.ClientStates.PrimaryColour,
                        backgroundColor: "#ffffffff",
                    }
                }
            }

            
            return (
                <div className={resultsClass} id="3">
                    <div className='rotaxResultsList'>
                        {this.props.Session.Competitors.map((driver, index) => (
                            <RotaxResultsRow driver={driver} indexRow={index} {...this.props} key={index}/>
                        ))}
                    </div>

                    <div className='rotaxResultsHead'>
                    <div className='rotaxRightText'>
                            RACE RESULTS
                        </div>
                        <div className='rotaxLeftText'>
                            {this.props.Session.SessionName}
                        </div>  
                    </div>
                </div>
            );
        }        
    }
export default RotaxResults ;