import React, { Component } from 'react';
import './stylesheets/LT.css';
import './stylesheets/hyundaiLT.css';
import './stylesheets/RotaxLT.css';
import { transform } from 'lodash';

class LTView extends Component {

  render() {
    
    if(this.props.Controller == true && this.props.ClientStates.Skin == "Hyundai"){
        var left = '100px'
        var top = '115px'
        var scale = '60%'
        var showClass = 'bannerShow'
        var otherShowClass = 'hyundaiLTShow'
    } else if(this.props.Controller == true && this.props.ClientStates.Skin == "rotax"){
        var left = '100px'
        var top = '115px'
        var scale = '60%'
        var showClass = 'bannerShow'
        var otherShowClass = 'RotaxLTShow'
    } else if(this.props.Controller == true){
        var left = '100px'
        var top = '0px'
        var scale = '80%'
        var showClass = 'bannerShow'
    } else {
        var left = this.props.LT.Left
        if(this.props.ClientStates.Skin == "Hyundai"){
            var top = '1800px'
        } else if(this.props.ClientStates.Skin == "rotax"){
            var top = '1840px'
            var left = '1000px'
        } else {
            var top = this.props.LT.Top
        }
        
        var scale = '100%'
        var showClass = 'bannerHide'
        var otherShowClass = 'hyundaiLTHide'
        if (this.props.Controls.LT == true){
            showClass = 'bannerShow'
            if(this.props.ClientStates.Skin == 'Hyundai'){
                otherShowClass = 'hyundaiLTShow'
            } else if (this.props.ClientStates.Skin == "rotax"){
                otherShowClass = 'RotaxLTShow'
            }
            
        }
    }

    if(this.props.LT.Number == ""){
        var numberStyle = {
            width: '0px',
            backgroundColor: this.props.LT.nbc,
            color: this.props.LT.nfc
        }
    } else {
        var numberStyle = {
            backgroundColor: this.props.LT.nbc,
            color: this.props.LT.nfc
        }
    }
    
    var positionStyle ={
            width: '76px',
            marginLeft: '0px'
        }

    if(this.props.LT.Position == ""){
        var positionStyle = {
            width: '10px',
            marginLeft: '66px'
        }
    } 
    
    if (this.props.ClientStates.Skin == "Hyundai"){
        var positionStyle ={
            width: '115px',
            height: '72px',
            marginLeft: '-15px',
            transform: 'skew(-20deg)',
            position: 'absolute',
            background: 'linear-gradient( -90deg, rgb(170,202,230) 0%, rgb(131,176,213) 100%)',
            textAlign: 'center',
            color: 'rgb(12 34 51)',
            fontFamily: 'hyundai-bold',
            fontSize: '57px',
            lineHeight: '78px',    
            zIndex: 1,
        }
        var positionTextStyle = {
            marginLeft: '10px',
            transform: 'skew(20deg)'
        }
    }

    var fadeBox = {
        position: 'absolute',
        bottom: '0px',
        height: '30px',
        width: '100%',
        background: 'linear-gradient( 0deg, rgb(170,202,230) 0%, rgba(131,176,213,0) 100%)',
        opacity: '0.3'
    }

    var lowerBox = <div className='hyundaiLowerRow'></div>

    if (this.props.LT.AdditionalInfo){
        var lowerBox = <div className='hyundaiLowerRow'><div className='hyundaiAdditionalInfo'>{this.props.LT.AdditionalInfo.toUpperCase()}</div></div>
    } else if (this.props.LT.DriverID && this.props.Session){
        var competitorsState = this.props.Session.Competitors
        var competitorIndex = competitorsState.findIndex( x => x.CompetitorId == this.props.LT.DriverID)
        if(competitorIndex > -1){
            var lowerBox = <div className='hyundaiLowerRow'><div className='hyundaiLowerTitle' style={{left: '40px'}}>best</div><div className='hyundaiLowerTime' style={{left: '100px'}}>{this.props.msToTime(competitorsState[competitorIndex].BestLaptime*1000) || ""}</div><div className='hyundaiLowerTitle' style={{left: '225px'}}>last</div><div className='hyundaiLowerTime' style={{left: '285px'}}>{this.props.msToTime(competitorsState[competitorIndex].LastLaptime*1000) || ""}</div></div>
            var position = this.props.Session.Competitors[competitorIndex].Position
        }
    } 

    if (this.props.LT.nat){
        var flag = <img className='hyundaiLTFlag' src={'/flags/'+this.props.LT.nat+'.png'}/>
    } else {
        var flag = <img className='hyundaiLTFlag' src={'/images/blank.png'}/>
    }


    if(this.props.ClientStates.Skin == 'Hyundai'){
        return (
            <div className={showClass} style={{scale: scale}}>
                <div className={otherShowClass} style={{marginLeft: left, marginTop: top}}>
                    <div style={positionStyle}>
                        <div style={positionTextStyle}>{position || this.props.LT.Position}</div>
                    </div>                
                    <div className='hyundaiUpperRow'>
                        <div style={fadeBox}></div>
                        <div className='hyundaiNameBox'>
                            {this.props.LT.Name.toUpperCase()}
                  
                        </div>
                    </div>
                    {lowerBox}
                </div>
            </div>
        )
    }else if(this.props.ClientStates.Skin == 'rotax'){
        return (
            <div className={showClass} style={{scale: scale}}>
                <div className={otherShowClass} style={{marginLeft: left, marginTop: top}}>              
                    <div className='RotaxUpperRow'>
                        <div className='RotaxNameBox'>
                            {this.props.LT.Name}
                        </div>
                    </div>
                    <div className='RotaxLowerRow'>
                        <div className='RotaxLowerTitle'>{this.props.LT.AdditionalInfo}</div>
                    </div>
                    <img style={{position: 'absolute', marginLeft: '-1px', marginTop: '-1px'}} src={'/images/RotaxLines.png'}></img>
                </div>
            </div>
        )
    } else {
        return (
            <div className={showClass} style={{scale: scale}}>
                <div className='LTShow' style={{marginLeft: left, marginTop: top}}>
                    <div className='positionBox' style={positionStyle}>
                        {this.props.LT.Position}
                    </div>
                    <div className='rowContainer'>                
                        <div className='upperRow'>
                            <div className='numberBox' style={numberStyle}>
                                {this.props.LT.Number}
                            </div>
                            <div className='nameBox'>
                                {this.props.LT.Name.toUpperCase()}
                            </div>
                        </div>
                        <div className='lowerRow'>
                            <div className='additionalInfo'>
                                {this.props.LT.AdditionalInfo.toUpperCase()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    
  }
}

export default LTView;
