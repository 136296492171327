import React, { PureComponent } from 'react';
import LTView from '../Graphics/lowerThird';
import './stylesheets/preview.css';

class LTPreview extends PureComponent {
    state = { 

     }

    render() {
        var showButton = <button attr1='LTShow' className='previewButton' onClick={this.props.controlSend}>Show</button>
        var LTQuestion = this.props.LT.Name
        if (LTQuestion == undefined){
            showButton = <button disabled attr1='LTShow' className='previewButton' onClick={this.props.controlSend}>Show</button>
        }

        var posEditor = {
            marginLeft: '0px',
            width: '40px'
        }

        var numEditor = {
            width: posEditor.width
        }

        var upperEditor = {

        }

        var lowerEditor = {

        }

        var editorDiv ={
            position: 'relavtive',
            marginTop: '75px',
            marginLeft: '10px',
            width: '350px'
        }
        
        var label ={
            marginLeft: '10px'
        }
        
        var clearButton = {
            position: 'relative',
            marginLeft: '364px',
            top: '-31px',
        }

        return(         
            <div className='box' style={{backgroundColor: this.props.color}}>
                <div className='boxHeader' attr='150px' onClick={this.props.collapse}>
                    LOWER THIRD PREVIEW
                </div>
                <div style={{width: '50px'}}>
                    {showButton}
                    <button attr1='LTHide' className='previewButton' onClick={this.props.controlSend}>Hide</button>
                </div>
                <LTView {...this.props} Controller={true}/>
                <div style={editorDiv}>
                    <label style={label} for='posEditor'>Pos:</label>
                    <input name='posEditor' type='number' style={posEditor} onChange={this.props.changeLT} attr1='pos' value={this.props.LT.Position}/>
                    <label style={label} for='upperEditor'>Upper:</label>
                    <input name='upperEditor' type='text' style={upperEditor} onChange={this.props.changeLT} attr1='upper' value={this.props.LT.Name}/>
                    <label style={label} for='numEditor'>Num:</label>
                    <input name='numEditor' type='number' style={numEditor} onChange={this.props.changeLT} attr1='num' value={this.props.LT.Number}/>
                    <label style={label} for='lowerEditor'>Lower:</label>
                    <input name='lowerEditor' type='text' style={lowerEditor} onChange={this.props.changeLT} attr1='lower' value={this.props.LT.AdditionalInfo}/>
                    <button attr1='LTClear' className='previewButton' style={clearButton} onClick={this.props.clearLT}>Clear</button> 
                </div>
                
            </div>
        )
    }
}

export default LTPreview;