import React, { PureComponent } from 'react';

class msukGridTile extends PureComponent {

    render() { 

        
        if (this.props.position % 2 === 0) {
            var side = '615px';
            var rowText = ''
        } else {
            var side = '0px';
            var row = (this.props.position+1)/2
            var rowText = 'ROW ' + row
        }

        //Calculates the margin shift for the current page to be visible
        var boost = (this.props.Controls.GridPage-1)*-600

        //Calculates if the tile should be below the current page (if highest number on page is lower than the tile position = boost the margin to ensure outside)
        if (parseInt(this.props.Controls.GridPage)*4 < parseInt(this.props.position)){
            boost = boost + 200
        }
        
        //Calculates if the tile should be above the current page (if lowest number on page is higher than the tile position = reduce the margin to ensure outside)
        if((parseInt(this.props.Controls.GridPage)-1)*4 >= parseInt(this.props.position)){
            boost = boost - 200
        }
        
        var tileSlot = {
            width: "656px",
            height: "245px",
            backgroundColor: "transparent",
            position: "absolute",
            overflow: "hidden",
            top: ((this.props.position-1)*150)+boost,
            marginLeft: side
        }

        if (this.props.position % 2 === 0) {
            tileSlot.top = tileSlot.top-85 +'px';
        } else {
            tileSlot.top = tileSlot.top + 'px'
        }

        var posBGIMG = {
            position: "absolute",
            marginTop: "105px",
            marginLeft: "40px",
            // width: "130px",
            height: "110px",
            // background: "RGB(39, 39, 39)",
            // transform: "skew(-10deg)",
            // borderRadius: "40px 0px 40px 0px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            fontSize: "50px",
            fontFamily: "msuk-Head",
            // boxShadow: "5px 5px 5px #00000091"
        }

        var posBG = {
            position: "absolute",
            marginTop: "105px",
            marginLeft: "52px",
            width: "130px",
            height: "110px",
            background: "RGB(4, 155, 123)",
            transform: "skew(-10deg)",
            // borderRadius: "40px 0px 40px 0px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            fontSize: "50px",
            fontFamily: "msuk-Head",
            // boxShadow: "5px 5px 5px #00000091"
        }

        var pos = {
            position: "absolute",
            marginTop: "105px",
            marginLeft: "52px",
            width: "130px",
            height: "110px",
            background: "transparent",
            // transform: "skew(-10deg)",
            // borderRadius: "40px 0px 40px 0px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            fontSize: "50px",
            fontFamily: "msuk-Head",
            // boxShadow: "5px 5px 5px #00000091"
        }


        var flag = {
            position: "absolute",
            width: "45px",
            height: "27px",
            marginTop: "109px",
            marginLeft: "544px",
            transform: "skew(-10deg)",
        }

        var driverNumber = {
            position: "absolute",
            marginTop: "109px",
            marginLeft: "589px",
            width: "45px",
            height: "27px",
            transform: "skew(-10deg)",
            background: "RGB(39, 39, 39)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            fontSize: "18px",
            fontFamily: "msuk-Head",
        }

        var driverName = {
            position: "absolute",
            marginLeft: "93.5px",
            marginTop: "136px",
            height: "30px",
            width: "530px",
            transform: "skew(-10deg)",
            background: "RGB(0, 168, 132)",
            display: "flex",
            alignItems: "center",
            justifyContent: "right",
            paddingRight: "5px",
            fontSize: "22px",
            fontFamily: "msuk-Main",
            color: "white",
        }

        var divider = {
            position: "absolute",
            marginLeft: "95.5px",
            marginTop: "166px",
            height: "4px",
            width: "530px",
            transform: "skew(-10deg)",
            background: "linear-gradient(270deg,RGB(63,80,71), RGB(110,106,106))",
            display: "flex",
            alignItems: "center",
            justifyContent: "right",
        }

        var qualifyTime = {
            position: "absolute",
            marginLeft: "100px",
            marginTop: "170px",
            height: "25px",
            width: "518px",
            transform: "skew(-10deg)",
            background: "RGB(0, 168, 132)",
            display: "flex",
            alignItems: "center",
            justifyContent: "right",
            paddingRight: "5px",
            fontSize: "18px",
            fontFamily: "msuk-Main",
            color: "white",
        }

        var teamName = {
            position: "absolute",
            marginLeft: "96px",
            marginTop: "195px",
            height: "20px",
            width: "518px",
            transform: "skew(-10deg)",
            background: "linear-gradient(270deg,RGBA(39,39,39,1.0), RGBA(110,106,106,0.0))",
            display: "flex",
            alignItems: "center",
            justifyContent: "right",
            paddingRight: "5px",
            fontSize: "12px",
            fontFamily: "msuk-Main",
            color: "white",
            letterSpacing: "1px"
        }

        var driverImg = {
            height: "225px"
        }

        var driverContainer = {
            position: "absolute",
            marginTop: "20px",
            marginLeft: "140px",
            overflow: "hidden",
            height: "195px",
            width: "100%"
        }

        var rowNum ={
            position: "absolute",
            marginTop: "215px",
            marginLeft: "40px",
            fontFamily: 'msuk-Main',
            fontSize: '20px',
            color: 'white'
        }

        var unskew ={
            transform: "skew(10deg)",
        }
            return ( 
                <div style={tileSlot} className="AnimateMargin">
                    <img style={flag} src={"./flags/"+this.props.driver.nat+".png"} onError={this.props.imageError}></img>
                    <div style={driverNumber}>
                        <div style={unskew}>{this.props.driver.CompetitorNumber}</div>
                    </div>
                    <div style={driverName}>
                        <div style={unskew}>{this.props.driver.CompetitorName.toUpperCase()}</div>
                    </div>
                    <div style={divider}></div>
                    <div style={qualifyTime}>
                        <div style={unskew}>{this.props.driver.teamName.toUpperCase()}</div>
                    </div>
                    <div style={teamName}>
                        <div style={unskew}>{this.props.driver.vehicle.toUpperCase()}</div>
                    </div>
                    <div style={posBG}></div>
                    <img style={posBGIMG} src={"../images/Black_BKC.png"} onError={this.props.imageError}></img>
                    <div style={pos}>{this.props.position}</div>
                    <div style={rowNum}>{rowText}</div>
                    
                    <div style={driverContainer}>
                        <img style={driverImg} src={this.props.driver.Picture+"-grid.png"} onError={this.props.imageError}></img>
                        {/* <img style={driverImg} src={"https://www.motorsport-timing.co.uk/media.motorsport-timing.co.uk/stream/-grid.png"} onError={this.props.imageError}></img> */}
                    </div>
                </div>
            );
        }
            
}
export default msukGridTile ;
