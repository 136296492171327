import React, { PureComponent } from 'react';

class ResultsRow extends PureComponent { 

    

    render() { 
        if (this.props.driver.seen == false || this.props.driver.Behind == "+DNS") {
            var opacity = 0
        } else {
            var opacity = 1
        }
		
		if (this.props.driver.NumberOfLaps < 1 && this.props.driver.GroupNumberOfLaps < 1){
            var opacity = 0
        } else if (this.props.Session.TSLGrouped == 1 && this.props.driver.GroupNumberOfLaps > 1) {
			var opacity = 1
		} else if (this.props.Session.State == "Running" && this.props.driver.NumberOfLaps < 1) {
			var opacity = 0
		} else if (this.props.Session.State == "ChequeredFlag" && this.props.driver.NumberOfLaps < 1) {
			var opacity = 0
		} else {
			var opacity = 1
		}

        if (this.props.driver.Behind == 0){
            var opacity = 0
        }

        
            if(this.props.Grouped == true){
                if(this.props.driver.GroupBehind){
                    var behind = this.props.driver.GroupBehind.replace(/\+/g,"")
                    var behind = this.props.driver.GroupBehind.replace(/-/g,"")
                } else if(this.props.driver.Behind){
                    var behind = this.props.driver.Behind.replace(/\+/g,"")
                    var behind = this.props.driver.Behind.replace(/-/g,"")
                }
            } else if(this.props.driver.Behind){
                var behind = this.props.driver.Behind.replace(/\+/g,"")
                var behind = this.props.driver.Behind.replace(/-/g,"")
            } else {
                var behind = ""
            }

        var extraInfo = <div></div>
        var nameWidth = "722px"
        //Check for vehicle information
        if (this.props.driver.vehicle){
            nameWidth = "644px"
            nameWidth = "340px"
            extraInfo = <div style={{left:'185px', width: '489px'}} className='extraInfo'><div className='extraInfoText' style={{fontSize: '25px'}}>{this.props.driver.vehicle}</div></div>
            extraInfo = <div style={{left:'-122px', width: '360px'}} className='extraInfo'><div className='extraInfoText' style={{fontSize: '25px'}}>{this.props.driver.vehicle}</div></div>
        }

        //Check for team information
        if (this.props.driver.teamName){
            nameWidth = "644px"
            nameWidth = "340px"
            extraInfo = <div style={{left:'185px', width: '489px'}} className='extraInfo'><div className='extraInfoText' style={{fontSize: '25px'}}>{this.props.driver.teamName}</div></div>
            extraInfo = <div style={{left:'-122px', width: '360px'}} className='extraInfo'><div className='extraInfoText' style={{fontSize: '25px'}}>{this.props.driver.teamName}</div></div>
        
        }

        //BP 24hr Classes
        var assName = this.props.driver.CompetitorName.indexOf('* ')
        if (assName != -1){
            var compName = this.props.driver.CompetitorName.replace('* ', '');
            var star_nbc = "#2faae1"
            var star_nfc = "#ffffff"
        }

        //Check Competitor Name
        var competitorName = this.props.driver.CompetitorName
        if(this.props.Controls.Name == "TEAM"){
            competitorName = this.props.driver.teamName
        }

        //Country Flags
        if (this.props.ClientStates.CountryFlags == true){
            if (this.props.driver.nat){
                var nationFlag = this.props.driver.nat   
            } else{
                var nationFlag = "blank"
            }
            var nationFlagClass = "resultNationFlag"
        } else {
            var nationFlag = "blank"
            var nationFlagClass = "resultNoNationFlag"
        }

        var posNumbersStyle = {
            fontFamily: "",
            color: "",
            backgroundColor: "",
        }

        if (this.props.ClientStates.Skin){
            if (this.props.ClientStates.Skin == "rotax"){
                posNumbersStyle = {
                    fontFamily: "rotax-Bold",
                    color: "#"+this.props.ClientStates.PrimaryColour,
                    backgroundColor: "#ffffffff",
                }
            }
        }

        var posStyle = {
            fontSize: '30px'
        }

        var numberStyle = {
            fontSize: '30px'
        }

        var extraInfoStyle = {
            fontSize: '25px',
            marginLeft: '747px'
        }

        var nameStyle = {
            fontSize: '30px'
        }


        if(this.props.driver.Position == 1){
            var rowBG = '-webkit-linear-gradient( -45deg, rgb(198,142,7) 0%, rgb(229,170,34) 40%, rgb(180,128,25) 58%, rgb(251,209,96) 100%)'
        } else {
            var rowBG = '-webkit-linear-gradient( -45deg, rgb(0, 168, 132) 0%, rgb(0, 168, 132) 40%, rgb(0, 168, 132) 58%, rgb(0, 168, 132) 100%)'
        }

        var row = {
            position: 'absolute',
            height: '48px',
            width: '746px',
            backgroundImage: rowBG,
            top: this.props.Controls.ResultsMargin + (parseInt(this.props.driver.Position)*59)+45, 
            opacity: opacity,
        }

        var posStyle = {
            position: 'absolute',
            fontFamily: 'msuk-Main',
            fontSize: '38px',
            color: 'white',
            width: '76px',
            textAlign: 'center',
        }

        var nameStyle = {
            position: 'absolute',
            fontFamily: 'msuk-Main',
            fontSize: '38px',
            color: 'white',
            marginLeft: '85px',
            textTransform: 'capitalize',
        }

        if(this.props.Session){
            return ( 
                <div className='AnimateMargin' style={row}>
                    <div style={posStyle}>
                        <div className='resultPositionText'>
                            {this.props.driver.Position}
                        </div>
                    </div>
                    <div style={nameStyle}>
                        <div className='resultNameText'>
                            {compName || competitorName.toUpperCase() || this.props.driver.CompetitorName.toUpperCase()}
                        </div>
                    </div>
                </div>
            );
        }        
        }
        
    }
export default ResultsRow ;