import React, { PureComponent } from 'react';
// import '../stylesheets/TowerRows.css';

class LiveChampsRows extends PureComponent {
    
    render() {
        if (this.props.boost == true){
            var marginBoost = 50
        } else {
            var marginBoost = 25
        }
        
        //Timing System switch for the data format
        if(this.props.ClientStates.TimingSystem == "TSL"){
            //Smart Naming Calculations START
            if(this.props.driver.Driver){
                var assName = this.props.driver.Driver.indexOf('* ')
                if (assName != -1){
                    var compName = this.props.driver.Driver.replace('* ', '');
                    var star_nbc = "#2faae1"
                    var star_nfc = "#ffffff"
                }
                if (this.props.driver.Driver.length > 20){
                    var competitorName = compName || this.props.driver.Driver
                    var run = 0
                    while (competitorName.length > 15){
                        var nameArray = competitorName.split(",").join(" ").split(" ")
                        
                        if (run > 0){
                            var nameArray = competitorName.split("-").join(" ").split(" ")
                            var nameStart = nameArray[0]
                            var firstName = nameStart.substring(0,2*run+1)
                        } else {
                            var firstName = nameArray[0][0]
                        }

                        nameArray.shift()
                        var competitorName = firstName+"."+nameArray
                        run++

                        competitorName = competitorName.replace(/,/g, ' ');

                        if (run > 5){ break }
                    }

                } else {
                    var competitorName = compName || this.props.driver.Driver

                }
            } else {
                var competitorName = "undefined"
            }
            //Smart Naming Calculations END

            //col4 content calculations START
            var col1Content = ""
            var col1BGColor = "rgb(0 0 0 / 75%)"
                if (this.props.driver.PositionChange > 0){
                    col1BGColor = "rgb(0 146 69 / 85%)"
                    col1Content = "▲"
                } else if (this.props.driver.PositionChange < 0){
                    col1BGColor = "rgb(193 39 45 / 85%)"
                    col1Content = "▼"
                } else {
                    col1Content = "◀▶"
                }
            //col4 class calculations END

            //opacity calc START
            var opacity = 1
                if (this.props.driver.Position == null){
                    opacity = 0
                }
            //opacity calc END

            return ( 
                <span>
                    <div className={"AnimateMargin"} style={{position: "absolute", opacity: opacity, top: this.props.margin[parseInt(this.props.driver.Position)]+marginBoost+"px", height: "35px"}}>
                        <div className={"liveChampsPosition"}>
                            <div className="liveChampsPositionNum">{this.props.driver.Position}</div>
                        </div>

                        <div id={this.props.indexRow + "_pos"} className="liveChampsCol1">
                            <div style={{backgroundColor: col1BGColor}} className="liveChampsPos">{ col1Content || this.props.driver.Position}</div>
                        </div>

                        <div className="liveChampsCol2">
                            <div className="liveChampsName">
                                <div className="name">{competitorName}</div>
                            </div>
                        </div>

                        <div className="liveChampsCol3">
                            <div className="liveChampsPoints">{this.props.driver.PointsToCount}</div>
                        </div>

                        {/* <div className={"liveChampsCol4"}>
                            <div className="liveChampsPointsChange">{this.props.driver.ChampPointsChange}</div>
                        </div> */}
                    </div>
                </span>
            );
        } else {
            //Smart Naming Calculations START
            if(this.props.driver.CompetitorName){
                var assName = this.props.driver.CompetitorName.indexOf('* ')
                if (assName != -1){
                    var compName = this.props.driver.CompetitorName.replace('* ', '');
                    var star_nbc = "#2faae1"
                    var star_nfc = "#ffffff"
                }
                if (this.props.driver.CompetitorName.length > 20){
                    var competitorName = compName || this.props.driver.CompetitorName
                    var run = 0
                    while (competitorName.length > 15){
                        var nameArray = competitorName.split(",").join(" ").split(" ")
                        
                        if (run > 0){
                            var nameArray = competitorName.split("-").join(" ").split(" ")
                            var nameStart = nameArray[0]
                            var firstName = nameStart.substring(0,2*run+1)
                        } else {
                            var firstName = nameArray[0][0]
                        }

                        nameArray.shift()
                        var competitorName = firstName+"."+nameArray
                        run++

                        competitorName = competitorName.replace(/,/g, ' ');

                        if (run > 5){ break }
                    }

                } else {
                    var competitorName = compName || this.props.driver.CompetitorName

                }
            } else {
                var competitorName = "undefined"
            }
            //Smart Naming Calculations END

            //Position Positive/Negative START
            if (this.props.driver.PositionChange > 0){
                var positionClass = undefined;
            } else if (this.props.driver.PositionChange < 0){
                var positionClass = undefined;
            } else {
                var positionClass = undefined;
            }
            //Position Positive/Negative END

            //col4 content calculations START
            var col1Content = ""
            var col1BGColor = "rgb(0 0 0 / 75%)"
                if (this.props.driver.ChampPositionChange > 0){
                    col1BGColor = "rgb(0 146 69 / 85%)"
                    col1Content = "▲"
                } else if (this.props.driver.ChampPositionChange < 0){
                    col1BGColor = "rgb(193 39 45 / 85%)"
                    col1Content = "▼"
                } else {
                    col1Content = "◀▶"
                }
            //col4 class calculations END

            //opacity calc START
            var opacity = 1
                if (this.props.driver.ChampPosition == null){
                    opacity = 0
                }
            //opacity calc END

            return ( 
                <span>
                    <div className={"AnimateMargin"} style={{position: "absolute", opacity: opacity, top: this.props.margin[parseInt(this.props.driver.ChampPosition)]+marginBoost+"px", height: "35px"}}>
                        <div className={"liveChampsPosition"}>
                            <div className="liveChampsPositionNum">{this.props.driver.ChampPosition}</div>
                        </div>

                        <div id={this.props.indexRow + "_pos"} className="liveChampsCol1">
                            <div style={{backgroundColor: col1BGColor}} className="liveChampsPos">{ col1Content || this.props.driver.ChampPosition}</div>
                        </div>

                        <div className="liveChampsCol2">
                            <div className="liveChampsName">
                                <div className="name">{competitorName}</div>
                            </div>
                        </div>

                        <div className="liveChampsCol3">
                            <div className="liveChampsPoints">{this.props.driver.ChampPoints}</div>
                        </div>

                        {/* <div className={"liveChampsCol4"}>
                            <div className="liveChampsPointsChange">{this.props.driver.ChampPointsChange}</div>
                        </div> */}
                    </div>
                </span>
            );
        }


    }
}


 
export default LiveChampsRows;