import React, { PureComponent } from 'react';

class CategoryExplainer extends PureComponent {
    state = { 

        }

    render() {

        var classArray = []

        if(this.props.Session){
            if(this.props.Session.Categories){
                var i = 0
                while (i < this.props.Session.Categories.length){
                    var classIndex = this.props.RaceClass.findIndex(x => x.name === this.props.Session.Categories[i])
                    
                    if (classIndex > -1){
                        classArray[i] = this.props.RaceClass[classIndex]
                    }

                    i++
                }
            }
        }

        var hide ={
            height: '0px',
            transition: 'height 1500ms cubic-bezier(0.25, 0.1, 0.17, 1.01) 0s',
            overflow: 'hidden',
            marginLeft: '360px',
            marginTop: '80px',
            position: 'absolute'
        }

        var show = {
            height: 30*classArray.length+'px',
            transition: 'height 1500ms cubic-bezier(0.25, 0.1, 0.17, 1.01) 0s',
            overflow: 'hidden',
            marginLeft: '360px',
            marginTop: '80px',
            position: 'absolute'
        }

        if(this.props.Controls.CategoryExplainer == true){
            var hideOrShow = show
        } else {
            var hideOrShow = hide
        }
        

        var bgStyle = {
            backgroundColor: 'rgb(0 0 0 / 65%)'

        }

        var categoryName = {
            paddingLeft : '10px',
            color: '#ffffff'
        }

        var row = {
            display: 'flex', // add this line
            padding: '5px 10px 5px 10px',
            backgroundColor: 'transparent',
            height: '20px',
            position: 'relative'
        }

        return ( 
            <div style={hideOrShow}>
                <div style={bgStyle}>
                    {classArray.map((category, index) => (
                        <div style={row}>
                            <div style={{backgroundColor: category.nbc, width: '10px', height: '90%'}}/>
                            <div style={categoryName}>{category.name}</div>
                        </div>   
                    ))}
                </div>
            </div>
        );
        } 
        
    // }
}
 
export default CategoryExplainer;