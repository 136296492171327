import React, { PureComponent } from 'react';
import Comparison from '../Graphics/comparison';
import './stylesheets/preview.css';

class Preview extends PureComponent {
    state = { 

     }

    render() {
        var showButton = <button attr1='compareShow' className='previewButton' onClick={this.props.controlSend}>Show</button>
        var compareQuestion = this.props.Controls.ComparisonLeadDriver
        if (compareQuestion == undefined){
            showButton = <button disabled attr1='compareShow' className='previewButton' onClick={this.props.controlSend}>Show</button>
        }
        
        return(         
            <div className='box' style={{backgroundColor: this.props.color}}>
                <div className='boxHeader' attr='150px' onClick={this.props.collapse}>
                    COMPARISON PREVIEW
                </div>
                {showButton}
                <button attr1='compareHide' className='previewButton' onClick={this.props.controlSend}>Hide</button>
                <Comparison {...this.props} controller={true}/>
                
                
            </div>
        )
    }
}

export default Preview;