import React, { PureComponent } from 'react';

class TimingRow extends PureComponent {

    render() {

    //check if the number is even
    if(this.props.driver.Position % 2 == 0) {
        var rowClass = "even"
    } else {
        var rowClass = "odd"
    }
	

    var battleButton = <button attr1="battle" attr2={this.props.driver.CompetitorId} onClick={this.props.battleCalc} className="timingBUTTON">BA</button>



    //check if in Battle
    var battleQuestion = this.props.Controls.BattleDrivers.indexOf(parseInt(this.props.driver.CompetitorId))
    if (battleQuestion > -1){
        var rowClass = "battle"
        var battleButton = <button attr1="battle" attr2={this.props.driver.CompetitorId} onClick={this.props.battleCalc} className="timingBUTTON">RE</button>
    }

    //check if in pit
    if (this.props.driver.InPit == true){
        var rowClass = "inPit"
    }

    //work out sector colours
    if (this.props.driver.CurrentLap && this.props.Session.Sectors){
        if (this.props.driver.CurrentLap.Sectors){
            var driverSectors = this.props.driver.CurrentLap.Sectors
            var i = 0
            var sectorColours = []
            while (i < driverSectors.length){
                var sectors = this.props.Session.Sectors
                var sectorIndex = sectors.findIndex( x => x.ID == parseInt(driverSectors[i].SectorID))
                if (this.props.Session.Sectors[sectorIndex].BestTime == driverSectors[i].SectorTime){
                    sectorColours[sectorIndex] = "fastestPink"
                } else if (driverSectors[i].SectorTime > driverSectors[i].BestTime){
                    sectorColours[sectorIndex] = "normal"
                } else if (driverSectors[i].SectorTime == driverSectors[i].BestTime){
                    sectorColours[sectorIndex] = "PositiveGreen"
                }
                i++
            }
        }
        
    }

    //check if onboard is active
    if(this.props.Controls.Onboard != true){
        var onboard = <button className="timingBUTTON" id={this.props.driver.CompetitorId + "Onboard"} attr1="onboard" attr2={this.props.driver.CompetitorId} onClick={this.props.controlSend}>OB</button>
    } else if (this.props.Controls.OnboardDriverID == this.props.driver.CompetitorId){
        var onboard = <button className="timingBUTTON" id={this.props.driver.CompetitorId + "Onboard"} attr1="onboard" attr2={this.props.driver.CompetitorId} onClick={this.props.controlSend}>OB</button>
    } else {
        var onboard = <button disabled className="timingBUTTON" id={this.props.driver.CompetitorId + "Onboard"} attr1="onboard" attr2={this.props.driver.CompetitorId} onClick={this.props.controlSend}>OB</button>
    }

    //check if enough laps for comparison
    var compareQuestion = this.props.driver.LapData
    if (compareQuestion != undefined){
        if (compareQuestion.length < 4){
        var compareButton = <button disabled attr1="compare" attr2={this.props.driver.CompetitorId} onClick={this.props.controlSend} className="timingBUTTON">CO</button>
        } else {
            var compareButton = <button attr1="compare" attr2={this.props.driver.CompetitorId} onClick={this.props.controlSend} className="timingBUTTON">CO</button>
        }
    } else {
        var compareButton = <button disabled attr1="compare" attr2={this.props.driver.CompetitorId} onClick={this.props.controlSend} className="timingBUTTON">CO</button>
    }

    var pointer = 'all'
	if(this.props.Session){
		if (this.props.driver.Behind == "+DNS"){
			var opacity = 0
            pointer = 'none'
		} else if (this.props.Session.TSLGrouped == 1 && this.props.driver.GroupNumberOfLaps > 1) {
			var opacity = 1
		} else if (this.props.Session.State == "Running" && this.props.driver.NumberOfLaps < 1) {
			var opacity = 0
            pointer = 'none'
		} else if (this.props.Session.State == "ChequeredFlag" && this.props.driver.NumberOfLaps < 1) {
			var opacity = 0
            pointer = 'none'
		} else {
            var opacity = 1
        }
	}    

    if(this.props.driver.CompetitorName == this.props.driver.teamName){
        var additional = this.props.driver.vehicle
    }

    var competitorName = this.props.driver.CompetitorName
    if(this.props.Controls.Name == "TEAM"){
        competitorName = this.props.driver.teamName
    }

    var LTInfo = {
        Left: '1300px',
        Top: '900px',
        Position: this.props.driver.GroupPosition || this.props.driver.Position || "",
        Number: this.props.driver.CompetitorNumber || "",
        Name: competitorName || "",
        AdditionalInfo: additional || this.props.driver.teamName || this.props.driver.vehicle || "",
        DriverID: this.props.driver.CompetitorId,
        nbc: this.props.driver.nbc,
        nfc: this.props.driver.nfc,
        nat: this.props.driver.nat || ""
    }

    LTInfo = JSON.stringify(LTInfo)

    if(this.props.Session.TSLGrouped == 1){
        if(this.props.Session.Sectors){
            if(this.props.driver.CurrentLap){
                return (
                    <div id={this.props.CompetitorId} className={"timingRow " + rowClass} style={{opacity: opacity, marginTop: (this.props.driver.GroupPosition*35)-35+"px"}}>
                        {battleButton}
                        <button className="timingBUTTON" id={this.props.driver.CompetitorId + "Expand"} attr1="expand" attr2={this.props.driver.CompetitorId} onClick={this.props.controlSend}>EX</button>
                        {onboard}
                        <button className="timingBUTTON" id={this.props.driver.CompetitorId + "LT"} attr1="lt" attr2={LTInfo} onClick={this.props.ltUpdate}>LT</button>
                        {compareButton}
                        <div className="timingPOS">{this.props.driver.GroupPosition}</div>
                        <div className="timingNUM" style={{backgroundColor: this.props.driver.nbc || "#2faae1", color: this.props.driver.nfc || "#fff"}}>{this.props.driver.CompetitorNumber}</div>
                        <div className="timingNAME">{competitorName}</div>
                        <div className="timingLAPS">{this.props.driver.GroupNumberOfLaps}</div>
                        <div className="timingLAST">{this.props.msToTime(this.props.driver.LastLaptime*1000)}</div>
                        <div className="timingBEHIND">{this.props.driver.GroupBehind}</div>
                        <div className="timingGAP">{this.props.driver.GroupGap}</div>
                        <div className="timingBEST">{this.props.msToTime(this.props.driver.GroupBestLaptime*1000)}</div>
                        <div className="timingPITS">{this.props.driver.ps}</div>
                        {this.props.driver.CurrentLap.Sectors.map((sector, index) => (
                                <div className={"timingSECTORS " + sectorColours[index]}>{this.props.msToTime(sector.SectorTime)}</div>
                            ))}
                        <img style={{opacity:0}}className="timingPhoto" attr1={this.props.driver.CompetitorId} src={this.props.driver.Picture+".png"} onError={this.props.controllerImageCheck}></img>
                    </div>
                )
            } else {
                return (
                    <div id={this.props.CompetitorId} className={"timingRow " + rowClass} style={{opacity: opacity, marginTop: (this.props.driver.GroupPosition*35)-35+"px"}}>
                        {battleButton}
                        <button className="timingBUTTON" id={this.props.driver.CompetitorId + "Expand"} attr1="expand" attr2={this.props.driver.CompetitorId} onClick={this.props.controlSend}>EX</button>
                        {onboard}
                        <button className="timingBUTTON" id={this.props.driver.CompetitorId + "LT"} attr1="lt" attr2={LTInfo} onClick={this.props.ltUpdate}>LT</button>
                        {compareButton}
                        <div className="timingPOS">{this.props.driver.GroupPosition}</div>
                        <div className="timingNUM" style={{backgroundColor: this.props.driver.nbc || "#2faae1", color: this.props.driver.nfc || "#fff"}}>{this.props.driver.CompetitorNumber}</div>
                        <div className="timingNAME">{competitorName}</div>
                        <div className="timingLAPS">{this.props.driver.GroupNumberOfLaps}</div>
                        <div className="timingLAST">{this.props.msToTime(this.props.driver.LastLaptime*1000)}</div>
                        <div className="timingBEHIND">{this.props.driver.GroupBehind}</div>
                        <div className="timingGAP">{this.props.driver.GroupGap}</div>
                        <div className="timingBEST">{this.props.msToTime(this.props.driver.GroupBestLaptime*1000)}</div>
                        <div className="timingPITS">{this.props.driver.ps}</div>
                        <img style={{opacity:0}}className="timingPhoto" attr1={this.props.driver.CompetitorId} src={this.props.driver.Picture+".png"} onError={this.props.controllerImageCheck}></img>
                    </div>
                )
            }
    
        } else {
            return ( 
                <div id={this.props.CompetitorId} className={"timingRow " + rowClass} style={{opacity: opacity, marginTop: (this.props.driver.GroupPosition*35)-35+"px"}}>
                    {battleButton}
                    <button className="timingBUTTON" id={this.props.driver.CompetitorId + "Expand"} attr1="expand" attr2={this.props.driver.CompetitorId} onClick={this.props.controlSend}>EX</button>
                    {onboard}
                    <button className="timingBUTTON" id={this.props.driver.CompetitorId + "LT"} attr1="lt" attr2={LTInfo} onClick={this.props.ltUpdate}>LT</button>
                    {compareButton}
                    <div className="timingPOS">{this.props.driver.GroupPosition}</div>
                    <div className="timingNUM" style={{backgroundColor: this.props.driver.nbc || "#2faae1", color: this.props.driver.nfc || "#fff"}}>{this.props.driver.CompetitorNumber}</div>
                    <div className="timingNAME">{competitorName}</div>
                    <div className="timingLAPS">{this.props.driver.GroupNumberOfLaps}</div>
                    <div className="timingLAST">{this.props.msToTime(this.props.driver.LastLaptime*1000)}</div>
                    <div className="timingBEHIND">{this.props.driver.GroupBehind}</div>
                    <div className="timingGAP">{this.props.driver.GroupGap}</div>
                    <div className="timingBEST">{this.props.msToTime(this.props.driver.GroupBestLaptime*1000)}</div>
                    <div className="timingPITS">{this.props.driver.ps}</div>
                    <img style={{opacity:0}}className="timingPhoto" attr1={this.props.driver.CompetitorId} src={this.props.driver.Picture+".png"} onError={this.props.controllerImageCheck}></img>
                </div>
            )
        }
    } else if (this.props.ClientStates.TimingSystem == "TSL" && this.props.ClientStates.Skin == "basic") {
        return ( 
            <div id={this.props.CompetitorId} className={"timingRow " + rowClass} style={{opacity: opacity, marginTop: (this.props.driver.Position*35)-35+"px", pointerEvents: pointer}}>
                {battleButton}
                <button className="timingBUTTON" id={this.props.driver.CompetitorId + "LT"} attr1="lt" attr2={LTInfo} onClick={this.props.ltUpdate}>LT</button>
                {compareButton}
                <div className="timingPOS">{this.props.driver.Position}</div>
                <div className="timingNUM" style={{backgroundColor: this.props.driver.nbc || "#2faae1", color: this.props.driver.nfc || "#fff"}}>{this.props.driver.CompetitorNumber}</div>
                <div className="timingNAME">{competitorName}</div>
                <div className="timingLAPS">{this.props.driver.NumberOfLaps}</div>
                <div className="timingLAST">{this.props.msToTime(this.props.driver.LastLaptime*1000)}</div>
                <div className="timingBEHIND">{this.props.driver.Behind}</div>
                <div className="timingGAP">{this.props.driver.Gap}</div>
                <div className="timingBEST">{this.props.msToTime(this.props.driver.BestLaptime*1000)}</div>
                <div className="timingPITS">{this.props.driver.ps}</div>
            </div>
        )
    } else {
        if(this.props.Session.Sectors){
            if(this.props.driver.CurrentLap){
                return (
                    <div id={this.props.CompetitorId} className={"timingRow " + rowClass} style={{opacity: opacity, marginTop: (this.props.driver.Position*35)-35+"px", pointerEvents: pointer}}>
                        {battleButton}
                        <button className="timingBUTTON" id={this.props.driver.CompetitorId + "Expand"} attr1="expand" attr2={this.props.driver.CompetitorId} onClick={this.props.controlSend}>EX</button>
                        {onboard}
                        <button className="timingBUTTON" id={this.props.driver.CompetitorId + "LT"} attr1="lt" attr2={LTInfo} onClick={this.props.ltUpdate}>LT</button>
                        {compareButton}
                        <div className="timingPOS">{this.props.driver.Position}</div>
                        <div className="timingNUM" style={{backgroundColor: this.props.driver.nbc || "#2faae1", color: this.props.driver.nfc || "#fff"}}>{this.props.driver.CompetitorNumber}</div>
                        <div className="timingNAME">{competitorName}</div>
                        <div className="timingLAPS">{this.props.driver.NumberOfLaps}</div>
                        <div className="timingLAST">{this.props.msToTime(this.props.driver.LastLaptime*1000)}</div>
                        <div className="timingBEHIND">{this.props.driver.Behind}</div>
                        <div className="timingGAP">{this.props.driver.Gap}</div>
                        <div className="timingBEST">{this.props.msToTime(this.props.driver.BestLaptime*1000)}</div>
                        <div className="timingPITS">{this.props.driver.ps}</div>
                        {this.props.driver.CurrentLap.Sectors.map((sector, index) => (
                                <div className={"timingSECTORS " + sectorColours[index]}>{this.props.msToTime(sector.SectorTime)}</div>
                            ))}
                        <img style={{opacity:0}}className="timingPhoto" attr1={this.props.driver.CompetitorId} src={this.props.driver.Picture+".png"} onError={this.props.controllerImageCheck}></img>
                    </div>
                )
            } else {
                return (
                    <div id={this.props.CompetitorId} className={"timingRow " + rowClass} style={{opacity: opacity, marginTop: (this.props.driver.Position*35)-35+"px", pointerEvents: pointer}}>
                        {battleButton}
                        <button  className="timingBUTTON" id={this.props.driver.CompetitorId + "Expand"} attr1="expand" attr2={this.props.driver.CompetitorId} onClick={this.props.controlSend}>EX</button>
                        {onboard}
                        <button  className="timingBUTTON" id={this.props.driver.CompetitorId + "LT"} attr1="lt" attr2={LTInfo} onClick={this.props.ltUpdate}>LT</button>
                        {compareButton}
                        <div className="timingPOS">{this.props.driver.Position}</div>
                        <div className="timingNUM" style={{backgroundColor: this.props.driver.nbc || "#2faae1", color: this.props.driver.nfc || "#fff"}}>{this.props.driver.CompetitorNumber}</div>
                        <div className="timingNAME">{competitorName}</div>
                        <div className="timingLAPS">{this.props.driver.NumberOfLaps}</div>
                        <div className="timingLAST">{this.props.msToTime(this.props.driver.LastLaptime*1000)}</div>
                        <div className="timingBEHIND">{this.props.driver.Behind}</div>
                        <div className="timingGAP">{this.props.driver.Gap}</div>
                        <div className="timingBEST">{this.props.msToTime(this.props.driver.BestLaptime*1000)}</div>
                        <div className="timingPITS">{this.props.driver.ps}</div>
                        <img style={{opacity:0}}className="timingPhoto" attr1={this.props.driver.CompetitorId} src={this.props.driver.Picture+".png"} onError={this.props.controllerImageCheck}></img>
                    </div>
                )
            }
    
        } else if (this.props.ClientStates.TimingSystem == "AC") {
            return ( 
                <div id={this.props.CompetitorId} className={"timingRow " + rowClass} style={{opacity: opacity, marginTop: (this.props.driver.Position*35)-35+"px", pointerEvents: pointer}}>
                    {battleButton}
                    <button className="timingBUTTON" id={this.props.driver.CompetitorId + "Expand"} attr1="expand" attr2={this.props.driver.CompetitorId} onClick={this.props.controlSend}>EX</button>
                    {onboard}
                    <button className="timingBUTTON" id={this.props.driver.CompetitorId + "LT"} attr1="lt" attr2={LTInfo} onClick={this.props.ltUpdate}>DA</button>
                    {compareButton}
                    <div className="timingPOS">{this.props.driver.Position}</div>
                    <div className="timingNUM" style={{backgroundColor: this.props.driver.nbc || "#2faae1", color: this.props.driver.nfc || "#fff"}}>{this.props.driver.CompetitorNumber}</div>
                    <div className="timingNAME">{competitorName}</div>
                    <div className="timingLAPS">{this.props.driver.NumberOfLaps}</div>
                    <div className="timingLAST">{this.props.msToTime(this.props.driver.LastLaptime*1000)}</div>
                    <div className="timingBEHIND">{this.props.driver.Behind}</div>
                    <div className="timingGAP">{this.props.driver.Gap}</div>
                    <div className="timingBEST">{this.props.msToTime(this.props.driver.BestLaptime*1000)}</div>
                    <div className="timingPITS">{this.props.driver.ps}</div>
                    <div className="timingPITS">{this.props.driver.Gear}</div>
                    <div className="timingPITS">{this.props.driver.RPM}</div>
                    <div className="timingPITS">{this.props.driver.MPH}</div>
                    <div className="timingPITS">{this.props.driver.Spline}</div>
                    <img style={{opacity:0}}className="timingPhoto" attr1={this.props.driver.CompetitorId} src={this.props.driver.Picture+".png"} onError={this.props.controllerImageCheck}></img>
                </div>
            )
        } else {
            return ( 
                <div id={this.props.CompetitorId} className={"timingRow " + rowClass} style={{opacity: opacity, marginTop: (this.props.driver.Position*35)-35+"px", pointerEvents: pointer}}>
                    {battleButton}
                    <button className="timingBUTTON" id={this.props.driver.CompetitorId + "Expand"} attr1="expand" attr2={this.props.driver.CompetitorId} onClick={this.props.controlSend}>EX</button>
                    {onboard}
                    <button className="timingBUTTON" id={this.props.driver.CompetitorId + "LT"} attr1="lt" attr2={LTInfo} onClick={this.props.ltUpdate}>LT</button>
                    {compareButton}
                    <div className="timingPOS">{this.props.driver.Position}</div>
                    <div className="timingNUM" style={{backgroundColor: this.props.driver.nbc || "#2faae1", color: this.props.driver.nfc || "#fff"}}>{this.props.driver.CompetitorNumber}</div>
                    <div className="timingNAME">{competitorName}</div>
                    <div className="timingLAPS">{this.props.driver.NumberOfLaps}</div>
                    <div className="timingLAST">{this.props.msToTime(this.props.driver.LastLaptime*1000)}</div>
                    <div className="timingBEHIND">{this.props.driver.Behind}</div>
                    <div className="timingGAP">{this.props.driver.Gap}</div>
                    <div className="timingBEST">{this.props.msToTime(this.props.driver.BestLaptime*1000)}</div>
                    <div className="timingPITS">{this.props.driver.ps}</div>
                    <img style={{opacity:0}}className="timingPhoto" attr1={this.props.driver.CompetitorId} src={this.props.driver.Picture+".png"} onError={this.props.controllerImageCheck}></img>
                </div>
            )
        }
    }
    

        
    }
}

export default TimingRow;