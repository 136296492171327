import React, { PureComponent } from 'react';
import TimingRow from './timingRow';
import './stylesheets/timing.css';

class Timing extends PureComponent {
    state = { 
        rMonitorRaceDuration: 9999,
        rMonitorSessionId: null
     }

    render() {
        //Clock data calculations START
        if (this.props.Session.SD == "rMonitor - Laps"){
            var competitorsState = this.props.Session.Competitors
            var driverIndex = competitorsState.findIndex( x => x.Position == 1)
            if (driverIndex > -1){
                var leaderLaps = competitorsState[driverIndex].NumberOfLaps
            } else {
                var leaderLaps = 0 
            }

            var totalLaps = parseInt(leaderLaps) + parseInt(this.props.timeRemaining)

            if (this.state.rMonitorRaceDuration == 9999 || this.state.rMonitorRaceDuration < totalLaps || this.props.Session.SessionId != this.state.rMonitorSessionId){
                this.setState({
                    rMonitorRaceDuration: totalLaps,
                    rMonitorSessionId: this.props.Session.SessionId
                })
            }
            
            if(leaderLaps == this.state.rMonitorRaceDuration || this.props.Session.State == "ChequeredFlag"){
                var clockData = "FINISHED"
            } else if (this.props.Session.SD == "rMonitor - Laps" && this.props.timeRemaining == "0"){
                var clockData = "FINISHED"
            } else if (leaderLaps == 0 && this.props.Session.State != "Running"){
                var clockData = "FORMATION"
            } else if (leaderLaps == 0 && this.props.Session.State == "Running"){
                var clockData = "LAP "+ 1 + " / " + this.state.rMonitorRaceDuration
            } else if (parseInt(leaderLaps)+1 == this.state.rMonitorRaceDuration ){
                var clockData = "FINAL LAP"
            } else {
                var clockData = "LAP "+ (parseInt(leaderLaps)+1) + " / " + this.state.rMonitorRaceDuration
            }
        } else if (this.props.Session.SD == "rMonitor - Time") {
            var clockData = this.props.timeRemaining
        } else if (this.props.ClientStates.TimingSystem == "TimeService" && this.props.Session.LengthMode == 0){
            var clockData = this.props.timeRemaining + " +" + this.props.Session.SD[this.props.Session.SD.length-2] + "L"
        } else {
            var addLapIndex = this.props.Session.SD.indexOf("+")
            var lapsIndex = this.props.Session.SD.indexOf("Laps")
    
            if (this.props.Session.Last == true){
                var clockData = "FINAL LAP"
            } else if (this.props.Session.End == true) {
                var clockData = "FINISHED"
            } else if (this.props.Session.State == "Formation"){
                var clockData = "FORMATION"
            } else if (this.props.Session.State == "Starting"){
                var clockData = this.props.timeRemaining
            } else if (lapsIndex > -1 ) {
                if(this.props.Session.Rd){
                    console.log(this.props.Session.Rd)
                    var remaining = this.props.Session.Rd.replace("L", "")
                } else {
                    var remaining = ""
                }
                
                var total = parseInt(this.props.Session.SD.replace(" Laps", ""))
                if (this.props.ClientStates.TimingSystem == "Speedhive"){
                    if (remaining != "NAN"){
                        var clockData =   "LAP " + remaining + " / " + total;
                    } else {
                        var clockData =  "";
                    }
                } else if (this.props.ClientStates.TimingSystem == "Apex"){
					var competitorsState = this.props.Session.Competitors
					var driverIndex = competitorsState.findIndex( x => x.Position == 1)
						if (driverIndex > -1){
							var leaderLaps = parseInt(competitorsState[driverIndex].NumberOfLaps)+1
						} else {
							var leaderLaps = 0 
						}
					
					if (remaining != "NAN"){
                        if (total - parseInt(leaderLaps) == 0){
                            var clockData = "FINAL LAP"
                        } else if (parseInt(leaderLaps) == total){
                            var clockData = "FINISHED"
                        } else {
                            var clockData =  "LAP " + leaderLaps + " / " + total ; 
                        }
                    } else {
                        var clockData =  "";
                    }
				} else {
                    if (remaining != "NAN"){
                        if (parseInt(remaining) == 1){
                            var clockData = "FINAL LAP"
                        } else if (parseInt(remaining) == 0){
                            var clockData = "FINISHED"
                        } else {
                            remaining = total - remaining+1
                            var clockData =  "LAP " + remaining + " / " + total ; 
                        }
                    } else {
                        var clockData =  "";
                    }
                }
                
            } else if (addLapIndex > -1){
                var clockData = this.props.timeRemaining + " +" + this.props.Session.SD[this.props.Session.SD.length-2] + "L"
            } else {
                var clockData = this.props.timeRemaining
            }
        }
        


        if (this.props.ClientStates.TimingSystem == "TSL" && this.props.Session.Sectors){
            //Sort grid positions
            function sectorsSort( a, b ) {
                if ( a.Name < b.Name ){
                return -1;
                }
                if ( a.Name > b.Name ){
                return 1;
                }
                return 0;
            }
  
            //var sectors = this.props.Session.Sectors.sort(sectorsSort);
            var sectors = []
        }

        if (this.props.Session){
            if (this.props.ClientStates.TimingSystem == "TSL" && this.props.ClientStates.Skin == "basic") {
                return ( 
                    <div className='timingDisplay'>
                        <div className='timingHeader'>
                            <div className="timingClock" style={{width: '54px'}}>{clockData}</div>
                            <div className="headPOS">P</div>
                            <div className="headNUM">#</div>
                            <div className="headNAME">NAME</div>
                            <div className="headLAPS">LAPS</div>
                            <div className="headLAST">LAST</div>
                            <div className="headBEHIND">GAP</div>
                            <div className="headGAP">DIFF</div>
                            <div className="headBEST">BEST</div>
                            <div className="headPITS">PITS</div>
                            
                        </div>
                        {this.props.Session.Competitors.map((driver, index) => (
                            <TimingRow pages={false} grouped={true} driver={driver} indexRow={index} margin={this.props.Controls.TowerMargin} {...this.props} key={index}/>
                        ))}
                    </div>
                )  
            } else if (this.props.ClientStates.TimingSystem == "TSL" && this.props.Session.Sectors){
                if (this.props.Session.Sectors.length > 1){
                    return ( 
                        <div className='timingDisplay'>
                            <div className='timingHeader'>
                                <div className="timingClock">{clockData}</div>
                                <div className="headPOS">P</div>
                                <div className="headNUM">#</div>
                                <div className="headNAME">NAME</div>
                                <div className="headLAPS">LAPS</div>
                                <div className="headLAST">LAST</div>
                                <div className="headBEHIND">GAP</div>
                                <div className="headGAP">DIFF</div>
                                <div className="headBEST">BEST</div>
                                <div className="headPITS">PITS</div>
                                {sectors.map((sector, index) => (
                                    <div className="headSector">{sector.Name.toUpperCase()}</div>
                                ))}
                            </div>
                        {this.props.Session.Competitors.map((driver, index) => (
                            <TimingRow pages={false} grouped={true} driver={driver} indexRow={index} margin={this.props.Controls.TowerMargin} {...this.props} key={index}/>
                        ))}
                        </div>
                    )
                } else {
                    return ( 
                        <div className='timingDisplay'>
                            <div className='timingHeader'>
                                <div className="timingClock">{clockData}</div>
                                <div className="headPOS">P</div>
                                <div className="headNUM">#</div>
                                <div className="headNAME">NAME</div>
                                <div className="headLAPS">LAPS</div>
                                <div className="headLAST">LAST</div>
                                <div className="headBEHIND">GAP</div>
                                <div className="headGAP">DIFF</div>
                                <div className="headBEST">BEST</div>
                                <div className="headPITS">PITS</div>
                                
                            </div>
                            {this.props.Session.Competitors.map((driver, index) => (
                                <TimingRow pages={false} grouped={true} driver={driver} indexRow={index} margin={this.props.Controls.TowerMargin} {...this.props} key={index}/>
                            ))}
                        </div>
                    )      
                }
            } else if (this.props.ClientStates.TimingSystem == "AC"){
                return ( 
                    <div className='timingDisplay'>
                        <div className='timingHeader'>
                            <div className="timingClock">{clockData}</div>
                            <div className="headPOS">P</div>
                            <div className="headNUM">#</div>
                            <div className="headNAME">NAME</div>
                            <div className="headLAPS">LAPS</div>
                            <div className="headLAST">LAST</div>
                            <div className="headBEHIND">GAP</div>
                            <div className="headGAP">DIFF</div>
                            <div className="headBEST">BEST</div>
                            <div className="headPITS">PITS</div>
                            <div className="headPITS">GEAR</div>
                            <div className="headPITS">RPM</div>
                            <div className="headPITS">MPH</div>
                            <div className="headPITS">%</div>
                        </div>
                        {this.props.Session.Competitors.map((driver, index) => (
                            <TimingRow pages={false} grouped={true} driver={driver} indexRow={index} margin={this.props.Controls.TowerMargin} {...this.props} key={index}/>
                        ))}
                    </div>
                )  
            } else {
                return ( 
                    <div className='timingDisplay'>
                        <div className='timingHeader'>
                            <div className="timingClock">{clockData}</div>
                            <div className="headPOS">P</div>
                            <div className="headNUM">#</div>
                            <div className="headNAME">NAME</div>
                            <div className="headLAPS">LAPS</div>
                            <div className="headLAST">LAST</div>
                            <div className="headBEHIND">GAP</div>
                            <div className="headGAP">DIFF</div>
                            <div className="headBEST">BEST</div>
                            <div className="headPITS">PITS</div>
                            
                        </div>
                        {this.props.Session.Competitors.map((driver, index) => (
                            <TimingRow pages={false} grouped={true} driver={driver} indexRow={index} margin={this.props.Controls.TowerMargin} {...this.props} key={index}/>
                        ))}
                    </div>
                )  
            }
            
        } else {
            return ( 
                <div className='timingDisplay'>
                    <div className='timingHeader'>
                        <div className="timingClock">{clockData}</div>
                        <div className="headPOS">P</div>
                        <div className="headNUM">#</div>
                        <div className="headNAME">NAME</div>
                        <div className="headLAPS">LAPS</div>
                        <div className="headLAST">LAST</div>
                        <div className="headBEHIND">GAP</div>
                        <div className="headGAP">DIFF</div>
                        <div className="headBEST">BEST</div>
                        <div className="headPITS">PITS</div>
                        
                    </div>
                {this.props.Session.Competitors.map((driver, index) => (
                    <TimingRow pages={false} grouped={true} driver={driver} indexRow={index} margin={this.props.Controls.TowerMargin} {...this.props} key={index}/>
                ))}
                </div>
            )
        }
        


        
    }
}

export default Timing;