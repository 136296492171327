import React, { useEffect, useRef, useState } from 'react';
import io from 'socket.io-client';

const WebRTCStreamer = () => {
    const socketRef = useRef(null);
    const pcRef = useRef(null);
    const [connectionStatus, setConnectionStatus] = useState('red');
    const retryCount = useRef(0);
    const maxRetries = 5; // Max retries before stopping attempts
    const retryInterval = 5000; // Time in milliseconds before retrying

    const setupWebRTCConnection = () => {
        if (pcRef.current) {
            pcRef.current.close();
        }

        pcRef.current = new RTCPeerConnection({
            iceServers: [
                { urls: 'stun:stun.l.google.com:19302' },
                {
                    urls: 'turn:77.68.88.157:3488', // Replace with your TURN server URL
                    username: 'alpha',    // Replace with your TURN server username
                    credential: 'knights' // Replace with your TURN server credential
                }]
        });

        const pc = pcRef.current;

        navigator.mediaDevices.getUserMedia({ video: true })
            .then(stream => {
                stream.getTracks().forEach(track => pc.addTrack(track, stream));
                return pc.createOffer();
            })
            .then(offer => pc.setLocalDescription(offer))
            .then(() => socketRef.current.emit('webrtc_offer', { 'offer': pc.localDescription }))
            .catch(error => console.error('Error:', error));

        pc.onicecandidate = event => {
            if (event.candidate) {
                socketRef.current.emit('webrtc_ice_candidate', { candidate: event.candidate });
            }
        };

        pc.onconnectionstatechange = () => {
            setConnectionStatus(getConnectionStatus(pc.connectionState));
            if (['disconnected', 'failed', 'closed'].includes(pc.connectionState)) {
                retryConnection();
            }
        };
    };

    const retryConnection = () => {
        if (retryCount.current < maxRetries) {
            setTimeout(() => {
                setupWebRTCConnection();
                retryCount.current += 1;
            }, retryInterval);
        } else {
            console.log('Max reconnection attempts reached.');
        }
    };

    const getConnectionStatus = (state) => {
        switch(state) {
            case 'connected': return 'green';
            case 'connecting': return 'yellow';
            default: return 'red';
        }
    };

    useEffect(() => {
        socketRef.current = io("https://controls.alphalive.co.uk");

        socketRef.current.on('newConnection', () => {
            setupWebRTCConnection();
        });

        // When receiving an answer
        socketRef.current.on('webrtc_answer', async (data) => {
            const pc = pcRef.current;
            if (pc && pc.signalingState === "have-local-offer") {
                await pc.setRemoteDescription(new RTCSessionDescription(data.answer));
            } else {
                console.error("Incorrect signaling state for setting remote description");
            }
        });

        // socketRef.current.on('webrtc_answer', async (data) => {
        //     const pc = pcRef.current;
        //     if (pc) {
        //         await pc.setRemoteDescription(new RTCSessionDescription(data.answer));
        //     }
        // });

        setupWebRTCConnection();

        return () => {
            if (pcRef.current) {
                pcRef.current.close();
            }
            if (socketRef.current) {
                socketRef.current.disconnect();
            }
        };
    }, []);

    return (
        <div className='box' style={{height: "30px"}}>
            <div className='boxHeader'>
                ONBOARD CAMERA STATUS : <div style={{
                marginLeft: '250px',
                marginTop: '-19px',
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                backgroundColor: connectionStatus,
            }} />
            </div>
        </div>
    );
};

export default WebRTCStreamer;
