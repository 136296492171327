import React, { PureComponent } from 'react';

class Gb3ResultsRow extends PureComponent { 

    render() { 
        if (this.props.driver.seen == false || this.props.driver.Behind == "+DNS") {
            var opacity = 0
        } else {
            var opacity = 1
        }
		
		if (this.props.driver.NumberOfLaps < 1 && this.props.driver.GroupNumberOfLaps < 1){
            var opacity = 0
        } else if (this.props.Session.TSLGrouped == 1 && this.props.driver.GroupNumberOfLaps > 1) {
			var opacity = 1
		} else if (this.props.Session.State == "Running" && this.props.driver.NumberOfLaps < 1) {
			var opacity = 0
		} else if (this.props.Session.State == "ChequeredFlag" && this.props.driver.NumberOfLaps < 1) {
			var opacity = 0
		} else {
			var opacity = 1
		}

        if (this.props.driver.Behind == 0){
            var opacity = 0
        }

        
            if(this.props.Grouped == true){
                if(this.props.driver.GroupBehind){
                    var behind = this.props.driver.GroupBehind.replace(/\+/g,"")
                    var behind = this.props.driver.GroupBehind.replace(/-/g,"")
                } else if(this.props.driver.Behind){
                    var behind = this.props.driver.Behind.replace(/\+/g,"")
                    var behind = this.props.driver.Behind.replace(/-/g,"")
                }
            } else if(this.props.driver.Behind){
                var behind = this.props.driver.Behind.replace(/\+/g,"")
                var behind = this.props.driver.Behind.replace(/-/g,"")
            } else {
                var behind = ""
            }

        var extraInfo = <div></div>
        var nameWidth = "722px"
        //Check for vehicle information
        if (this.props.driver.vehicle){
            nameWidth = "459px"
            extraInfo = <div className='gb3ExtraInfo'><div className='extraInfoText'>{this.props.driver.vehicle}</div></div>
        }

        //Check for team information
        if (this.props.driver.teamName){
            nameWidth = "459px"
            extraInfo = <div className='gb3ExtraInfo'><div className='extraInfoText'>{this.props.driver.teamName}</div></div>
        }

        //BP 24hr Classes
        var assName = this.props.driver.CompetitorName.indexOf('* ')
        if (assName != -1){
            var compName = this.props.driver.CompetitorName.replace('* ', '');
            var star_nbc = "#2faae1"
            var star_nfc = "#ffffff"
        }

        //Check Competitor Name
        var competitorName = this.props.driver.CompetitorLastName || this.props.driver.CompetitorName
        if(this.props.Controls.Name == "TEAM"){
            competitorName = this.props.driver.teamName
        }

        //Country Flags
        if (this.props.ClientStates.CountryFlags == true){
            if (this.props.driver.nat){
                var nationFlag = this.props.driver.nat   
            } else{
                var nationFlag = "blank"
            }
            var nationFlagClass = "resultNationFlag"
        } else {
            var nationFlag = "blank"
            var nationFlagClass = "resultNoNationFlag"
        }

        if(this.props.Session){
            if (this.props.Grouped == true){
                return ( 
                    <div className='resultRow AnimateMargin' style={{marginTop: this.props.Controls.ResultsMargin + parseInt(this.props.driver.GroupPosition)*50, opacity: opacity }}>
                        <div className='resultPositionBox'>
                            <div className='resultPositionText'>
                                {this.props.driver.GroupPosition}
                            </div>
                        </div>
                        <div className='resultNumberBox' style={{backgroundColor: star_nbc || this.props.driver.nbc || "#2faae1", color: star_nfc || this.props.driver.nfc || "#fff" }}>
                            <div className='numberText'>
                                {this.props.driver.CompetitorNumber}
                            </div>
                        </div>
                        <div className='resultNameBox' style={{width: nameWidth}}>
                            <img className={nationFlagClass} src={"flags/"+nationFlag+".png"} onError={this.props.imageError}></img>
                            <div className='resultNameText'>
                                {compName || competitorName || this.props.driver.CompetitorName}
                            </div>
                        </div>
                        {extraInfo}
                        <div className='resultGapBox'>
                            <div className='resultGapText'>
                                +{behind.replace(/\+/g,"")}
                            </div>
                        </div>
                    </div>
                );
            } else {
                return ( 
                    <div className='gb3ResultRow AnimateMargin' style={{marginTop: this.props.Controls.ResultsMargin + parseInt(this.props.driver.Position)*45 + 55, opacity: opacity }}>
                        <div className='gb3ResultPositionBox'>
                            <div className='resultPositionText'>
                                {this.props.driver.Position}
                            </div>
                        </div>
                        <div className='gb3ResultNumberBox' style={{backgroundColor: star_nbc || this.props.driver.nbc || "#2faae1", color: star_nfc || this.props.driver.nfc || "#fff" }}>
                            <div className='numberText'>
                                {this.props.driver.CompetitorNumber}
                            </div>
                        </div>
                        <div className='gb3ResultNameBox' style={{width: nameWidth}}>
                            <img className={nationFlagClass} src={"flags/"+nationFlag+".png"} onError={this.props.imageError}></img> 
                            <div className='resultNameText'>
                                { compName || competitorName.toUpperCase() || this.props.driver.CompetitorName}
                            </div>
                        </div>
                        {extraInfo}
                        <div className='gb3ResultGapBox'>
                            <div className='resultGapText'>
                                {behind.replace(/\+/g,"")}
                            </div>
                        </div>
                    </div>
                );
            }  
        }        
        }
        
    }
export default Gb3ResultsRow ;