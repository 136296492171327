import React, { Component } from 'react';
import './stylesheets/LT.css';
import './stylesheets/hyundaiOnboardData.css';

class DataView extends Component {
  render() {
    var maxRPM = 9000
    var currentRPM = 900
    var driverName = ""
    var currentSpeed = 0
    var currentGear = 0

     if(this.props.Session){
        if(this.props.Session.Competitors.length > 0){
            if(this.props.LT){
                var competitorsState = this.props.Session.Competitors
                var driverIndex = competitorsState.findIndex( x => x.CompetitorId == this.props.LT.DriverID)
                driverName = this.props.LT.Name
                if(competitorsState[driverIndex]){
                    currentSpeed = competitorsState[driverIndex].MPH
                    currentRPM = competitorsState[driverIndex].RPM
                    currentGear = competitorsState[driverIndex].Gear-1

                    var gearLess3 =  parseInt(currentGear)-3
                    var gearLess2 = parseInt(currentGear)-2
                    var gearLess1 =parseInt(currentGear)-1
                    var gearUp1 = parseInt(currentGear)+1
                    var gearUp2 = parseInt(currentGear)+2
                    var gearUp3 = parseInt(currentGear)+3

                    if(gearLess3 < 1){
                        gearLess3 = 'N'
                    }
                    if(gearLess2 < 1){
                        gearLess3 = 'R'
                        gearLess2 = 'N'
                    }
                    if(gearLess1 < 1){
                        gearLess3 = ''
                        gearLess2 = 'R'
                        gearLess1 = 'N'
                    }

                    if(gearUp3 > 6){
                        gearUp3 = ''
                    }

                    if(gearUp2 > 6){
                        gearUp3 = ''
                        gearUp2 = ''
                    }

                    if(gearUp1 > 6){
                        gearUp3 = ''
                        gearUp2 = ''
                        gearUp1 = ''
                    }
                    
                    if(currentGear == 0){
                        gearLess3 = '-'
                        gearLess2 = '-'
                        gearLess1 = 'R'
                        currentGear = 'N'
                        gearUp3 = '3'
                        gearUp2 = '2'
                        gearUp1 = '1'
                    }

                    if(currentGear == -1){
                      gearLess3 = '-'
                      gearLess2 = '-'
                      gearLess1 = '-'
                      currentGear = 'R'
                      gearUp3 = 'N'
                      gearUp2 = '1'
                      gearUp1 = '2'
                  }
                }
                
            }
        }
    }

    var revPercent = currentRPM / maxRPM *100

    var revDeg = (revPercent / 100) * 180

    var revDeg = 180 - revDeg




    // Style definitions
    if(this.props.Controller == true){
      var containerStyle = {
        position: 'relative',
        width: '180px',
        marginLeft: '200px',
        marginTop: '0px',
        transform: 'scale(1)',
        color: 'white',
      };

      var hide = {
        position: 'relative',
        height: '180px',
        overflow: 'hidden'
      }
    } else {
      var containerStyle = {
        position: 'relative',
        width: '180px',
        marginLeft: '1600px',
        marginTop: '-440px',
        transform: 'scale(1)',
        color: 'white',
        };

      if (this.props.Controls.DataLT != true){
        var hide = {
          position: 'relative',
          width: '0px',
          height: '48px',
          overflow: 'hidden',
          transition: 'height 750ms, width 750ms',
          transitionDelay: '0ms, 1500ms'
        }
      } else {
        var hide = {
          position: 'relative',
          height: '48px',
          overflow: 'hidden',
          width: '180px',
          height: '200px',
          overflow: 'hidden',
          transition: 'height 750ms, width 750ms',
          transitionDelay: '1500ms, 0ms'
        }
      }
      
    }

    const dataHeader = {
      height: '48px',
      width: '180px',
      background: 'linear-gradient( 180deg, rgb(255,255,255) 0%, rgb(255,255,255) 70%, rgb(152,201,237) 100%)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: 'bold',
      fontSize: '14px',
      color: '#283845',
      textTransform: 'uppercase',
    };

    const dataBody = {
      height: '132px',
      width: '180px',
      backgroundColor: 'rgb(9 17 30)',
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    };

    const arc = {
      position: 'absolute',
      width: '140px',
      height: '70px',
      borderTopLeftRadius: '80px',
      borderTopRightRadius: '80px',
      backgroundColor: 'transparent',
      border: '8px solid rgb(62 84 99)',
      borderBottom: 'none',
      transform: 'rotate(0deg)',
      top: '10px',
    };

    const revArc = {
        position: 'absolute',
        width: '140px',
        height: '70px',
        borderTopLeftRadius: '80px',
        borderTopRightRadius: '80px',
        backgroundColor: 'transparent',
        border: '8px solid rgb(152 201 237)',
        borderBottom: 'none',
        transform: 'rotate(-'+revDeg+'deg)',
        transformOrigin: '50% 100%',
        transition: 'transform 100ms',
        top: '10px',
      };

      const revContainer ={
        position: 'absolute',
        overflow: 'hidden',
        width: '100%',
        height: '89px',
        top: '0px',
        left: '12px'
      }

    const centerCircle = {
      position: 'absolute',
      width: '90px',
      height: '90px',
      borderRadius: '50%',
      backgroundColor: 'transparent',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      top: '20px',
      flexDirection: 'column',
    };

    const speed = {
      fontSize: '2em',
      fontWeight: 'bold',
      color: 'white',
    };

    const speedUnit = {
      fontSize: '13px',
      color: '#fff',
    };

    const gearContainer = {
      position: 'absolute',
      bottom: '5px',
      width: '160px',
      display: 'flex',
      justifyContent: 'space-between',
      color: 'rgb(62 107 140)',
      fontSize: '18px',
      lineHeight: '30px'
    };

    const selectedGear = {
      fontSize: '24px',
      fontWeight: 'bold',
      color: 'rgb(152 201 237)',
    };

    // Render logic based on props
    if (this.props.ClientStates.Skin === 'Hyundai') {
      return (
        <div style={containerStyle}>
          <div style={hide}>
            <div style={dataHeader}>{driverName}</div>
            <div style={dataBody}>
                <div style={revContainer}>
                    <div style={arc}></div>
                    <div style={revArc}></div>
              </div>
              <div style={centerCircle}>
                <span style={speed}>{currentRPM}</span>
                <span style={speedUnit}>RPM</span>
              </div>
              <div style={gearContainer}>
                <span>{gearLess3}</span>
                <span>{gearLess2}</span>
                <span>{gearLess1}</span>
                <span style={selectedGear}>{currentGear}</span>
                <span>{gearUp1}</span>
                <span>{gearUp2}</span>
                <span>{gearUp3}</span>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default DataView;
