import React, { PureComponent } from 'react';

class TowerHeader extends PureComponent {

    state = { 
        rMonitorRaceDuration: 9999,
        rMonitorSessionId: null
    }

    render() { 

        //Clock data calculations START
        if (this.props.Session.SD == "rMonitor - Laps"){
            var competitorsState = this.props.Session.Competitors
            var driverIndex = competitorsState.findIndex( x => x.Position == 1)
            if (driverIndex > -1){
                var leaderLaps = competitorsState[driverIndex].NumberOfLaps
            } else {
                var leaderLaps = 0 
            }

            var totalLaps = parseInt(leaderLaps) + parseInt(this.props.timeRemaining)

            
            if (this.state.rMonitorRaceDuration == 9999 || this.state.rMonitorRaceDuration < totalLaps || this.props.Session.SessionId != this.state.rMonitorSessionId){
                this.setState({
                    rMonitorRaceDuration: totalLaps,
                    rMonitorSessionId: this.props.Session.SessionId
                })
            }
            
            if(leaderLaps == this.state.rMonitorRaceDuration || this.props.Session.State == "ChequeredFlag"){
                var clockData = "FINISHED"
            } else if (this.props.Session.SD == "rMonitor - Laps" && this.props.timeRemaining == "0"){
                var clockData = "FINISHED"
            } else if (leaderLaps == 0 && this.props.Session.State != "Running"){
                var clockData = "FORMATION"
            } else if (leaderLaps == 0 && this.props.Session.State == "Running"){
                var clockData = "LAP "+ 1 + " / " + this.state.rMonitorRaceDuration
            } else if (parseInt(leaderLaps)+1 == this.state.rMonitorRaceDuration ){
                var clockData = "FINAL LAP"
            } else {
                var clockData = "LAP "+ (parseInt(leaderLaps)+1) + " / " + this.state.rMonitorRaceDuration
            }
            
		} else if (this.props.Session.SD == "rMonitor - Time") {
            var clockData = this.props.timeRemaining
        } else {
            var addLapIndex = this.props.Session.SD.indexOf("+")
            var lapsIndex = this.props.Session.SD.indexOf("Laps")

            if (this.props.Session.End == true){
                var clockData = "FINISHED"
            } else if (this.props.Session.Last == true) {
                var clockData = "FINAL LAP"
            } else if (this.props.Session.State == "Formation"){
                var clockData = "FORMATION"
                // var clockData = this.props.Session.SD
            } else if (this.props.Session.State == "Starting"){
                var clockData = this.props.timeRemaining
            } else if (lapsIndex > -1 && this.props.ClientStates.TimingSystem != "rMonitor") {
                var remaining = this.props.Session.Rd.replace("L", "")
                var total = parseInt(this.props.Session.SD.replace(" Laps", ""))
                if (this.props.ClientStates.TimingSystem == "Speedhive"){
                    if (remaining != "NAN"){
                        var clockData =   "LAP " + remaining + " / " + total;
                    } else {
                        var clockData =  "";
                    }
                } else if (this.props.ClientStates.TimingSystem == "Apex"){
					var competitorsState = this.props.Session.Competitors
					var driverIndex = competitorsState.findIndex( x => x.Position == 1)
						if (driverIndex > -1){
							var leaderLaps = parseInt(competitorsState[driverIndex].NumberOfLaps)+1
						} else {
							var leaderLaps = 0 
						}
					
					if (remaining != "NAN"){
                        if (total - parseInt(leaderLaps) == 0){
                            var clockData = "FINAL LAP"
                        } else if (parseInt(leaderLaps) == total){
                            var clockData = "FINISHED"
                        } else {
                            var clockData =  leaderLaps + " / " + total ; 
                        }
                    } else {
                        var clockData =  "";
                    }
				} else {
                    if (remaining != "NAN"){
                        if (parseInt(remaining) == 1){
                            var clockData = "FINAL LAP"
                        } else if (parseInt(remaining) == 0){
                            var clockData = "FINISHED"
                        } else {
                            remaining = total - remaining+1
                            if(this.props.ClientStates.Skin == "Hyundai"){
                                var clockData =  "LAP " + remaining + "/" + total ; 
                            } else {
                                var clockData =  "LAP " + remaining + " / " + total ; 
                            }
                            
                        }
                    } else {
                        var clockData =  "";
                    }
                }
            } else if (addLapIndex > -1 && this.props.ClientStates.TimingSystem != "rMonitor" && this.props.ClientStates.TimingSystem != "TimeService"){
                var clockData = this.props.timeRemaining + " +" + this.props.Session.SD[this.props.Session.SD.length-2] + "L"
            } else if (this.props.ClientStates.TimingSystem == "TimeService" && this.props.Session.LengthMode == 0){
                var clockData = this.props.timeRemaining + " +" + this.props.Session.SD[this.props.Session.SD.length-2] + "L"
            } else {
                var clockData = this.props.timeRemaining
            }
        }

        var towerHeadClass = "towerHead"
        var font = {}

        if (this.props.ClientStates.Skin == "Ginetta"){
            towerHeadClass = "ginettaHead"
        } else if (this.props.ClientStates.Skin == "onside" && this.props.Session.State == "ChequeredFlag"){
            towerHeadClass = "onsideCheckered gb3Head"
        } else if (this.props.ClientStates.Skin == "gb3" && this.props.Session.State == "ChequeredFlag"){
            towerHeadClass = "onsideCheckered gb3Head"
        } else if (this.props.ClientStates.Skin == "gb3"){
            towerHeadClass = "gb3Head"
        } else if (this.props.ClientStates.Skin == "basic"){
            towerHeadClass = "basicHead"
        } else if (this.props.ClientStates.Skin == "Hyundai"){
            towerHeadClass = "hyundaiHead"
            return ( 
                <div className={towerHeadClass} style={{height:"35px", ...font} }>
                    <img className='SponsorLogoHide' src={"images/sponsorLogos/"+this.props.ClientStates.TowerSponsorLogo} style={{height:this.props.ClientStates.TowerSponsorSize, width: "0px"}}></img>
                    <div className='hyundaiTowerHeadText' style={{height:"35px"}}>{this.props.Session.SessionName +" - "+ clockData}</div>
                    {/* <div className='TowerHeadText' style={{height:"35px"}}></div> */}
                </div>
            );
        } else if (this.props.ClientStates.Skin){
            var font = {
                fontSize: parseInt(this.props.ClientStates.FontSize)+7 + 'px'
            }
        }

        if (this.props.ClientStates.TowerSponsor == true){
            return ( 
                <div className={towerHeadClass} style={{height:this.props.ClientStates.TowerHeadHeight}}>
                    <img className='SponsorLogoShow' src={"images/sponsorLogos/"+this.props.ClientStates.TowerSponsorLogo} style={{height:this.props.ClientStates.TowerSponsorHeight, width: this.props.ClientStates.TowerSponsorWidth}}></img>
                    {/* <div className='TowerHeadTextSponsor' style={{height:this.props.ClientStates.TowerSponsorSize, width: 230 - parseInt(this.props.ClientStates.TowerSponsorWidth) + "px"}}></div> */}
                    <div className='TowerHeadTextSponsor' style={{height:this.props.ClientStates.TowerSponsorSize, width: 230 - parseInt(this.props.ClientStates.TowerSponsorWidth) + "px"}}>{clockData}</div>
                </div>
            );
        } else {
            return ( 
                <div className={towerHeadClass} style={{height:"35px", ...font} }>
                    <img className='SponsorLogoHide' src={"images/sponsorLogos/"+this.props.ClientStates.TowerSponsorLogo} style={{height:this.props.ClientStates.TowerSponsorSize, width: "0px"}}></img>
                    <div className='TowerHeadText' style={{height:"35px"}}>{clockData}</div>
                    {/* <div className='TowerHeadText' style={{height:"35px"}}></div> */}
                </div>
            );
        }        
    }


}
export default TowerHeader ;
