import React, { Component } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import './Graphics/stylesheets/qualifying.css';


class QualifyingPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
          startTime: Date.now()/1000,  // Current time in Unix timestamp (seconds)
          currentTime: Date.now()/1000,  // Initialize currentTime with current Unix timestamp (seconds)
          driverSelect: 0
        };
      }

    
      componentDidMount() {
        this.intervalID = setInterval(
          () => this.tick(),
          100  // Update every tenth of a second
        );
      }
    
      componentWillUnmount() {
        clearInterval(this.intervalID);  // Clear interval when component unmounts
      }
    
      tick() {
        this.setState({
          currentTime: Date.now() / 1000,  // Update currentTime with current Unix timestamp (seconds)
        });
      }
    
      formatTime(timeInSeconds) {
        const hours = Math.floor(timeInSeconds / 3600);
        timeInSeconds %= 3600;
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = (timeInSeconds % 60).toFixed(1); // Keep 1 decimal point to show tenths of a second
        
        // Zero padding for single digit minutes and seconds
        const paddedMinutes = minutes.toString().padStart(2, "0");
        const paddedSeconds = seconds.toString().padStart(4, "0");  // 4 because we're showing a decimal place
    
        return `${hours > 0 ? `${hours}:` : ''}${paddedMinutes}:${paddedSeconds}`;
      }

      changeDriver = (e) => {
        var newDriver = e.target.value
        this.setState({
            driverSelect: parseInt(newDriver)
        })
      }

      
    
      render() {
        //work out sector colours
        

        const elapsedTime = this.state.currentTime - (1683375253070000/1000000);
        if (this.props.Session.Competitors){
            if (this.props.Session.Competitors[this.state.driverSelect]){
                if (this.props.Session.Competitors[this.state.driverSelect].CurrentLap){
                    if (this.props.Session.Competitors[this.state.driverSelect].CurrentLap.Sectors){
                        var driverSectors = this.props.Session.Competitors[this.state.driverSelect].CurrentLap.Sectors
                        var i = 0
                        var sectorColours = []
                        var sectorDiff = []
                        while (i < driverSectors.length){
                            var sectors = this.props.Session.blt.sectors
                            var sectorIndex = sectors.findIndex( x => x.ID == parseInt(driverSectors[i].SectorID))
                            sectorDiff[sectorIndex] =  driverSectors[i].SectorTime - this.props.Session.blt.sectors[sectorIndex].Time
                            if (this.props.Session.Sectors[sectorIndex].BestTime == driverSectors[i].SectorTime){
                                sectorColours[sectorIndex] = "fastestPink"
                                sectorDiff[sectorIndex] = sectorDiff[sectorIndex]
                                sectorDiff[sectorIndex] = sectorDiff[sectorIndex]
                            } else if (driverSectors[i].SectorTime > driverSectors[i].BestTime){
                                sectorColours[sectorIndex] = "normal"
                                sectorDiff[sectorIndex] = "+"+sectorDiff[sectorIndex]
                            } else if (driverSectors[i].SectorTime == driverSectors[i].BestTime){
                                sectorColours[sectorIndex] = "PositiveGreen"
                                sectorDiff[sectorIndex] = "+"+sectorDiff[sectorIndex]
                            }
                            i++
                        }
                    }
                return (
                  <div>
                    <h2>{this.formatTime(elapsedTime)}</h2>
                    <input type="number" defaultValue={this.state.driverSelect} onChange={this.changeDriver}></input>
                    <div>{this.props.Session.Competitors[this.state.driverSelect].CompetitorName}</div>
                    <div><TransitionGroup>
                      {this.props.Session.Competitors[this.state.driverSelect].CurrentLap.Sectors.map((sector, index) => (
                        <CSSTransition
                          key={sector.SectorTime}  // Replace this with a unique key, e.g., sector id
                          timeout={500} // Duration of the transition
                          classNames="fade" // Prefix for the CSS classes, e.g., fade-enter, fade-enter-active
                        >
                          <div className={"qualifyingSECTORS " + sectorColours[index]}>{this.props.msToTime(sectorDiff[index]/1000)}</div>
                        </CSSTransition>
                      ))}
                    </TransitionGroup></div>
                    
                    <div><TransitionGroup>
                      {this.props.Session.Competitors[this.state.driverSelect].CurrentLap.Sectors.map((sector, index) => (
                        <CSSTransition
                          key={sector.SectorTime}  // Replace this with a unique key, e.g., sector id
                          timeout={500} // Duration of the transition
                          classNames="fade" // Prefix for the CSS classes, e.g., fade-enter, fade-enter-active
                        >
                          <div className={"qualifyingSECTORS " + sectorColours[index]}>{sectorDiff[index]}</div>
                        </CSSTransition>
                      ))}
                    </TransitionGroup></div>
                    
                </div>
                );
                } else {
                    return (
                    <div>
                        <h2>{this.formatTime(elapsedTime)}</h2>
                        <input type="number" defaultValue={this.state.driverSelect} onChange={this.changeDriver}></input>
                        <h3>waiting for lap</h3>
                    </div>
                    )
                }
            } else {
                return (
                    <div>
                        <h2>{this.formatTime(elapsedTime)}</h2>
                        <input type="number" defaultValue={this.state.driverSelect} onChange={this.changeDriver}></input>
                        <h3>waiting for first Competitor</h3>
                    </div>
                    )
            }
                
        } else {
            return (
                <div>
                    <h2>{this.formatTime(elapsedTime)}</h2>
                    <input type="number" defaultValue={this.state.driverSelect} onChange={this.changeDriver}></input>
                    <h3>waiting for Competitors</h3>
                </div>
                )
        }
        
        
      }
    }
    
export default QualifyingPage ;
