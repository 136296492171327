import React, { PureComponent } from 'react';

class Session extends PureComponent {
    state = { 

     }

    render() {
        if (this.props.ClientStates.TimingSystem == "HS" || this.props.ClientStates.TimingSystem == "Speedhive"){
            return(         
                <div id='session' className='box' style={{backgroundColor: this.props.color}}>
                <div className='boxHeader' attr='150px' onClick={this.props.collapse}>
                    SESSION
                </div>
                <div className='text'>Name: {this.props.Session.SessionName}</div>
                <div className='text'>
                    Timing Page:{' '}
                    <input type='text' id='timingPage' onChange={this.props.HSPage}/>
                </div>
                <div className='text'>
                    State:{' '}
                    <select id='state' value={this.props.Session.State} onChange={this.props.changeSessionState}>
                    <option value='Formation'>Formation</option>  
                    <option value='Starting'>Starting</option>  
                    <option value='Running'>Green</option>
                    <option value='RedFlag'>Red</option>
                    <option value='Yellow'>Yellow</option>
                    <option value='LastLap'>Final Lap</option>
                    <option value='ChequeredFlag'>Finished</option>
                    </select>
                    <button className="controlBUTTON" onClick={this.props.manualRaceStart}>Race Start</button>
                </div>
                
                <div className='text'>
                    Duration:{' '}
                    <input type='text' id='duration' defaultValue={this.props.Session.SD} onChange={this.props.changeDuration}/>
                </div>
                </div>
            )
        } else if (this.props.ClientStates.TimingSystem == "TSL"){
            return(         
            <div id='session' className='box' style={{backgroundColor: this.props.color}}>
                <div className='boxHeader' attr='150px' onClick={this.props.collapse}>
                    SESSION INFO
                </div>
                <div className='text'>Name: {this.props.Session.SessionName}</div>
                <div className='text'>Circuit: {this.props.Session.tn}</div>
                <div className='text'>State: {this.props.Session.State}</div>
                <div className='text'>Duration: {this.props.Session.SD}</div>
                <div className='text'>Grouped: {this.props.Session.TSLGroupedType}</div>
            </div>
            )
        } else if (this.props.ClientStates.TimingSystem == "TimeService"){
            return(
                <div id='session' className='box' style={{backgroundColor: this.props.color}}>
                    <div className='boxHeader' attr='150px' onClick={this.props.collapse}>
                        SESSION INFO
                    </div>
                    <div className='text'>Name:                     
                        <select id='state' value={this.props.Session.SessionId} onChange={this.props.sessionSelect}>
                            {this.props.Races.map((races, index) => (
                                <option key={index} value={races.SessionId}>{races.SessionName}</option> ))}
                        </select>
                    </div>
                    <div className='text'>Date: {this.props.Session.Date}</div>
                    <div className='text'>State: {this.props.Session.State}</div>
                    <div className='text'>Duration: {this.props.Session.SD}</div>
                </div>
            )
            
        } else {
            return(         
                <div id='session' className='box' style={{backgroundColor: this.props.color}}>
                    <div className='boxHeader' attr='150px' onClick={this.props.collapse}>
                        SESSION INFO
                    </div>
                    <div className='text'>Name: {this.props.Session.SessionName}</div>
                    <div className='text'>Circuit: {this.props.Session.tn}</div>
                    <div className='text'>Date: {this.props.Session.Date}</div>
                    <div className='text'>State: {this.props.Session.State}</div>
                    <div className='text'>Duration: {this.props.Session.SD}</div>
                    
                </div>
                )
        }
        
    }
}

export default Session;