import React from 'react';
import './editor.css'; // Importing the CSS file

class StatsPage extends React.Component {
    renderCompetitor = (competitor, index) => {
        if (competitor) {
            if (competitor.LapData) {
                return (
                    <div className="competitor-row" style={{ marginTop: (competitor.GridPosition + 1) * 50 + "px" }} key={competitor.CompetitorId}>
                        <div className="competitor-start">
                            <span className="competitor-cell">{competitor.GridPosition + 1}</span>
                            <span className="competitor-cell">{competitor.CompetitorNumber}</span>
                            <span className="competitor-cell">{competitor.CompetitorName}</span>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div className="competitor-row" key={competitor.CompetitorId}>
                        <span className="competitor-cell">{competitor.GridPosition}</span>
                        <span className="competitor-cell">{competitor.CompetitorNumber}</span>
                        <span className="competitor-cell">{competitor.CompetitorName}</span>
                    </div>
                );
            }
        } else {
            return (
                <div></div>
            )
        }
    }

    diagonalLine = ({y2}) => {
        return (
            <svg width={"50px"} height={y2+"px"}>
                <line x1={0} y1={0} x2={50} y2={y2} style={{ stroke: 'black', strokeWidth: 2 }} />
            </svg>
        );
    }

    renderPositions = (competitor, index) => {
        if (competitor) {
            if (competitor.LapData) {
                return (
                    <div key={competitor.CompetitorId}>
                        <div style={{ position: "absolute", marginTop: (competitor.GridPosition + 1) * 50 + "px", marginLeft: "300px" }}>{competitor.CompetitorNumber}</div>
                        {competitor.LapData.map((lap, lapIndex) => {
                            const nextLap = lapIndex > 0 ? competitor.LapData[lapIndex + 1] : null;

                            if (nextLap) {
                                return (
                                    <div key={lap.LapNo} style={{ position: "absolute", marginTop: lap.Position * 50 + "px", marginLeft: (lap.LapNo * 50) + 50 + "px" }}>
                                        {competitor.CompetitorNumber}
                                        {this.diagonalLine({y2: ( nextLap.Position - lap.Position) * 50 })}
                                    </div>
                                );
                            } else {
                                return (
                                    <div key={lap.LapNo} style={{ position: "absolute", marginTop: lap.Position * 50 + "px", marginLeft: (lap.LapNo * 50) + 50 + "px" }}>
                                        {competitor.CompetitorNumber}
                                    </div>
                                );
                            }
                        })}
                    </div>
                )
            } else {
                return <div key={competitor.CompetitorId}></div>
            }
        } else {
            return <div key={index}></div>
        }
    }

    render() {
        if (this.props.Session.Competitors) {
            const { Competitors } = this.props.Session;

            return (
                <div>
                    <div>
                        {Competitors.map(this.renderCompetitor)}
                    </div>
                    <div>
                        {Competitors.map(this.renderPositions)}
                    </div>
                </div>
            );
        } else {
            return (
                <div>No Competitors</div>
            )
        }
    }
}

export default StatsPage;
