import React, { PureComponent } from 'react';
import './stylesheets/suggestions.css';

class Suggestions extends PureComponent {
    state = { 

     }

    render() {
        return(         
            <div className='box' style={{backgroundColor: this.props.color}}>
                <div className='boxHeader' onClick={this.props.collapse}>
                    SUGGESTIONS
                </div>
                
            </div>
        )
    }
}

export default Suggestions;