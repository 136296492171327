import React, { PureComponent } from 'react';
import TowerRows from './towerRows';
import './stylesheets/Tower.css';

class Tower extends PureComponent { 

    render() { 

        var transitionBattle = ""

        if(this.props.Controls.TowerShow == true){
            var towerClass = "TowerDiv_show"
        } else {
            var towerClass = "TowerDiv_hide"
        }

        //Vmix Check
        if(this.props.vmix == "true"){
            var divNudge = "7px"
        } else {
            var divNudge = "0px"
        }

        //Split Tower Size Calculations START
        var upperHeight = 350
        var lowerHeight = 350
        if(this.props.Controls.BattleShow == true && this.props.Session.Competitors.length > 20 && this.props.Controls.BattlePosition < 11){
            upperHeight = 377
        } else if ((this.props.Controls.BattleShow == true && this.props.Session.Competitors.length > 20 && this.props.Controls.BattlePosition > 11)){
            lowerHeight = 377
        }
        //Split Tower Size Calculations END

        //Battle Graphic Calculations START
            if (this.props.Controls.BattlePosition == 1){
                var battleEnd = "st"
                var battleMargin = 4
            } else if (this.props.Controls.BattlePosition == 2){
                var battleEnd = "nd"
                var battleMargin = 12+25
            } else if (this.props.Controls.BattlePosition == 3){
                var battleEnd = "rd"
                var battleMargin = 47+25
            } else {
                var battleEnd = "th"
                if (parseInt(this.props.Controls.BattlePosition) > 12){
                    var battleMargin = 12*35+4
                } else {
                    var battleMargin = (parseInt(this.props.Controls.BattlePosition)-1)*35+2
                }
            }
        //Battle Graphic Calculations END

        //Progression Graphic Calculations START
        var progressionClass = "ProgressionHide"
        var progressionBoost = 0
		var transitionBattle = ""
        
        if (this.props.Controls.Expand == true){
            var competitorsState = this.props.Session.Competitors
            var driverIndex = competitorsState.findIndex( x => x.CompetitorId == this.props.Controls.ExpandDriverID)
            if (driverIndex > -1){
                var expandPosition = competitorsState[driverIndex].Position

                if(expandPosition <= this.props.ClientStates.DefaultProgression){
                    progressionBoost = 72
                }

                if(expandPosition < this.props.Controls.BattlePosition){
                    // battleMargin = 47+25+38
					battleMargin = battleMargin+73
                    // transitionBattle = "margin-top 1000ms ease-in-out"
                } else {
					// transitionBattle = "margin-top 1000ms ease-in-out"
				}
            }
        }

        if (this.props.Controls.Onboard == true){
            var competitorsState = this.props.Session.Competitors
            var driverIndex = competitorsState.findIndex( x => x.CompetitorId == this.props.Controls.OnboardDriverID)
            if (driverIndex > -1){
                var expandPosition = parseInt(competitorsState[driverIndex].Position)

                if(expandPosition <= this.props.ClientStates.DefaultProgression){
                    progressionBoost = 203
                }

                if(expandPosition <= this.props.Controls.BattlePosition){
                    battleMargin = 47+25+203
                    // transitionBattle = "margin-top 1000ms ease-in-out"
                } else {
					// transitionBattle = "margin-top 1000ms ease-in-out"
				}
            }
            
        }

        if (this.props.Controls.BattleShow == true && this.props.Controls.BattlePosition <= this.props.ClientStates.DefaultProgression){
            progressionBoost = progressionBoost + 27
        }
		
		if(this.props.Controls.BattleShow == true){
			transitionBattle = "margin-top 1000ms ease-in-out"
		} else {
			transitionBattle = ""
		}
			

        var progressionMargin = this.props.ClientStates.DefaultProgression * 35 + 3 + progressionBoost

        if (this.props.Controls.ProgressionShow == true){
            upperHeight = upperHeight + 9
            progressionClass = "ProgressionShow"
        }

        if (this.props.Session.TSLGrouped == 1){
            var grouped = true
        } else {
            var grouped = false
        }


        
        //Progression Graphic Calculations END

        //Expanded Graphic Calculations START
        if (this.props.Controls.Expand == true && this.props.Session.Competitors[this.props.Controls.ExpandIndex].Position < 11){
            upperHeight = upperHeight + 70
        } 
        //Expanded Graphic Calculations END

        //Onboard Graphic Calculations START
        if (this.props.Controls.Onboard == true && this.props.Session.Competitors[this.props.Controls.OnboardIndex].Position < 11){
            upperHeight = upperHeight + 203
        } else if (this.props.Controls.Onboard == true && this.props.Session.Competitors[this.props.Controls.OnboardIndex].Position > 11){
            lowerHeight = lowerHeight + 203
        }
        //Onboard Graphic Calculations END

        //Font size setup START
        var battleFont = {}
        var battleClass = "BattleGraphic"
        var battleTextClass = "BattleText"
        if(this.props.ClientStates.Skin){
			if(this.props.ClientStates.Skin == "pscse"){
				battleFont = {
					fontSize: parseInt(this.props.ClientStates.FontSize)+4 + "px"
				}
			} if(this.props.ClientStates.Skin == "Hyundai"){
                battleClass = "hyundaiBattleGraphic"
                battleTextClass = "hyundaiBattleText"
				battleFont = {
					fontSize: parseInt(this.props.ClientStates.FontSize)+4 + "px"
				}
			} else {
				battleFont = {
					fontSize: parseInt(this.props.ClientStates.FontSize)-4 + "px"
				}
			}
        }
        //Font size setup End

        if(this.props.Controls.BattleShow == true && this.props.Session.Competitors.length > 20){
            return ( 
                <div className={towerClass} style={{height:"800px", marginLeft: divNudge}}>
                    <div className={battleClass} style={{marginTop: battleMargin+"px", transition: transitionBattle, ...battleFont}}>
                        <div className={battleTextClass}>BATTLE FOR {this.props.Controls.BattlePosition}{battleEnd}</div>
                    </div>
                    <div className={progressionClass + " StreamBlue"} style={{marginTop: progressionMargin}}>
                        <div className="progressionSide"></div>
                    </div>
                    <div className='TowerTop' style={{height:upperHeight+"px"}}>                 
                        {this.props.Session.Competitors.map((driver, index) => (
                            <TowerRows grouped={grouped} pages={false} driver={driver} indexRow={index} margin={this.props.Controls.TowerMargin} {...this.props} key={index}/>
                        ))}
                    </div>
                    <div className='TowerSplit'></div>
                    <div className='TowerBottom' style={{height:lowerHeight+"px"}}>
                        {this.props.Session.Competitors.map((driver, index) => (
                            <TowerRows grouped={grouped} pages={true} driver={driver} indexRow={index} margin={this.props.Controls.TowerPagesMargin} {...this.props} key={index}/>
                        ))}
                    </div>
                    
                </div>
            );
        } else if (this.props.Controls.BattleShow == true) {
            return ( 
                <div className={towerClass} style={{height:"675px", marginLeft: divNudge}}>
                     <div className={battleClass} style={{marginTop: battleMargin+"px", transition: transitionBattle, ...battleFont}}>
                        <div className={battleTextClass}>BATTLE FOR {this.props.Controls.BattlePosition}{battleEnd}</div>
                    </div>
                    <div className={progressionClass + " StreamBlue"} style={{marginTop: progressionMargin}}>
                        <div className="progressionSide"></div>
                    </div>
                    {this.props.Session.Competitors.map((driver, index) => (
                        <TowerRows grouped={grouped} pages={false} driver={driver} indexRow={index} margin={this.props.Controls.TowerMargin} {...this.props} key={index}/>
                    ))}
                    
                </div>
            );
        } else if(this.props.Session.Competitors.length > 20 && parseInt(this.props.ClientStates.DefaultProgression) < 10){
            return ( 
                <div className={towerClass} style={{height:"678px", marginLeft: divNudge}}>
                    <div className={battleClass} style={{marginTop: battleMargin+"px", height: '0px' , ...battleFont}}>
                        <div className={battleTextClass}>BATTLE FOR {this.props.Controls.BattlePosition}{battleEnd}</div>
                    </div>
                    <div className={progressionClass + " StreamBlue"} style={{marginTop: progressionMargin}}>
                        <div className="progressionSide"></div>
                    </div>
                    
                    <div className='TowerTop' style={{height:upperHeight+"px"}}>
                        {this.props.Session.Competitors.map((driver, index) => (
                            <TowerRows grouped={grouped} pages={false} driver={driver} indexRow={index} margin={this.props.Controls.TowerMargin} {...this.props} key={index}/>
                        ))}
                    </div>
                    <div className='TowerSplit'></div>
                    <div className='TowerBottom' style={{height:lowerHeight+"px"}}>
                        {this.props.Session.Competitors.map((driver, index) => (
                            <TowerRows grouped={grouped} pages={true} driver={driver} indexRow={index} margin={this.props.Controls.TowerPagesMargin} {...this.props} key={index}/>
                        ))}
                    </div>
                    
                </div>
            );
        } else {
            return ( 
                <div className={towerClass} style={{height:"675px", marginLeft: divNudge}}>
                    <div className={battleClass} style={{marginTop: battleMargin+"px", height: '0px' , ...battleFont}}>
                        <div className={battleTextClass}>BATTLE FOR {this.props.Controls.BattlePosition}{battleEnd}</div>
                    </div>
                    <div className={progressionClass + " StreamBlue"} style={{marginTop: progressionMargin}}>
                        <div className="progressionSide"></div>
                    </div>
                    {this.props.Session.Competitors.map((driver, index) => (
                        <TowerRows grouped={grouped} pages={false} driver={driver} indexRow={index} margin={this.props.Controls.TowerMargin} {...this.props} key={index}/>
                    ))}
                    
                </div>
            );
        }

            
        }        
    }
export default Tower ;
