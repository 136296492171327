import React, { Component } from 'react';
import './setup.css'; // Importing the CSS file
import RaceControl from './Graphics/raceControl';

class RCControl extends Component {

    renderNotifications = (message, index) => {
        return (
            <div key={index}>
                <button onClick={this.props.sendNotification}>Select</button>
				<span style={{padding:'10px'}}>{message.t}</span>
                <span style={{padding:'10px'}}>{message.nt}</span>
            </div>
        );
    }

    render() {
        
        var Notifications = this.props.Notifications

        document.title = 'Alpha Live Graphics - Race Control Messages';

            return (
                <div id="Hello">

                    <RaceControl {...this.props} controller={true}/>

                    <div style={{position: 'absolute',marginTop: '75px'}}>
                        <button attr1='notificationShow' className="controlBUTTON" onClick={this.props.controlSend}>Show</button>
                        <button attr1='notificationHide' className="controlBUTTON" onClick={this.props.controlSend}>Hide</button>
                    </div>
                    

                    <div>
                        <label for="NewMessage">New Message:</label>
                        <input id='NewMessage' style={{marginTop: '100px', width: '500px'}}></input>
                        <button onClick={this.props.addNotification} >ADD</button>
                    </div>
                    

                    
                    <div id='rcMSG List'>
                        {Notifications.slice().reverse().map(this.renderNotifications)}
                    </div>
                </div>
            );
        
    }
}

export default RCControl;
