import react , { Component } from "react";
import Timing from './Controller/timing';
import Session from './Controller/session';
import './Controller/stylesheets/session.css';
import BasicControls from './Controller/basicControls';
import ClientState from './Controller/clientState';
import DataPreview from './Controller/dataDisplayPreview';
import Suggestions from './Controller/suggestions';
import Preview from './Controller/preview';
import Championship from './Controller/championship';
import LTPreview from './Controller/lowerThirdPreview';
import WebRTCStreamer from './Controller/webCam';

const fetch = require("node-fetch");

class ControllerPage extends Component {

    render() {
    document.title = 'Controller - Alpha Live Graphics';
    if(this.props.Camera == "true"){
        var camera = <WebRTCStreamer/>
    } else {
        var camera = <div></div>
    }

    var font = {
        fontFamily: 'Montserrat'
    }

    if (this.props.Session.Competitors == undefined) {
        return (
            <div>Waiting for data....
            </div>
        )
    } else if (this.props.ClientStates.TimingSystem == "TSL" && this.props.ClientStates.Skin == "basic"){
        return (
            <div>
                <div className="DirectorsDiv" style={font}>
                    <Timing {...this.props}/>
                    <div className="boxContainer">
                        {camera}
                        <Session {...this.props} color="rgb(62 61 61)" collapse={this.collapse.bind(this)}/>
                        <BasicControls {...this.props} color="rgb(62 61 61)" collapse={this.collapse.bind(this)}/>
                        <LTPreview {...this.props} color="rgb(62 61 61)" collapse={this.collapse.bind(this)}/>
                        <Preview {...this.props} color="rgb(62 61 61)" collapse={this.collapse.bind(this)}/>
                        {/* <ClientState {...this.props} color="rgb(62 61 61)" collapse={this.collapse.bind(this)}/> */}
                        {/* <Suggestions {...this.props} color="rgb(62 61 61)" collapse={this.collapse.bind(this)}/> */}
                    </div>
                    
                </div>
            </div> 
        )
    } else if (this.props.ClientStates.TimingSystem == "Apex" || this.props.ClientStates.TimingSystem == "ApexTest") {
        return (
            <div>
                <div className="DirectorsDiv" style={font}>
                    <Timing {...this.props}/>
                    <div className="boxContainer">
                        {camera}
                        <Session {...this.props} color="rgb(62 61 61)" collapse={this.collapse.bind(this)}/>
                        <BasicControls {...this.props} color="rgb(62 61 61)" collapse={this.collapse.bind(this)}/>
                        <LTPreview {...this.props} color="rgb(62 61 61)" collapse={this.collapse.bind(this)}/>
                        <Championship {...this.props} color="rgb(62 61 61)" collapse={this.collapse.bind(this)}/>
                        <ClientState {...this.props} color="rgb(62 61 61)" collapse={this.collapse.bind(this)}/>
                        {/* <Suggestions {...this.props} color="rgb(62 61 61)" collapse={this.collapse.bind(this)}/> */}
                    </div>
                    
                </div>
            </div> 
        )
    } else if (this.props.ClientStates.TimingSystem == "TSL"){
        return (
            <div>
                <div className="DirectorsDiv" style={font}>
                    
                    <Timing {...this.props}/>
                    <div className="boxContainer">
                        {camera}
                        <Session {...this.props} color="rgb(62 61 61)" collapse={this.collapse.bind(this)}/>
                        <BasicControls {...this.props} color="rgb(62 61 61)" collapse={this.collapse.bind(this)}/>
                        <LTPreview {...this.props} color="rgb(62 61 61)" collapse={this.collapse.bind(this)}/>
                        <Championship {...this.props} color="rgb(62 61 61)" collapse={this.collapse.bind(this)}/>
                        <Preview {...this.props} color="rgb(62 61 61)" collapse={this.collapse.bind(this)}/>
                        <ClientState {...this.props} color="rgb(62 61 61)" collapse={this.collapse.bind(this)}/>
                        {/* <Suggestions {...this.props} color="rgb(62 61 61)" collapse={this.collapse.bind(this)}/> */}
                    </div>
                    
                </div>
            </div> 
        )
        
    } else if (this.props.ClientStates.TimingSystem == "AC"){
        return (
            <div>
                <div className="DirectorsDiv" style={font}>
                    <Timing {...this.props}/>
                    <div className="boxContainer">
                        {camera}
                        <Session {...this.props} color="rgb(62 61 61)" collapse={this.collapse.bind(this)}/>
                        <BasicControls {...this.props} color="rgb(62 61 61)" collapse={this.collapse.bind(this)}/>
                        <LTPreview {...this.props} color="rgb(62 61 61)" collapse={this.collapse.bind(this)}/>
                        <DataPreview {...this.props} color="rgb(62 61 61)" collapse={this.collapse.bind(this)}/>
                        <Preview {...this.props} color="rgb(62 61 61)" collapse={this.collapse.bind(this)}/>
                        {/* <ClientState {...this.props} color="rgb(62 61 61)" collapse={this.collapse.bind(this)}/> */}
                        {/* <Suggestions {...this.props} color="rgb(62 61 61)" collapse={this.collapse.bind(this)}/> */}
                    </div>
                    
                </div>
            </div> 
        )
    } else {
        return (
            <div>
                <div className="DirectorsDiv" style={font}>
                    <Timing {...this.props}/>
                    <div className="boxContainer">
                        {camera}
                        <Session {...this.props} color="rgb(62 61 61)" collapse={this.collapse.bind(this)}/>
                        <BasicControls {...this.props} color="rgb(62 61 61)" collapse={this.collapse.bind(this)}/>
                        <LTPreview {...this.props} color="rgb(62 61 61)" collapse={this.collapse.bind(this)}/>
                        <Preview {...this.props} color="rgb(62 61 61)" collapse={this.collapse.bind(this)}/>
                        <ClientState {...this.props} color="rgb(62 61 61)" collapse={this.collapse.bind(this)}/>
                        {/* <Suggestions {...this.props} color="rgb(62 61 61)" collapse={this.collapse.bind(this)}/> */}
                    </div>
                    
                </div>
            </div> 
        )}
        }


    handleBattleCommand = (words) => {
        
        // Here you can add your code to process the command
    }

    collapse = (e) => {
        var grabber = e.target.parentNode
        console.log(e.target.getAttribute("attr"))
        if(grabber.style.height == "25px"){
            grabber.style.height = e.target.getAttribute("attr")
        } else {
            grabber.style.height = "25px"
        }
        
    }

    toggle = () => {
        if (this.state.expand === "expandOn") {
            this.setState({expand: "expandOff"})
        } else {
        this.setState({expand: "expandOn"})
        }
    }

    processDrivers = () => {
        //console.log("processData")
        var fileInput = document.getElementById("raceSelect")
        //console.log(fileInput)
        this.readFile(fileInput)
    }

    readFile = (fileInput) => {
        var reader = new FileReader();
        reader.onload = () => {
            //document.getElementById('out').innerHTML = reader.result;
            this.processCSV(reader.result)
        };
        // start reading the file. When it is done, calls the onload event defined above.
        reader.readAsBinaryString(fileInput.files[0]);
        //reader.readAsText(fileInput.files[0]);
    };

    clearCSV = () => {
        fetch("http://77.68.119.159:4003/?method=clear&drivers=one")
            .then(response => response.json())
            .then(data => document.getElementById("driversDisplay").innerHTML = JSON.stringify(data));
    }

    processCSV = (csv) => {
        var allTextLines = csv.split("\r\n");
        var lines = [];
        var removedRows = allTextLines.splice(0,2);
        var i = 0
        while (i<allTextLines.length) {
            var data = allTextLines[i]
            var dataArray = data.split(",")
            lines.push(dataArray[0])
            lines.push("")
            lines.push(dataArray[5])
            i++
        }
        
        // var currentData = JSON.stringify(lines);
        console.log("currentData", lines)
        // console.log(currentData)
        document.getElementById("driversDisplay").innerHTML = lines

        this.push("HAND"+lines)

        // fetch("http://77.68.119.159:4003/?method=ADD&drivers="+currentData)
        //     .then(response => response.json())
        //     // .then(data => document.getElementById("driversDisplay").innerHTML = JSON.stringify(data));
    }

    setClock = () => {
        var minutes = document.getElementById("setTimeMins").value
        var seconds = minutes * 60

        fetch("http://77.68.119.159:2001/?message=b"+seconds)

    }

    push = (message) => {
        fetch("http://77.68.119.159:2001/?message="+message)
        
    }

    hsURL = (message) => {
        fetch("http://77.68.119.159:2146/?connect=connect&message="+message)

    }

    expand = (pos) => {
        var heightArray = [];
        var i = 1
        //update to length for production version
        while (i<=20) {
            if (i<pos) {
            heightArray.push("40px")
            } else if (i == pos) {
            heightArray.push("120px")
            } else {
            heightArray.push("40px")
            }
            i++
        }

        var heightString = heightArray.toString()
        fetch("http://77.68.119.159:2001/?message="+heightString)
    }

    connect = (message) => {
        fetch("http://77.68.119.159:2146/?connect="+message)

    }

}

export default ControllerPage;