import React, { PureComponent } from 'react';
import '../stylesheets/gb3Rows.css';

class gb3Row extends PureComponent {
    
    render() {
        var marginBoost = 0
        var photoClass = "photo-off"
        var winnerNum = ""
        //Opacity Calculations START
        if (this.props.driver.NumberOfLaps < 1){
            var opacity = 0
            marginBoost = 1000
        } else if (this.props.driver.seen == false) {
            var opacity = 0
            marginBoost = 10000000000
        } else {
            var opacity = 1
        }
        //Opacity Calculations END

        if (this.props.driver.Expanded == true){
            var height = "75px" 
        } else {
            var height = "35px" 
        }

        //Battle Calculations START
        if (this.props.Controls.BattleShow == true){
            var battleQuestion = this.props.Controls.BattleDrivers.indexOf(parseInt(this.props.driver.CompetitorId))
            if (battleQuestion != -1 && this.props.pages == false){
                var nameBG = "rgb(255 255 255 / 75%)"
                var nameText = "#000000"
                marginBoost = 25
            } else if (battleQuestion != -1 && this.props.pages == true && parseInt(this.props.Controls.BattlePosition) > 12) {
                var nameBG = "rgb(255 255 255 / 75%)"
                var nameText = "#000000"
                marginBoost = 25
            } else if (battleQuestion == -1 && this.props.pages == true && parseInt(this.props.driver.Position) >= parseInt(this.props.Controls.BattlePosition) && parseInt(this.props.Controls.BattlePosition) > 12) {
                var nameBG = "rgb(0 0 0 / 65%)"
                var nameText = "#fff"
                marginBoost = 27
            } else if (parseInt(this.props.driver.Position) >= parseInt(this.props.Controls.BattlePosition) && this.props.pages == false ) {
                var nameBG = "rgb(0 0 0 / 65%)"
                var nameText = "#fff"
                marginBoost = 27
            } else {
                var nameBG = "rgb(0 0 0 / 65%)"
                var nameText = "#fff"
            }
        } else {
            var nameBG = "rgb(0 0 0 / 65%)"
            var nameText = "#fff"
        }
        //Battle Calculations END

        //Smart Naming Calculations START
        if(this.props.driver.CompetitorLastName){
            var competitorName = this.props.driver.CompetitorLastName.toUpperCase()
        } else if(this.props.driver.CompetitorName){
            var assName = this.props.driver.CompetitorName.indexOf('* ')
            if (assName != -1){
                var compName = this.props.driver.CompetitorName.replace('* ', '');
                var star_nbc = "#2faae1"
                var star_nfc = "#ffffff"
            } else if (this.props.Controls.Name == "TEAM"){
                var compName = this.props.driver.teamName.toUpperCase()
            } else {
                var compName = this.props.driver.CompetitorName.toUpperCase()
            }

            if (compName.length > 16){
                var competitorName = compName || this.props.driver.CompetitorName
                var run = 0
                var motorsport = competitorName.toUpperCase().indexOf('MOTORSPORT')
                var msport = competitorName.toUpperCase().indexOf('MSPORT')
                var racing = competitorName.toUpperCase().indexOf('RACING')
                var team = competitorName.toUpperCase().indexOf('TEAM')
                var racingIndex = -1
                if(racing > -1){
                    var nameArray = competitorName.toUpperCase().split(",").join(" ").split(" ")
                    if (nameArray[nameArray.length] != "RACING"){
                        var racingIndex = 0
                    } 
                }

                while (competitorName.length > 16){
                    if (motorsport > -1){
                        competitorName = competitorName.replace("motorsports", "");
                        competitorName = competitorName.replace("Motorsports", "");
                        competitorName = competitorName.replace("MOTORSPORTS", "");
                        competitorName = competitorName.replace("MOTORSPORT", "");
                        competitorName = competitorName.replace("Motorsport", "");
                        competitorName = competitorName.replace("motorsport", "");
                        motorsport = -1
                        run++
                        if (run > 5){ break }
                    } else if (msport > -1){
                        competitorName = competitorName.replace("MSport", "");
                        msport = -1
                        run++
                        if (run > 5){ break }
                    
                    } else if (team > -1) {
                        competitorName = competitorName.replace("TEAM", "");
                        competitorName = competitorName.replace("Team", "");
                        competitorName = competitorName.replace("team", "");
                        racing = -1
                        run++
                        if (run > 5){ break }
                    } else if (racing > -1) {
                        competitorName = competitorName.replace("RACING", "");
                        competitorName = competitorName.replace("Racing", "");
                        competitorName = competitorName.replace("racing", "");
                        racing = -1
                        run++
                        if (run > 5){ break }
                    } else {
                        var nameArray = competitorName.split(",").join(" ").split(" ")
                        if (run > 0){
                            var nameArray = competitorName.split("-").join(" ").split(" ")
                            var nameStart = nameArray[0]
                            var firstName = nameStart.substring(0,2*run+1)
                        } else {
                            var firstName = nameArray[0][0]
                        }

                        nameArray.shift()
                        var competitorName = firstName+"."+nameArray
                        run++

                        competitorName = competitorName.replace(/,/g, ' ');

                        if (run > 5){ break }
                    }
                    
                }

            } else {
                var competitorName = compName || this.props.driver.CompetitorName

            }
        } else {
            var competitorName = "undefined"
        }
        //Smart Naming Calculations END

        //Position Positive/Negative START
        if (this.props.driver.PositionChange > 0){
            var positionClass = undefined;
        } else if (this.props.driver.PositionChange < 0){
            var positionClass = undefined;
        } else {
            var positionClass = undefined;
        }
        //Position Positive/Negative END

        //extraBox & position class Calculations START
        var extraBoxClass = "extraBox transparent"
        var extraBoxContent = ""
        var extraBoxImgClass = "extraBoxImgHide"
        var tintMargin = 40
       if (this.props.driver.IsBestLapInRace == true && this.props.driver.TakenChequered == true){
            extraBoxClass = "extraBox gb3Black"
            positionClass = "fastestPink positionneutral"
            extraBoxImgClass = "extraBoxImgShow"
            tintMargin = "5px"
        } else if (this.props.driver.TakenChequered == true){
            extraBoxClass = "extraBox gb3Black"
            positionClass = "positionneutral"
            extraBoxImgClass = "extraBoxImgShow"
            tintMargin = 5
        } else if (this.props.driver.InPit == true){
            extraBoxClass = "extraBox StreamBlue"
            positionClass = "StreamBlue positionneutral"
            extraBoxContent = "PIT"
            tintMargin = 5
        } else if (this.props.driver.IsBestLapInRace == true && this.props.driver.NumberOfLaps > 1 && this.props.Session.SessionType != 'Qual' && this.props.Session.SessionType != 'Qualifying' && this.props.Session.SessionType != 'Practice'){
            extraBoxClass = "extraBox fastestPink"
            positionClass = "fastestPink positionneutral"
            extraBoxContent = "FL"
            tintMargin = 5
        }
        //extraBox & position class Calculations END

        //col4 content calculations START
        var col4Content = ""
        var col4BGColor = "rgb(0 0 0 / 65%)"
        var tintWidth = 337
        var gb3NameWidth = ""
        if (this.props.Controls.Col4 == "gap"){
            if (this.props.driver.Position == 1 && this.props.Session.SessionType != "Qual"){
                col4Content = "Gap"
            } else if (this.props.driver.Position == 1 && this.props.Session.SessionType == "Qual") {
                col4Content = this.props.msToTime(this.props.driver.BestLaptime*1000)
            } else if (this.props.Session.SessionType == "Qual") {
                col4Content = "+" + this.props.driver.Behind
            } else {
                col4Content = this.props.driver.Behind
            }
            tintWidth = 337 + ( 40 - tintMargin)
        } else if (this.props.Controls.Col4 == "diff"){
            if (this.props.driver.Position == 1){
                col4Content = "Diff"
            } else {
                col4Content = this.props.driver.Gap
            }
            tintWidth = 337 + ( 40 - tintMargin)
        } else if (this.props.Controls.Col4 == "pits"){
            col4Content = this.props.driver.ps
            tintWidth = 337 + ( 40 - tintMargin)
            gb3NameWidth = "gb3NameWidth"
        } else if (this.props.Controls.Col4 == "positions"){
            if (this.props.ClientStates.TimingSystem == "TSL" || this.props.ClientStates.TimingSystem == "rMonitor"){
                col4Content = this.props.driver.GridPosition - this.props.driver.Position
            } else {
                col4Content = (this.props.driver.GridPosition+1) - this.props.driver.Position
            }
            
            tintWidth = 337 + ( 40 - tintMargin)
            if (col4Content > 0){
                col4BGColor = "rgb(0 146 69 / 85%)"
                col4Content = "▲" + col4Content
            } else if (col4Content < 0){
                col4BGColor = "rgb(193 39 45 / 85%)"
                col4Content = "▼" + Math.abs(col4Content)
            } else {
                col4Content = "◀▶"
            }
            gb3NameWidth = "gb3NameWidth"
        } else if (this.props.Controls.Col4 == "none"){
            tintWidth = 337
            gb3NameWidth = "gb3NameWidth"
        }
        //col4 class calculations END

        //Retired Settings START
        if (this.props.driver.Retired == true){
            var rowTint = "rgb(0 0 0 / 70%)"
        } else {
            var rowTint = "transparent"
        }
        //Retired Settings END

        //Progression Settings Start
        var progressionNo = this.props.Controls.ProgressionPosition || this.props.ClientStates.DefaultProgression
        if (this.props.Controls.ProgressionShow == true && this.props.pages == false){
            if (parseInt(this.props.driver.Position) > parseInt(progressionNo)){
                marginBoost = marginBoost + 9
            } else {
                marginBoost = marginBoost + 0
            }
        }
        //Progression Settings End

        //Expand Content Start
        if (this.props.driver.Position == 1 && this.props.driver.TakenChequered == true && this.props.ClientStates.DriverPhotos == true){
            //Winner Data
            // var photoURL = this.props.driver.Picture + "-4.png"+`?t=${Date.now()}`
            var photoURL = this.props.driver.Picture + "-4.png"
            var firstSpaceIndex = this.props.driver.CompetitorName.indexOf(" ");
            var firstName = this.props.driver.CompetitorName.substring(0, firstSpaceIndex);
            var remainingName = this.props.driver.CompetitorName.substring(firstSpaceIndex + 1);
            var expandedHTML = <div className='expandedTextBox'><div className='winnerFirstName'>{firstName}</div><div className='winnerRemainingName'>{remainingName}</div><div className='winnerTeam'>{this.props.driver.teamName}</div><div className='winnerVehicle'>{this.props.driver.vehicle}</div></div>
            competitorName = ""
            positionClass = "winnerPosition"
            winnerNum = "winnerNum"
        } else if (this.props.driver.Position == 1 ){
            //1st Position Data
            // var photoURL = this.props.driver.Picture + "-3.png"+`?t=${Date.now()}`
            var photoURL = this.props.driver.Picture + "-3.png"
            var expandedHTML = <div className='expandedTextBox'><div className='winnerTeam'>{this.props.driver.teamName}</div><div className='winnerVehicle'>{this.props.driver.vehicle}</div></div>
        } else {
            //All other Positions Data
            // var photoURL = this.props.driver.Picture + "-3.png"+`?t=${Date.now()}`
            var photoURL = this.props.driver.Picture + "-3.png"
            var expandedHTML = <div className='expandedTextBox'><div className='winnerTeam'>{this.props.driver.teamName}</div><div className='winnerVehicle'>{this.props.driver.vehicle}</div></div>
        }
        //Expand Content End

        //Expand Check Start
        if (this.props.Controls.Expand == true){
            if (this.props.Controls.ExpandDriverID == this.props.driver.CompetitorId && this.props.pages == false){
                //Expand top 10
                var expandClass = "expand-on"
                col4BGColor = "rgb(0 0 0 / 85%)"
                photoClass = "photo-on"
                col4Content = ""
            } else if (this.props.Controls.ExpandDriverID == this.props.driver.CompetitorId && this.props.pages == true && this.props.driver.Position > 10){
                //Expand after top 10
                var expandClass = "expand-on"
                col4BGColor = "rgb(0 0 0 / 85%)"
                col4Content = ""
                photoClass = "photo-on"
            } else {
                //Drivers after the expanded driver
                var expandClass = "expand-off"
                if (this.props.driver.Position > this.props.Session.Competitors[this.props.Controls.ExpandIndex].Position && this.props.pages == false){
                    marginBoost = marginBoost + 72
                } else if (this.props.Session.Competitors[this.props.Controls.ExpandIndex].Position > 10 && this.props.driver.Position > this.props.Session.Competitors[this.props.Controls.ExpandIndex].Position && this.props.pages == true){
                    marginBoost = marginBoost + 72
                }
                
            }
        } else if (this.props.Controls.Onboard == true){
            if (this.props.Controls.OnboardDriverID == this.props.driver.CompetitorId && this.props.pages == false){
                //Expand top 10
                var expandClass = "expand-off"
                var nameBG = "rgb(255 255 255 / 95%)"
                var nameText = "#000000"
                // col4BGColor = "rgb(0 0 0 / 85%)"
                // photoClass = "photo-on"
                // col4Content = ""
            } else if (this.props.Controls.OnboardDriverID == this.props.driver.CompetitorId && this.props.pages == true && this.props.driver.Position > 10){
                //Expand after top 10
                var expandClass = "expand-off"
                var nameBG = "rgb(255 255 255 / 95%)"
                var nameText = "#000000"
                // col4BGColor = "rgb(0 0 0 / 85%)"
                // col4Content = ""
                // photoClass = "photo-on"
            } else {
                //Drivers after the expanded driver
                var expandClass = "expand-off"
                if (parseInt(this.props.driver.Position) > parseInt(this.props.Session.Competitors[this.props.Controls.OnboardIndex].Position) && this.props.pages == false){
                    marginBoost = marginBoost + 203
                } else if (parseInt(this.props.Session.Competitors[this.props.Controls.OnboardIndex].Position) > 10 && parseInt(this.props.driver.Position) > parseInt(this.props.Session.Competitors[this.props.Controls.OnboardIndex].Position) && this.props.pages == true){
                    marginBoost = marginBoost + 203
                }
                
            }
        } else {
            var expandClass = "expand-off"
        }
        //Expand Check End



        if (!this.props.driver.Position){
            opacity = 0
        }

        var font = {
            fontSize: "14px"
        }

        if (this.props.ClientStates.Skin){
            font = {
                fontFamily: this.props.ClientStates.Skin,
                fontSize: "17px"
            }
        }

            return ( 
                // <span attr2="2" onClick={() => this.props.expandDriver(this.props.indexRow,"click")}>
                <span>
                    <div className={"AnimateMargin"} style={{position: "absolute",  top: this.props.margin[parseInt(this.props.driver.Position)]+marginBoost+"px", height: height, opacity: opacity, ...font}}>
                        {/* Expand div */}
                        <div className={expandClass} style={{top: this.props.margin+2+"px"}}>
                            <div className={"imageContainer "+ photoClass}>
                                {expandedHTML}
                                <img className="driverTowerImage" src={photoURL} onError={this.props.imageError}></img>
                            </div>
                        </div>
                        <div className={extraBoxClass}>
                            <div className='extraBoxText'>{extraBoxContent}</div>
                            <img className={extraBoxImgClass}src="images/CheckeredFlag.png"></img>
                        </div>
                        {/* Position Collumn */}
                        <div id={this.props.indexRow + "_pos"} className="gb3Col1">
                            <div className={positionClass || this.props.driver.PosChangeClass ||  "gb3PositionNeutral"}>{this.props.driver.Position}</div>
                        </div>
                        {/* Vehicle Number Collumn */}
                        <div className="gb3Col2">
                            <div className={"gb3CatBG "+ winnerNum} style={{backgroundColor: star_nbc || this.props.driver.nbc || "#2faae1", color: star_nfc || this.props.driver.nfc || "#ffffff"}}>
                                {this.props.driver.CompetitorNumber}
                            </div>
                        </div>
                        {/* Competitor Name Collumn */}
                        <div className={"gb3Col3 " + gb3NameWidth} style={{backgroundColor: nameBG, color: nameText}}>
                            <div className="gb3Name">{competitorName}</div>
                        </div>
                        {/* Data Collumn */}
                        <div className={"gb3Col4 " + "gb3_"+this.props.Controls.Col4} style={{backgroundColor: col4BGColor}}>
                            <div className="gapText">{col4Content}</div>
                        </div>

                        <div className='flagDiv'>
                            {/* <img className='flag' src={'/flags/'+this.props.nat+'.png'}></img> */}
                        </div>
                    
                        <div className='tint' style={{backgroundColor: rowTint, width: tintWidth, marginLeft: tintMargin}}>

                        </div>
                        
                        
                    </div>
                </span>
            );
        } 

}


 
export default gb3Row;