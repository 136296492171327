import React, { PureComponent } from 'react';

class msukGridTile extends PureComponent {

    render() { 

        
        if (this.props.position % 2 === 0) {
            var side = '500px';
        } else {
            var side = '0px';
        }

        //Calculates the margin shift for the current page to be visible
        var boost = (this.props.Controls.GridPage-1)*-600

        //Calculates if the tile should be below the current page (if highest number on page is lower than the tile position = boost the margin to ensure outside)
        if (parseInt(this.props.Controls.GridPage)*4 < parseInt(this.props.position)){
            boost = boost + 200
        }
        
        //Calculates if the tile should be above the current page (if lowest number on page is higher than the tile position = reduce the margin to ensure outside)
        if((parseInt(this.props.Controls.GridPage)-1)*4 >= parseInt(this.props.position)){
            boost = boost - 200
        }
        
        var tileSlot = {
            width: "656px",
            height: "222px",
            backgroundColor: "transparent",
            position: "absolute",
            overflow: "hidden",
            top: ((this.props.position-1)*150)+boost + "px",
            marginLeft: side
        }

        var posBG = {
            position: "absolute",
            marginTop: "120px",
            marginLeft: "40px",
            width: "130px",
            height: "92px",
            background: "linear-gradient(225deg,RGB(114,110,109), RGB(33,33,33))",
            transform: "skew(-15deg)",
            borderStyle: "solid",
            borderColor: "white",
            borderWidth: "1px",
            borderRadius: "40px 0px 40px 0px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            fontSize: "50px",
            fontFamily: "msuk-Bold",
            boxShadow: "5px 5px 5px #00000091"
        }

        var flag = {
            position: "absolute",
            width: "45px",
            height: "27px",
            marginTop: "109px",
            marginLeft: "555px",
            transform: "skew(-20deg)",
        }

        var driverNumber = {
            position: "absolute",
            marginTop: "109px",
            marginLeft: "600px",
            width: "45px",
            height: "27px",
            transform: "skew(-20deg)",
            background: "linear-gradient(225deg,RGB(114,110,109), RGB(33,33,33))",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            fontSize: "18px",
            fontFamily: "msuk-Medium",
        }

        var driverName = {
            position: "absolute",
            marginLeft: "99.5px",
            marginTop: "136px",
            height: "30px",
            width: "530px",
            transform: "skew(-20deg)",
            background: "linear-gradient(270deg,RGB(103,189,146), RGB(84,149,116))",
            display: "flex",
            alignItems: "center",
            justifyContent: "right",
            paddingRight: "5px",
            fontSize: "22px",
            fontFamily: "msuk-Medium",
            color: "white",
        }

        var divider = {
            position: "absolute",
            marginLeft: "98.25px",
            marginTop: "166px",
            height: "4px",
            width: "530px",
            transform: "skew(-20deg)",
            background: "linear-gradient(270deg,RGB(63,80,71), RGB(110,106,106))",
            display: "flex",
            alignItems: "center",
            justifyContent: "right",
        }

        var qualifyTime = {
            position: "absolute",
            marginLeft: "100px",
            marginTop: "170px",
            height: "25px",
            width: "518px",
            transform: "skew(-20deg)",
            background: "linear-gradient(270deg,RGB(103,189,146), RGB(84,149,116))",
            display: "flex",
            alignItems: "center",
            justifyContent: "right",
            paddingRight: "5px",
            fontSize: "18px",
            fontFamily: "msuk-Medium",
            color: "white",
        }

        var teamName = {
            position: "absolute",
            marginLeft: "91.75px",
            marginTop: "195px",
            height: "20px",
            width: "518px",
            transform: "skew(-20deg)",
            background: "linear-gradient(270deg,RGBA(63,80,71,1.0), RGBA(110,106,106,0.0))",
            display: "flex",
            alignItems: "center",
            justifyContent: "right",
            paddingRight: "5px",
            fontSize: "12px",
            fontFamily: "msuk-Medium",
            color: "white",
            letterSpacing: "1px"
        }

        var driverImg = {
            height: "225px"
        }

        var driverContainer = {
            position: "absolute",
            marginTop: "20px",
            marginLeft: "140px",
            overflow: "hidden",
            height: "195px",
            width: "100%"
        }

        var unskew ={
            transform: "skew(10deg)",
        }
            return ( 
                <div style={tileSlot} className="AnimateMargin">
                    <img style={flag} src={"./flags/"+this.props.driver.nat+".png"} onError={this.props.imageError}></img>
                    <div style={driverNumber}>
                        <div style={unskew}>{this.props.driver.CompetitorNumber}</div>
                    </div>
                    <div style={driverName}>
                        <div style={unskew}>{this.props.driver.CompetitorName.toUpperCase()}</div>
                    </div>
                    <div style={divider}></div>
                    <div style={qualifyTime}>
                        <div style={unskew}>{this.props.driver.teamName.toUpperCase()}</div>
                    </div>
                    <div style={teamName}>
                        <div style={unskew}>{this.props.driver.vehicle.toUpperCase()}</div>
                    </div>
                    <div style={posBG}>{this.props.position}</div>
                    <div style={driverContainer}>
                        <img style={driverImg} src={this.props.driver.Picture+"-grid.png"} onError={this.props.imageError}></img>
                    </div>
                </div>
            );
        }
            
}
export default msukGridTile ;
