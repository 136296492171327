import React, { Component } from 'react';
import './setup.css'; // Importing the CSS file
import SetupCompetitor from './setup_Comp';

class Setup extends Component {

    render() {
        document.title = 'Alpha Live Graphics -Setup';
        if(this.props.Session.Competitors == undefined){
            return(
                <div>No Competitors</div>
            )
        } else {
            return (
                <div>
                    <div className="competitor-row" key={999} style={{marginTop: 40 +"px"}}>
                        <span className="competitor-cell">ID</span>
                        <span className="competitor-cell">GRID POS</span>
                        <span className="competitor-cell">NUMBER</span>
                        <span className="competitor-cell">NAME</span>
                        <span className="competitor-cell">TEAM</span>
                        <span className="competitor-cell">VEHICLE</span>
                        <span className="competitor-cell">NAT</span>
                        <span className="competitor-cell">Q-Time</span>
                        <button className="competitor-cell" onClick={() => this.updateAll('new')}>UPDATE ALL</button>
                        <button className="competitor-cell" onClick={() => this.saveAll('new')}>SAVE ALL</button>
                    </div>
                    {this.props.Session.Competitors.map((driver, index) => (
                        <SetupCompetitor driver={driver} index={index} {...this.props} key={index}/>
                    ))}
                    {/* {Competitors.map(this.renderCompetitor)} */}
                    <div id="addPreset" className="addCompetitor"> 
                        <button className="competitor-cell" onClick={() => this.props.acAddPresetCompetitor('new')}>Save</button>
                        <input
                            id="inGameName"                       
                            className="competitor-cell"
                            type="text" 
                            attr1="inGameName"
                            defaultValue={"In Game Name"}
                        />
                        <input
                            id="GraphicName"                       
                            className="competitor-cell"
                            type="text" 
                            attr1="Graphic Name"
                            defaultValue={"Graphic Name"}
                        />
                        <span className="competitor-cell">Num:</span>
                        <input
                            id="NewNumber"                       
                            className="competitor-cell"
                            style={{width:'40px'}}
                            type="number" 
                            attr1="NewNumber"
                        />
                        <span className="competitor-cell">Grid:</span>
                        <input
                            id="GridPos"                       
                            className="competitor-cell"
                            style={{width:'40px'}}
                            type="number" 
                            attr1="GridPos"
                        />
                        <span className="competitor-cell">Team:</span>
                        <input 
                            id="TeamName"                      
                            className="competitor-cell"
                            type="text" 
                            attr1="TeamName"
                        />
                        <span className="competitor-cell">Vehicle:</span>
                        <input
                            id="Vehicle"               
                            className="competitor-cell"
                            type="text" 
                            attr1="Vehicle"
                        />
                        <select id="natCode" name="country"                    
                            attr1="nat"
                            defaultValue={"competitor.nat"}>
                            <option value="">Please Select a Country</option>
                            <option value="">Select a country</option>
                            <option value="AFG">Afghanistan</option>
                            <option value="ALB">Albania</option>
                            <option value="DZA">Algeria</option>
                            <option value="ASM">American Samoa</option>
                            <option value="AND">Andorra</option>
                            <option value="AGO">Angola</option>
                            <option value="AIA">Anguilla</option>
                            <option value="ATA">Antarctica</option>
                            <option value="ATG">Antigua and Barbuda</option>
                            <option value="ARG">Argentina</option>
                            <option value="ARM">Armenia</option>
                            <option value="ABW">Aruba</option>
                            <option value="AUS">Australia</option>
                            <option value="AUT">Austria</option>
                            <option value="AZE">Azerbaijan</option>
                            <option value="BHS">Bahamas</option>
                            <option value="BHR">Bahrain</option>
                            <option value="BGD">Bangladesh</option>
                            <option value="BRB">Barbados</option>
                            <option value="BLR">Belarus</option>
                            <option value="BEL">Belgium</option>
                            <option value="BLZ">Belize</option>
                            <option value="BEN">Benin</option>
                            <option value="BMU">Bermuda</option>
                            <option value="BTN">Bhutan</option>
                            <option value="BOL">Bolivia</option>
                            <option value="BIH">Bosnia and Herzegovina</option>
                            <option value="BWA">Botswana</option>
                            <option value="BRA">Brazil</option>
                            <option value="BRN">Brunei Darussalam</option>
                            <option value="BGR">Bulgaria</option>
                            <option value="BFA">Burkina Faso</option>
                            <option value="BDI">Burundi</option>
                            <option value="CPV">Cabo Verde</option>
                            <option value="KHM">Cambodia</option>
                            <option value="CMR">Cameroon</option>
                            <option value="CAN">Canada</option>
                            <option value="CYM">Cayman Islands</option>
                            <option value="CAF">Central African Republic</option>
                            <option value="TCD">Chad</option>
                            <option value="CHL">Chile</option>
                            <option value="CHN">China</option>
                            <option value="COL">Colombia</option>
                            <option value="COM">Comoros</option>
                            <option value="COG">Congo</option>
                            <option value="COD">Congo, Democratic Republic of the</option>
                            <option value="CRI">Costa Rica</option>
                            <option value="CIV">Côte d'Ivoire</option>
                            <option value="HRV">Croatia</option>
                            <option value="CUB">Cuba</option>
                            <option value="CYP">Cyprus</option>
                            <option value="CZE">Czech Republic</option>
                            <option value="DNK">Denmark</option>
                            <option value="DJI">Djibouti</option>
                            <option value="DMA">Dominica</option>
                            <option value="DOM">Dominican Republic</option>
                            <option value="ECU">Ecuador</option>
                            <option value="EGY">Egypt</option>
                            <option value="SLV">El Salvador</option>
                            <option value="GNQ">Equatorial Guinea</option>
                            <option value="ERI">Eritrea</option>
                            <option value="EST">Estonia</option>
                            <option value="SWZ">Eswatini</option>
                            <option value="ETH">Ethiopia</option>
                            <option value="FJI">Fiji</option>
                            <option value="FIN">Finland</option>
                            <option value="FRA">France</option>
                            <option value="GAB">Gabon</option>
                            <option value="GMB">Gambia</option>
                            <option value="GEO">Georgia</option>
                            <option value="DEU">Germany</option>
                            <option value="GHA">Ghana</option>
                            <option value="GRC">Greece</option>
                            <option value="GRL">Greenland</option>
                            <option value="GRD">Grenada</option>
                            <option value="GUM">Guam</option>
                            <option value="GTM">Guatemala</option>
                            <option value="GIN">Guinea</option>
                            <option value="GNB">Guinea-Bissau</option>
                            <option value="GUY">Guyana</option>
                            <option value="HTI">Haiti</option>
                            <option value="HND">Honduras</option>
                            <option value="HKG">Hong Kong</option>
                            <option value="HUN">Hungary</option>
                            <option value="ISL">Iceland</option>
                            <option value="IND">India</option>
                            <option value="IDN">Indonesia</option>
                            <option value="IRN">Iran</option>
                            <option value="IRQ">Iraq</option>
                            <option value="IRL">Ireland</option>
                            <option value="ISR">Israel</option>
                            <option value="ITA">Italy</option>
                            <option value="JAM">Jamaica</option>
                            <option value="JPN">Japan</option>
                            <option value="JOR">Jordan</option>
                            <option value="KAZ">Kazakhstan</option>
                            <option value="KEN">Kenya</option>
                            <option value="KIR">Kiribati</option>
                            <option value="PRK">Korea, Democratic People's Republic of</option>
                            <option value="KOR">Korea, Republic of</option>
                            <option value="KWT">Kuwait</option>
                            <option value="KGZ">Kyrgyzstan</option>
                            <option value="LAO">Lao People's Democratic Republic</option>
                            <option value="LVA">Latvia</option>
                            <option value="LBN">Lebanon</option>
                            <option value="LSO">Lesotho</option>
                            <option value="LBR">Liberia</option>
                            <option value="LBY">Libya</option>
                            <option value="LIE">Liechtenstein</option>
                            <option value="LTU">Lithuania</option>
                            <option value="LUX">Luxembourg</option>
                            <option value="MAC">Macao</option>
                            <option value="MDG">Madagascar</option>
                            <option value="MWI">Malawi</option>
                            <option value="MYS">Malaysia</option>
                            <option value="MDV">Maldives</option>
                            <option value="MLI">Mali</option>
                            <option value="MLT">Malta</option>
                            <option value="MHL">Marshall Islands</option>
                            <option value="MRT">Mauritania</option>
                            <option value="MUS">Mauritius</option>
                            <option value="MEX">Mexico</option>
                            <option value="FSM">Micronesia</option>
                            <option value="MDA">Moldova</option>
                            <option value="MCO">Monaco</option>
                            <option value="MNG">Mongolia</option>
                            <option value="MNE">Montenegro</option>
                            <option value="MSR">Montserrat</option>
                            <option value="MAR">Morocco</option>
                            <option value="MOZ">Mozambique</option>
                            <option value="MMR">Myanmar</option>
                            <option value="NAM">Namibia</option>
                            <option value="NRU">Nauru</option>
                            <option value="NPL">Nepal</option>
                            <option value="NLD">Netherlands</option>
                            <option value="NZL">New Zealand</option>
                            <option value="NIC">Nicaragua</option>
                            <option value="NER">Niger</option>
                            <option value="NGA">Nigeria</option>
                            <option value="NOR">Norway</option>
                            <option value="OMN">Oman</option>
                            <option value="PAK">Pakistan</option>
                            <option value="PLW">Palau</option>
                            <option value="PAN">Panama</option>
                            <option value="PNG">Papua New Guinea</option>
                            <option value="PRY">Paraguay</option>
                            <option value="PER">Peru</option>
                            <option value="PHL">Philippines</option>
                            <option value="POL">Poland</option>
                            <option value="PRT">Portugal</option>
                            <option value="QAT">Qatar</option>
                            <option value="ROU">Romania</option>
                            <option value="RUS">Russian Federation</option>
                            <option value="RWA">Rwanda</option>
                            <option value="WSM">Samoa</option>
                            <option value="SMR">San Marino</option>
                            <option value="STP">Sao Tome and Principe</option>
                            <option value="SAU">Saudi Arabia</option>
                            <option value="SEN">Senegal</option>
                            <option value="SRB">Serbia</option>
                            <option value="SYC">Seychelles</option>
                            <option value="SLE">Sierra Leone</option>
                            <option value="SGP">Singapore</option>
                            <option value="SVK">Slovakia</option>
                            <option value="SVN">Slovenia</option>
                            <option value="SLB">Solomon Islands</option>
                            <option value="SOM">Somalia</option>
                            <option value="ZAF">South Africa</option>
                            <option value="ESP">Spain</option>
                            <option value="LKA">Sri Lanka</option>
                            <option value="SDN">Sudan</option>
                            <option value="SUR">Suriname</option>
                            <option value="SWE">Sweden</option>
                            <option value="CHE">Switzerland</option>
                            <option value="SYR">Syrian Arab Republic</option>
                            <option value="TWN">Taiwan</option>
                            <option value="TJK">Tajikistan</option>
                            <option value="TZA">Tanzania</option>
                            <option value="THA">Thailand</option>
                            <option value="TLS">Timor-Leste</option>
                            <option value="TGO">Togo</option>
                            <option value="TON">Tonga</option>
                            <option value="TTO">Trinidad and Tobago</option>
                            <option value="TUN">Tunisia</option>
                            <option value="TUR">Turkey</option>
                            <option value="TKM">Turkmenistan</option>
                            <option value="TUV">Tuvalu</option>
                            <option value="UGA">Uganda</option>
                            <option value="UKR">Ukraine</option>
                            <option value="ARE">United Arab Emirates</option>
                            <option value="GBR">United Kingdom</option>
                            <option value="USA">United States of America</option>
                            <option value="URY">Uruguay</option>
                            <option value="UZB">Uzbekistan</option>
                            <option value="VUT">Vanuatu</option>
                            <option value="VEN">Venezuela</option>
                            <option value="VNM">Viet Nam</option>
                            <option value="YEM">Yemen</option>
                            <option value="ZMB">Zambia</option>
                            <option value="ZWE">Zimbabwe</option>
                        </select>
                        <span className="competitor-cell">Qual Time:</span>
                        <input
                            id="QualTime"             
                            className="competitor-cell"
                            type="text" 
                            attr1="QualTime"
                        />

                    </div>
                </div>
            );
            
        }
        
    }

    saveAll = async () => {
        const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
        var i = 0
        var competitors = this.props.Session.Competitors
        while (i < competitors.length){
            var button = document.getElementById("saveButton-"+i)
            button.click()
            i++
            await delay(100); // Wait for 500ms before the next click
        }
    }

    updateAll = async () => {
        const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
        var i = 0
        var competitors = this.props.Session.Competitors
        while (i < competitors.length){
            var button = document.getElementById("updateButton-"+i)
            button.click()
            i++
            await delay(100); // Wait for 500ms before the next click
        }
    }
}

export default Setup;
