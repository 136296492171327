import React, { PureComponent } from 'react';
import '../stylesheets/TowerRows.css';

class GroupedRow extends PureComponent {
    
    render() {
        var marginBoost = 0
        var photoClass = "photo-off"
        var winnerNum = ""
        //Opacity Calculations START
        if (this.props.driver.GroupNumberOfLaps < 1){
            var opacity = 0
            marginBoost = 1000
        } else if (this.props.driver.seen == false) {
            var opacity = 0
            marginBoost = 10000000000
        } else {
            var opacity = 1
        }
        //Opacity Calculations END

        if (this.props.driver.Expanded == true){
            var height = "75px" 
        } else {
            var height = "35px" 
        }

        //Battle Calculations START
        if (this.props.Controls.BattleShow == true){
            var battleQuestion = this.props.Controls.BattleDrivers.indexOf(parseInt(this.props.driver.CompetitorId))
            if (battleQuestion != -1 && this.props.pages == false){
                var nameBG = "rgb(255 255 255 / 75%)"
                var nameText = "#000000"
                marginBoost = 25
            } else if (battleQuestion != -1 && this.props.pages == true && parseInt(this.props.Controls.BattlePosition) > 12) {
                var nameBG = "rgb(255 255 255 / 75%)"
                var nameText = "#000000"
                marginBoost = 25
            } else if (battleQuestion == -1 && this.props.pages == true && parseInt(this.props.driver.Position) >= parseInt(this.props.Controls.BattlePosition) && parseInt(this.props.Controls.BattlePosition) > 12) {
                var nameBG = "rgb(0 0 0 / 85%)"
                var nameText = "#fff"
                marginBoost = 27
            } else if (parseInt(this.props.driver.Position) >= parseInt(this.props.Controls.BattlePosition) && this.props.pages == false ) {
                var nameBG = "rgb(0 0 0 / 85%)"
                var nameText = "#fff"
                marginBoost = 27
            } else {
                var nameBG = "rgb(0 0 0 / 85%)"
                var nameText = "#fff"
            }
        } else {
            var nameBG = "rgb(0 0 0 / 85%)"
            var nameText = "#fff"
        }
        //Battle Calculations END

        //Smart Naming Calculations START
        if(this.props.driver.CompetitorName){
            var assName = this.props.driver.CompetitorName.indexOf('* ')
            if (assName != -1){
                var compName = this.props.driver.CompetitorName.replace('* ', '');
                var star_nbc = "#2faae1"
                var star_nfc = "#ffffff"
            }
            if (this.props.driver.CompetitorName.length > 15){
            var competitorName = compName || this.props.driver.CompetitorName
            var run = 0
            while (competitorName.length > 15){
                var nameArray = competitorName.split(",").join(" ").split(" ")
                
                if (run > 0){
                    var nameArray = competitorName.split("-").join(" ").split(" ")
                    var nameStart = nameArray[0]
                    var firstName = nameStart.substring(0,2*run+1)
                } else {
                    var firstName = nameArray[0][0]
                }

                nameArray.shift()
                var competitorName = firstName+"."+nameArray
                run++

                competitorName = competitorName.replace(/,/g, ' ');

                if (run > 5){ break }
            }

            } else {
                var competitorName = compName || this.props.driver.CompetitorName

            }
        } else {
            var competitorName = "undefined"
        }
        //Smart Naming Calculations END

        //Position Positive/Negative START
        if (this.props.driver.PositionChange > 0){
            var positionClass = undefined;
        } else if (this.props.driver.PositionChange < 0){
            var positionClass = undefined;
        } else {
            var positionClass = undefined;
        }
        //Position Positive/Negative END

        //extraBox & position class Calculations START
        var extraBoxClass = "extraBox transparent"
        var extraBoxContent = ""
        var extraBoxImgClass = "extraBoxImgHide"
        var tintMargin = 40
       if (this.props.driver.IsBestLapInRace == true && this.props.driver.TakenChequered == true){
            extraBoxClass = "extraBox Black"
            positionClass = "fastestPink positionneutral"
            extraBoxImgClass = "extraBoxImgShow"
            tintMargin = "5px"
        } else if (this.props.driver.TakenChequered == true){
            extraBoxClass = "extraBox Black"
            positionClass = "positionneutral"
            extraBoxImgClass = "extraBoxImgShow"
            tintMargin = 5
        } else if (this.props.driver.InPit == true){
            extraBoxClass = "extraBox StreamBlue"
            positionClass = "StreamBlue positionneutral"
            extraBoxContent = "PIT"
            tintMargin = 5
        } else if (this.props.driver.IsBestLapInRace == true && this.props.driver.NumberOfLaps > 1 && this.props.Session.SessionType != 'Qual' && this.props.Session.SessionType != 'Qualifying' && this.props.Session.SessionType != 'Practice'){
            extraBoxClass = "extraBox fastestPink"
            positionClass = "fastestPink positionneutral"
            extraBoxContent = "FL"
            tintMargin = 5
        }
        //extraBox & position class Calculations END

        //col4 content calculations START
        var col4Content = ""
        var col4BGColor = "rgb(0 0 0 / 65%)"
        var tintWidth = 310
        if (this.props.Controls.Col4 == "gap"){
            if (this.props.driver.Position == 1){
                col4Content = "Gap"
            } else {
                col4Content = this.props.driver.GroupBehind
            }
            tintWidth = 310 + ( 40 - tintMargin)
        } else if (this.props.Controls.Col4 == "diff"){
            if (this.props.driver.Position == 1){
                col4Content = "Diff"
            } else {
                col4Content = this.props.driver.GroupGap
            }
            tintWidth = 310 + ( 40 - tintMargin)
        } else if (this.props.Controls.Col4 == "pits"){
            col4Content = this.props.driver.ps
            tintWidth = 265 + ( 40 - tintMargin)
        } else if (this.props.Controls.Col4 == "positions"){
            if (this.props.ClientStates.TimingSystem == "TSL"){
                col4Content = this.props.driver.GridPosition - this.props.driver.Position
            } else {
                col4Content = (this.props.driver.GridPosition+1) - this.props.driver.Position
            }
            
            tintWidth = 265 + ( 40 - tintMargin)
            if (col4Content > 0){
                col4BGColor = "rgb(0 146 69 / 85%)"
                col4Content = "▲ " + col4Content
            } else if (col4Content < 0){
                col4BGColor = "rgb(193 39 45 / 85%)"
                col4Content = "▼ " + Math.abs(col4Content)
            } else {
                col4Content = "◀▶"
            }
        } else if (this.props.Controls.Col4 == "none"){
            tintWidth = 265
        }
        //col4 class calculations END

        //Retired Settings START
        if (this.props.driver.Retired == true){
            var rowTint = "rgb(0 0 0 / 70%)"
        } else {
            var rowTint = "transparent"
        }
        //Retired Settings END

        //Progression Settings Start
        var progressionNo = this.props.Controls.ProgressionPosition || this.props.ClientStates.DefaultProgression
        if (this.props.Controls.ProgressionShow == true && this.props.pages == false){
            if (parseInt(this.props.driver.Position) > parseInt(progressionNo)){
                marginBoost = marginBoost + 9
            } else {
                marginBoost = marginBoost + 0
            }
        }
        //Progression Settings End

        //Expand Content Start
        if (this.props.driver.Position == 1 && this.props.driver.TakenChequered == true && this.props.ClientStates.DriverPhotos == true){
            //Winner Data
            // var photoURL = this.props.driver.Picture + "-4.png"+`?t=${Date.now()}`
            var photoURL = this.props.driver.Picture + "-4.png"
            var firstSpaceIndex = this.props.driver.CompetitorName.indexOf(" ");
            var firstName = this.props.driver.CompetitorName.substring(0, firstSpaceIndex);
            var remainingName = this.props.driver.CompetitorName.substring(firstSpaceIndex + 1);
            var expandedHTML = <div className='expandedTextBox'><div className='winnerFirstName'>{firstName}</div><div className='winnerRemainingName'>{remainingName}</div><div className='winnerTeam'>{this.props.driver.teamName}</div><div className='winnerVehicle'>{this.props.driver.vehicle}</div></div>
            competitorName = ""
            positionClass = "winnerPosition"
            winnerNum = "winnerNum"
        } else if (this.props.driver.Position == 1 ){
            //1st Position Data
            // var photoURL = this.props.driver.Picture + "-3.png"+`?t=${Date.now()}`
            var photoURL = this.props.driver.Picture + "-3.png"
            var expandedHTML = <div className='expandedTextBox'><div className='winnerTeam'>{this.props.driver.teamName}</div><div className='winnerVehicle'>{this.props.driver.vehicle}</div></div>
        } else {
            //All other Positions Data
            // var photoURL = this.props.driver.Picture + "-3.png"+`?t=${Date.now()}`
            var photoURL = this.props.driver.Picture + "-3.png"
            var expandedHTML = <div className='expandedTextBox'><div className='winnerTeam'>{this.props.driver.teamName}</div><div className='winnerVehicle'>{this.props.driver.vehicle}</div></div>
        }
        //Expand Content End

        //Expand Check Start
        if (this.props.Controls.Expand == true){
            if (this.props.Controls.ExpandDriverID == this.props.driver.CompetitorId && this.props.pages == false){
                //Expand top 10
                var expandClass = "expand-on"
                col4BGColor = "rgb(0 0 0 / 85%)"
                photoClass = "photo-on"
                col4Content = ""
            } else if (this.props.Controls.ExpandDriverID == this.props.driver.CompetitorId && this.props.pages == true && this.props.driver.GroupPosition > 10){
                //Expand after top 10
                var expandClass = "expand-on"
                col4BGColor = "rgb(0 0 0 / 85%)"
                col4Content = ""
                photoClass = "photo-on"
            } else {
                //Drivers after the expanded driver
                var expandClass = "expand-off"
                if (this.props.driver.Position > this.props.Session.Competitors[this.props.Controls.ExpandIndex].GroupPosition && this.props.pages == false){
                    marginBoost = marginBoost + 72
                } else if (this.props.Session.Competitors[this.props.Controls.ExpandIndex].Position > 10 && this.props.driver.Position > this.props.Session.Competitors[this.props.Controls.ExpandIndex].Position && this.props.pages == true){
                    marginBoost = marginBoost + 72
                }
                
            }
        } else {
            var expandClass = "expand-off"
        }
        //Expand Check End

        if (!this.props.driver.GroupPosition){
            opacity = 0
        }

            return ( 
                // <span attr2="2" onClick={() => this.props.expandDriver(this.props.indexRow,"click")}>
                <span>
                    <div className={"AnimateMargin"} style={{position: "absolute",  top: this.props.margin[parseInt(this.props.driver.GroupPosition)]+marginBoost+"px", height: height, opacity: opacity}}>
                        {/* expand div below*/}
                        <div className={expandClass} style={{top: this.props.margin+2+"px"}}>
                            <div className={"imageContainer "+ photoClass}>
                                {expandedHTML}
                                <img className="driverTowerImage" src={photoURL} onError={this.props.imageError}></img>
                            </div>
                            
                            {/* <div className="expandedDiff">{col4Content}</div> */}
                        </div>
                        <div className={extraBoxClass}>
                            <div className='extraBoxText'>{extraBoxContent}</div>
                            <img className={extraBoxImgClass}src="images/CheckeredFlag.png"></img>
                        </div>
                        <div id={this.props.indexRow + "_pos"} className="col1">
                            <div className={positionClass || this.props.driver.PosChangeClass ||  "positionneutral"}>{this.props.driver.GroupPosition}</div>
                        </div>

                        <div className="col2">
                            <div className={"catBG "+ winnerNum} style={{backgroundColor: star_nbc || this.props.driver.nbc || "#2faae1", color: star_nfc || this.props.driver.nfc || "#ffffff"}}>
                                {this.props.driver.CompetitorNumber}
                            </div>
                        </div>

                        <div className="col3" style={{backgroundColor: nameBG, color: nameText}}>
                            <div className="name">{competitorName}</div>
                        </div>

                        <div className={"col4 " + this.props.Controls.Col4} style={{backgroundColor: col4BGColor}}>
                            <div className="gapText">{col4Content}</div>
                        </div>

                        <div className='flagDiv'>
                            {/* <img className='flag' src={'/flags/'+this.props.nat+'.png'}></img> */}
                        </div>
                    
                        <div className='tint' style={{backgroundColor: rowTint, width: tintWidth, marginLeft: tintMargin}}>

                        </div>
                        
                        
                    </div>
                </span>
            );
        } 

}


 
export default GroupedRow;