import React, { PureComponent } from 'react';
import './stylesheets/basicControls.css';

class BasicControls extends PureComponent {
    state = { 

     }

    render() {

        if (this.props.ClientStates.TimingSystem == "AC"){
            return(         
                <div className='box' style={{backgroundColor: this.props.color}}>
                    <div className='boxHeader' attr='150px' onClick={this.props.collapse}>
                        BASIC CONTROLS
                    </div>
                    <div className='buttonBox'>     
                        <div className='buttonRow'>CLOCK 
                            <button attr1='clockShow' className="controlBUTTON" onClick={this.props.controlSend}>Show</button>
                            <button attr1='clockHide' className="controlBUTTON" onClick={this.props.controlSend}>Hide</button>
                        </div>
                        <div className='buttonRowRight'>TOWER 
                            <button attr1='towerShow' className="controlBUTTON" onClick={this.props.controlSend}>Show</button>
                            <button attr1='towerHide' className="controlBUTTON" onClick={this.props.controlSend}>Hide</button>
                        </div>              
                        <div className='buttonRow'>RESULTS 
                            <button attr1='resultsShow' className="controlBUTTON" onClick={this.props.controlSend}>Show</button>
                            <button attr1='resultsPage' className="controlBUTTON" onClick={this.props.controlSend}>Page</button>
                            <button attr1='resultsHide' className="controlBUTTON" onClick={this.props.controlSend}>Hide</button>
                        </div>
                        <div className='buttonRowRight'>BATTLE 
                            <button attr1='battleShow' className="controlBUTTON" onClick={this.props.controlSend}>Show</button>
                            <button attr1='battleClear' className="controlBUTTON" onClick={this.props.controlSend}>Clear</button>                    
                        </div> 
                        <div className='buttonRow'>GRIDS 
                            <button attr1='gridsShow' className="controlBUTTON" onClick={this.props.controlSend}>Show</button>
                            <button attr1='gridsPage' className="controlBUTTON" onClick={this.props.controlSend}>Page</button>
                            <button attr1='gridsHide' className="controlBUTTON" onClick={this.props.controlSend}>Hide</button>
                        </div>
                        <div className='buttonRowRight'>NAME
                            <button attr1='NAME-DRIVER' className="controlBUTTON" onClick={this.props.controlSend}>Driver</button>
                            <button attr1='NAME-TEAM' className="controlBUTTON" onClick={this.props.controlSend}>Team</button>                    
                        </div>
                        <div className='buttonRow'>DATA
                            <button attr1='col4Gap' className="controlBUTTON" onClick={this.props.controlSend}>Gap</button>
                            <button attr1='col4Diff' className="controlBUTTON" onClick={this.props.controlSend}>Diff</button>
                            <button attr1='col4Pos' className="controlBUTTON" onClick={this.props.controlSend}>Pos</button>
                            <button attr1='col4None' className="controlBUTTON" onClick={this.props.controlSend}>Hide</button>
                        </div>
                        <div className='buttonRow'>AC
                            {/* <button attr1='Next_Session' className="controlBUTTON" onClick={this.props.acSendMessage}>NEXT</button>
                            <button attr1='Car_Info' className="controlBUTTON" onClick={this.props.acSendMessage}>CAR INFO</button>
                            <button attr1='Session_Info' className="controlBUTTON" onClick={this.props.acSendMessage}>SESSION</button>
                            <button attr1='Restart_Session' className="controlBUTTON" onClick={this.props.acSendMessage}>RESTART</button>
                            <button attr1='Start_RealTime' className="controlBUTTON" onClick={this.props.acSendMessage}>REAL TIME</button> */}
                            <button attr1='AC_SET_GRID' className="controlBUTTON" onClick={this.props.controlSend}>SET GRID</button>
                        </div>
                    </div>
                </div>
            )
        } if (this.props.ClientStates.Skin == "basic"){
            return(         
                <div className='box' style={{backgroundColor: this.props.color}}>
                    <div className='boxHeader' attr='150px' onClick={this.props.collapse}>
                        BASIC CONTROLS
                    </div>
                    <div className='buttonBox'>     
                        <div className='buttonRow'>CLOCK 
                            <button attr1='clockShow' className="controlBUTTON" onClick={this.props.controlSend}>Show</button>
                            <button attr1='clockHide' className="controlBUTTON" onClick={this.props.controlSend}>Hide</button>
                        </div>
                        <div className='buttonRowRight'>TOWER 
                            <button attr1='towerShow' className="controlBUTTON" onClick={this.props.controlSend}>Show</button>
                            <button attr1='towerHide' className="controlBUTTON" onClick={this.props.controlSend}>Hide</button>
                        </div>              
                        <div className='buttonRow'>RESULTS 
                            <button attr1='resultsShow' className="controlBUTTON" onClick={this.props.controlSend}>Show</button>
                            <button attr1='resultsPage' className="controlBUTTON" onClick={this.props.controlSend}>Page</button>
                            <button attr1='resultsHide' className="controlBUTTON" onClick={this.props.controlSend}>Hide</button>
                        </div>
                        <div className='buttonRowRight'>BATTLE 
                            <button attr1='battleShow' className="controlBUTTON" onClick={this.props.controlSend}>Show</button>
                            <button attr1='battleClear' className="controlBUTTON" onClick={this.props.controlSend}>Clear</button>                    
                        </div> 
                        <div className='buttonRowRight'>NAME
                            <button attr1='NAME-DRIVER' className="controlBUTTON" onClick={this.props.controlSend}>Driver</button>
                            <button attr1='NAME-TEAM' className="controlBUTTON" onClick={this.props.controlSend}>Team</button>                    
                        </div>
                        <div className='buttonRow'>DATA
                            <button attr1='col4Gap' className="controlBUTTON" onClick={this.props.controlSend}>Gap</button>
                            <button attr1='col4Diff' className="controlBUTTON" onClick={this.props.controlSend}>Diff</button>
                            <button attr1='col4Pos' className="controlBUTTON" onClick={this.props.controlSend}>Pos</button>
                            <button attr1='col4None' className="controlBUTTON" onClick={this.props.controlSend}>Hide</button>
                        </div>
                    </div>
                </div>
            )
        } else {
            return(         
                <div className='box' style={{backgroundColor: this.props.color}}>
                    <div className='boxHeader' onClick={this.props.collapse}>
                        BASIC CONTROLS
                    </div>
                    <div className='buttonBox'>     
                        <div className='buttonRow'>CLOCK 
                            <button attr1='clockShow' className="controlBUTTON" onClick={this.props.controlSend}>Show</button>
                            <button attr1='clockHide' className="controlBUTTON" onClick={this.props.controlSend}>Hide</button>
                        </div>
                        <div className='buttonRowRight'>BATTLE 
                            <button attr1='battleShow' className="controlBUTTON" onClick={this.props.controlSend}>Show</button>
                            <button attr1='battleClear' className="controlBUTTON" onClick={this.props.controlSend}>Clear</button>                    
                        </div>               
                        <div className='buttonRow'>TOWER 
                            <button attr1='towerShow' className="controlBUTTON" onClick={this.props.controlSend}>Show</button>
                            <button attr1='towerHide' className="controlBUTTON" onClick={this.props.controlSend}>Hide</button>
                        </div>
                        <div className='buttonRowRight'>ADVANCE
                            <button attr1='progressionShow' className="controlBUTTON" onClick={this.props.controlSend}>Show</button>
                            <button attr1='progressionClear' className="controlBUTTON" onClick={this.props.controlSend}>Clear</button>                    
                        </div>  
                        <div className='buttonRow'>RESULTS 
                            <button attr1='resultsShow' className="controlBUTTON" onClick={this.props.controlSend}>Show</button>
                            <button attr1='resultsPage' className="controlBUTTON" onClick={this.props.controlSend}>Page</button>
                            <button attr1='resultsHide' className="controlBUTTON" onClick={this.props.controlSend}>Hide</button>
                        </div>
                        <div className='buttonRow'>GRIDS 
                            <button attr1='gridsShow' className="controlBUTTON" onClick={this.props.controlSend}>Show</button>
                            <button attr1='gridsPage' className="controlBUTTON" onClick={this.props.controlSend}>Page</button>
                            <button attr1='gridsHide' className="controlBUTTON" onClick={this.props.controlSend}>Hide</button>
                        </div>
                        <div className='buttonRowRight'>CLASS INFO
                            <button attr1='catShow' className="controlBUTTON" onClick={this.props.controlSend}>Show</button>
                            <button attr1='catClear' className="controlBUTTON" onClick={this.props.controlSend}>Clear</button>                    
                        </div>  
                        <div className='buttonRow'>DATA COL 
                            <button attr1='col4Gap' className="controlBUTTON" onClick={this.props.controlSend}>Gap</button>
                            <button attr1='col4Diff' className="controlBUTTON" onClick={this.props.controlSend}>Diff</button>
                            <button attr1='col4Pos' className="controlBUTTON" onClick={this.props.controlSend}>Pos</button>
                            <button attr1='col4Pits' className="controlBUTTON" onClick={this.props.controlSend}>Pits</button>
                            <button attr1='col4None' className="controlBUTTON" onClick={this.props.controlSend}>Hide</button>
                        </div>
                        <div className='buttonRowRight'>NAME
                            <button attr1='NAME-DRIVER' className="controlBUTTON" onClick={this.props.controlSend}>Driver</button>
                            <button attr1='NAME-TEAM' className="controlBUTTON" onClick={this.props.controlSend}>Team</button>                    
                        </div>  
                    </div>
                </div>
            )
        }

        
    }
}

export default BasicControls;