import React, { PureComponent } from 'react';
import ResultsRow from './ginettaResultsRow';
import '../stylesheets/GinettaResults.css';
import '../stylesheets/GinettaResultsRow.css';

class GinettaResults extends PureComponent { 

    render() { 

            if(this.props.Controls.ResultsShow == true){
                var resultsClass = "resultsDiv_show"
            } else {
                var resultsClass = "resultsDiv_hide"
            }
            
            if(this.props.Session.Competitors){
                var index = this.props.Session.Competitors.findIndex(x => parseInt(x.Position) === 1)
                //BP 24hr Classes
                if (index != -1){
                   var assName = this.props.Session.Competitors[index].CompetitorName.indexOf('* ')
                    if (assName != -1){
                        var compName = this.props.Session.Competitors[index].CompetitorName.replace('* ', '');
                        var star_nbc = "#2faae1"
                        var star_nfc = "#ffffff"
                    } 
                }
                
            } else {
                var index = 0
            }

            if(this.props.Session.State == "ChequeredFlag" || this.props.Session.State == "Ended" ||  this.props.Session.End == true){
                var resultText = "Result"
            } else {
                var resultText = "Current Standings"
            }

            //Country Flags
            if (this.props.ClientStates.CountryFlags == true){
                if (this.props.Session.Competitors[index]){
                    var nationFlag = this.props.Session.Competitors[index].nat 
                } else {
                    var nationFlag = "blank"
                }
                
                var nationFlagClass = "resultNationFlag"
            } else {
                var nationFlag = "blank"
                var nationFlagClass = "resultNoNationFlag"
            }

            
            
            if(this.props.Session.Competitors[index] && this.props.ClientStates.DriverPhotos == false){
                return (
                    <div className={resultsClass}>
                        <div className='ginettaResultsHead_Narrow'>
                            <div className='ginettaResultsHeadDiv_Narrow'>
                                <div className='upperText_Narrow'>
                                    {this.props.Session.SessionName}
                                </div>  
                            </div>
                            <div className='ginettaResultsHeadDiv_Narrow'>
                                <div className='lowerText'>
                                    {resultText}
                                </div>
                            </div>
                        </div>
                        <div className='firstPlaceRow'>
                            <div className='resultPositionBox'>
                                <div className='resultPositionText'>
                                    1
                                </div>
                            </div>
                            <div className='resultNumberBox' style={{backgroundColor: star_nbc || this.props.Session.Competitors[index].nbc || "#2faae1", color: star_nfc || this.props.Session.Competitors[index].nfc || "#fff"}}>
                                <div className='numberText'>
                                    {this.props.Session.Competitors[index].CompetitorNumber}
                                </div>
                            </div>
                            <div className='resultNameBox'>
                                <div className='resultNameText'>
                                    {compName || this.props.Session.Competitors[index].CompetitorName}
                                </div>
                            </div>
                            <div className='resultGapBox'>
                                <div className='resultGapText'>
                                    {this.props.Session.Competitors[index].NumberOfLaps + " LAPS"}
                                </div>
                            </div>
                        </div>

                        <div className='resultsList'>
                            {this.props.Session.Competitors.map((driver, index) => (
                                <ResultsRow driver={driver} indexRow={index} {...this.props} key={index}/>
                            ))}
                        </div>
                    </div>
                )
            } else if (this.props.Session.Competitors[index] && this.props.ClientStates.DriverPhotos == true && this.props.Session.SessionType == "Qual") {
                return ( 
                    <div className={resultsClass}>
                        <div className='ginettaResultsHead'>
                            <div className='resultsHeadDiv'>
                                <div className='upperText'>
                                    {this.props.Session.SessionName}
                                </div>  
                            </div>
                            <div className='resultsHeadDiv'>
                                <div className='lowerText'>
                                    RESULTS
                                </div>
                            </div>
                        </div>
                        <div className='ginettaResultsWinner'>
                            <div className='winnerText'>POLE POSITION</div>
                        </div>
                        <div className='ginettaFirstPlaceBox'>
                            <div className='ginettaFirstPlaceNumberPlate' style={{backgroundColor: this.props.Session.Competitors[index].nbc || "#2faae1", color: this.props.Session.Competitors[index].nfc || "#fff"}}>
                                <div className='ginettaFirstPlaceNumberText'>{this.props.Session.Competitors[index].CompetitorNumber}</div>
                            </div>
                            <img className="ginettaResultWinnerNationFlag" src={"flags/"+nationFlag+".png"} onError={this.props.imageError}></img>
                            <img className="ginettaResultWinnerVehicleLogo" src="images/GinettaLargeIcon.png"></img>
                            <div className='firstPlaceNameText'>{this.props.Session.Competitors[index].CompetitorName}</div>
                            <img className="ginettaResultPoleImage" src={this.props.Session.Competitors[index].Picture + "-7.png"} onError={this.props.imageError}></img>
                            <div className="resultWinnerSubText" style={{marginTop: "80px"}}>{this.props.Session.Competitors[index].teamName}</div>
                            <div className="resultWinnerSubText">{this.props.Session.Competitors[index].vehicle}</div>
                            <div className="resultWinnerLaps">{this.props.msToTime(this.props.Session.Competitors[index].BestLaptime*1000)}</div>
                            
                        </div>

                        <div className='resultsListPhoto'>
                            {this.props.Session.Competitors.map((driver, index) => (
                                <ResultsRow driver={driver} indexRow={index} {...this.props} key={index}/>
                            ))}
                        </div>
                    </div>
                );
            } else if (this.props.Session.Competitors[index] && this.props.ClientStates.DriverPhotos == true) {
                return ( 
                    <div className={resultsClass}>
                        <div className='ginettaResultsHead'>
                            <div className='resultsHeadDiv'>
                                <div className='upperText'>
                                    {this.props.Session.SessionName}
                                </div>  
                            </div>
                            <div className='resultsHeadDiv'>
                                <div className='lowerText'>
                                    RESULTS
                                </div>
                            </div>
                        </div>
                        <div className='ginettaResultsWinner'>
                            <div className='winnerText'>WINNER</div>
                        </div>
                        <div className='ginettaFirstPlaceBox'>
                            <div className='ginettaFirstPlaceNumberPlate' style={{backgroundColor: this.props.Session.Competitors[index].nbc || "#2faae1", color: this.props.Session.Competitors[index].nfc || "#fff"}}>
                                <div className='ginettaFirstPlaceNumberText'>{this.props.Session.Competitors[index].CompetitorNumber}</div>
                            </div>
                            <img className="ginettaResultWinnerNationFlag" src={"flags/"+nationFlag+".png"} onError={this.props.imageError}></img> 
                            <img className="ginettaResultWinnerVehicleLogo" src="images/GinettaLargeIcon.png"></img>
                            <div className='firstPlaceNameText'>{this.props.Session.Competitors[index].CompetitorName}</div>
                            <img className="resultWinnerImage" src={this.props.Session.Competitors[index].Picture + "-4.png"} onError={this.props.imageError}></img>
                            <div className="resultWinnerSubText" style={{marginTop: "80px"}}>{this.props.Session.Competitors[index].teamName}</div>
                            <div className="resultWinnerSubText">{this.props.Session.Competitors[index].vehicle}</div>
                            <div className="resultWinnerLaps">{this.props.Session.Competitors[index].NumberOfLaps + " LAPS"}</div>
                
                        </div>

                        <div className='ginettaResultsListPhoto'>
                            {this.props.Session.Competitors.map((driver, index) => (
                                <ResultsRow driver={driver} indexRow={index} {...this.props} key={index}/>
                            ))}
                        </div>
                    </div>
                );
            } else {
                return (
                <div className="resultsDiv_hide">
                        <div className='ginettaResultsHead'>
                            <div className='resultsHeadDiv'>
                                <div className='upperText'>
                                    {this.props.Session.SessionName}
                                </div>  
                            </div>
                            <div className='resultsHeadDiv'>
                                <div className='lowerText'>
                                    RESULTS
                                </div>
                            </div>
                        </div>
                        <div className='ginettaResultsWinner'>
                            <div className='winnerText'>WINNER</div>
                        </div>
                        <div className='ginettaFirstPlaceBox'>
                            <div className='ginettaFirstPlaceNumberPlate' style={{backgroundColor: "transparent", color: "transparent"}}>
                                <div className='ginettaFirstPlaceNumberText'></div>
                            </div>
                            <div className='firstPlaceNameText'></div>
                            <img className="resultWinnerImage" src={"images/Blank.png"}></img>
                            <div className="resultWinnerSubText" style={{marginTop: "80px"}}></div>
                            <div className="resultWinnerSubText"></div>
                            <div className="resultWinnerLaps">LAPS</div>
                
                        </div>

                        <div className='resultsListPhoto'>
                            {this.props.Session.Competitors.map((driver, index) => (
                                <ResultsRow driver={driver} indexRow={index} {...this.props} key={index}/>
                            ))}
                        </div>
                    </div>
                );
            }   
        }        
    }
export default GinettaResults ;