import React, { Component } from 'react';
import WebRTCStreamReceiver from './webRTCStreamer';
import './stylesheets/Onboard.css';

class OnboardView extends Component {
  state = {
    transition: 'height 1000ms ease-in-out',
  };

  transitionEnd = (e) => {
    if (e.propertyName == 'height') {
      console.log(e)
      console.log(e.target.clientHeight)
      if(e.target.clientHeight == 200){
        this.setState({ transition: 'height 1000ms ease-in-out, margin-top 1000ms ease-in-out' });
      } else{
        this.setState({ transition: 'height 1000ms ease-in-out' });
      }
    }
  };

  render() {
    var onboardPos = this.props.Controls.OnboardIndex > -1 ? this.props.Session.Competitors[this.props.Controls.OnboardIndex].Position : 0;

    if (parseInt(onboardPos) > 12){
      onboardPos = 13
    }

    if(this.props.Controls.Onboard == true){
      var height = '200px'
    } else {
      var height = '0px'
    }
    
    const imageStyle = {
      width: '356px',
      position: 'absolute',
      overflow: 'hidden',
      marginTop: ((onboardPos * 35) + 80) + 'px',
      marginLeft: '40px',
      borderBottomRightRadius: '10px',
      transition: this.state.transition,
      height: height,
    };

    return (
      <div style={imageStyle} onTransitionEnd={this.transitionEnd}>
        <WebRTCStreamReceiver {...this.props} />
      </div>
    );
  }
}

export default OnboardView;
